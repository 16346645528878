import * as THREE from 'three';
import { KeyboardController } from 'shared/webgl/controllers';
import { INITIAL_CAMERA } from 'shared/constants/model-settings';
import { Easing, Tween } from '@tweenjs/tween.js';
import { KeyBindingsMap } from 'shared/types/key-binding';
import { EKeyBindingsKeys } from 'shared/enums/EKeyBindingsKeys';
import {
  CAMERA_MAX_ACCELERATION,
  CAMERA_MAX_DISTANCE,
  CAMERA_MAX_FOV,
  CAMERA_MAX_RECENTER_DISTANCE,
  CAMERA_MAX_SENSITIVITY,
  CAMERA_MAX_SPEED,
  CAMERA_MIN_ACCELERATION,
  CAMERA_MIN_DISTANCE,
  CAMERA_MIN_FOV,
  CAMERA_MIN_SENSITIVITY,
  CAMERA_MIN_SPEED
} from 'shared/constants/scene-settings-constants';
import { Camera, ESnackbarStyle } from 'shared/types';
import { checkIfPointInCameraFrustum } from 'utils/scenes-utils';
import { CubeOrbitControllerScene, MainScene } from 'shared/webgl/scenes';
import { openNotification } from 'utils/notification-utils';
import { ETouchMovementType, SetupCameraControllerData } from 'shared/interfaces';
import { checkIfCommentElement, isTextInputActive } from 'utils/dom-utils';

export class CameraController {
  public cameraTarget: THREE.Vector3 = new THREE.Vector3();
  public cubeOrbitControllerScene: CubeOrbitControllerScene;

  private enabled: boolean = true;
  private sensitivity: number = 0.0085;
  private acceleration: number = 0.3;
  private speed: number = 0.075;
  private dollyThreshold: number = 1.9;
  private fov: number = CAMERA_MIN_FOV;
  private finalSensitivity: number = this.sensitivity;
  private finalAcceleration: number = this.acceleration;
  private finalSpeed: number = this.speed;
  private finalFov: number = CAMERA_MIN_FOV;
  private spherical: THREE.Spherical = new THREE.Spherical();
  private sphericalDelta: THREE.Spherical = new THREE.Spherical();
  private sphericalUltimate: THREE.Spherical = new THREE.Spherical();
  private mainScene: MainScene;
  private cameraCopy: THREE.PerspectiveCamera;
  private keyboardController: KeyboardController = new KeyboardController();
  private velocity: THREE.Vector3 = new THREE.Vector3();
  private isInFocus: boolean = false;

  private listeningBlock: HTMLElement | null | undefined;
  private toolTipBlockEl: HTMLDivElement | null | undefined;
  private tooltipTimeout: NodeJS.Timeout | null | undefined;
  private onModelCameraChangedFn: (() => void) | undefined;
  private touchEventData: {
    prevTouchPosition: THREE.Vector2;
    movement: THREE.Vector2;
    prevTouchDistance: number;
    type: ETouchMovementType;
  } = {
    prevTouchPosition: new THREE.Vector2(),
    movement: new THREE.Vector2(),
    prevTouchDistance: 0,
    type: ETouchMovementType.Rotation
  };
  private events: {
    contextmenu: (event: MouseEvent) => void;
    mouseup: (event: MouseEvent) => void;
    mousedown: (event: MouseEvent) => void;
    wheel: (event: WheelEvent) => void;
    mousemove: (event: MouseEvent) => void;
    touchmove: (event: TouchEvent) => void;
    touchstart: (event: TouchEvent) => void;
    mouseleave: (event: MouseEvent) => void;
    keyup: (event: KeyboardEvent) => void;
    keydown: (event: KeyboardEvent) => void;
  } | undefined;

  constructor(mainScene: MainScene) {
    this.mainScene = mainScene;
    this.cameraCopy = this.mainScene.camera.clone(true);
    this.cameraTarget = this.mainScene.orbitControls.target;
    this.fov = (this.mainScene.orbitControls.object as THREE.PerspectiveCamera).fov;
    this.finalFov = this.fov;

    this.cubeOrbitControllerScene = new CubeOrbitControllerScene(this.updateCamera.bind(this));

    const animate = (): void => {
      this.tick();
      requestAnimationFrame(animate);
    };

    this.setCameraPlacement(INITIAL_CAMERA);

    animate();
  }

  public setUp({
    listeningBlock,
    tooltipBlock,
    cubeOrbitControllerBlock,
    onModelCameraChangedFn
  }: SetupCameraControllerData): void {
    this.listeningBlock = listeningBlock;
    this.toolTipBlockEl = tooltipBlock;
    this.onModelCameraChangedFn = onModelCameraChangedFn;

    this.keyboardController.setOnModelCameraChangedFn(this.handleOnModelCameraChanged.bind(this));

    if (cubeOrbitControllerBlock) {
      this.cubeOrbitControllerScene.mount(cubeOrbitControllerBlock, this.mainScene.camera);
      this.cubeOrbitControllerScene.updateCamera(this.mainScene.camera, this.cameraTarget);
    }
  }

  public setKeybindingsMap(keyBindingsMap: KeyBindingsMap): void {
    this.keyboardController.clearActions();
    this.keyboardController.setKeyMap(keyBindingsMap);
  }

  public handleRecenterCamera(modelCamera: Camera = INITIAL_CAMERA): void {
    this.cameraCopy.copy(this.mainScene.camera);

    if (modelCamera.fixed) {
      this.animateRecenter(modelCamera);
      return
    }

    const onSuccess = (): void => {
      this.animateRecenter(modelCamera);
    };

    const onError = (): void => {
      openNotification(
        ESnackbarStyle.HOLD_UP,
        `The model is outside the scene boundaries! Try reducing your model's offset and scale.`
      );
    };

    const target = new THREE.Vector3(modelCamera.target.x, modelCamera.target.y, modelCamera.target.z);
    this.zoomOutToFitModelInFrustum(this.cameraCopy, target, onSuccess, onError);
  }

  private animateRecenter(modelCamera: Camera = INITIAL_CAMERA): void {
    const camera = this.mainScene.orbitControls.object as THREE.PerspectiveCamera;

    this.finalFov = this.fov;
    this.velocity.setScalar(0);
    this.spherical.set(1, 0, 0);
    this.sphericalDelta.set(1, 0, 0);
    this.cameraTarget.set(modelCamera.target.x, modelCamera.target.y, modelCamera.target.z);

    this.cameraCopy.position.set(modelCamera.position.x, modelCamera.position.y, modelCamera.position.z);
    this.cameraCopy.lookAt(modelCamera.target.x, modelCamera.target.y, modelCamera.target.z);
    const endQuaternion = this.cameraCopy.quaternion.clone();

    new Tween(camera.quaternion)
      .to(endQuaternion, 500)
      .easing(Easing.Cubic.InOut)
      .start();

    new Tween(camera.position)
      .to(modelCamera.position, 500)
      .easing(Easing.Cubic.InOut)
      .onUpdate(() => {
        this.cubeOrbitControllerScene.updateCamera(this.mainScene.camera, this.cameraTarget);
      })
      .onComplete(() => {
        this.cubeOrbitControllerScene.updateCamera(this.mainScene.camera, this.cameraTarget);
        this.handleOnModelCameraChanged();
      })
      .start();
  }

  public setCameraPlacement({ position, rotation, target }: Camera): void {
    const camera = this.mainScene.orbitControls.object;

    camera.position.set(position.x, position.y, position.z);
    camera.rotation.set(rotation.x, rotation.y, rotation.z);
    this.cameraTarget.set(target.x, target.y, target.z);

    camera.lookAt(this.cameraTarget);

    this.handleOnModelCameraChanged();
  }

  public init(): void {
    this.events = {
      contextmenu: (event: MouseEvent): void => {
        this.onContextMenu(event);
      },
      mousedown: (event: MouseEvent): void => {
        if (event.target instanceof HTMLCanvasElement && isTextInputActive()) {
          (document.activeElement as HTMLElement)?.blur();
        }
        this.onMouseDown(event);
      },
      wheel: (event: WheelEvent): void => {
        this.onMouseWheel(event);
      },
      mouseup: (event: MouseEvent): void => {
        this.onMouseUp(event);
      },
      mousemove: (event: MouseEvent): void => {
        this.onMouseMove(event);
      },
      keydown: (event: KeyboardEvent): void => {
        this.onKeyDown(event);
      },
      keyup: (event: KeyboardEvent): void => {
        this.onKeyUp(event);
      },
      touchmove: (event: TouchEvent): void => {
        this.onTouchMove(event);
      },
      touchstart: (event: TouchEvent): void => {
        this.onTouchStart(event);
      },
      mouseleave: (event: MouseEvent): void => {
        this.onMouseLeave(event);
      },
    };

    this.attachEventListeners();
  }

  public pause(): void {
    this.enabled = false;

    this.keyboardController.clearActions();
    this.cubeOrbitControllerScene.stopAnimationLoop();
  }

  public play(): void {
    this.enabled = true;

    this.cubeOrbitControllerScene.startAnimationLoop();
  }

  public remove(): void {
    this.enabled = false;

    this.removeEventListeners();
    this.cubeOrbitControllerScene.dispose();
  }

  public handleRefreshCOCSWebGL(): void {
    this.cubeOrbitControllerScene.refreshWebGL();
  }

  public moveCamera(x: number, y: number): void {
    this.velocity.x += x;
    this.velocity.y += y;
  }

  public handleOnModelCameraChanged(): void {
    this.cubeOrbitControllerScene.updateCamera(this.mainScene.camera, this.cameraTarget);

    if (this.onModelCameraChangedFn) {
      this.onModelCameraChangedFn();
    }
  }

  private onContextMenu(event: MouseEvent): void {
    event.preventDefault();
  }

  private onMouseLeave(event: MouseEvent): void {
    this.isInFocus = false;
    this.keyboardController.clearActions();
  }

  private onTouchStart(event: TouchEvent): void {
    if (event.target && checkIfCommentElement(event.target as HTMLElement)) return;

    if (event.touches.length === 2) {
      const touch1 = event.touches[0];
      const touch2 = event.touches[1];

      this.touchEventData.type = ETouchMovementType.Dolly;

      this.touchEventData.prevTouchDistance = Math.hypot(
        touch1.pageX - touch2.pageX,
        touch1.pageY - touch2.pageY
      );

      this.touchEventData.prevTouchPosition.x = (touch1.pageX + touch2.pageX) / 2;
      this.touchEventData.prevTouchPosition.y = (touch1.pageY + touch2.pageY) / 2;
    } else {
      const touch = event.touches[0];

      this.touchEventData.type = ETouchMovementType.Rotation;

      this.touchEventData.prevTouchPosition.x = touch.pageX;
      this.touchEventData.prevTouchPosition.y = touch.pageY;
    }
  }

  private onTouchMove(event: TouchEvent): void {
    if (event.target && checkIfCommentElement(event.target as HTMLElement)) return;

    event.preventDefault();

    if (event.touches.length === 2 && this.touchEventData.type === ETouchMovementType.Dolly) {
      const { camera, objectWrapper } = this.mainScene;

      const touch1 = new THREE.Vector2(event.touches[0].pageX, event.touches[0].pageY);
      const touch2 = new THREE.Vector2(event.touches[1].pageX, event.touches[1].pageY);
      const sides = touch1.clone().sub(touch2);

      const currentTouchDistance = Math.hypot(sides.x, sides.y);
      const currentTouchPosition = touch1.clone().add(touch2).divideScalar(2);
      const zoomDelta = currentTouchDistance - this.touchEventData.prevTouchDistance;
      const distance = camera.position.distanceTo(objectWrapper.position);

      this.touchEventData.movement
        .copy(currentTouchPosition)
        .sub(this.touchEventData.prevTouchPosition)
        .divideScalar(2);

      if (Math.abs(zoomDelta) > this.dollyThreshold) {
        this.dolly({
          delta: (-distance * zoomDelta) / 1000,
          absoluteDelta: true
        });
      } else {
        this.pan(event);
      }

      this.touchEventData.prevTouchDistance = currentTouchDistance;
      this.touchEventData.prevTouchPosition.copy(currentTouchPosition);
    } else if (this.touchEventData.type === ETouchMovementType.Rotation) {
      const touch = event.touches[0];

      const movementX = touch.pageX - this.touchEventData.prevTouchPosition.x;
      const movementY = touch.pageY - this.touchEventData.prevTouchPosition.y;

      this.touchEventData.movement.set(movementX, movementY);

      this.computeCameraRotation(event);

      this.touchEventData.prevTouchPosition.x = touch.pageX;
      this.touchEventData.prevTouchPosition.y = touch.pageY;
    }
  }

  private updateSensitivity(e: WheelEvent): void {
    if (!this.enabled) return;

    this.acceleration += (e.deltaY * (CAMERA_MAX_ACCELERATION - CAMERA_MIN_ACCELERATION)) / 1000;
    this.speed += (e.deltaY * (CAMERA_MAX_SPEED - CAMERA_MIN_SPEED)) / 1000;
    this.sensitivity += (e.deltaY * (CAMERA_MAX_SENSITIVITY - CAMERA_MIN_SENSITIVITY)) / 1000;

    this.acceleration =
      this.acceleration > CAMERA_MAX_ACCELERATION ? CAMERA_MAX_ACCELERATION : this.acceleration;
    this.acceleration =
      this.acceleration < CAMERA_MIN_ACCELERATION ? CAMERA_MIN_ACCELERATION : this.acceleration;

    this.speed = this.speed > CAMERA_MAX_SPEED ? CAMERA_MAX_SPEED : this.speed;
    this.speed = this.speed < CAMERA_MIN_SPEED ? CAMERA_MIN_SPEED : this.speed;

    this.sensitivity =
      this.sensitivity > CAMERA_MAX_SENSITIVITY ? CAMERA_MAX_SENSITIVITY : this.sensitivity;
    this.sensitivity =
      this.sensitivity < CAMERA_MIN_SENSITIVITY ? CAMERA_MIN_SENSITIVITY : this.sensitivity;

    this.updateTooltip();
  }

  private animateSensitivity(): void {
    if (this.keyboardController.actions.fastSpeed) {
      this.finalSpeed = this.speed * 2;
      this.finalSensitivity = this.sensitivity * 2;
      this.finalAcceleration = this.acceleration * 2;
    } else {
      this.finalSpeed = this.speed / 2;
      this.finalSensitivity = this.sensitivity / 2;
      this.finalAcceleration = this.acceleration / 2;
    }
  }

  private updateTooltip(): void {
    if (!this.enabled || !this.toolTipBlockEl) return;

    this.toolTipBlockEl.style.pointerEvents = 'none';
    this.toolTipBlockEl.style.display = 'flex';
    this.toolTipBlockEl.children[0].children[0].textContent = `Movement speed: ${this.speed.toFixed(
      2
    )}`;
    this.toolTipBlockEl.children[0].children[1].textContent = `Rotation speed: ${this.sensitivity.toFixed(
      2
    )}`;

    if (this.tooltipTimeout) clearTimeout(this.tooltipTimeout);

    this.tooltipTimeout = setTimeout((): void => {
      this.toolTipBlockEl!.style.display = 'none';
    }, 2000);
  }

  private onMouseUp(event: MouseEvent): void {
    event.preventDefault();

    if (!this.enabled) return;

    document.body.style.cursor = 'default';
    this.keyboardController.onButtonUp(event);
  }

  private onMouseDown(event: MouseEvent): void {
    if (event.target && checkIfCommentElement(event.target as HTMLElement)) return;

    event.preventDefault();

    if (!this.enabled) return;

    document.body.style.cursor = 'grabbing';
    this.keyboardController.onButtonDown(event);
  }

  private onMouseMove(event: MouseEvent): void {
    if (!this.enabled) return;

    this.isInFocus = event.target ? !checkIfCommentElement(event.target as HTMLElement) : true;

    if (this.keyboardController.actions[EKeyBindingsKeys.rotate]) {
      this.computeCameraRotation(event);
    }

    if (this.keyboardController.actions[EKeyBindingsKeys.pan]) {
      this.pan(event);
    }

    if (this.keyboardController.actions[EKeyBindingsKeys.quickZoom]) {
      this.quickZoom(event);
    }
  }

  private onMouseWheel(e: WheelEvent): void {
    if (!this.enabled || !this.isInFocus) return;

    e.preventDefault();

    if (this.keyboardController.actions.speedControl) {
      this.updateSensitivity(e);
    } else {
      this.dolly({ wheelEvent: e });
    }
  }

  private onKeyDown(event: KeyboardEvent): void {
    if (!this.enabled || !this.isInFocus) return;

    event.preventDefault();
    this.keyboardController.onButtonDown(event);
  }

  private onKeyUp(event: KeyboardEvent): void {
    if (!this.enabled) return;

    this.keyboardController.onButtonUp(event);
  }

  private dolly(options: {
    wheelEvent?: WheelEvent;
    delta?: number;
    absoluteDelta?: boolean;
  }): void {
    if (!this.enabled) return;

    const delta = !options.wheelEvent
      ? options.delta
        ? options.absoluteDelta
          ? options.delta
          : Math.sign(options.delta)
        : 0
      : Math.sign(options.wheelEvent.deltaY);

    if (delta === 0) return;

    const sign = options.wheelEvent
      ? Math.sign(options.wheelEvent.deltaY)
      : options.delta
      ? Math.sign(options.delta)
      : 0;
    const ratio = !!options.wheelEvent ? 0.5 : !!options.absoluteDelta ? 0.8 : 1;

    this.velocity.z = this.finalAcceleration * ratio * sign;

    this.updateSpherical();
    this.handleOnModelCameraChanged();
  }

  private updateCameraPosition(): void {
    const prevVelocityZ = this.velocity.z;

    switch (true) {
      case this.keyboardController.actions[EKeyBindingsKeys.left] &&
        !this.keyboardController.actions[EKeyBindingsKeys.right]:
        this.velocity.x -= this.finalAcceleration;
        break;

      case this.keyboardController.actions[EKeyBindingsKeys.right] &&
        !this.keyboardController.actions[EKeyBindingsKeys.left]:
        this.velocity.x += this.finalAcceleration;
        break;

      default:
        this.velocity.x += (0 - this.velocity.x) / 5;
        break;
    }

    switch (true) {
      case this.keyboardController.actions[EKeyBindingsKeys.back] &&
        !this.keyboardController.actions[EKeyBindingsKeys.forward]:
        this.velocity.z += this.finalAcceleration;
        break;

      case this.keyboardController.actions[EKeyBindingsKeys.forward] &&
        !this.keyboardController.actions[EKeyBindingsKeys.back]:
        this.velocity.z -= this.finalAcceleration;
        break;

      default:
        this.velocity.z += (0 - this.velocity.z) / 5;
        break;
    }

    switch (true) {
      case this.keyboardController.actions[EKeyBindingsKeys.down] &&
        !this.keyboardController.actions[EKeyBindingsKeys.up]:
        this.velocity.y -= this.finalAcceleration;
        break;

      case this.keyboardController.actions[EKeyBindingsKeys.up] &&
        !this.keyboardController.actions[EKeyBindingsKeys.down]:
        this.velocity.y += this.finalAcceleration;
        break;

      default:
        this.velocity.y += (0 - this.velocity.y) / 5;
        break;
    }

    this.velocity.x = Math.max(-this.finalSpeed, Math.min(this.finalSpeed, this.velocity.x));
    this.velocity.y = Math.max(-this.finalSpeed, Math.min(this.finalSpeed, this.velocity.y));

    if (prevVelocityZ !== this.velocity.z) {
      this.updateSpherical();
    }
  }

  private animateCameraPosition(): void {
    const camera = this.mainScene.orbitControls.object as THREE.PerspectiveCamera;

    const xAxis = new THREE.Vector3(1, 0, 0).applyQuaternion(camera.quaternion);
    const yAxis = new THREE.Vector3(0, 1, 0).applyQuaternion(camera.quaternion);
    const zAxis = new THREE.Vector3(0, 0, 1).applyQuaternion(camera.quaternion);

    const deltaVelocity = this.velocity.clone().multiplyScalar(1 / 4);
    this.velocity.sub(deltaVelocity);

    camera.position.add(xAxis.multiplyScalar(deltaVelocity.x));
    camera.position.add(yAxis.multiplyScalar(deltaVelocity.y));
    camera.position.add(zAxis.multiplyScalar(deltaVelocity.z));

    this.updateCameraTarget(deltaVelocity.x, deltaVelocity.y);
  }

  private computeCameraRotation(event: MouseEvent | TouchEvent): void {
    const movementX =
      event instanceof MouseEvent ? event.movementX : this.touchEventData.movement.x;
    const movementY =
      event instanceof MouseEvent ? event.movementY : this.touchEventData.movement.y;

    const rotationX = movementX * this.finalSensitivity;
    const rotationY = movementY * this.finalSensitivity;

    this.sphericalDelta.theta -= rotationX;
    this.sphericalDelta.phi -= rotationY;

    this.updateSpherical();
    this.handleOnModelCameraChanged();
  }

  private updateSpherical(cocsSpherical?: THREE.Spherical): void {
    const camera = this.mainScene.orbitControls.object as THREE.PerspectiveCamera;
    const offset = new THREE.Vector3();
    const quaternion = new THREE.Quaternion().setFromUnitVectors(
      camera.up,
      new THREE.Vector3(0, 1, 0)
    );
    const quaternionInverse = quaternion.invert();

    offset.copy(camera.position).sub(this.cameraTarget);
    offset.applyQuaternion(quaternion);

    this.spherical.setFromVector3(offset);

    if (cocsSpherical) {
      this.spherical.theta = cocsSpherical.theta;
      this.spherical.phi = cocsSpherical.phi;
    } else {
      this.spherical.theta += this.sphericalDelta.theta;
      this.spherical.phi += this.sphericalDelta.phi;
    }

    this.spherical.makeSafe();

    this.spherical.radius += this.velocity.z * 0.1;
    this.spherical.radius = Math.max(
      CAMERA_MIN_DISTANCE,
      Math.min(CAMERA_MAX_DISTANCE, this.spherical.radius)
    );

    this.spherical.makeSafe();

    offset.setFromSpherical(this.spherical);
    offset.applyQuaternion(quaternionInverse);

    camera.position.copy(this.cameraTarget).add(offset);
    camera.lookAt(this.cameraTarget);
    camera.updateProjectionMatrix();

    this.sphericalDelta.set(0, 0, 0);
  }

  private pan(event: MouseEvent | TouchEvent): void {
    if (!this.enabled || !this.mainScene.canvas) return;

    const camera = this.mainScene.orbitControls.object;

    const movementX =
      event instanceof MouseEvent ? event.movementX : this.touchEventData.movement.x;
    const movementY =
      event instanceof MouseEvent ? event.movementY : this.touchEventData.movement.y;

    const xAxis = new THREE.Vector3(1, 0, 0).applyQuaternion(camera.quaternion);
    const yAxis = new THREE.Vector3(0, 1, 0).applyQuaternion(camera.quaternion);

    const center = new THREE.Vector3();
    const distance = camera.position.distanceTo(center);
    const scaledSensitivity = this.finalSensitivity * distance;

    const normalizedMovementX = movementX / this.mainScene.canvas.width;
    const normalizedMovementY = movementY / this.mainScene.canvas.height;

    this.velocity.x += -normalizedMovementX * scaledSensitivity * 150;
    this.velocity.y += normalizedMovementY * scaledSensitivity * 150;

    camera.position.add(xAxis.multiplyScalar(this.velocity.x));
    camera.position.add(yAxis.multiplyScalar(this.velocity.y));

    this.updateCameraTarget(this.velocity.x, this.velocity.y);
    this.handleOnModelCameraChanged();
  }

  private quickZoom(event: MouseEvent): void {
    const movementY = event.movementY;

    this.velocity.z = this.finalAcceleration * movementY * 5;

    this.updateSpherical();
    this.handleOnModelCameraChanged();
  }

  private updateFov(): void {
    if (!this.enabled) return;

    const camera = this.mainScene.orbitControls.object as THREE.PerspectiveCamera;

    if (
      this.keyboardController.actions.increaseFOV &&
      !this.keyboardController.actions.decreaseFOV
    ) {
      this.finalFov -= 3.3 * this.finalSpeed;
    } else if (
      this.keyboardController.actions.decreaseFOV &&
      !this.keyboardController.actions.increaseFOV
    ) {
      this.finalFov += 3.3 * this.finalSpeed;
    }

    this.finalFov = Math.max(CAMERA_MIN_FOV, Math.min(CAMERA_MAX_FOV, this.finalFov));

    camera.fov += (this.finalFov - camera.fov) / 20;

    camera.updateProjectionMatrix();
  }

  private updateCameraTarget(distX: number, distY: number): void {
    const camera = this.mainScene.orbitControls.object;
    const offsetByX = new THREE.Vector3();
    const offsetByY = new THREE.Vector3();

    offsetByX.setFromMatrixColumn(camera.matrix, 0);
    offsetByY.setFromMatrixColumn(camera.matrix, 1);
    offsetByX.multiplyScalar(distX);
    offsetByY.multiplyScalar(distY);

    this.cameraTarget.add(offsetByX);
    this.cameraTarget.add(offsetByY);

    this.normalizeCameraTargetPosition();
  }

  private normalizeCameraTargetPosition(): void {
    const maxDist = 1000;
    const camera = this.mainScene.orbitControls.object;
    const distance = camera.position.distanceTo(this.mainScene.objectWrapper.position);

    if (distance > maxDist) {
      const direction = new THREE.Vector3()
        .subVectors(camera.position, this.mainScene.objectWrapper.position)
        .normalize();
      camera.position.copy(
        direction.multiplyScalar(maxDist).add(this.mainScene.objectWrapper.position)
      );
    }
  }

  private zoomOutToFitModelInFrustum(
    camera: THREE.PerspectiveCamera,
    target: THREE.Vector3,
    onSuccess: () => void,
    onError: () => void
  ): void {
    const directionFactor = new THREE.Vector3()
      .subVectors(camera.position, target)
      .multiplyScalar(0.1);
    const boundingBox = this.mainScene.modelBoundingBox;
    const points = [
      new THREE.Vector3(boundingBox.min.x, boundingBox.min.y, boundingBox.min.z),
      new THREE.Vector3(boundingBox.min.x, boundingBox.min.y, boundingBox.max.z),
      new THREE.Vector3(boundingBox.min.x, boundingBox.max.y, boundingBox.min.z),
      new THREE.Vector3(boundingBox.max.x, boundingBox.min.y, boundingBox.min.z),
      new THREE.Vector3(boundingBox.min.x, boundingBox.max.y, boundingBox.max.z),
      new THREE.Vector3(boundingBox.max.x, boundingBox.max.y, boundingBox.min.z),
      new THREE.Vector3(boundingBox.max.x, boundingBox.min.y, boundingBox.max.z),
      new THREE.Vector3(boundingBox.max.x, boundingBox.max.y, boundingBox.max.z)
    ];

    const handleCheckAndZoomOut = (): void => {
      const dist = camera.position.distanceTo(target);
      const isModelVisible = points.every((point): boolean =>
        checkIfPointInCameraFrustum(point, camera)
      );

      if (!isModelVisible && dist <= CAMERA_MAX_RECENTER_DISTANCE) {
        camera.position.add(directionFactor);
        handleCheckAndZoomOut();
      } else if (!isModelVisible) {
        onError();
      } else if (isModelVisible) {
        onSuccess();
      }
    };

    handleCheckAndZoomOut();
  }

  private updateCamera(cameraFrom: THREE.Camera): void {
    this.sphericalUltimate.setFromVector3(cameraFrom.position);

    this.updateSpherical(this.sphericalUltimate);
  }

  private tick(): void {
    if (!this.enabled) return;

    this.animateSensitivity();
    this.animateCameraPosition();
    this.updateCameraPosition();
    this.updateFov();
  }

  private attachEventListeners(): void {
    if (!this.events || !this.listeningBlock) return;

    this.listeningBlock.addEventListener('contextmenu', this.events.contextmenu.bind(this));
    this.listeningBlock.addEventListener('mousedown', this.events.mousedown.bind(this));
    this.listeningBlock.addEventListener('wheel', this.events.wheel.bind(this));
    this.listeningBlock.addEventListener('mousemove', this.events.mousemove.bind(this));
    this.listeningBlock.addEventListener('touchmove', this.events.touchmove.bind(this));
    this.listeningBlock.addEventListener('touchstart', this.events.touchstart.bind(this));
    this.listeningBlock.addEventListener('mouseleave', this.events.mouseleave.bind(this));
    window.addEventListener('mouseup', this.events.mouseup.bind(this));
    window.addEventListener('keydown', this.events.keydown.bind(this));
    window.addEventListener('keyup', this.events.keyup.bind(this));
  }

  private removeEventListeners(): void {
    if (!this.events || !this.listeningBlock) return;

    this.listeningBlock.removeEventListener('contextmenu', this.events.contextmenu.bind(this));
    this.listeningBlock.removeEventListener('mousedown', this.events.mousedown.bind(this));
    this.listeningBlock.removeEventListener('wheel', this.events.wheel.bind(this));
    this.listeningBlock.removeEventListener('mousemove', this.events.mousemove.bind(this));
    this.listeningBlock.removeEventListener('touchmove', this.events.touchmove.bind(this));
    this.listeningBlock.removeEventListener('touchstart', this.events.touchstart.bind(this));
    this.listeningBlock.removeEventListener('mouseleave', this.events.mouseleave.bind(this));
    window.removeEventListener('mouseup', this.events.mouseup.bind(this));
    window.removeEventListener('keydown', this.events.keydown.bind(this));
    window.removeEventListener('keyup', this.events.keyup.bind(this));

    this.keyboardController.clearActions();
  }
}
