import React from 'react';
import * as Styled from './styles';
import { useAppDispatch } from 'shared/hooks';
import { closeConfirmModal } from 'services/store/reducers/modalReducer';
import { ESnackbarStyle, PlanFeature, SubscriptionProduct } from 'shared/types';
import { openNotification } from 'utils/notification-utils';

type Props = {
  currentPlan: SubscriptionProduct;
  selectedPlan: SubscriptionProduct;
  handleSelectPlanAction: (selectedPlan: SubscriptionProduct) => Promise<void>;
};

const ModalDowngradePlan: React.FC<Props> = ({
  currentPlan,
  selectedPlan,
  handleSelectPlanAction
}): JSX.Element => {
  const dispatch = useAppDispatch();

  const handleDowngradeButtonClick = async (): Promise<void> => {
    try {
      await handleSelectPlanAction(selectedPlan);
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    }
  };

  const handleCancelButtonClick = (): void => {
    dispatch(closeConfirmModal());
  };

  const FEATURES = currentPlan.metadata.product_features.reduce(
    (acc: PlanFeature[], currentFeature): PlanFeature[] => {
      const isFeatureDisabled = !selectedPlan.metadata.product_features.find(
        (selectedFeature): boolean => selectedFeature.id === currentFeature.id
      )?.enabled;
      return currentFeature.enabled && isFeatureDisabled ? [...acc, currentFeature] : acc;
    },
    []
  );

  return (
    <Styled.ModalWindowContainer>
      <Styled.ContentText>
        If you choose to downgrade, you will lose access to:
      </Styled.ContentText>
      <Styled.FeaturesBlock>
        {FEATURES.map(
          ({ title, id }): JSX.Element => (
            <Styled.FeatureItem key={id}>{title}</Styled.FeatureItem>
          )
        )}
      </Styled.FeaturesBlock>
      <Styled.ContentText>
        Are you sure you wish to downgrade your account?
      </Styled.ContentText>
      <Styled.ActionButtons>
        <Styled.ActionButton className='downgrade-button' onClick={handleDowngradeButtonClick}>
          Downgrade
        </Styled.ActionButton>
        <Styled.ActionButton className='cancel-button' onClick={handleCancelButtonClick}>
          Cancel
        </Styled.ActionButton>
      </Styled.ActionButtons>
    </Styled.ModalWindowContainer>
  );
};

export default ModalDowngradePlan;
