import React, { useCallback, useEffect } from 'react';
import { useLocation } from 'react-router';
import * as Styled from './styles';
import {
  Footer,
  ModalCreateAccount,
  ModalLogin,
  ModelUploader,
  ModelViewer,
  NavigationPrevent,
  PreviewViewerController
} from 'shared/components';
import {
  ArModeState,
  AuthState,
  EModelType,
  ModelWithMetadata,
  SampleModel,
  ViewerDataState
} from 'shared/types';
import { useAppDispatch, useAppSelector, useURLParamsHandling } from 'shared/hooks';
import { setTotalLoadingSteps, stopLoader } from 'services/store/reducers/loaderReducer';
import {
  clearViewerDataState,
  setIsModelLoading,
  setIsViewerPage,
  setModel,
  setModelFileType,
  setModelMtls,
  stopZenMode
} from 'services/store/reducers/viewerDataReducer';
import { SeoSection } from './components';
import { setIsPageWithScene } from 'services/store/reducers/sidebarReducer';
import { setIsViewerClosed } from 'utils/storage-utils';
import { clearModelFeatureAccess } from 'services/store/reducers/modelFeatureAccessReducer';
import { setInitialPermissionsState } from 'services/store/reducers/modelPermissionsReducer';
import { showModal } from 'services/store/reducers/modalReducer';
import { ModelParserService } from 'services/strategy-services';
import { createNewModelObject } from 'utils/model-utils';
import { E3DModelFileTypes } from 'shared/enums/E3DModelFileTypes';
import { setIsBrandingLoading } from 'services/store/reducers/brandingReducer';

const HomePage = (): React.ReactElement => {
  useURLParamsHandling();
  const location = useLocation();
  const dispatch = useAppDispatch();
  const params = new URLSearchParams(location.search);
  const isArModeLink = !!params.get('ar');
  const sampleModelName = params.get('sample-model') as SampleModel | null;
  const { isAuth } = useAppSelector((store): AuthState => store.auth);
  const { isArMode } = useAppSelector((store): ArModeState => store.arMode);
  const { model, modelFileType, modelMtls, isZenMode, isEmbeddedModelMode } = useAppSelector(
    (store): ViewerDataState => store.viewerData
  );  

  useEffect((): void => {
    const LOGIN_PATHS = ['/log-in', '/login', '/sign-in', '/signin'];
    const SIGNUP_PATHS = ['/signup', '/sign-up', '/register'];
    const isLoginPath = LOGIN_PATHS.some((path): boolean => path === location.pathname);
    const isSignupPath = SIGNUP_PATHS.some((path): boolean => path === location.pathname);

    if (isLoginPath && !isAuth) dispatch(showModal(<ModalLogin />));
    if (isSignupPath && !isAuth) dispatch(showModal(<ModalCreateAccount />));
  }, [dispatch, isAuth, location]);

  useEffect((): (() => void) => {
    return (): void => {
      setIsViewerClosed(true);
      dispatch(stopLoader());
      dispatch(clearViewerDataState());
      dispatch(clearModelFeatureAccess());
      dispatch(setIsPageWithScene(false));
      dispatch(setIsModelLoading(false));
      dispatch(setIsViewerPage(false));
      dispatch(setInitialPermissionsState());
      dispatch(stopZenMode());
    };
  }, [dispatch]);


  const loadSampleModel = useCallback(
    async (sampleName: SampleModel): Promise<void> => {
      dispatch(setTotalLoadingSteps(1));
      const publicUrl = `${process.env.REACT_APP_URL}/resources/samples/${sampleName}/${sampleName}.glb`;
      const modelMetadata = await new ModelParserService();
      await modelMetadata.parse('glb', { publicUrl });
      const modelStats = modelMetadata.modelStats();
      const model: ModelWithMetadata = createNewModelObject(
        sampleName,
        modelStats.fileSize.totalSize || 1,
        publicUrl,
        EModelType.SAMPLE
      );
      model.metadata = { ...model.metadata, ...modelStats };
      dispatch(setModelFileType(E3DModelFileTypes.gltf));
      dispatch(setModelMtls([]));
      await dispatch(setModel({ model }));
    },
    [dispatch]
  );

  useEffect((): void => {
    (async (): Promise<void> => {
      const isSampleModel = Object.values(SampleModel).some(
        (name): boolean => name === sampleModelName
      );
      if (!!sampleModelName && isSampleModel) {
        dispatch(setIsBrandingLoading(false));
        await loadSampleModel(sampleModelName);
      }
    })();
  }, [dispatch, loadSampleModel, sampleModelName]);

  return (
    <NavigationPrevent>
      <Styled.HomePageContainer
        $isModelLoaded={!!model}
        $isArMode={isArMode}
        $isZenMode={isZenMode}
        $isEmbeddedModel={isEmbeddedModelMode}
      >
        {!!model && !!modelFileType ? (
          <ModelViewer
            model={model}
            modelFileType={modelFileType}
            modelMtls={modelMtls}
            isArModeClickAction={isArModeLink}
            isUploadMode
          />
        ) : (
          <>
            {!isEmbeddedModelMode && (
              <>
                <ModelUploader loadSampleModel={loadSampleModel} />
                <PreviewViewerController />
              </>
            )}
          </>
        )}
      </Styled.HomePageContainer>
      {!model && !isEmbeddedModelMode && (
        <>
          <SeoSection />
          <Footer />
        </>
      )}
    </NavigationPrevent>
  );
};

export default HomePage;
