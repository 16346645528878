import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';

export const TreeNode = styled.div<{ $level: number; $hasChildren: boolean; $isFocusable: boolean }>`
  margin-left: ${({ $level }) => $level * 20}px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  user-select: none;
  border-radius: 4px;
  transition: color 0.2s ease, background-color 0.2s ease;
  color: ${COLORS.white};
  position: relative;
  cursor: ${({ $isFocusable, $hasChildren }) => ($isFocusable || $hasChildren ? 'pointer' : 'default')};
  
  ${({ $level }) => $level > 0 && `
    &::before {
      content: '';
      position: absolute;
      left: -5px;
      top: 0;
      bottom: 0;
      width: 1px;
      background-color: ${COLORS.white};
      opacity: 0.3;
    }
  `}

  ${({ $isFocusable, $hasChildren }) => {
    if ($isFocusable && !$hasChildren) {
      return `
        &:hover {
          color: var(--button-text-color);
          background-color: var(--button-background-color);
        }
      `;
    }
    else if ($isFocusable || $hasChildren) {
      return `
        &:hover:not(:has(${FocusIcon}:hover)) {
          color: var(--button-background-color);
        }
      `;
    }
    else return '';
  }}
`;

export const NodeIcon = styled.span<{ $isFocusable: boolean }>`
  margin-right: 8px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  
  svg {
    width: 100%;
    height: 100%;
  }
`;

export const QuestionIcon = styled.div`
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: ${COLORS.doveGray};
  color: ${COLORS.white};
  border-radius: 50%;
  font-weight: 500;
  font-size: 12px;
  line-height: 12px;
`;

export const CaretIcon = styled.span`
  margin-right: 8px;
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
  transition: transform 0.1s ease;
`;

export const NodeLabel = styled.span`
  font-size: 14px;
  flex: 1;
  line-height: 1.5;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding: 7px 0;
`;

export const FocusIcon = styled.span<{ $hasChildren: boolean }>`
  margin-left: 8px;
  width: 30px;
  height: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $hasChildren }) => $hasChildren ? COLORS.white : 'inherit'};
  border-radius: 4px;
  flex-shrink: 0;
  z-index: 1;
  transition: color 0.2s ease, background-color 0.2s ease;

  ${({ $hasChildren }) => $hasChildren && `
    &:hover {
      color: var(--button-text-color);
      background-color: var(--button-background-color);
    }
  `}
`;

export const ChildrenContainer = styled.div<{ $isExpanded: boolean }>`
  overflow: hidden;
  max-height: ${({ $isExpanded }) => $isExpanded ? 'fit-content' : '0'};
`; 