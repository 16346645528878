import { ScheduledMaintenancePanel } from 'shared/components/header/components/scheduled-maintenance-panel';
import { ValentinesPromotionPanel } from 'shared/components/header/components/valentines-promotion-panel';
import { FC } from 'react';

export const HEADER_HEIGHT = {
	NONE: 0,
	SMALL: 34,
	LARGE: 60,
};


const HEADER_MESSAGES = {
	NONE: undefined,
	MAINTENANCE: ScheduledMaintenancePanel,
	VALENTINES: ValentinesPromotionPanel,
} as const;

export const HEADER_MESSAGE: FC | undefined = HEADER_MESSAGES.NONE;

export const HEADER_MESSAGE_HEIGHT: number = 58;