import { COLORS } from 'shared/constants/colors';
import styled from 'styled-components';
import { DEVICE, DEVICE_SIZES } from 'shared/constants/deviceSizes';

export const ViewerOverlay = styled.div<{ $isDragActive: boolean }>`
  position: absolute;
  height: 100%;
  width: 100%;
  background: rgba(217, 217, 217, 0.8);
  z-index: 1019;
  display: ${({ $isDragActive }): string => ($isDragActive ? 'flex' : 'none')};
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const PlusIcon = styled.img`
  width: 23px;
  height: 23px;
`;

export const UploadDescription = styled.span`
  margin-top: 10px;
  font-weight: 600;
  font-size: 20px;
  line-height: 24px;
`;

export const SidebarContainer = styled.div<{ $isArMode: boolean; $isSidebarHidden: boolean }>`
  position: absolute;
  left: 0;
  width: min-content;
  z-index: ${({ $isArMode }): string => ($isArMode ? '1035' : '1010')};
  transition: all 0.5s;
  transform: ${({ $isSidebarHidden }): string =>
    $isSidebarHidden ? 'translateX(-100%)' : 'translateX(0)'};
  box-shadow: 12px 8px 12px rgba(0, 0, 0, 0.15),
              4px 4px 12px rgba(0, 0, 0, 0.1);
`;

export const ThreeJSStatsContainer = styled.div<{
  $isSharePanelActive: boolean;
  $isEmbeddedModelMode: boolean;
}>`
  position: absolute;
  top: 0;
  right: 0;
  z-index: 10;
  transform: translateX(
    ${({ $isSharePanelActive, $isEmbeddedModelMode }): string =>
      $isEmbeddedModelMode ? '0' : '-213px'}
  );
  transition: transform 0.5s;

  @media ${DEVICE.tabletLarge} {
    transform: translateX(0);
    visibility: ${({ $isSharePanelActive }): string => ($isSharePanelActive ? 'hidden' : 'visible')};
  }
`;

export const CubeOCContainer = styled.div<{ $isAuth: boolean; $isSidebarHidden: boolean, $isEmbeddedModelMode: boolean }>`
  position: absolute;
  bottom: 0;
  left: ${({ $isEmbeddedModelMode, $isSidebarHidden }): string => ($isEmbeddedModelMode ? '0px' : ($isSidebarHidden ? '0px' : '330px'))};
  z-index: 10;
  transition: all 0.5s;
  cursor: grab;
  overflow: hidden;

  ${({ $isEmbeddedModelMode, $isAuth }): string => {
    if ($isEmbeddedModelMode) {
      return '';
    } else {
      return `
        @media (max-width: ${DEVICE_SIZES.desktop + ($isAuth ? 130 : 70)}px) {
          transform: translateY(-60px);
        }

        @media ${DEVICE.tabletLarge} {
          transform: translateY(-60px);
        }
      `;
    }
  }}
`;

export const ShareModelContainer = styled.div<{
  $isSharePanelActive: boolean;
  $isEmbeddedModelMode: boolean;
  $footerHeight: number;
}>`
  position: absolute;
  right: ${({ $isEmbeddedModelMode }): string => ($isEmbeddedModelMode ? '50%' : '0')};
  transform: translateX(
    ${({ $isEmbeddedModelMode }): string => ($isEmbeddedModelMode ? '50%' : '0')}
  );
  top: ${({ $isEmbeddedModelMode }): string => ($isEmbeddedModelMode ? 'initial' : '0')};
  bottom: ${({ $isEmbeddedModelMode, $footerHeight }): string =>
    $isEmbeddedModelMode ? `${$footerHeight + 10}px` : 'initial'};
  width: 213px;
  z-index: 1015;
  display: ${({ $isSharePanelActive, $isEmbeddedModelMode }): string =>
    $isEmbeddedModelMode ? ($isSharePanelActive ? 'block' : 'none') : 'block'};
  box-shadow: 12px 8px 12px rgba(0, 0, 0, 0.15),
              4px 4px 12px rgba(0, 0, 0, 0.1);

  @media ${DEVICE.tabletLarge} {
    display: ${({ $isSharePanelActive }): string => ($isSharePanelActive ? 'block' : 'none')};
    top: initial;
    bottom: ${({ $isEmbeddedModelMode, $footerHeight }): string =>
      $isEmbeddedModelMode ? `${$footerHeight + 10}px` : '50px'};
  }
`;

export const FeaturesBlockContainer = styled.div`
  position: absolute;
  bottom: 0;
  right: 0;
  z-index: 1010;

  @media ${DEVICE.tabletLarge} {
    display: none;
  }
`;

export const ViewerFooterContainer = styled.div<{
  $isArMode: boolean;
  $isEmbeddedModelMode: boolean;
}>`
  position: absolute;
  width: ${({ $isEmbeddedModelMode }): string => ($isEmbeddedModelMode ? '100%' : 'initial')};
  bottom: ${({ $isEmbeddedModelMode }): string => ($isEmbeddedModelMode ? '8px' : '0')};
  left: ${({ $isEmbeddedModelMode }): string => ($isEmbeddedModelMode ? 'initial' : '50%')};
  transform: ${({ $isEmbeddedModelMode }): string =>
    $isEmbeddedModelMode ? 'initial' : 'translate(-50%, 0)'};
  display: flex;
  justify-content: center;
  z-index: ${({ $isEmbeddedModelMode }): string => ($isEmbeddedModelMode ? '5' : '1050')};

  @media ${DEVICE.tabletLarge} {
    transform: translate(
      ${({ $isEmbeddedModelMode }): string => ($isEmbeddedModelMode ? '0' : '-50%, 0')}
    );
  }
`;

export const CommentsBarContainer = styled.div`
  @media${DEVICE.tabletLarge} {
    display: none;
  }
`;

export const AppLogo = styled.div`
  position: absolute;
  z-index: 1;
  top: 30px;
  right: 30px;

  img {
    max-width: 75px;
    max-height: 50px;
    object-fit: contain;
  }
`;

export const SaveModelBlock = styled.section`
  position: absolute;
  right: 0px;
  display: flex;
  flex-direction: column;
  z-index: 1;
  background: rgba(83, 83, 83, 0.4);
  backdrop-filter: blur(6px);
  -webkit-backdrop-filter: blur(6px);
  padding: 15px;
`;

export const SaveModelLabel = styled.div`
  display: flex;
  font-weight: 400;
  font-size: 14px;
  line-height: 15px;
  color: ${COLORS.white};
  padding-bottom: 15px;
  padding-left: 2px;
`;

export const QuickViewHint = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  margin-left: 8px;
  width: 16px;
  height: 16px;
  border-radius: 50%;
  background: rgba(255, 255, 255, 0.51);
  font-size: 12px;
  color: ${COLORS.white};
  text-decoration: none;
`;

export const QuickViewModeMessage = styled.div`
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
  text-align: center;
  color: ${COLORS.white};

  & > .highlighted-text {
    color: ${COLORS.turbo};
  }
`;

export const LogoContainer = styled.a`
  position: absolute;
  top: 8px;
  left: 8px;
  display: flex;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.7);
  backdrop-filter: blur(8.5px);
  padding: 4px 8px;
  border-radius: 4px;
  z-index: 1;
  text-decoration: none;
  cursor: pointer;
  color: ${COLORS.white};

  &:hover {
    color: var(--theme-secondary-color);
  }
`;

export const EmbeddedLogoImage = styled.img`
  max-width: 28px;
  max-height: 20px;
  object-fit: contain;
`;

export const PoweredByText = styled.span`
  margin-left: 8px;
  font-size: 12px;
  white-space: nowrap;
  user-select: none;
  margin-top: 1px;
`;

export const EmbeddedModelAppLogo = styled.a`
  display: flex;
  align-items: center;
`;

export const EmbeddedModelLink = styled.a`
  position: absolute;
  right: 8px;
  top: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  background: rgba(0, 0, 0, 0.7);
  border-radius: 4px;
  z-index: 1;
  cursor: pointer;
  color: ${COLORS.white};

  svg {
    width: 18px;
    height: 18px;
    margin-bottom: 1px;
  }

  &:hover {
    color: var(--theme-secondary-color);
  }
`;
