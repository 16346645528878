import React, { useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import FastClick from 'fastclick';
import { useAppDispatch, useAppSelector, useBrowserStore } from 'shared/hooks';
import * as Styled from './styles';
import { closeSidebar } from 'services/store/reducers/sidebarReducer';
import { SidebarItem } from 'shared/types';
import { ModalLogout } from 'shared/components';
import { showConfirmModal } from 'services/store/reducers/modalReducer';
import { convertStringToButtonId } from 'utils/form-utils';
import { RootState } from 'services/store';
import moment from 'moment';
import {
  AdminSettingsIcon,
  CreateTeamIcon,
  HistoryIcon,
  HomeIcon,
  LogoutIcon,
  MyModelsIcon,
  SettingsIcon,
  TeamIcon,
  SharedWithMeIcon
} from 'assets/dynamic-icons';
import { clearViewerDataState } from 'services/store/reducers/viewerDataReducer';
import { clearModelLocalStorage } from 'utils/storage-utils';
import { CustomTooltip } from 'shared/components';
import { DEVICE_SIZES } from 'shared/constants/deviceSizes';

const Sidebar = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const store = useAppSelector((store): RootState => store);
  const { isSidebarOpened, isPageWithScene } = store.sidebar;
  const { isMasterAdmin, isAuth } = store.auth;
  const { teams } = store.teams;
  const { removeFilesFromBrowserStore } = useBrowserStore();

  const closeSidebarMenu = (): void => {
    dispatch(closeSidebar());
  };

  useEffect(() => {
    const handleClick = () => {
      if (isSidebarOpened) {
        closeSidebarMenu();
      }
    };

    document.addEventListener('click', handleClick);
    return () => document.removeEventListener('click', handleClick);
  }, [isSidebarOpened]);

  const logOut = (): void => {
    dispatch(showConfirmModal({ header: null, content: <ModalLogout /> }));
  };

  const getSidebarItem = ({ title, icon: Icon, link, isTeam, clickAction }: SidebarItem): React.ReactElement => {
    const maxWidth = window.innerWidth <= DEVICE_SIZES.tabletLarge ? 216 : 160;

    const sidebarItemContent = (
      <Styled.SidebarItem onClick={clickAction}>
        <CustomTooltip content={title} maxWidth={maxWidth}>
            <Styled.SidebarItemContainer id={!isTeam ? convertStringToButtonId(title) : undefined}>
              <Styled.SidebarItemIcon>
                <Icon />
            </Styled.SidebarItemIcon>
            <Styled.SidebarItemTitle $isPageWithScene={isPageWithScene}>
              {title}
            </Styled.SidebarItemTitle>
          </Styled.SidebarItemContainer>
        </CustomTooltip>
      </Styled.SidebarItem>
    );

    if (link) {
      return (
        <NavLink
          isActive={(match): boolean => !!match?.isExact}
          activeClassName='active'
          to={link}
          onClick={() => {
            clickAction?.();
            if (window.location.pathname === link) window.location.reload(); 
            // TODO: Instead of reloading, navigate to the main "tab" of the opened workspace
          }}
        >
          {sidebarItemContent}
        </NavLink>
      );
    }

    return sidebarItemContent;
  };

  const TEAMS_ITEMS = teams.map(
    ({ id, teamName }): SidebarItem => ({
      title: teamName,
      link: `/workspace/${id}`,
      icon: TeamIcon,
      clickAction: closeSidebarMenu,
      isTeam: true
    })
  );

  const DEFAULT_SIDEBAR_ITEMS: SidebarItem[][] = [
    [
      {
        title: 'Home',
        link: '/',
        icon: HomeIcon,
        clickAction: (): void => {
          dispatch(clearViewerDataState());
          removeFilesFromBrowserStore();
          clearModelLocalStorage();
          closeSidebarMenu();
        }
      }
    ],
    [
      {
        title: 'My workspace',
        link: '/my-workspace',
        icon: MyModelsIcon,
        clickAction: closeSidebarMenu
      },
      ...TEAMS_ITEMS,
      {
        title: 'Create Team Space',
        link: '/create-team',
        icon: CreateTeamIcon,
        clickAction: closeSidebarMenu
      }
    ],
    [
      {
        title: 'Recents',
        link: '/recents',
        icon: HistoryIcon,
        clickAction: closeSidebarMenu
      },
      {
        title: 'Shared with me',
        link: '/shared-with-me',
        icon: SharedWithMeIcon,
        clickAction: closeSidebarMenu
      }
    ],
    [
      {
        title: 'Account settings',
        link: '/account-settings',
        icon: SettingsIcon,
        clickAction: closeSidebarMenu
      }
    ],
    [
      {
        title: 'Log Out',
        icon: LogoutIcon,
        clickAction: logOut
      }
    ]
  ];

  const adminPanelItem: SidebarItem[] = [
    {
      title: 'Admin Panel',
      link: '/admin-panel',
      icon: AdminSettingsIcon,
      clickAction: closeSidebarMenu
    }
  ];

  const sidebarItems = isMasterAdmin
    ? [
        ...DEFAULT_SIDEBAR_ITEMS.slice(0, -1),
        adminPanelItem,
        DEFAULT_SIDEBAR_ITEMS[DEFAULT_SIDEBAR_ITEMS.length - 1]
      ]
    : DEFAULT_SIDEBAR_ITEMS;

  return (
    <>
      <Styled.SidebarContainer $isSidebarOpened={isSidebarOpened} $isPageWithScene={isPageWithScene}>
        <Styled.SidebarContent
          ref={(element): void => {
            if (element) {
              // @ts-ignore
              FastClick.attach(element);
            }
          }}
        >
          <Styled.SidebarItems>
            {sidebarItems.map(
              (item, index): React.ReactElement => (
                <Styled.SidebarMenuBlock key={`menu-block-${index}`}>
                  {item.map(
                    (sidebarItem): React.ReactElement => (
                      <React.Fragment key={`sidebar-item-${sidebarItem.title}`}>
                        {getSidebarItem(sidebarItem)}
                      </React.Fragment>
                    )
                  )}
                </Styled.SidebarMenuBlock>
              )
            )}
          </Styled.SidebarItems>
          
          <Styled.FooterContainer
            $isPageWithScene={isPageWithScene}
          >
            <Styled.FooterMenu $isPageWithScene={isPageWithScene}>
              <div className="footer-row">
                <a href={`${process.env.REACT_APP_URL}/blog${isAuth ? '?auth=1' : ''}`} rel='noreferrer' id='footer-blog-button'>
                  Blog
                </a>
                <Styled.Dot />
                <NavLink to='/pricing' id='footer-pricing-button'>
                  Pricing
                </NavLink>
                <Styled.Dot />
                <NavLink to='/contact-us' id='footer-support-button'>
                  Support
                </NavLink>
              </div>
              <div className="footer-row">
                <a
                  href={`${process.env.REACT_APP_URL}/privacy-policy${isAuth ? '?auth=1' : ''}`}
                  target='_blank'
                  rel='noreferrer'
                  id='footer-privacy-policy-button'
                >
                  Privacy Policy
                </a>
                <Styled.Dot />
                <a
                  href={`${process.env.REACT_APP_URL}/terms-of-use${isAuth ? '?auth=1' : ''}`}
                  target='_blank'
                  rel='noreferrer'
                  id='footer-terms-button'
                >
                  Terms of Use
                </a>
              </div>
            </Styled.FooterMenu>
            <Styled.FooterCopyright $isPageWithScene={isPageWithScene}>
              <div className="full-copyright">
                <span>Copyright © 2022-{moment().year()} glbee</span>
                <span>instant 3D model sharing</span>
              </div>
              <div className="mini-copyright">
                © glbee
              </div>
            </Styled.FooterCopyright>
          </Styled.FooterContainer>
        </Styled.SidebarContent>
      </Styled.SidebarContainer>
      <Styled.Overlay $isSidebarOpened={isSidebarOpened} onClick={closeSidebarMenu} />
    </>
  );
};

export default Sidebar;
