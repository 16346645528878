import React from 'react';
import * as Styled from './styles';
import { useAppDispatch, useAppSelector, useBrowserStore } from 'shared/hooks';
import { openSidebar } from 'services/store/reducers/sidebarReducer';
import {
  ModalLogin,
  ModalQuickGuide,
  ModalCreateAccount,
  ProjectLogo
} from 'shared/components';
import { showModal } from 'services/store/reducers/modalReducer';
import { useHistory } from 'react-router-dom';
import { clearViewerDataState } from 'services/store/reducers/viewerDataReducer';
import {
  BurgerMenuIcon,
  NewModelIcon,
  QuickViewIcon,
} from 'assets/dynamic-icons';
import Logo from 'assets/images/logo.png';
import { clearModelLocalStorage } from 'utils/storage-utils';
import { RootState } from 'services/store';
import { CustomTooltip } from 'shared/components';
import { convertStringToButtonId } from 'utils/form-utils';
import { HEADER_MESSAGE } from 'shared/constants/header';
import { TOOLTIPS } from 'shared/constants/tooltips';

const Header = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const history = useHistory();
  const store = useAppSelector((state): RootState => state);
  const { isAuth } = store.auth;
  const { brandColors, brandLogoUrl } = store.branding.activeBranding;
  const { removeFilesFromBrowserStore } = useBrowserStore();
  const { headerTextColor } = brandColors;

  const handleLogoClick = (): void => {
    dispatch(clearViewerDataState());
    removeFilesFromBrowserStore();
    clearModelLocalStorage();
    history.push('/');
  };

  const handleSidebarButtonClick = (): void => {
    dispatch(openSidebar());
  };

  const handleLoginButtonClick = (): void => {
    dispatch(showModal(<ModalLogin />));
  };

  const handleQuickGuideClick = (): void => {
    dispatch(showModal(<ModalQuickGuide />));
  };

  const handleUpgradeButtonClick = (): void => {
    history.push('/pricing');
  };

  const handleQuickViewClick = (): void => {
    history.push('/quick-view');
  };

  const handleUploadClick = (): void => {
    history.push('/upload');
  };

  const handleSignupButtonClick = (): void => {
    dispatch(showModal(<ModalCreateAccount />));
  };

  return (
    <Styled.HeaderContainer>
      {HEADER_MESSAGE && <HEADER_MESSAGE />}
      <Styled.HeaderContent $backgroundColor={headerTextColor}>
        {isAuth && (
          <Styled.MenuButton onClick={handleSidebarButtonClick}>
            <BurgerMenuIcon />
          </Styled.MenuButton>
        )}
        <Styled.ProjectLogoContainer $isAuth={isAuth}>
          <ProjectLogo />
        </Styled.ProjectLogoContainer>
        <Styled.LogoMobileView
          src={brandLogoUrl || Logo}
          onClick={handleLogoClick}
          $isAuth={isAuth}
          alt='glbee Free Online GLTF Viewer'
          id='app-logo-button'
        />
        <Styled.HeaderActions $isAuth={isAuth}>
          <CustomTooltip content={TOOLTIPS.QUICK_VIEW} placement='bottom'>
            <Styled.NavButtonContainer
              type='button'
              id={convertStringToButtonId('Quick View')}
              onClick={handleQuickViewClick}
              $isAuth={isAuth}
            >
              <Styled.IconWrapper><QuickViewIcon /></Styled.IconWrapper>
              <span>Quick View</span>
            </Styled.NavButtonContainer>
          </CustomTooltip>

          <CustomTooltip content={TOOLTIPS.UPLOAD} placement='bottom'>
            <Styled.NavButtonContainer
              type='button'
              id={convertStringToButtonId('Upload for Sharing')}
              onClick={handleUploadClick}
              $isAuth={isAuth}
            >
              <Styled.IconWrapper><NewModelIcon /></Styled.IconWrapper>
              <span>Upload</span>
              <span className='hide-mobile'>for Sharing</span>
            </Styled.NavButtonContainer>
          </CustomTooltip>

          {isAuth ? (
            <>
              {/* <Styled.GuideButton onClick={handleQuickGuideClick}>
                ?
              </Styled.GuideButton> */}
              <Styled.AuthButton onClick={handleUpgradeButtonClick} id="upgrade-button" $filled>
                <span>Upgrade</span>
              </Styled.AuthButton>
            </>
          ) : (
            <>
              <Styled.AuthButton onClick={handleLoginButtonClick}>
                <span>Login</span>
              </Styled.AuthButton>
              <Styled.AuthButton onClick={handleSignupButtonClick} $filled>
                <span>Signup</span>
              </Styled.AuthButton>
            </>
          )}
        </Styled.HeaderActions>
      </Styled.HeaderContent>
    </Styled.HeaderContainer>
  );
};

export default Header;
