import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import valentinesBanner from 'assets/images/valentines-banner.png';

export const ValentinesPromotion = styled.div`
  width: 100%;
  height: 58px;
  background: url(${valentinesBanner}) center/cover no-repeat;
  background-color: #ffecf2;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.black};
  font-size: 14px;
  font-weight: bold;
  padding: 0 16px;
  text-align: center;

  @media ${DEVICE.tablet} {
    font-size: 12px;
    line-height: 15px;
  }
`;

export const ClickableText = styled.span`
  cursor: pointer;
  text-decoration: underline;
  
  &:hover {
    opacity: 0.8;
  }
`;
