import * as THREE from 'three';

// region Model
// region Main scene
export const MODEL_MAX_SCALE: THREE.Vector3 = new THREE.Vector3(50, 50, 50);
export const MODEL_MIN_SCALE: THREE.Vector3 = new THREE.Vector3(0.001, 0.001, 0.001);
export const MODEL_DEF_POSITION: THREE.Vector3 = new THREE.Vector3(0, 0, 0);
// endregion

// region 8th Wall scene
export const EW_MODEL_MAX_SCALE_FACTOR = 1000;
export const EW_MODEL_MIN_SCALE_FACTOR = 0.001;
// endregion
// endregion

// region Renderer
export const DEF_RENDERER_SETTINGS = [
  {
    alpha: true,
    antialias: true,
    preserveDrawingBuffer: true,
    powerPreference: 'high-performance'
  }
];
// endregion

// region Screenshot Renderer
export const DEF_SS_RENDERER_SETTINGS = [{ alpha: true, antialias: true }];
// endregion

// region Camera
export const CAMERA_FOV = 45;
export const CAMERA_NEAR = 0.01;
export const CAMERA_FAR = 1000000000;

export const DEF_CAMERA_SETTINGS = [
  CAMERA_FOV,
  window.innerWidth / window.innerHeight,
  CAMERA_NEAR,
  CAMERA_FAR
];
// endregion

// region Screenshot Camera
export const SS_CAMERA_FOV = 45;
export const SS_CAMERA_NEAR = 0.1;
export const SS_CAMERA_FAR = 1000;

export const DEF_SS_CAMERA_SETTINGS = [SS_CAMERA_FOV, 1, SS_CAMERA_NEAR, SS_CAMERA_FAR];
// endregion

// region Cube orbit controller Camera
export const COC_CAMERA_FOV = 60;
export const COC_CAMERA_NEAR = 0.1;
export const COC_CAMERA_FAR = 100;
// endregion

// region Camera controller
export const CAMERA_MAX_SPEED = 50;
export const CAMERA_MIN_SPEED = 0.01;
export const CAMERA_MAX_ACCELERATION = 2;
export const CAMERA_MIN_ACCELERATION = 0.01;
export const CAMERA_MAX_SENSITIVITY = 0.025;
export const CAMERA_MIN_SENSITIVITY = 0.001;
export const CAMERA_MAX_FOV = 160;
export const CAMERA_MIN_FOV = 20;
export const CAMERA_MAX_DISTANCE = 10000;
export const CAMERA_MIN_DISTANCE = 0;
export const CAMERA_MAX_RECENTER_DISTANCE = 150;
export const CAMERA_MIN_RECENTER_DISTANCE = 0.001;
// endregion

// region Light
export const BASIC_LIGHT_INTENSITY_RATIO = 0.457;
export const POINT_LIGHT_INTENSITY_RATIO = 0.457;
export const HEMISPHERE_LIGHT_INTENSITY_RATIO = 40;

export const LIGHT_POSITION_RATIO = 2.1;
// endregion

// region Light
export const ENVIRONMENT_WRAPPER_SCALE = 200;

export const ENVIRONMENT_GREY_SPHERE_COLOR = new THREE.Color(0x454545);
export const ENVIRONMENT_GREY_GROUND_COLOR = new THREE.Vector4(69 / 255, 69 / 255, 69 / 255, 0.5);

export const ENVIRONMENT_WHITE_SPHERE_COLOR = new THREE.Color(0xcacaca);
export const ENVIRONMENT_WHITE_GROUND_COLOR = new THREE.Vector4(
  202 / 255,
  202 / 255,
  202 / 255,
  0.5
);
// endregion
