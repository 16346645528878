import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import styled from 'styled-components';

export const FeaturesTableContainer = styled.div`
  margin: 30px auto 50px auto;
  max-width: 100%;
  width: 800px;
  color: ${COLORS.white};
  position: relative;
`;

export const HeaderWrapper = styled.div`
  position: absolute;
  left: -20px;
  right: -20px;
  height: 100%;

  > div {
    position: sticky;
    top: 60px;
  }
`;

export const FeatureTableHeaderBackground = styled.div`
  position: absolute;
  left: -20px;
  right: -20px;
  top: 0px;
  height: 80px; // Height of header row + padding
  background: ${COLORS.charcoalGradient};
  border-radius: 6px 6px 0 0;
  z-index: 1;

  @media ${DEVICE.tablet} {
    height: 100px;  // Price wraps to new line so header is larger
  }
`;

export const FeatureTableBodyBackground = styled.div`
  position: absolute;
  left: -20px;
  right: -20px;
  top: 80px; // Start where header ends
  bottom: -20px;
  background: ${COLORS.charcoalGradient};
  border-radius: 0 0 6px 6px;
  z-index: 0;

  @media ${DEVICE.tablet} {
    top: 100px;  // Price wraps to new line so header is larger
  }
`;

export const FeatureTable = styled.table`
  width: 100%;
  border-spacing: 0;
  position: relative;

  thead {
    position: sticky;
    top: 60px;
    z-index: 2;
  }
`;

export const CustomPrice = styled.span`
  font-size: 20px;
  color: ${COLORS.turbo};

  @media ${DEVICE.mobile} {
    font-size: 16px;
  }
`;

export const PriceSection = styled.div`
  height: 32px;
  color: ${COLORS.turbo};
  display: inline-flex;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
  line-height: 0;
`;

export const Currency = styled.span`
  margin-top: -6px;
  font-size: 10px;

  @media ${DEVICE.mobile} {
    margin-top: -2px;
  }
`;

export const Price = styled.span`
  padding: 0px 3px;  
  font-size: 20px;

  @media ${DEVICE.mobile} {
    font-size: 16px;
  }
`;

export const Duration = styled.span`
  font-size: 10px;

  @media ${DEVICE.tablet} {
    width: 100%;
    text-align: center;
    display: block;
    padding-top: 3px;
    line-height: 0;
  }
`;

export const FeatureTableRow = styled.tr<{ $isHeader?: boolean }>`
  border-bottom: ${({ $isHeader }): string => ($isHeader ? 'none' : `1px solid ${COLORS.doveGray}`)};
`;

export const FeatureHeaderCell = styled.th<{ $isHeaderColumn?: boolean }>`
  max-width: ${({ $isHeaderColumn }): string => ($isHeaderColumn ? '100px' : '50px')};
  text-align: ${({ $isHeaderColumn }): string => ($isHeaderColumn ? 'left' : 'center')};
  font-size: ${({ $isHeaderColumn }): string => ($isHeaderColumn ? '32px' : 'unset')};
  font-weight: 400;
  border-bottom: none;
  padding: 15px ${({ $isHeaderColumn }): string => ($isHeaderColumn ? '15px' : '0px')};
  vertical-align: top;

  @media (max-width: 733px) {
    line-height: ${({ $isHeaderColumn }): string => ($isHeaderColumn ? '100%' : 'unset')};
  }

  @media ${DEVICE.mobile} {
    line-height: unset;
    font-size: ${({ $isHeaderColumn }): string => ($isHeaderColumn ? '20px' : 'unset')};
  }
`;

export const FeatureCell = styled.td<{ $isHeaderColumn?: boolean; $isSectionHeader?: boolean }>`
  border-right: ${({ $isHeaderColumn, $isSectionHeader }): string => 
    ($isHeaderColumn && !$isSectionHeader ? `1px solid ${COLORS.doveGray}` : 'none')};
  max-width: ${({ $isHeaderColumn, $isSectionHeader }): string => 
    $isSectionHeader ? 'none' : ($isHeaderColumn ? '120px' : '70px')};
  text-align: ${({ $isHeaderColumn }): string => ($isHeaderColumn ? 'left' : 'center')};
  padding-left: ${({ $isHeaderColumn }): string => ($isHeaderColumn ? '15px' : '0px')};
  padding-right: ${({ $isHeaderColumn }): string => ($isHeaderColumn ? '15px' : '0px')};
  padding-top: ${({ $isSectionHeader }): string => ($isSectionHeader ? '20px' : '15px')};
  padding-bottom: 15px;
`;

export const FeatureAvailableIcon = styled.img<{ $isAvailable?: boolean }>`
  width: 14px;
  height: 14px;
  opacity: ${({ $isAvailable }): string => ($isAvailable ? '1' : '0.25')};
`;