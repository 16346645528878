import React, { useEffect, useState } from 'react';
import * as Styled from './styles';
import Cube from 'assets/images/cube.svg';
import Spinner from 'assets/images/spinner.svg';
import SomeNotifications from 'assets/images/some-notifications.svg';
import moment from 'moment';
import {
  EMAIL_FROM_ANGLE_BRACKETS_REGEX,
  EMAIL_INTO_ANGLE_BRACKETS_REGEX
} from 'shared/constants/regexps';
import { useAppDispatch, useAppSelector, useNotificationPreferencesData } from 'shared/hooks';
import { RootState } from 'services/store';
import { setActiveCommentId, setIsCommentsVisible, setRecenterComment } from 'services/store/reducers/commentsReducer';
import { EModelType, ENotificationPreferenceMode, Note } from 'shared/types';
import { CustomTooltip, NotificationSettingsBar } from 'shared/components';

type Props = {
  modelId: string;
};

const CommentsBar: React.FC<Props> = ({ modelId }): JSX.Element => {
  const dispatch = useAppDispatch();
  const store = useAppSelector((state): RootState => state);
  const { isCommentsVisible, isCommentsLoading, plainComments } = store.comments;
  const { isAuth } = store.auth;
  const { modelType } = store.viewerData;
  const isSampleModel = modelType === EModelType.SAMPLE;
  const [isNotificationSettingsActive, setIsNotificationSettingsActive] = useState<boolean>(false);
  const {
    fetchModelNotificationPreferences,
    updateModelNotificationPreferences,
    notificationPreferences
  } = useNotificationPreferencesData();

  useEffect((): void => {
    (async (): Promise<void> => {
      if (isAuth && !isSampleModel) {
        await fetchModelNotificationPreferences(modelId);
      }
    })();
  }, [fetchModelNotificationPreferences, isAuth, isSampleModel, modelId]);

  useEffect((): (() => void) => {
    const userbackButton = document.getElementsByClassName('userback-button-e')[0];
    userbackButton.classList.add('left-side');

    return (): void => {
      userbackButton.classList.remove('left-side');
    };
  }, []);

  const handleSwitcherClick = (): void => {
    dispatch(setIsCommentsVisible(!isCommentsVisible));
  };

  const NOTES = [...plainComments].sort((a, b): number => (a.updatedAt < b.updatedAt ? 1 : -1));

  const handleCommentClick = (commentId: number): void => {
    if (isCommentsVisible) {
      dispatch(setActiveCommentId(commentId));
      dispatch(setRecenterComment(true));
    }
  };

  const getNoteMessage = (note: Note): (JSX.Element | string)[] => {
    const message: (JSX.Element | string)[] = [];
    const messageArr = note.message.split(' ');

    messageArr.reduce((text: string, word: string, index): string => {
      if (EMAIL_INTO_ANGLE_BRACKETS_REGEX.test(word)) {
        const email = word.match(EMAIL_FROM_ANGLE_BRACKETS_REGEX);
        if (!!email?.length) {
          message.push(text.trim());
          const mention = note.mentions?.find(({ person }): boolean => person?.email === email[0]);
          const result =
            !!mention?.person && !!mention?.seenComment
              ? `@${mention.person.firstName} ${mention.person.lastName}`
              : `@${email[0]}`;
          message.push(
            <span className='mention' key={index}>
              {` ${word.replace(EMAIL_INTO_ANGLE_BRACKETS_REGEX, result)} `}
            </span>
          );
          return '';
        }
      }

      if (index === messageArr.length - 1) {
        message.push(`${text} ${word}`);
      }
      return `${text} ${word}`;
    }, '');

    return message;
  };

  const handleNotificationsButtonClick = (): void => {
    setIsNotificationSettingsActive((prev): boolean => !prev);
  };

  const handleCloseClick = (): void => {
    setIsNotificationSettingsActive(false);
  };

  const updateNotificationPreferences = async (
    mode: ENotificationPreferenceMode
  ): Promise<void> => {
    await updateModelNotificationPreferences(modelId, mode);
  };

  return (
    <Styled.CommentsBarContainer>
      {isNotificationSettingsActive && (
        <Styled.CommentNotificationsBarContainer>
          <NotificationSettingsBar
            handleCloseClick={handleCloseClick}
            updateNotificationPreferences={updateNotificationPreferences}
            notificationPreferences={notificationPreferences}
          />
        </Styled.CommentNotificationsBarContainer>
      )}
      <Styled.CommentsBarHeader>
        {isAuth && !isSampleModel && (
          <Styled.NotificationsButton type='button' onClick={handleNotificationsButtonClick}>
            <img src={SomeNotifications} alt='Some Notifications' />
            <span>Notifications</span>
          </Styled.NotificationsButton>
        )}
        <Styled.SwitcherContainer onClick={handleSwitcherClick} $isActive={isCommentsVisible}>
          <div className='switcher-wheel' />
          <span>Show</span>
          <span>Hide</span>
        </Styled.SwitcherContainer>
      </Styled.CommentsBarHeader>
      <Styled.LoaderContainer $isCommentsLoading={isCommentsLoading}>
        <img className='spinner' src={Spinner} alt='Loading' />
      </Styled.LoaderContainer>
      {!!NOTES.length ? (
        <Styled.CommentsList>
          {NOTES.map((note): JSX.Element => {
            const ownerName = !!note.author.firstName
              ? `${note.author.firstName} ${note.author.lastName}`
              : 'User';
            return (
              <Styled.CommentContainer
                key={note.id}
                onClick={(): void => handleCommentClick(note.id)}
              >
                <Styled.CommentDate>
                  <span>#{note.commentNum}</span>
                  <span>{moment(note.updatedAt).format('DD MMM YYYY')}</span>
                </Styled.CommentDate>
                <Styled.CommentHeader>
                  <img src={Cube} alt='Comment' />
                  <CustomTooltip content={ownerName}>
                    <Styled.CommentOwner>{ownerName}</Styled.CommentOwner>
                  </CustomTooltip>
                  <Styled.CommentCreateAt>
                    {moment(note.updatedAt).fromNow()}
                  </Styled.CommentCreateAt>
                </Styled.CommentHeader>
                <Styled.CommentText>{getNoteMessage(note)}</Styled.CommentText>
              </Styled.CommentContainer>
            );
          })}
        </Styled.CommentsList>
      ) : (
        <Styled.NoCommentsText>No comments</Styled.NoCommentsText>
      )}
    </Styled.CommentsBarContainer>
  );
};

export default CommentsBar;
