import {
  BrandingSettings,
  CubemapFiles,
  CubemapImagePaths,
  CustomCubemap,
  CustomEnvironment,
  CustomTheme, ThemeCSSVariables
} from 'shared/types';
import { COLORS } from './colors';

export const CREATE_NEW_ID = -1;

export const DEFAULT_THEME_CSS_VARIABLES: ThemeCSSVariables = {
  buttonBackgroundColor: COLORS.turbo,
  buttonTextColor: COLORS.black,
  headerBackgroundColor: COLORS.mineShaft,
  sidebarBackgroundColor: COLORS.deepBlack,
  footerBackgroundColor: COLORS.codGrayGradient,
  textColor: COLORS.white,
  themeMainColor: COLORS.deepBlack,
  themeSecondaryColor: COLORS.turbo,
  inputBackgroundColor: COLORS.mineShaft,
  inputBackgroundSecondaryColor: COLORS.codGray,
  pageBackgroundColor: COLORS.deepBlack,
  modalBackgroundColor: COLORS.codGray
}

export const DEFAULT_PICKER_COLORS = [
  COLORS.mineShaft,
  COLORS.mischka,
  COLORS.turbo,
  COLORS.doveGray,
  COLORS.white
];

export const DEFAULT_THEME_SETTINGS: CustomTheme = {
  id: CREATE_NEW_ID,
  brandName: 'New theme',
  brandLogoUrl: '',
  siteTitle: 'glbee',
  siteSubtitle: 'instant 3D model sharing',
  brandOwnerId: -1,
  brandTeamId: -1,
  brandColors: {
    headerTextColor: COLORS.white,
    buttonTextColor: COLORS.black,
    buttonBackgroundColor: COLORS.turbo,
    normalTextColor: '#1F1F1F'
  }
};

export const DEFAULT_BRANDING: BrandingSettings = {
  id: null,
  brandName: 'Default Glbee',
  brandLogoUrl: '',
  siteTitle: '',
  siteSubtitle: '',
  brandColors: {
    headerTextColor: '',
    buttonTextColor: '',
    buttonBackgroundColor: '',
    normalTextColor: ''
  }
};

export const DEFAULT_ENV_SETTINGS: CustomEnvironment = {
  id: CREATE_NEW_ID,
  environmentName: 'Add new environment',
  environmentOwnerId: '',
  environmentTeamId: '',
  fileId: '',
  fileName: '',
  publicUrl: '',
  presets: {
    scale: { x: 1, y: 1, z: 1 }
  },
  thumbnailUrl: ''
};

export const DEFAULT_CUBEMAP_IMAGE_PATHS: CubemapImagePaths = {
  pxPlaneUrl: `${process.env.REACT_APP_URL}/resources/viewer/cubemaps/BasicWarehouse/px.jpg`,
  nxPlaneUrl: `${process.env.REACT_APP_URL}/resources/viewer/cubemaps/BasicWarehouse/nx.jpg`,
  pyPlaneUrl: `${process.env.REACT_APP_URL}/resources/viewer/cubemaps/BasicWarehouse/py.jpg`,
  nyPlaneUrl: `${process.env.REACT_APP_URL}/resources/viewer/cubemaps/BasicWarehouse/ny.jpg`,
  pzPlaneUrl: `${process.env.REACT_APP_URL}/resources/viewer/cubemaps/BasicWarehouse/pz.jpg`,
  nzPlaneUrl: `${process.env.REACT_APP_URL}/resources/viewer/cubemaps/BasicWarehouse/nz.jpg`
};

export const INITIAL_CUBEMAP_FILES: CubemapFiles = {
  nxPlane: null,
  nyPlane: null,
  nzPlane: null,
  pxPlane: null,
  pyPlane: null,
  pzPlane: null
};

export const CUBEMAP_POSITION_KEYS: {
  position: string;
  pathKey: keyof CubemapImagePaths;
  fileKey: keyof CubemapFiles;
}[] = [
  {
    position: 'px',
    pathKey: 'pxPlaneUrl',
    fileKey: 'pxPlane'
  },
  {
    position: 'nx',
    pathKey: 'nxPlaneUrl',
    fileKey: 'nxPlane'
  },
  {
    position: 'py',
    pathKey: 'pyPlaneUrl',
    fileKey: 'pyPlane'
  },
  {
    position: 'ny',
    pathKey: 'nyPlaneUrl',
    fileKey: 'nyPlane'
  },
  {
    position: 'pz',
    pathKey: 'pzPlaneUrl',
    fileKey: 'pzPlane'
  },
  {
    position: 'nz',
    pathKey: 'nzPlaneUrl',
    fileKey: 'nzPlane'
  }
];

export const DEFAULT_CUBEMAP_SETTINGS: CustomCubemap = {
  id: CREATE_NEW_ID,
  cubemapName: 'Create new cubemap',
  cubemapType: '',
  cubemapOwnerId: -1,
  cubemapTeamId: -1,
  ...DEFAULT_CUBEMAP_IMAGE_PATHS
};
