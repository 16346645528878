import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import { DEVICE } from 'shared/constants/deviceSizes';

export const ModelUploaderWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  z-index: 1;
  background-color: ${() => `${COLORS.black}30`};
`;

export const ModelUploaderContainer = styled.section`
  width: calc(100% - 60px);
  height: calc(100% - 60px);

  @media ${DEVICE.tabletLarge} {
    width: calc(100% - 30px);
    height: calc(100% - 30px);
  }
`;

export const UploadField = styled.div<{ $isDragActive: boolean }>`
  position: relative;
  width: 100%;
  height: 100%;
  border: 1px solid ${COLORS.turbo};
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 7px;
  cursor: pointer;
  background-color: ${({ $isDragActive }): string =>
    $isDragActive ? 'rgba(255, 228, 6, 0.45)' : `${COLORS.mischka}40`};
`;

export const UploadFieldContent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

export const PlusIcon = styled.img`
  height: 50px;
  width: 50px;

  @media ${DEVICE.mobile} {
    height: 40px;
    width: 40px;
  }
`;

export const UploadDescription = styled.div`
  margin-top: 24px;
  color: ${COLORS.white};
  font-family: ${FONTS.interRegular};
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: 0 20px;

  span {
    transition: color 0.3s ease;
  }

  span:first-child {
    font-weight: 600;
    font-size: 28px;
    line-height: 34px;
    margin-bottom: 16px;

    @media ${DEVICE.mobile} {
      font-size: 22px;
      line-height: 28px;
      margin-bottom: 12px;
    }
  }

  span:last-child {
    font-weight: 400;
    font-size: 20px;
    line-height: 24px;

    @media ${DEVICE.mobile} {
      font-size: 16px;
      line-height: 20px;
    }
  }
`;

export const UploaderModeSwitcher = styled.div`
  margin-top: 20px;
  position: relative;
  display: inline-flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

export const UploaderMode = styled.div<{ $isActive: boolean }>`
  font-weight: 400;
  font-size: 20px;
  line-height: 24px;
  color: ${({ $isActive }): string => ($isActive ? COLORS.turbo : '#fff')};
  cursor: pointer;
  transition: all 0.3s ease;
  position: absolute;
  white-space: nowrap;
  display: flex;
  align-items: center;
  gap: 8px;
  margin: 0 10px;
  
  @media ${DEVICE.mobile} {
    font-size: 16px;
    line-height: 20px;
  }

  &:first-of-type {
    right: calc(100% + 22px);

    @media ${DEVICE.mobile} {
      right: calc(100% + 17px);
    }
  }
  
  &:last-of-type {
    left: calc(100% + 5px);

    @media ${DEVICE.mobile} {
      left: calc(100%);
    }
  }

  img {
    width: 16px;
    height: 16px;
  }

  &:hover {
    color: ${COLORS.turbo};
    text-shadow: 0 0 10px rgba(255, 228, 6, 0.3);
  }
`;

export const SwitchKnob = styled.div<{ $isUploadMode: boolean }>`
  width: 32px;
  height: 32px;
  background: ${COLORS.galleryGradient};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5), -2px 0 10px rgba(0, 0, 0, 0.5), 2px 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) ${({ $isUploadMode }): string => 
    $isUploadMode ? 'translateX(34px)' : 'translateX(2px)'};
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    width: 14px;
    height: 14px;
    fill: none;
  }
`;

export const SwitchTrack = styled.div<{ $isUploadMode: boolean }>`
  width: 70px;
  height: 36px;
  background: var(--button-background-color);
  border-radius: 50px;
  position: relative;
  padding: 1px;
  cursor: pointer;
  transition: opacity 0.3s ease;
  overflow: hidden;

  &:hover {
    ${SwitchKnob} {
      transform: translateY(-50%) ${({ $isUploadMode }): string => 
        $isUploadMode ? 'translateX(34px) scale(1.1)' : 'translateX(2px) scale(1.1)'};
    }
  }
`;

export const SupportedFormats = styled.div`
  position: absolute;
  bottom: 20px;
  left: 20px;
  font-weight: 400;
  font-size: 16px;
  line-height: 19px;
  color: ${COLORS.mischka}E0;
  font-family: ${FONTS.interRegular};
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  width: 70vw;

  @media ${DEVICE.mobile} {
    font-size: 14px;
    line-height: 17px;
    bottom: 15px;
    left: 15px;
  }
`;

export const SampleModelsBlock = styled.section`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10vh;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

export const SampleModelsTitle = styled.span`
  font-weight: 400;
  font-size: 16px;
  margin-bottom: 10px;
  color: ${COLORS.mischka}E0;
  text-shadow: 0 4px 4px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;

  @media ${DEVICE.mobile} {
    font-size: 14px;
    margin-bottom: 8px;
  }

  img {
    width: 12px;
    height: 12px;
    margin-left: 8px;

    @media ${DEVICE.mobile} {
      width: 10px;
      height: 10px;
    }
  }
`;

export const SampleModels = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 180px;
  margin-top: 10px;

  .dot {
    width: 5px;
    height: 5px;
    background: ${COLORS.mischka};
    border-radius: 50%;
  }
`;

export const SampleModel = styled.img`
  transition: transform 0.5s ease;
  transform: scale(1);

  &:hover,
  &[data-active="true"] {
    transform: scale(1.3);
  }

  &.yellow-car {
    width: 45px;
    height: 36px;

    @media ${DEVICE.mobile} {
      width: 36px;
      height: 29px;
    }
  }

  &.yellow-bee {
    width: 32px;
    height: 36px;

    @media ${DEVICE.mobile} {
      width: 26px;
      height: 29px;
    }
  }

  &.example-room {
    width: 42px;
    height: 35px;

    @media ${DEVICE.mobile} {
      width: 34px;
      height: 28px;
    }
  }
`;

export const SecureIcon = styled.img`
  width: 70px;
  height: 80px;
  position: absolute;
  bottom: 20px;
  right: 20px;
  transition: all 0.1s;

  @media ${DEVICE.mobile} {
    width: 56px;
    height: 64px;
    bottom: 15px;
    right: 15px;
  }

  &:hover {
    transform: scale(1.05);
  }
`;
