import React from 'react';
import * as Styled from './styles';
import { ModelTexture, ModelWithMetadata } from 'shared/types';
import Warning from 'assets/images/warning.svg';
import { convertBytesToMegabytes, getModelMetadata } from 'utils/model-utils';
import { CustomTooltip } from 'shared/components';

type DataItem = {
  title: string;
  value: string | number;
};

type TextureItem = {
  title: string;
  value: number;
  texture: ModelTexture;
};

type Props = {
  model: ModelWithMetadata;
};

const ModelMetadata: React.FC<Props> = ({ model }): JSX.Element => {
  const modelTotalSize = convertBytesToMegabytes(model.modelSize);
  const modelSize = convertBytesToMegabytes(model.metadata.fileSize.gltfSize, 3);
  const modelBinSize = convertBytesToMegabytes(model.metadata.fileSize.binSize);
  const { geometry, details, textures, litValues } = getModelMetadata(model);

  const getTextureTooltip = ({ title, value, texture }: TextureItem): JSX.Element => (
    <Styled.TooltipContainer>
      <Styled.TooltipField>{`${title} x ${value}`}</Styled.TooltipField>
      <Styled.TooltipField>
        {`JPG Max size: ${convertBytesToMegabytes(texture.jpeg.maxSize)}mb`}
      </Styled.TooltipField>
      <Styled.TooltipField>
        {`JPG Min size: ${convertBytesToMegabytes(texture.jpeg.minSize)}mb`}
      </Styled.TooltipField>
      <Styled.TooltipField>
        {`PNG Max size: ${convertBytesToMegabytes(texture.png.maxSize)}mb`}
      </Styled.TooltipField>
      <Styled.TooltipField>
        {`PNG Min size: ${convertBytesToMegabytes(texture.png.minSize)}mb`}
      </Styled.TooltipField>
    </Styled.TooltipContainer>
  );

  const getFormatTooltip = (): JSX.Element => (
    <Styled.TooltipContainer>
      <Styled.TooltipField>{`${model.metadata.format.toUpperCase()} size ${modelSize}Mb`}</Styled.TooltipField>
      <Styled.TooltipField>{`.BIN size ${modelBinSize}Mb`}</Styled.TooltipField>
    </Styled.TooltipContainer>
  );

  const getNPOTTooltip =
    (): string => `Warning: NPOT textures are textures that are not restricted to powers of 2. These textures may
      cause crashes or poor performance on some devices. To improve performance, resize these
      textures to be power of two.`;

  const getDataList = (list: DataItem[]): JSX.Element[] =>
    list.map(({ title, value }): JSX.Element => {
      return (
        <React.Fragment key={title}>
          <Styled.DescriptionField>
            <span>{title}</span>
            <CustomTooltip content={value.toString()} maxWidth={55} placement='right'>
              <span>{value}</span>
            </CustomTooltip>
          </Styled.DescriptionField>
          {title === 'Materials' && (
            <Styled.LitField>
              <Styled.LitItem $isActive={litValues.isLit}>Lit</Styled.LitItem>
              <Styled.LitItem $isActive={litValues.isUnlit}>Unlit</Styled.LitItem>
            </Styled.LitField>
          )}
        </React.Fragment>
      );
    });

  return (
    <Styled.ModelDescription>
      <Styled.Textures>
        <Styled.TexturesField className='block-title'>
          <Styled.FieldTitle>Textures</Styled.FieldTitle>
          <Styled.FieldValue>Count</Styled.FieldValue>
        </Styled.TexturesField>
        {textures.map(
          ({ title, value, texture }): JSX.Element => (
            <CustomTooltip
              key={title}
              content={getTextureTooltip({ title, value, texture })}
              placement='right'
            >
              <Styled.TexturesField>
                {title === 'NPOT' ? (
                  <CustomTooltip content={getNPOTTooltip()}>
                    <Styled.FieldTitle>
                      <span>{title}</span>
                      <img src={Warning} alt='NPOT' />
                    </Styled.FieldTitle>
                  </CustomTooltip>
                ) : (
                  <Styled.FieldTitle>
                    <span>{title}</span>
                  </Styled.FieldTitle>
                )}
                <Styled.FieldValue>{value}</Styled.FieldValue>
              </Styled.TexturesField>
            </CustomTooltip>
          )
        )}
      </Styled.Textures>

      <Styled.BottomSection>
        <Styled.DescriptionBlock>
          <Styled.BlockTitle>Geometry</Styled.BlockTitle>
          {getDataList(geometry)}
        </Styled.DescriptionBlock>
        <Styled.DescriptionBlock>
          <Styled.BlockTitle>Details</Styled.BlockTitle>
          {getDataList(details)}
          <CustomTooltip content={getFormatTooltip()} placement='right'>
            <>
              <Styled.DescriptionField>
                <span>Size:</span>
                <span>{modelTotalSize}Mb</span>
              </Styled.DescriptionField>
              <Styled.DescriptionField>
                <span>Format:</span>
                <span>{model.metadata.format}</span>
              </Styled.DescriptionField>
            </>
          </CustomTooltip>
        </Styled.DescriptionBlock>
      </Styled.BottomSection>
    </Styled.ModelDescription>
  );
};

export default ModelMetadata;
