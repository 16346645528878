import React, { useState } from 'react';
import Check from 'assets/images/check.svg';
import * as Styled from './styles';
import { useAppDispatch } from 'shared/hooks';
import { openNotification } from 'utils/notification-utils';
import { ESnackbarStyle } from 'shared/types';
import { getIn, useFormik } from 'formik';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { recoverAccount, registerUser } from 'services/api/authService';
import { validation } from 'services/validation';
import { FormField, ModalLogin } from 'shared/components';
import { closeModal, showModal } from 'services/store/reducers/modalReducer';
import {
  EmailNotificationSubtitle,
  EmailNotificationTitle,
  FormFieldLabel,
  IncorrectEmailText,
  ModalForm,
  ModalSubtitle,
  ModalTitle,
  NoEmailText,
  SubmitButton
} from 'shared/styles';
import { loginUser, logoutUser } from 'services/store/reducers/authReducer';

type InitialValues = {
  email: string;
  password: string;
  confirmPassword: string;
  firstName: string;
  lastName: string;
  companyName: string;
  acceptTermsAndConditions: boolean;
  subscribeForMarketingEmails: boolean;
};

type Props = {
  email?: string;
  token?: string;
  isAccountRecovery?: boolean;
};

const ModalCreateAccount: React.FC<Props> = ({ isAccountRecovery, email, token }): JSX.Element => {
  const [isRequestSuccessfullySent, setIsRequestSuccessfullySent] = useState<boolean>(false);
  const dispatch = useAppDispatch();
  const initialValues: InitialValues = {
    email: email || '',
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    companyName: '',
    acceptTermsAndConditions: false,
    subscribeForMarketingEmails: false
  };

  const formik = useFormik({
    initialValues,
    onSubmit: async (values, { setFieldError }): Promise<void> => {
      dispatch(startLoader());
      try {
        dispatch(logoutUser());
        const recaptcha = await grecaptcha.enterprise.execute(
          process.env.REACT_APP_RECAPTCHA_KEY!,
          { action: 'login' }
        );
        if (isAccountRecovery && !!token) {
          const { data } = await recoverAccount({ ...values, recaptcha }, token);
          localStorage.setItem('token', data.token);
          await dispatch(loginUser({ user: data }));
          dispatch(closeModal());
          return;
        }
        await registerUser({ ...values, recaptcha });
        setIsRequestSuccessfullySent(true);
      } catch (e) {
        if (e && e.status === 400 && e.message) {
          setFieldError('email', e.message);
        } else {
          openNotification(ESnackbarStyle.ERROR, e?.message);
        }
      } finally {
        dispatch(stopLoader());
      }
    },
    validationSchema: validation.REGISTRATION
  });

  const handleCheckboxClick =
    (fieldTitle: string): (() => void) =>
    (): void => {
      const currentValue = getIn(formik.values, fieldTitle);
      formik.setFieldValue(fieldTitle, !currentValue);
    };

  const handleLoginClick = (): void => {
    dispatch(showModal(<ModalLogin />));
  };

  const handleCreateAccountClick = (): void => {
    dispatch(showModal(<ModalCreateAccount />));
  };

  return (
    <Styled.ModalWindowContainer>
      <Styled.LeftSide>
        <Styled.LiftSideContent>
          {isRequestSuccessfullySent ? (
            <Styled.SuccessfullySentContainer>
              <EmailNotificationTitle>Check your email</EmailNotificationTitle>
              <EmailNotificationSubtitle>
                A verification link was sent to <span className='email'>{formik.values.email}</span>
              </EmailNotificationSubtitle>
              <NoEmailText>If you don’t see the email, please check your spam folder</NoEmailText>
              <IncorrectEmailText>
                <span>Email address incorrect?</span>
                <span className='highlighted-text' onClick={handleCreateAccountClick}>
                  Create an Account
                </span>
              </IncorrectEmailText>
            </Styled.SuccessfullySentContainer>
          ) : (
            <>
              {isAccountRecovery ? (
                <>
                  <EmailNotificationTitle>Could not find account!</EmailNotificationTitle>
                  <EmailNotificationSubtitle>
                    Finish your account setup for{' '}
                    <span className='email'>{formik.values.email}</span>
                  </EmailNotificationSubtitle>
                </>
              ) : (
                <>
                  <ModalSubtitle>Sign up to share and view models</ModalSubtitle>
                  <ModalTitle>Create An Account</ModalTitle>
                  <ModalSubtitle>
                    <span>Already a member?</span>
                    <span onClick={handleLoginClick} className='log-in'>
                      Log in
                    </span>
                  </ModalSubtitle>
                </>
              )}

              <ModalForm onSubmit={formik.handleSubmit} id='create-account-form'>
                <Styled.FormNameBlock>
                  <Styled.FormFieldContainer>
                    <FormFieldLabel>First Name*</FormFieldLabel>
                    <FormField formik={formik} name={'firstName'} isAutofillDisabled />
                  </Styled.FormFieldContainer>
                  <Styled.FormFieldContainer>
                    <FormFieldLabel>Last Name*</FormFieldLabel>
                    <FormField formik={formik} name={'lastName'} isAutofillDisabled />
                  </Styled.FormFieldContainer>
                </Styled.FormNameBlock>
                <FormFieldLabel>Email*</FormFieldLabel>
                <FormField
                  formik={formik}
                  name={'email'}
                  type={'email'}
                  isDisabled={isAccountRecovery}
                  isAutofillDisabled
                />
                <FormFieldLabel>Company Name (optional)</FormFieldLabel>
                <FormField formik={formik} name={'companyName'} type={'text'} />
                <FormFieldLabel>Password*</FormFieldLabel>
                <FormField formik={formik} name={'password'} isPassword isAutofillDisabled />
                <FormFieldLabel>Confirm Password*</FormFieldLabel>
                <FormField formik={formik} name={'confirmPassword'} isPassword isAutofillDisabled />
                <Styled.CheckboxesBlock>
                  <Styled.CheckboxField
                    id='terms-privacy-checkbox'
                    onClick={handleCheckboxClick('acceptTermsAndConditions')}
                  >
                    <Styled.Checkbox>
                      {formik.values.acceptTermsAndConditions && <img src={Check} alt='Check' />}
                    </Styled.Checkbox>
                    <Styled.FieldText>
                      <span>I accept the platform</span>
                      <a
                        href={`${process.env.REACT_APP_URL}/terms-of-use?auth=0`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        Terms of Use
                      </a>
                      <span>and</span>
                      <a
                        href={`${process.env.REACT_APP_URL}/privacy-policy?auth=0`}
                        target='_blank'
                        rel='noreferrer'
                      >
                        Privacy Policy
                      </a>
                    </Styled.FieldText>
                  </Styled.CheckboxField>
                  <Styled.CheckboxField
                    id='marketing-emails-checkbox'
                    onClick={handleCheckboxClick('subscribeForMarketingEmails')}
                  >
                    <Styled.Checkbox>
                      {formik.values.subscribeForMarketingEmails && <img src={Check} alt='Check' />}
                    </Styled.Checkbox>
                    <Styled.FieldText>I opt in to marketing communications</Styled.FieldText>
                  </Styled.CheckboxField>
                </Styled.CheckboxesBlock>
                <SubmitButton
                  className='create-account-button'
                  type='submit'
                  disabled={formik.isSubmitting || !formik.values.acceptTermsAndConditions}
                  id='create-account-button'
                >
                  Create an Account
                </SubmitButton>
              </ModalForm>
            </>
          )}
        </Styled.LiftSideContent>
      </Styled.LeftSide>
      <Styled.RightSide></Styled.RightSide>
    </Styled.ModalWindowContainer>
  );
};

export default ModalCreateAccount;
