import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  display: flex;
  max-width: 1050px;
  max-height: 1030px;
  width: 90vw;
  height: 100%;
  color: ${COLORS.white};
  position: relative;
  overflow-y: scroll;
  overflow-x: hidden;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};
  border-radius: 7px;
`;

export const AlertText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 18px;
  color: rgba(214, 215, 227, 0.8);
`;

export const ModalTitle = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 34px;
  padding: 15px 0;
  color: ${COLORS.white};
  margin-bottom: 0;

  @media ${DEVICE.tablet} {
    font-size: 24px;
    line-height: 26px;
  }
`;

export const ModalSubtitle = styled.span`
  font-weight: 400;
  font-size: 23px;
  line-height: 28px;
  color: ${COLORS.white};
  margin-bottom: 0;

  @media ${DEVICE.tablet} {
    font-size: 16px;
    line-height: 18px;
  }
`;

export const ModalContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 50px;
`;

export const SubscriptionsContainer = styled.div`
  margin-top: 30px;
`;
