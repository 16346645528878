import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router';
import * as Styled from './styles';
import SecureIcon from 'assets/images/ip-secure-icon.svg';
import YellowCar from 'assets/images/yellow-car.png';
import YellowBee from 'assets/images/yellow-bee.png';
import ExampleRoom from 'assets/images/example-room.png';
import Hint from 'assets/images/hint.svg';
import { QuickViewIcon, NewModelIcon } from 'assets/dynamic-icons';
import { useAppDispatch, useAppSelector, useUploadFile } from 'shared/hooks';
import { AuthState, EModelType, SampleModel, TeamsState } from 'shared/types';
import { FileWithPath, useDropzone } from 'react-dropzone';
import { showModal } from 'services/store/reducers/modalReducer';
import { ModelsSecure, CustomTooltip } from 'shared/components';
import { TOOLTIPS } from 'shared/constants/tooltips';
import UploadIcon from 'assets/images/upload-file.svg';

type Props = {
  loadSampleModel: (sampleName: SampleModel) => Promise<void>;
};

const ModelUploader: React.FC<Props> = ({ loadSampleModel }): JSX.Element => {
  const location = useLocation();
  const { isAuth } = useAppSelector((state): AuthState => state.auth);
  const { activeTeam } = useAppSelector((state): TeamsState => state.teams);
  const dispatch = useAppDispatch();
  const [isUploadMode, setIsUploadMode] = useState(location.pathname === '/upload');
  const { uploadModelToSpace, uploadModelToViewer } = useUploadFile();
  const [activeModelIndex, setActiveModelIndex] = useState<number>(-1);
  const [isHoveringModels, setIsHoveringModels] = useState<boolean>(false);

  useEffect(() => {
    if (isHoveringModels) return;

    const cycleInterval = setInterval(() => {
      setActiveModelIndex((prev) => (prev + 1) % 3);
    }, 2000);

    return () => clearInterval(cycleInterval);
  }, [isHoveringModels]);

  useEffect((): void => {
    setIsUploadMode(location.pathname === '/upload');
  }, [location]);

  const handleSampleModelClick =
    (sampleName: SampleModel): ((event: React.MouseEvent<HTMLElement>) => void) =>
    async (event): Promise<void> => {
      event.stopPropagation();
      await loadSampleModel(sampleName);
    };

  const onDrop = async (files: FileWithPath[]): Promise<void> => {
    switch (true) {
      case !!activeTeam:
        await uploadModelToSpace(files, {
          spaceTitle: activeTeam!.teamName,
          spaceId: activeTeam!.id
        });
        break;
      case isUploadMode:
        isAuth
          ? await uploadModelToSpace(files)
          : await uploadModelToViewer(files, EModelType.ANONYMOUS);
        break;
      case !isUploadMode:
        await uploadModelToViewer(files, EModelType.QUICK_VIEW);
        break;
    }
  };

  const handleSecureIconClick = (event: React.MouseEvent<HTMLElement>): void => {
    event.stopPropagation();
    dispatch(showModal(<ModelsSecure />));
  };

  const toggleUploaderMode =
    (isUploadMode: boolean): ((event: React.MouseEvent<HTMLElement>) => void) =>
    (event): void => {
      event.stopPropagation();
      setIsUploadMode(isUploadMode);
    };

  const { getRootProps, getInputProps, isDragActive } = useDropzone({ onDrop });

  return (
    <Styled.ModelUploaderWrapper {...getRootProps()}>
      <input {...getInputProps()} />
      <Styled.ModelUploaderContainer>
        <Styled.UploadField $isDragActive={isDragActive}>
          <>
            <Styled.UploadFieldContent>
              <Styled.PlusIcon src={UploadIcon} alt='Arrow exiting a box to convey file drag and drop' draggable={false} />
              <Styled.UploadDescription>
                <span>{`Drag & drop 3D files here to ${isUploadMode ? 'upload for sharing' : 'quickly preview'}`}</span>
                <span>or click to browse</span>
              </Styled.UploadDescription>

              <Styled.UploaderModeSwitcher>
                <CustomTooltip content={TOOLTIPS.QUICK_VIEW} placement='bottom'>
                  <Styled.UploaderMode $isActive={!isUploadMode} onClick={toggleUploaderMode(false)}>
                    <span>Quick View</span>
                    <img src={Hint} alt='Hint' />
                  </Styled.UploaderMode>
                </CustomTooltip>
                <Styled.SwitchTrack $isUploadMode={isUploadMode} onClick={(e) => {
                  e.stopPropagation();
                  toggleUploaderMode(!isUploadMode)(e);
                }}>
                  <Styled.SwitchKnob $isUploadMode={isUploadMode}>
                    {isUploadMode ? <NewModelIcon /> : <QuickViewIcon />}
                  </Styled.SwitchKnob>
                </Styled.SwitchTrack>
                <CustomTooltip content={TOOLTIPS.UPLOAD} placement='bottom'>
                  <Styled.UploaderMode $isActive={isUploadMode} onClick={toggleUploaderMode(true)}>
                    <span>Upload</span>
                    <img src={Hint} alt='Hint' />
                  </Styled.UploaderMode>
                </CustomTooltip>
              </Styled.UploaderModeSwitcher>
            </Styled.UploadFieldContent>

            <Styled.SupportedFormats>
              Supported formats: .GLB, .GLTF, .FBX, .OBJ, and .STL
            </Styled.SupportedFormats>

            <Styled.SampleModelsBlock 
              onMouseEnter={() => setIsHoveringModels(true)}
              onMouseLeave={() => setIsHoveringModels(false)}
            >
              <Styled.SampleModelsTitle>
                <span>No model? Preview a sample:</span>
              </Styled.SampleModelsTitle>
              <Styled.SampleModels>
                <Styled.SampleModel
                  src={YellowCar}
                  className='yellow-car'
                  onClick={handleSampleModelClick(SampleModel.CAR)}
                  draggable={false}
                  data-active={activeModelIndex === 0 && !isHoveringModels}
                />
                <div className='dot' />
                <Styled.SampleModel
                  src={YellowBee}
                  className='yellow-bee'
                  onClick={handleSampleModelClick(SampleModel.BEE)}
                  draggable={false}
                  data-active={activeModelIndex === 1 && !isHoveringModels}
                />
                <div className='dot' />
                <Styled.SampleModel
                  src={ExampleRoom}
                  className='example-room'
                  onClick={handleSampleModelClick(SampleModel.VIKING_SCENE)}
                  draggable={false}
                  data-active={activeModelIndex === 2 && !isHoveringModels}
                />
              </Styled.SampleModels>
            </Styled.SampleModelsBlock>

            <Styled.SecureIcon src={SecureIcon} onClick={handleSecureIconClick} draggable={false} />
          </>
        </Styled.UploadField>
      </Styled.ModelUploaderContainer>
    </Styled.ModelUploaderWrapper>
  );
};

export default ModelUploader;
