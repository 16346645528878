import { useCallback, useEffect, useLayoutEffect, useRef, useState } from 'react';
import * as Styled from './styles';
import { ContentHeader, PageContainer, PageTitle } from 'shared/styles';
import { SearchSelect, ModelList } from 'shared/components';
import { useAppDispatch, useSharedWithMeData } from 'shared/hooks';
import { stopLoader } from 'services/store/reducers/loaderReducer';

const SharedWithMePage = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const [isModelListLoaded, setIsModelListLoaded] = useState(false);
  const isInitTabVisit = useRef<boolean>(true);

  useEffect(
    (): (() => void) => (): void => {
      dispatch(stopLoader());
    },
    [dispatch]
  );

  const {
    fetchModel,
    modelsData,
    setSearch,
    isSearchMode,
    setIsSearchMode,
    updateModelList,
    resetModelList,
  } = useSharedWithMeData();

  useLayoutEffect((): void => {
    (async (): Promise<void> => {
      if (isInitTabVisit.current) {
        isInitTabVisit.current = false;
        await resetModelList();
        setIsModelListLoaded(true);
      }
    })();
  }, [resetModelList]);

  const handleSearchInputChange = useCallback(
    (value: string): void => {
      setSearch(value);
    },
    [setSearch]
  );

  const handleSearchListItemClick = async (modelId: string): Promise<void> => {
    await fetchModel(modelId);
  };

  return (
    <PageContainer>
      <Styled.PageContent>
        <ContentHeader>
          <PageTitle>Shared with me</PageTitle>
          <Styled.SearchSelectContainer>
            <SearchSelect
              inputAction={handleSearchInputChange}
              listAction={handleSearchListItemClick}
              options={isSearchMode ? modelsData.slice(0, 5) : []}
              setIsSearchMode={setIsSearchMode}
            />
          </Styled.SearchSelectContainer>
        </ContentHeader>
        {isModelListLoaded && (
          <ModelList
            initialModels={modelsData}
            fetchMoreModels={updateModelList}
          />
        )}
      </Styled.PageContent>
    </PageContainer>
  );
};

export default SharedWithMePage;
