import { COLORS } from 'shared/constants/colors';
import styled from 'styled-components';

export const SwitcherContainer = styled.div<{
  $isActive: boolean;
  $isDisabled: boolean;
  $isSmall: boolean;
  $withoutCustomStyle: boolean;
}>`
  width: ${({ $isSmall }): string => ($isSmall ? '32px' : '60px')};
  height: ${({ $isSmall }): string => ($isSmall ? '18px' : '30px')};
  border-radius: 100px;
  cursor: pointer;
  display: flex;
  align-items: center;
  background: ${({ $isDisabled, $isActive, $withoutCustomStyle }): string =>
    $isDisabled
      ? COLORS.doveGray
      : $isActive
      ? $withoutCustomStyle
        ? COLORS.corn
        : 'var(--button-background-color)'
      : COLORS.codGray};
  transition: all 0.3s ease;
  overflow: hidden;

  ${({ $isDisabled, $isActive, $isSmall }): string =>
    $isDisabled
      ? 'cursor: not-allowed;'
      : `
        &:hover {
          .switcher-wheel {
            transform: translateY(0) scale(1.1) translateX(
              ${$isSmall ? ($isActive ? '15px' : '1px') : $isActive ? '30px' : '2px'}
            );
          }
        }
      `
   }

  .switcher-wheel {
    width: ${({ $isSmall }): string => ($isSmall ? '14px' : '24px')};
    height: ${({ $isSmall }): string => ($isSmall ? '14px' : '24px')};
    background: ${COLORS.galleryGradient};
    box-shadow: ${({ $isSmall }): string =>
      $isSmall
        ? '0 0 5px rgba(0, 0, 0, 0.5), -2px 0 5px rgba(0, 0, 0, 0.5), 2px 0 5px rgba(0, 0, 0, 0.5)'
        : '0 0 10px rgba(0, 0, 0, 0.5), -2px 0 10px rgba(0, 0, 0, 0.5), 2px 0 10px rgba(0, 0, 0, 0.5)'
    };
    border-radius: 50%;
    transition: all 0.3s;
    transform: translateX(
      ${({ $isActive, $isSmall }): string =>
        $isSmall ? ($isActive ? '16px' : '2px') : $isActive ? '32px' : '3px'}
    );
  }
`;
