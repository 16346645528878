import React, { useCallback } from 'react';
import * as Styled from './styles';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { AuthState, SubscriptionsState } from 'shared/types';
import { showModal, showConfirmModal } from 'services/store/reducers/modalReducer';
import { ModalLogin, ModalCheckout, ModalDowngradePlan } from 'shared/components';
import { EConfirmModalHeader } from 'shared/types';

export const ValentinesPromotionPanel = (): React.ReactElement => {
  const dispatch = useAppDispatch();
  const { isAuth } = useAppSelector((state): AuthState => state.auth);
  const { subscriptionPlans, currentSubscriptionPlan, planLevels } = useAppSelector(
    (store): SubscriptionsState => store.subscriptions
  );
  
  const promoCode = 'GLBEEMINE';
  const promoOffer = '1 Month FREE of Premium Mesh Plan';

  const handleSelectPlanAction = useCallback(async (selectedPlan) => {
    dispatch(
      showConfirmModal({
        header: EConfirmModalHeader.CHECKOUT,
        content: <ModalCheckout plan={selectedPlan} isMonthlyType={true} promoCode={promoCode} />
      })
    );
  }, [dispatch, promoCode]);

  const handleClick = useCallback(() => {
    if (!isAuth) {
      dispatch(showModal(<ModalLogin />));
      return;
    }

    const meshPlan = subscriptionPlans.find(plan => plan.name?.includes('Mesh'));
    if (!meshPlan) return;

    if (currentSubscriptionPlan) {
      const currentPlan = subscriptionPlans.find(p => p.id === currentSubscriptionPlan.productId);
      if (!currentPlan) return;

      const currentPlanLevel = planLevels?.get(currentPlan) || 0;
      const selectedPlanLevel = planLevels?.get(meshPlan) || 0;
      
      if (selectedPlanLevel < currentPlanLevel) {
        dispatch(
          showConfirmModal({
            header: 'Downgrade Plan',
            content: (
              <ModalDowngradePlan
                currentPlan={currentPlan}
                selectedPlan={meshPlan}
                handleSelectPlanAction={handleSelectPlanAction}
              />
            )
          })
        );
        return;
      }
    }

    handleSelectPlanAction(meshPlan);
  }, [dispatch, isAuth, subscriptionPlans, currentSubscriptionPlan, planLevels, handleSelectPlanAction]);

  return (
    <Styled.ValentinesPromotion>
      <span className='bold-text'>
        {`Your Valentine's Gift 💖 ${promoOffer}! `}
        <Styled.ClickableText onClick={handleClick}>
          Click here
        </Styled.ClickableText>
        {` to use code '${promoCode}' 🚀`}
      </span>
    </Styled.ValentinesPromotion>
  );
};
