import React, { useEffect, useState } from 'react';
import { useAppSelector } from 'shared/hooks';
import { RootState } from 'services/store';
import { ExclamationCircleFilled } from '@ant-design/icons';
import * as Styled from './styles';
import { MAX_VERTICES, MAX_TEXTURES } from 'shared/constants/performance-model-warning';
import { formatNumberToNumberWithCommas } from 'utils/form-utils';
import { ModelWithMetadata } from 'shared/types/model';

type Warning = {
  id: number;
  message: string;
  header: string;
};

type Props = {
  model: ModelWithMetadata;
};

const WarningsTab: React.FC<Props> = ({ model }): JSX.Element => {
  const [warnings, setWarnings] = useState<Warning[]>([]);
  const { hasSkeleton } = useAppSelector((store: RootState) => store.viewerData);
  const { buttonBackgroundColor, buttonTextColor } = useAppSelector(
    (store: RootState) => store.branding.activeBranding.brandColors
  );

  useEffect(() => {
    const newWarnings: Warning[] = [];
    
    if (model?.metadata?.geometry?.vertices && model.metadata.geometry.vertices >= MAX_VERTICES) {
      newWarnings.push({
        id: 1,
        header: "High Polygon Count",
        message: `Some devices may struggle to display this model. To improve compatibility, consider reducing the vertex count to under ${formatNumberToNumberWithCommas(MAX_VERTICES)} vertices.`
      });
    }
    
    if (model?.metadata?.textures?.['2048']?.totalCount && model.metadata.textures['2048']?.totalCount > MAX_TEXTURES['2048']) {
      newWarnings.push({
        id: 2,
        header: "Too Many 2K Textures",
        message: `Some devices may struggle to display this model. To improve compatibility, consider using fewer than ${formatNumberToNumberWithCommas(MAX_TEXTURES['2048'])} textures with 2k resolution.`
      });
    }
    
    if (model?.metadata?.textures?.['4096']?.totalCount && model.metadata.textures['4096']?.totalCount > MAX_TEXTURES['4096']) {
      newWarnings.push({
        id: 3,
        header: "Too Many 4K Textures",
        message: `Some devices may struggle to display this model. To improve compatibility, consider using fewer than ${formatNumberToNumberWithCommas(MAX_TEXTURES['4096'])} textures with 4k resolution.`
      });
    }

    if (model?.metadata?.textures?.NPOT?.totalCount && model.metadata.textures.NPOT.totalCount > 0) {
      newWarnings.push({
        id: 4,
        header: "Non-power-of-2 Textures",
        message: "Some devices may struggle to display this model. To improve compatibility, consider revising textures to have power-of-2 dimensions (e.g. 512×512, 1024×1024)."
      });
    }

    if (hasSkeleton) {
      newWarnings.push({
        id: 5,
        header: "Skeletal Structures",
        message: "Interactive features like annotations may not work correctly. Consider using a static mesh for full interactivity."
      });
    }

    setWarnings(newWarnings);
  }, [hasSkeleton, model]);

  const handleFeedbackClick = () => {
    if (window.Userback) {
      window.Userback.open('feature_request');
    }
  };

  return (
    <Styled.WarningsTabContainer>
      {warnings.length > 0 ? (
        <Styled.WarningsList>
          {warnings.map((warning) => (
            <Styled.WarningContainer key={warning.id}>
              <Styled.WarningHeader>
                <ExclamationCircleFilled />
                <span>{warning.header}</span>
              </Styled.WarningHeader>
              <Styled.WarningText>{warning.message}</Styled.WarningText>
            </Styled.WarningContainer>
          ))}
        </Styled.WarningsList>
      ) : (
        <Styled.SimpleText>No issues detected! 🎉</Styled.SimpleText>
      )}

      <Styled.FeedbackContainer>
        <Styled.FeedbackText>
          More warnings are coming! Was one unclear or missing? Help us improve!
        </Styled.FeedbackText>
        <Styled.FeedbackButton 
          onClick={handleFeedbackClick}
          $activeColor={buttonBackgroundColor}
          $activeTextColor={buttonTextColor}
        >
          Suggest a Warning
        </Styled.FeedbackButton>
      </Styled.FeedbackContainer>
    </Styled.WarningsTabContainer>
  );
};

export default WarningsTab; 