// Workspace & Model Access Notifications
export const VIEWS_LIMIT =
  'The model owner has reached their view limit for this month. They need to upgrade their account or wait until the view limit is reset. ';
export const AR_VIEWS_LIMIT =
  'The model owner has reached their AR view limit for this month. They need to upgrade their account or wait until the AR view limit is reset. ';
export const NEED_TO_LOGIN = 'Please sign in to view this model.';
export const RESTRICT_VIEWING =
  'You need permission to view this model! Please ask the model owner or switch to an account with permission.';
export const NO_ACCESS_AR = 'Upgrade your account to view this model in AR.';
export const RESTRICT_AR_VIEWING =
  'You need permission to view this model in AR! Please ask the model owner or switch to an account with permission.';
export const RESTRICT_COMMENTS =
  'You need permission to leave comments! Please ask the model owner or switch to an account with permission.';
export const NO_MODEL =
  'Unable to find model! Please check with the model owner as they may have deleted it.';

// File Upload & Format Notifications
export const INCORRECT_FILE_FORMAT =
  'Unsupported file type! Please upload a .GLB, .GLTF, .OBJ, or .STL, or a .ZIP containing one of these formats.';
export const INCORRECT_ENV_FILE_FORMAT =
  'Unsupported file type! Please upload a .GLB, .FBX, .OBJ, or .STL.';
export const FILE_SIZE_LIMIT = 'Model too large! Upgrade your account to upload larger models.';

// Feature Access & Account Notifications
export const RESTRICT_FEATURE =
  'The model owner must upgrade their account before you can use this feature.';
export const UPDATE_YOUR_ACCOUNT = 'Upgrade your account to unlock this feature.';
export const EMAIL_VERIFIED =
  'Your email address has been verified! Please login to complete your account setup.';
export const SUBSCRIPTION_UPDATED = 'Your subscription has been modified.';

// Model Management Notifications
export const LINK_COPIED = 'Sharing link has been successfully copied!';
export const INVITE_SUCCESSFULLY_SENT = 'You have just sent an invite to view this model.';
export const REVOKED_ACCESS = 'You have successfully revoked access to this model.';
export const REVOKED_ALL_ACCESSES = 'You have successfully revoked all access to this model.';
export const MODEL_DELETED = 'Model successfully deleted from your account.';
export const MODEL_UPLOADED = 'Your model has been successfully uploaded.';
export const MODEL_DOWNLOADING = 'Your model is being downloaded.';
export const CHANGES_SAVED = 'Changes successfully saved.';

// Team Workspace Notifications
export const USER_ADDED_TO_TEAM = 'User has been successfully added to the workspace.';
export const USER_INVITED_TO_TEAM = 'User has been successfully invited to the workspace.';
export const TEAM_NAME_CHANGED = 'Your team name has been successfully changed.';
export const TEAM_CREATED = 'Your team has been successfully created.';
export const TEAM_DELETED = 'Your team has been successfully deleted.';
export const UPGRADE_YOUR_TEAM_PLAN = 'Upgrade your team plan to unlock this feature.';
export const TEAM_MODEL_LIMIT = (teamName: string) => 
  `Model limit reached! Upgrade or add seats to unlock more models in ${teamName} workspace.`;
export const TEAM_MODEL_SIZE_LIMIT = (teamName: string) => 
  `Model too large! Upgrade ${teamName} workspace to upload larger models.`;

// Settings & Preferences
export const KEY_BINDINGS_CHANGED = 'Your key bindings have been successfully changed.';
export const KEY_BINDINGS_DELETED = 'Your key bindings have been successfully reset to default.';
