import styled from "styled-components";
import { COLORS } from "shared/constants/colors";

export const WarningsTabContainer = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow-y: auto;
  padding: 0;
  margin-bottom: 15px;
`;

export const WarningsList = styled.div`
  display: flex;
  flex-direction: column;
  padding-top: 15px;
  padding-left: 15px;
  padding-right: 15px;
`;

export const WarningContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.doveGray};
  border-radius: 4px;
  padding: 15px;
  margin-bottom: 15px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
`;

export const WarningHeader = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 8px;
  
  svg {
    font-size: 16px;
    color: ${COLORS.turbo};
    margin-right: 8px;
  }
  
  span {
    font-weight: 500;
    color: ${COLORS.turbo};
    font-size: 14px;
  }
`;

export const WarningText = styled.p`
  margin: 0;
  color: ${COLORS.white};
  font-size: 14px;
  line-height: 1.6;
  word-break: break-word;
`;

export const SimpleText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  color: ${COLORS.white};
  font-size: 14px;
  padding: 15px;
`;

export const FeedbackContainer = styled(WarningContainer)`
  margin: 0 15px;
  text-align: center;
`;

export const FeedbackButton = styled.button<{ $activeColor?: string; $activeTextColor?: string }>`
  width: 100%;
  height: 40px;
  border: none;
  border-radius: 4px;
  background-color: ${({ $activeColor }): string => $activeColor || COLORS.turbo};
  color: ${({ $activeTextColor }): string => $activeTextColor || COLORS.codGray};
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: opacity 0.2s ease;

  &:hover {
    opacity: 0.8;
  }

  &:active {
    opacity: 0.6;
  }
`;

export const FeedbackText = styled(WarningText)`
  margin-bottom: 12px;
`;
