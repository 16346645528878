import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalContainer = styled.div<{ $hasPreview: boolean, $longDescription: boolean }>`
  display: flex;
  height: 95vh;
  max-height: ${({ $hasPreview, $longDescription }) => $hasPreview ? ($longDescription ? '830px' : '800px') : '800px'};
  max-width: ${({ $hasPreview }) => $hasPreview ? '1250px' : '1050px'};
  width: 90vw;
  color: ${COLORS.white};
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};
  border-radius: 7px;
  overflow: hidden;

  @media ${DEVICE.tabletLarge} {
    flex-direction: column;
    overflow-y: auto;
  }
`;

export const LeftSide = styled.div`
  flex: 1;
  padding: 50px 50px 0 50px;
  overflow-y: auto;

  @media ${DEVICE.mobile} {
    padding: 30px;
  }
`;

export const ModelTitleContainer = styled.div`
  margin-bottom: 30px;
  
  h1 {
    padding-top: 0;
  }
`;

export const FeaturePreview = styled.img`
  display: none;

  @media ${DEVICE.tabletLarge} {
    width: 400px;
    display: block;
    margin: 0 auto 30px auto;
  }
`;

export const TitleText = styled.span`
  display: inline;
`;

export const Lock = styled.span`
  display: inline-flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.turbo};
  width: 28px;
  min-width: 28px;
  height: 28px;
  margin-right: 15px;
  vertical-align: middle;
  position: relative;
  top: -2px;

  img {
    width: 20px;
    height: 20px;
  }
`;

export const RightSide = styled.div`
  background: ${DEFAULT_THEME_CSS_VARIABLES.modalBackgroundColor};
  display: flex;
  align-items: center;
  justify-content: center;
  max-width: 400px;

  @media ${DEVICE.tabletLarge} {
    display: none;
  }

  img {
    height: 100%;
    object-fit: cover;
  }
`;
