import { useAppSelector } from 'shared/hooks/store-hooks';
import { RootState } from 'services/store';
import { ESnackbarStyle, EModelType, ModelSettings } from 'shared/types';
import { MAX_MODEL_NAME_LENGTH } from 'shared/constants/limits';
import { getChangedModel } from 'utils/model-utils';
import { updateModel } from 'services/api/modelService';
import {
  getEmbeddedModelSettingsStatus,
  setModelDataLocalStorage,
  updateModelInSessionStorage
} from 'utils/storage-utils';
import { openNotification } from 'utils/notification-utils';
import { useEffect, useMemo, useRef, useState } from 'react';
import { useDebounce } from 'shared/hooks';
import { CHANGES_SAVED } from 'shared/constants/notifications';
import { API_REQUEST_CANCELED_CODE } from 'shared/constants/errors';

type Result = {
  isSettingsSaving: boolean;
};

type Settings = {
  modelSettings: ModelSettings;
  modelName: string;
};

const useAutoSave = (): Result => {
  const store = useAppSelector((store): RootState => store);
  const {
    model,
    modelSettings,
    modelName,
    modelType,
    isSceneCompletelyLoaded,
    isEmbeddedModelMode
  } = store.viewerData;
  const { activeBranding } = store.branding;
  const { hasEditAccess } = store.modelFeatureAccess;
  const [isSettingsSaving, setIsSettingsSaving] = useState<boolean>(false);
  
  const currentSettings = useMemo(
    (): Settings => ({ modelSettings, modelName }),
    [modelSettings, modelName]
  );
  const DEBOUNCE_TIME = 3000;
  const debouncedSettings = useDebounce(currentSettings, DEBOUNCE_TIME);
  const isInitModelSetup = useRef<boolean>(true);
  const isAnonymousModel = modelType === EModelType.ANONYMOUS;
  const lastSaveTime = useRef<number>(0);

  const saveModelChanges = async (
    settings: Settings,
    showNotification = false
  ): Promise<void> => {
    if (!model) return;
    
    try {
      setIsSettingsSaving(true);
      const name = settings.modelName.trim().slice(0, MAX_MODEL_NAME_LENGTH) || model.modelName;
      
      const changedModel = getChangedModel(
        name,
        settings.modelSettings,
        activeBranding.id
      );

      await updateModel(changedModel, model.id);
      updateModelInSessionStorage(changedModel);
      
      lastSaveTime.current = Date.now();
      
      if (showNotification) {
        openNotification(ESnackbarStyle.SUCCESS, CHANGES_SAVED);
      }
    } catch (e) {
      if (e?.code === API_REQUEST_CANCELED_CODE) return;
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      setIsSettingsSaving(false);
    }
  };

  const handleModelSave = (showNotification: boolean) => {
    const hasChangeAccess =
      isSceneCompletelyLoaded &&
      hasEditAccess &&
      !isInitModelSetup.current &&
      !isEmbeddedModelMode &&
      !getEmbeddedModelSettingsStatus();

    const timeSinceLastSave = Date.now() - lastSaveTime.current;

    if (hasChangeAccess && timeSinceLastSave > DEBOUNCE_TIME) {
      saveModelChanges(currentSettings, showNotification);
    }
    isInitModelSetup.current = false;
  };

  // Save these changes immediately after change. //
  useEffect((): void => {
    handleModelSave(false);
  }, [modelName, modelSettings.camera]);

  // Save all remaining changes after debounce. //
  useEffect((): void => {
    handleModelSave(true);
  }, [debouncedSettings]);

  useEffect((): void => {
    if (isAnonymousModel) {
      setModelDataLocalStorage({ 
        modelName: debouncedSettings.modelName, 
        modelSettings: debouncedSettings.modelSettings, 
        modelType: EModelType.ANONYMOUS 
      });
    }
  }, [debouncedSettings, isAnonymousModel]);

  return { isSettingsSaving };
};

export default useAutoSave;
