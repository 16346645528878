import React from 'react';
import * as Styled from './styles';
import { ModalSubtitle, ModalTitle } from 'shared/styles';
import Lock from 'assets/images/lock-icon.svg';
import { SubscriptionInfo } from 'shared/components';
import { EPremiumFeature, EQuotaName } from 'shared/types';
import { FEATURES } from 'shared/constants/features';
import { COLORS } from 'shared/constants/colors';
import { useAppSelector } from 'shared/hooks';

type Props = {
  feature: EPremiumFeature | EQuotaName;
};

const ModalFeatureSignedIn: React.FC<Props> = ({ feature }): JSX.Element => {
  const { user } = useAppSelector((state) => state.auth);
  const description = FEATURES[feature].description.map((line, index) => (
    <span key={`modal-desc-${index}`} style={{ color: index % 2 === 0 ? undefined : COLORS.turbo }}>
      {line}
      {' '}
    </span>
  ));
  const hasPreview = !!FEATURES[feature].preview;
  const longDescription = FEATURES[feature].description.reduce((acc, line) => acc + line.length, 0) > 90;

  return (
    <Styled.ModalContainer $hasPreview={hasPreview} $longDescription={longDescription}>
      <Styled.LeftSide>
        <Styled.ModelTitleContainer>
          <ModalTitle>
            <Styled.TitleText>
              <Styled.Lock>
                <img src={Lock} alt='Lock' />
              </Styled.Lock>{FEATURES[feature].title}
            </Styled.TitleText>
          </ModalTitle>
          <ModalSubtitle>
            <span style={{ color: COLORS.turbo, fontWeight: 600 }}>
              {['Hey', 'Hi', 'Hello'][Math.floor(Math.random() * 3)]} {user?.firstName}!
            </span> {FEATURES[feature].hook}
          </ModalSubtitle>
          <ModalSubtitle>{description}</ModalSubtitle>
        </Styled.ModelTitleContainer>
        {hasPreview && (
          <Styled.FeaturePreview src={FEATURES[feature].preview} alt={FEATURES[feature].title} />
        )}
        <SubscriptionInfo isModal hideCustomPlan={hasPreview} />
      </Styled.LeftSide>
      {hasPreview && (
        <Styled.RightSide>
          <img src={FEATURES[feature].preview} alt={FEATURES[feature].title} />
        </Styled.RightSide>
      )}
    </Styled.ModalContainer>
  );
};

export default ModalFeatureSignedIn;
