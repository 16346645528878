import * as THREE from 'three';
import { INTERNAL_OBJECT_WRAPPER_NAME } from 'shared/constants/scenes-constants';
import { ETextureSizes } from 'shared/enums/ETextureSizes';
import { DEVICE_SIZES } from 'shared/constants/deviceSizes';
import { GroundWithFadeShader } from 'shared/webgl/gl-shaders';
import { ModelMaterial } from 'shared/types/model';
import { AxisValues } from 'shared/types';

interface IntersectionPart {
  object: THREE.Intersection['object'];
  point: THREE.Intersection['point'];
  face: THREE.Intersection['face'];
  distance: THREE.Intersection['distance'];
}

interface SetupRendererDomElementProps {
  element: HTMLElement;
  id: string;
  top?: number;
  right?: number;
  bottom?: number;
  left?: number;
  disablePinterEvents?: boolean;
}

export const clearObject = (obj: THREE.Object3D): void => {
  obj?.traverseVisible((obj: any): void => {
    const _obj = obj as THREE.Mesh;

    if (Array.isArray(_obj.material)) {
      _obj.material.forEach((_material: any): void => {
        _material.dispose();
      });
    }

    if (_obj.material) {
      (_obj.material as THREE.Material).dispose();
    }

    if (_obj.geometry) {
      (_obj.geometry as THREE.BufferGeometry).dispose();
    }
  });

  obj?.clear();
};

export const getCameraAngleRelativeToObject = (
  camera: THREE.Object3D,
  object: THREE.Object3D,
  callback?: (angle: number) => void
): number => {
  const cameraPosition = camera.getWorldPosition(new THREE.Vector3());
  const targetPosition = object.getWorldPosition(new THREE.Vector3());
  const distX = targetPosition.x - cameraPosition.x;
  const distZ = targetPosition.z - cameraPosition.z;
  const angle = (Math.atan2(distX, distZ) * 180) / Math.PI - 41;

  if (callback) {
    callback(angle);
  }

  return angle;
};

export const getObjectFullPath = (object: THREE.Object3D): string => {
  const path: string[] = [];
  let parent: THREE.Object3D | null = object;

  while (!!parent && parent.name !== INTERNAL_OBJECT_WRAPPER_NAME) {
    path.push(parent.name);
    parent = parent.parent;
  }

  return path.reverse().join('/');
};

export const findObjectByPath = (
  path: string,
  parentIndex: number,
  indexedMap: THREE.Object3D[],
  updateIndexedMap: () => void,
  notificationCallback?: () => void
): THREE.Object3D | undefined => {
  updateIndexedMap();

  const name = path.split('/').pop()!;
  const splitPath = path.split('/');
  // deletes unnecessary part of path for old comments
  const modelInnerPath = splitPath.slice(splitPath.indexOf(INTERNAL_OBJECT_WRAPPER_NAME) + 1);
  const objects = indexedMap.filter((item): boolean => item.name === name);

  if (objects.length === 1) {
    return objects[0];
  }

  for (let i = 0; i < modelInnerPath.length; i++) {
    const targetPath = modelInnerPath.slice(i).join('/');
    const foundItem = objects.find((item): boolean => getObjectFullPath(item).includes(targetPath));

    if (foundItem) {
      if (indexedMap[parentIndex] !== foundItem && notificationCallback) {
        notificationCallback();
      }

      return foundItem;
    }
  }
};

export const checkIfPointInCameraFrustum = (
  point: THREE.Vector3,
  camera: THREE.PerspectiveCamera
): boolean => {
  updateMatrices(camera);

  const frustum = new THREE.Frustum();
  const matrix = new THREE.Matrix4();

  matrix.multiplyMatrices(camera.projectionMatrix, camera.matrixWorldInverse);
  frustum.setFromProjectionMatrix(matrix);

  return frustum.containsPoint(point);
};

export const generateGriddedEnvironment = async (
  renderer: THREE.WebGLRenderer,
  groundTexturePath: string,
  groundMaskColor: THREE.Vector4,
  sphereColor: THREE.Color
): Promise<THREE.Group> => {
  const groundGeometry = new THREE.CircleGeometry(1, 100);
  const groundTexture = await new THREE.TextureLoader().loadAsync(groundTexturePath);

  groundTexture.anisotropy = renderer.capabilities.getMaxAnisotropy();
  groundTexture.minFilter = THREE.LinearMipmapLinearFilter;
  groundTexture.magFilter = THREE.LinearFilter;
  groundTexture.wrapT = THREE.RepeatWrapping;
  groundTexture.wrapS = THREE.RepeatWrapping;

  const groundMaterial = new THREE.ShaderMaterial({
    uniforms: THREE.UniformsUtils.merge([
      THREE.UniformsLib.lights,
      THREE.UniformsLib.fog,
      {
        maskColor: { value: groundMaskColor },
        ground: { value: groundTexture }
      }
    ]),
    vertexShader: GroundWithFadeShader.vertexSource,
    fragmentShader: GroundWithFadeShader.fragmentSource,
    lights: true,
    transparent: true,
    side: THREE.DoubleSide
  });
  const ground = new THREE.Mesh(groundGeometry, groundMaterial);

  ground.position.set(0, 0, 0);
  ground.rotation.set(THREE.MathUtils.degToRad(-90), 0, 0);
  ground.scale.set(1, 1, 1);

  const sphereGeometry = new THREE.SphereGeometry(1);
  const sphereMaterial = new THREE.MeshBasicMaterial({
    color: sphereColor,
    side: THREE.BackSide
  });
  const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

  sphere.position.set(0, 0, 0);
  sphere.rotation.set(0, 0, 0);
  sphere.scale.set(1, 1, 1);

  const group = new THREE.Group();

  group.add(sphere);
  group.add(ground);

  return group;
};

export const generateSimpleEnvironment = async (
  renderer: THREE.WebGLRenderer,
  color: THREE.Color
): Promise<THREE.Group> => {
  const sphereGeometry = new THREE.SphereGeometry(1);
  const sphereMaterial = new THREE.MeshBasicMaterial({
    color,
    side: THREE.BackSide
  });
  const sphere = new THREE.Mesh(sphereGeometry, sphereMaterial);

  sphere.position.set(0, 0, 0);
  sphere.rotation.set(0, 0, 0);
  sphere.scale.set(1, 1, 1);

  const groundGeometry = new THREE.CircleGeometry(1, 100);
  const groundMaterial = new THREE.ShadowMaterial({
    transparent: true,
    opacity: 0.5,
    side: THREE.DoubleSide
  });

  const ground = new THREE.Mesh(groundGeometry, groundMaterial);
  ground.position.set(0, 0, 0);
  ground.rotation.set(THREE.MathUtils.degToRad(-90), 0, 0);
  ground.scale.set(1, 1, 1);

  const group = new THREE.Group();
  group.add(sphere);
  group.add(ground);

  return group;
};

export const getDeviceMaxTextureSize = (canvasId: string): ETextureSizes => {
  const gl = (document.getElementById(canvasId) as HTMLCanvasElement).getContext('webgl2');

  const maxTextureSize = gl!.getParameter(gl!.MAX_TEXTURE_SIZE);

  return maxTextureSize >= 2048
    ? maxTextureSize >= 4096
      ? ETextureSizes.d4k
      : ETextureSizes.d2k
    : ETextureSizes.d1k;
};

export const setupRenderer = (
  renderer: THREE.WebGLRenderer,
  width: number,
  height: number
): void => {
  renderer.setClearColor(0xcccccc, 0);
  renderer.setPixelRatio(window.innerWidth >= DEVICE_SIZES.desktop ? window.devicePixelRatio : 1);
  renderer.setSize(width, height);
  renderer.shadowMap.type = THREE.PCFSoftShadowMap;
  renderer.shadowMap.enabled = true;
  renderer.toneMapping = THREE.LinearToneMapping;
  renderer.outputColorSpace = THREE.SRGBColorSpace;
};

export const getObjectBoundingBox = (target: THREE.Object3D): THREE.Box3 => {
  const box = new THREE.Box3();

  target.traverse((mesh): void => {
    if (mesh instanceof THREE.Mesh<any>) {
      const geometry = mesh.geometry;
      const positionAttribute = geometry.getAttribute('position');

      for (let i = 0, l = positionAttribute?.count; i < l; i++) {
        const vertex = new THREE.Vector3().fromBufferAttribute(positionAttribute, i);

        mesh.localToWorld(vertex);
        box.expandByPoint(vertex);
      }
    }
  });

  return box;
};

export const getObjectScaleFactor = (
  target: THREE.Object3D | THREE.Box3,
  desiredSize: number,
  deepCheck: boolean = false
): number => {
  const boundingBox = !(target instanceof THREE.Box3)
    ? deepCheck
      ? getObjectBoundingBox(target)
      : new THREE.Box3().setFromObject(target, true)
    : target;

  return (
    desiredSize /
    Math.max(
      boundingBox.max.x - boundingBox.min.x,
      boundingBox.max.y - boundingBox.min.y,
      boundingBox.max.z - boundingBox.min.z
    )
  );
};

export const checkIfModelContainsSkinnedMesh = (object: THREE.Object3D): boolean => {
  let hasSkinnedMesh = false;

  object.traverse((item): void => {
    if (item instanceof THREE.SkinnedMesh) {
      hasSkinnedMesh = true;

      return;
    }
  });

  return hasSkinnedMesh;
};

export const updateSkinnedMeshBB = (skinnedMesh: THREE.SkinnedMesh, aabb: THREE.Box3): void => {
  const skeleton = skinnedMesh.skeleton;
  const boneMatrices = skeleton.boneMatrices;
  const geometry = skinnedMesh.geometry;
  const index = geometry.index;
  const position = geometry.attributes.position;
  const skinIndex = geometry.attributes.skinIndex as THREE.BufferAttribute;
  const skinWeight = geometry.attributes.skinWeight as THREE.BufferAttribute;
  const bindMatrix = skinnedMesh.bindMatrix;
  const bindMatrixInverse = skinnedMesh.bindMatrixInverse;

  aabb.makeEmpty();

  const count = index ? index.count : position.count;

  for (let i = 0; i < count; i++) {
    const indexX = index ? index.getX(i) : i;
    const vertex = new THREE.Vector3();
    const temp = new THREE.Vector3();
    const skinned = new THREE.Vector3();
    const skinIndices = new THREE.Vector4();
    const skinWeights = new THREE.Vector4();
    const boneMatrix = new THREE.Matrix4();

    vertex.fromBufferAttribute(position, indexX);
    skinIndices.fromBufferAttribute(skinIndex, indexX);
    skinWeights.fromBufferAttribute(skinWeight, indexX);

    vertex.applyMatrix4(bindMatrix);
    skinned.set(0, 0, 0);

    for (let j = 0; j < 4; j++) {
      const si = skinIndices.getComponent(j);
      const sw = skinWeights.getComponent(j);
      boneMatrix.fromArray(boneMatrices, si * 16);

      temp.copy(vertex).applyMatrix4(boneMatrix).multiplyScalar(sw);
      skinned.add(temp);
    }

    skinned.applyMatrix4(bindMatrixInverse);
    aabb.expandByPoint(skinned);
  }

  aabb.applyMatrix4(skinnedMesh.matrixWorld);
};

export const intersectSkinnedMeshByTriangles = (
  skinnedMesh: THREE.SkinnedMesh,
  rayCaster: THREE.Raycaster
): IntersectionPart[] => {
  const skeleton = skinnedMesh.skeleton;
  const boneMatrices = skeleton.boneMatrices;
  const geometry = skinnedMesh.geometry;
  const index = geometry.index;
  const position = geometry.attributes.position;
  const skinIndex = geometry.attributes.skinIndex as THREE.BufferAttribute;
  const skinWeight = geometry.attributes.skinWeight as THREE.BufferAttribute;
  const bindMatrix = skinnedMesh.bindMatrix;
  const bindMatrixInverse = skinnedMesh.bindMatrixInverse;
  const intersects: IntersectionPart[] = [];

  const getTransformedVertex = (index: number): THREE.Vector3 => {
    const vertex = new THREE.Vector3();
    const temp = new THREE.Vector3();
    const skinned = new THREE.Vector3();
    const skinIndices = new THREE.Vector4();
    const skinWeights = new THREE.Vector4();
    const boneMatrix = new THREE.Matrix4();

    vertex.fromBufferAttribute(position, index);
    skinIndices.fromBufferAttribute(skinIndex, index);
    skinWeights.fromBufferAttribute(skinWeight, index);

    vertex.applyMatrix4(bindMatrix);
    skinned.set(0, 0, 0);

    for (let j = 0; j < 4; j++) {
      const si = skinIndices.getComponent(j);
      const sw = skinWeights.getComponent(j);
      boneMatrix.fromArray(boneMatrices, si * 16);

      temp.copy(vertex).applyMatrix4(boneMatrix).multiplyScalar(sw);
      skinned.add(temp);
    }

    return skinned.applyMatrix4(bindMatrixInverse).applyMatrix4(skinnedMesh.matrixWorld);
  };

  const count = index ? index.count : position.count;

  for (let i = 0; i < count; i++) {
    const indexA = index ? index.getX(i) : i;
    const indexB = index ? index.getX(i + 1) : i + 1;
    const indexC = index ? index.getX(i + 2) : i + 2;

    const vertexA = getTransformedVertex(indexA);
    const vertexB = getTransformedVertex(indexB);
    const vertexC = getTransformedVertex(indexC);

    const intersectionPoint = rayCaster.ray.intersectTriangle(
      vertexA,
      vertexB,
      vertexC,
      false,
      new THREE.Vector3()
    );

    if (intersectionPoint) {
      intersects.push({
        object: skinnedMesh,
        point: intersectionPoint,
        distance: intersectionPoint.distanceTo(rayCaster.ray.origin),
        face: null
      });
    }
  }

  return intersects;
};

export const intersectSkinnedObjectByBBs = (
  rayCaster: THREE.Raycaster,
  object: THREE.Object3D
): IntersectionPart[] => {
  const intersects: IntersectionPart[] = [];

  object.traverse((skinnedMesh): void => {
    if (skinnedMesh instanceof THREE.SkinnedMesh) {
      const aabb = new THREE.Box3();

      updateSkinnedMeshBB(skinnedMesh, aabb);

      const intersectionPoint = rayCaster.ray.intersectBox(aabb, new THREE.Vector3());

      if (intersectionPoint) {
        intersects.push({
          object: skinnedMesh,
          point: intersectionPoint,
          distance: intersectionPoint.distanceTo(rayCaster.ray.origin),
          face: null
        });
      }
    }
  });

  return intersects.sort((a, b): number => a.distance - b.distance);
};

export const intersectSkinnedObjectByTriangles = (
  rayCaster: THREE.Raycaster,
  object: THREE.Object3D
): IntersectionPart[] => {
  const intersects: IntersectionPart[] = [];

  object.traverse((skinnedMesh): void => {
    if (skinnedMesh instanceof THREE.SkinnedMesh) {
      intersects.concat(intersectSkinnedMeshByTriangles(skinnedMesh, rayCaster));
    }
  });

  return intersects.sort((a, b): number => a.distance - b.distance);
};

export const setupRendererDomElement = ({
  element,
  id,
  top,
  right,
  bottom,
  left,
  disablePinterEvents
}: SetupRendererDomElementProps): void => {
  element.id = id;
  element.style.position = 'absolute';
  element.style.scale = '0.9999';

  if (top) {
    element.style.top = top + 'px';
  } else if (bottom) {
    element.style.bottom = bottom + 'px';
  }

  if (left) {
    element.style.left = left + 'px';
  } else if (right) {
    element.style.right = right + 'px';
  }

  if (disablePinterEvents) {
    element.style.pointerEvents = 'none';
  }
};

export const traverseMaterials = (
  target: THREE.Object3D,
  callback: (material: ModelMaterial) => void
): void => {
  target.traverse((item): void => {
    if (item instanceof THREE.Mesh<any>) {
      const materials = Array.isArray(item.material) ? item.material : [item.material];

      materials.forEach(callback);
    }
  });
};

export const setFromAxial = (target: THREE.Vector3 | THREE.Euler, axial: AxisValues): void => {
  target.set(axial.x, axial.y, axial.z);
};

export const setFromDegrees = (target: THREE.Vector3 | THREE.Euler, degrees: AxisValues): void => {
  target.set(
    THREE.MathUtils.degToRad(degrees.x),
    THREE.MathUtils.degToRad(degrees.y),
    THREE.MathUtils.degToRad(degrees.z)
  );
};

export const getMinAxial = (target: THREE.Vector3 | THREE.Euler): number => {
  return Math.min(...Object.values(target));
};

export const getAxialMax = (target: THREE.Vector3 | THREE.Euler | AxisValues): number => {
  return Math.max(target.x, target.y, target.z);
};

export const checkFiniteness = (target: (THREE.Vector3 | THREE.Euler | AxisValues)[]): boolean => {
  return target.every(
    (item): boolean =>
      Number.isFinite(item.x) &&
      Number.isFinite(item.y) &&
      Number.isFinite(item.z)
  );
};

export const updateMatrices = (target: THREE.Object3D): void => {
  target.updateMatrixWorld(true);
  target.updateMatrix();
  target.updateWorldMatrix(true, true);
}
