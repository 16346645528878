import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';
import { HEADER_HEIGHT, HEADER_MESSAGE } from 'shared/constants/header';
import { HEADER_MESSAGE_HEIGHT } from 'shared/constants/header';

export const getContentPaddingTopCSS = (
  isArMode: boolean,
  isZenMode: boolean,
  isEmbeddedModelMode: boolean,
): string => {
  if (isZenMode || isEmbeddedModelMode) return 'padding-top: 0px;';

  const headerHeightDesktop = isArMode ? HEADER_HEIGHT.SMALL : HEADER_HEIGHT.LARGE;
  const messageHeight = (HEADER_MESSAGE) ? HEADER_MESSAGE_HEIGHT : 0;
  return `
    padding-top: ${headerHeightDesktop + messageHeight}px;

    @media ${DEVICE.tablet} {
      padding-top: ${HEADER_HEIGHT.SMALL + messageHeight}px;
    }
  `;
}

const getHeightCalcCSS = (
  headerHeight: number,
  includeFooter: boolean,
  property: 'height' | 'min-height' | 'max-height'
): string => {
  const headerMessageHeight = (HEADER_MESSAGE && headerHeight > 0) ? HEADER_MESSAGE_HEIGHT : 0;
  const headerHeightDesktop = headerHeight + headerMessageHeight;
  const headerHeightTablet = Math.min(HEADER_HEIGHT.SMALL, headerHeight) + headerMessageHeight;
  const footerHeight = includeFooter ? 0 : 0;

  return `
    ${property}: calc(100vh - ${headerHeightDesktop}px - ${footerHeight}px);
    @supports (height: 100dvh) {
      ${property}: calc(100dvh - ${headerHeightDesktop}px - ${footerHeight}px);
    }

    @media ${DEVICE.tablet} {
      ${property}: calc(100vh - ${headerHeightTablet}px);
      @supports (height: 100dvh) {
        ${property}: calc(100dvh - ${headerHeightTablet}px);
      }
    }
  `;
};

export const getContentMaxHeightCSS = (headerHeight: number, includeFooter: boolean): string => 
  getHeightCalcCSS(headerHeight, includeFooter, 'max-height');

export const getContentHeightCSS = (headerHeight: number, includeFooter: boolean): string => 
  getHeightCalcCSS(headerHeight, includeFooter, 'height');

export const getContentMinHeightCSS = (headerHeight: number, includeFooter: boolean): string => 
  getHeightCalcCSS(headerHeight, includeFooter, 'min-height');

export const ErrorMessage = styled.div`
  margin-top: 3px;
  color: ${COLORS.red};
  font-size: 12px;
  font-weight: 700;
`;

export const PageContainer = styled.div`
  width: 100%;
  min-height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  padding: 20px 30px;
  position: relative;

  @media ${DEVICE.tablet} {
    padding: 20px 10px;
  }
`;

export const FullPageContainer = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  flex: 1;
  align-items: center;
  justify-content: center;
  padding: 20px;

  .spinner {
    width: 70px;
    height: 70px;
  }
`;

export const PageTitle = styled.h1`
  font-family: ${FONTS.interRegular};
  font-size: 22px;
  font-weight: 700;
  display: flex;
  width: 100%;
  color: ${COLORS.mischka};
  margin-bottom: 0;
`;

export const ContentHeader = styled.header`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: var(--page-background-color);
  position: sticky;
  padding: 10px 0;
  z-index: 3;

  @media ${DEVICE.tablet} {
    flex-direction: column;
  }
`;

export const FormFieldLabel = styled.label`
  display: inline-block;
  font-family: ${FONTS.interRegular};
  font-weight: 600;
  font-size: 12px;
  line-height: 14px;
  color: ${COLORS.white};
  padding-bottom: 8px;
`;

export const ModalWindowContainer = styled.div`
  display: flex;
  font-family: ${FONTS.interRegular};
  max-width: 1170px;
  max-height: 860px;
  height: 85vh;
  width: 90vw;
  color: ${COLORS.white};
  position: relative;
  overflow-y: scroll;
  background: #090909;

  ::-webkit-scrollbar {
    width: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLORS.doveGray};
    border-radius: 2px;
  }

  @media${DEVICE.mobile} {
    width: 95vw;
  }
`;

export const ModalTitle = styled.h1`
  font-weight: 600;
  font-size: 32px;
  line-height: 140%;
  padding-top: 15px;
  color: ${COLORS.white};
  margin-bottom: 0;
`;

export const ModalSubtitle = styled.h2`
  font-weight: 400;
  font-size: 20px;
  margin-top: 15px;
  line-height: 140%;
  color: ${COLORS.mischka};
  margin-bottom: 0;

  .log-in {
    margin-left: 5px;
    color: ${COLORS.turbo};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;

export const FeatureDescription = styled.article`
  font-weight: 400;
  font-size: 12px;
  line-height: 14px;
  display: flex;
  color: ${COLORS.white};
`;

export const ModalForm = styled.form`
  margin-top: 40px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const SubmitButton = styled.button`
  margin: 30px auto 0 auto;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 200px;
  height: 36px;
  font-weight: 600;
  color: ${COLORS.black};
  font-family: ${FONTS.interRegular};
  border-radius: 4px;
  background: ${COLORS.turbo};
  opacity: 0.9;
  font-size: 15px;
  line-height: 17px;
  transition: all 0.2s;

  &:hover {
    background: ${COLORS.corn};
  }
`;

export const ModalCopyright = styled.div`
  display: flex;
  align-items: center;
  align-self: flex-start;
  opacity: 0.5;
  padding-top: 20px;
  margin-top: auto;

  span {
    font-weight: 500;
    font-size: 10px;
    line-height: 12px;
    color: ${COLORS.white};
  }

  img {
    margin-left: 3px;
    width: 70px;
    height: 18px;
  }
`;

export const CustomMessageContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 5px;
`;

export const CustomMessageContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  border-right: 3px solid ${COLORS.turbo};
  border-left: 3px solid ${COLORS.turbo};
  max-width: 530px;
  padding: 10px;
`;

export const CustomMessageContentText = styled.span`
  color: ${COLORS.white};
  font-size: 14px;
`;

export const CustomAlertContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 20px 14px;
  max-width: 500px;
  border: 1px solid ${COLORS.turbo};
  border-radius: 4px;
  position: relative;
`;

export const CustomAlertTitle = styled.div`
  display: flex;
  align-items: center;

  & > h1 {
    font-size: 22px;
    line-height: 24px;
    font-weight: 700;
    color: ${COLORS.white};
    margin: 4px 0 0 4px;
  }
`;

export const CustomAlertContent = styled.div`
  margin-top: 20px;
  color: ${COLORS.white};
  font-size: 14px;
`;

export const CloseAlertButton = styled.div`
  position: absolute;
  top: 12px;
  right: 4px;
`;

export const EmailNotificationTitle = styled.h1`
  font-weight: 600;
  font-size: 38px;
  line-height: 40px;
  color: ${COLORS.white};
  margin-bottom: 0;
  text-align: center;
`;

export const EmailNotificationSubtitle = styled.h2`
  font-weight: 400;
  font-size: 22px;
  line-height: 30px;
  margin: 20px 0 0;
  text-align: center;
  color: rgba(214, 215, 227, 0.8);

  & > .email {
    color: ${COLORS.turbo};
  }
`;

export const NoEmailText = styled.p`
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
  color: rgba(255, 255, 255, 0.8);
  margin: 20px 0 0;
  text-align: center;
`;

export const IncorrectEmailText = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;

  span {
    font-size: 18px;
    line-height: 20px;
    white-space: nowrap;
  }

  span:first-child {
    color: rgba(214, 215, 227, 0.8);
    margin-right: 5px;
  }

  .highlighted-text {
    font-weight: 600;
    color: ${COLORS.turbo};
    cursor: pointer;

    &:hover {
      text-decoration: underline;
    }
  }
`;
