import React, { useRef, useState } from 'react';
import * as Styled from './styles';
import { useAppDispatch } from 'shared/hooks';
import { closeConfirmModal } from 'services/store/reducers/modalReducer';
import { MAX_MODEL_NAME_LENGTH } from 'shared/constants/limits';
import { ErrorMessage } from 'shared/styles';
import { setModelName } from 'services/store/reducers/viewerDataReducer';

type Props = {
  modelName: string;
};

const ModalRenameModel: React.FC<Props> = ({ modelName }): JSX.Element => {
  const dispatch = useAppDispatch();
  const inputRef = useRef<HTMLInputElement>(null);
  const [error, setError] = useState<string>('');

  const handleRenameButtonClick = async (): Promise<void> => {
    if (!inputRef.current) return;
    
    const trimmedValue = inputRef.current.value.trim();
    
    if (!trimmedValue) {
      setError('Model name cannot be empty.');
      return;
    } else if (trimmedValue.length > MAX_MODEL_NAME_LENGTH) {
      setError(`Model name cannot exceed ${MAX_MODEL_NAME_LENGTH} characters.`);
      return;
    } else if (trimmedValue !== modelName) {
      dispatch(setModelName(trimmedValue));
    } 
    
    dispatch(closeConfirmModal());
  };

  const handleKeyUpdate = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.key === 'Enter') {
      await handleRenameButtonClick();
    } else if (event.currentTarget.value.trim() === '') {
      setError('Model name cannot be empty.');
    } else {
      setError('');
    }
  };

  const handleCancelButtonClick = (): void => {
    dispatch(closeConfirmModal());
  };

  return (
    <Styled.ModalWindowContainer>
      <Styled.Message>
        Enter a new name for your model below.
      </Styled.Message>
      <Styled.NameInput
        ref={inputRef}
        defaultValue={modelName}
        onKeyUp={handleKeyUpdate}
        maxLength={MAX_MODEL_NAME_LENGTH}
        autoFocus
      />
      {error && <ErrorMessage>{error}</ErrorMessage>}
      <Styled.ActionButtons>
        <Styled.ActionButton className='cancel-button' onClick={handleCancelButtonClick}>
          Cancel
        </Styled.ActionButton>
        <Styled.ActionButton
          className='rename-button'
          onClick={handleRenameButtonClick}
          disabled={!!error}
        >
          Rename
        </Styled.ActionButton>
      </Styled.ActionButtons>
    </Styled.ModalWindowContainer>
  );
};

export default ModalRenameModel;
