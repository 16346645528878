import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';
import { getContentHeightCSS } from 'shared/styles';
import { HEADER_HEIGHT } from 'shared/constants/header';

export const SidebarItemIcon = styled.div`
  width: 14px;
  height: 14px;
  margin-right: 13px;
  color: var(--text-color);
  filter: drop-shadow(1px 1px 1px rgb(0 0 0 / 0.7));
`;

export const SidebarItemTitle = styled.div<{ $isPageWithScene: boolean }>`
  margin-top: 2px;
  font-size: 14px;
  font-weight: 500;
  color: var(--text-color);
  white-space: nowrap;
  display: ${({ $isPageWithScene }): string => ($isPageWithScene ? 'none' : 'block')};
  max-width: 140px;
  text-transform: capitalize;
  overflow: hidden;
  text-overflow: ellipsis;

  @media ${DEVICE.tabletLarge} {
    display: block;
  }
`;

export const SidebarContainer = styled.aside<{
  $isSidebarOpened: boolean;
  $isPageWithScene: boolean;
}>`
  width: ${({ $isPageWithScene }): string => ($isPageWithScene ? '60px' : '220px')};
  left: 0;
  height: 100vh;
  position: fixed;
  z-index: 1052;
  background: var(--sidebar-background-color);
  backdrop-filter: blur(5px);
  transition: all 0.3s;
  border-right: 1px solid rgba(255, 255, 255, 0.17);
  display: flex;

  &:hover {
    width: 220px;

    ${SidebarItemTitle} {
      display: block;
    }
  }

  @media ${DEVICE.tabletLarge} {
    width: 220px;
    z-index: 1052;
    transform: ${({ $isSidebarOpened }): string =>
      $isSidebarOpened ? 'translateX(0%)' : 'translateX(-100%)'};
  }
`;

export const SidebarContent = styled.div`
  display: flex;
  flex-direction: column;
  ${() => getContentHeightCSS(HEADER_HEIGHT.LARGE, false)}
  overflow-x: hidden;
  overflow-y: auto;
  min-height: 0;
  height: 100%;
`;

export const SidebarItems = styled.nav`
  display: flex;
  flex-direction: column;
  padding-top: 10px;
  flex: 1;
`;

export const SidebarMenuBlock = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:last-child) {
    &:after {
      content: '';
      margin: 0 auto;
      width: 85%;
      height: 1px;
      background-color: var(--text-color);
      opacity: 0.2;
    }
  }
`;

export const SidebarItem = styled.div`
  display: flex;
  align-items: center;
  padding-left: 22px;
  width: 100%;
  min-height: 45px;
  cursor: pointer;

  a {
    display: flex;
    align-items: center;
  }

  & > .active,
  &:hover {
    ${SidebarItemIcon} {
      color: var(--theme-secondary-color);
    }

    ${SidebarItemTitle} {
      color: var(--theme-secondary-color);
    }
  }
`;

export const SidebarItemContainer = styled.div`
  display: flex;
  align-items: center;
`;

export const FooterContainer = styled.footer<{
  $isPageWithScene: boolean;
}>`
  display: flex;
  flex-direction: column;
  gap: 15px;
  font-size: 12px;
  color: var(--text-color);
  padding: 16px;
  width: ${({ $isPageWithScene }): string => ($isPageWithScene ? '60px' : '220px')};
  flex-shrink: 0;
  transition: width 0.3s;

  ${SidebarContainer}:hover & {
    width: 220px;
  }

  @media ${DEVICE.tabletLarge} {
    width: 220px;
  }
`;

export const FooterMenu = styled.div<{ $isPageWithScene: boolean }>`
  display: ${({ $isPageWithScene }): string => ($isPageWithScene ? 'none' : 'flex')};
  flex-direction: column;
  gap: 8px;
  align-items: center;
  width: 100%;
  min-width: 188px;

  ${SidebarContainer}:hover & {
    display: flex;
  }

  .footer-row {
    display: flex;
    align-items: center;
    gap: 8px;
    justify-content: center;
    width: 100%;
    flex-wrap: nowrap;
  }

  a {
    color: var(--text-color);
    text-decoration: none;
    font-weight: 500;
    font-size: 12px;
    transition: color 0.2s ease;
    white-space: nowrap;

    &:hover {
      color: var(--theme-secondary-color);
    }
  }
`;

export const FooterCopyright = styled.div<{ $isPageWithScene: boolean }>`
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 0px;
  opacity: 0.6;
  font-size: 12px;
  text-align: center;
  
  .full-copyright {
    display: ${({ $isPageWithScene }): string => ($isPageWithScene ? 'none' : 'flex')};
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    min-width: 188px;
    width: 100%;
    white-space: nowrap;
  }

  .mini-copyright {
    display: ${({ $isPageWithScene }): string => ($isPageWithScene ? 'block' : 'none')};
    margin-right: auto;
    max-width: 30px;
  }

  ${SidebarContainer}:hover & {
    font-size: 12px;

    .full-copyright {
      display: flex;
    }

    .mini-copyright {
      display: none;
    }
  }
`;

export const Dot = styled.div`
  width: 3px;
  height: 3px;
  background-color: var(--text-color);
  border-radius: 50%;
  opacity: 0.5;
`;

export const Overlay = styled.div<{ $isSidebarOpened: boolean }>`
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
  background: rgba(0, 0, 0, 0.5);

  @media ${DEVICE.tabletLarge} {
    display: ${({ $isSidebarOpened }): string => ($isSidebarOpened ? 'flex' : 'none')};
    z-index: 1000;
  }
`;
