import styled from 'styled-components';
import { getContentMinHeightCSS } from 'shared/styles';
import { HEADER_HEIGHT } from 'shared/constants/header';

export const Viewer = styled.div<{
  $isArMode: boolean;
  $isZenMode: boolean;
  $isEmbeddedModelMode: boolean;
  $isEmbedModelScene: boolean;
}>`
  position: relative;
  width: 100%;
  ${({ $isArMode, $isZenMode, $isEmbeddedModelMode, $isEmbedModelScene }): string => {
    if ($isZenMode) return getContentMinHeightCSS(HEADER_HEIGHT.NONE, true);
    else if ($isEmbeddedModelMode || $isEmbedModelScene) return getContentMinHeightCSS(HEADER_HEIGHT.NONE, false);

    const headerHeight = $isArMode ? HEADER_HEIGHT.SMALL : HEADER_HEIGHT.LARGE;
    return getContentMinHeightCSS(headerHeight, true);
  }}

  top: 0;
  left: 0;

  box-shadow: 0 0 32px rgba(0, 0, 0, 0.2);
  overflow: hidden;
  scrollbar-width: none;
`;
