import { COLORS } from 'shared/constants/colors';
import { getContentMaxHeightCSS } from 'shared/styles';
import { HEADER_HEIGHT } from 'shared/constants/header';
import styled from 'styled-components';

export const ArrowLeftContainer = styled.div`
  position: absolute;
  right: -26px;
  top: 5px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 26px;
  height: 40px;
  border-radius: 0 8px 8px 0;
  background: rgba(83, 83, 83, 0.4);
  border-right: 1px solid ${COLORS.doveGray};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  transition: all 0.5s;
  box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.3);
`;

export const ArrowLeft = styled.img<{ $isSidebarHidden: boolean }>`
  width: 20px;
  height: 20px;
  margin-right: 2px;
  transition: all 0.5s;
  transform: rotate(${({ $isSidebarHidden }): string => ($isSidebarHidden ? '180deg' : '0deg')});
`;

export const SidebarContainer = styled.aside<{
  $isArMode: boolean;
  $isZenMode: boolean;
}>`
  height: min-content;
  ${({ $isArMode, $isZenMode }): string => {
    if ($isZenMode) return getContentMaxHeightCSS(HEADER_HEIGHT.NONE, true);
    const headerHeight = $isArMode ? HEADER_HEIGHT.SMALL : HEADER_HEIGHT.LARGE;
    return getContentMaxHeightCSS(headerHeight, true);
  }}
  width: 327px;
  transition: all 0.5s;
  display: flex;
  flex-direction: column;
`;

export const FullModeContainer = styled.div`
  display: flex;
  flex-direction: column;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 8px 8px 0 0;
  min-height: 0;
  flex: 1;
`;

export const SidebarHeaderContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 15px 10px;
  border-bottom: 1px solid var(--border-color);
  background: rgba(83, 83, 83, 0.4);
  min-height: 48px;
  flex: 0 0 auto;
  border-radius: 8px 8px 0 0;
  border-top: 2px solid ${COLORS.doveGray};
`;

export const ModelHeaderRow = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
`;

export const ModelName = styled.h1`
  margin: 0;
  padding: 0 15px;
  font-size: 24px;
  font-weight: 600;
  color: ${COLORS.white};
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
`;

export const ModelTimestamp = styled.div`
  font-size: 12px;
  color: ${COLORS.white};
  opacity: 0.4;
  text-align: center;
  width: 100%;
`;

export const IconButton = styled.button<{ $isDelete?: boolean }>`
  margin-bottom: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${COLORS.mischka};
  stroke-width: 1.5;
  
  &:hover {
    color: ${({ $isDelete }): string => $isDelete ? COLORS.red : 'var(--theme-secondary-color)'};
  }

  svg {
    width: 18px;
    height: 18px;
  }
`;

export const SidebarTabText = styled.span`
  white-space: nowrap;
  max-width: fit-content;
  transition: max-width 0.3s ease, opacity 0.3s ease;
  padding-left: 8px;
  user-select: none;
`;

export const SidebarTabs = styled.div`
  display: flex;
  background: rgba(83, 83, 83, 0.4);
  width: 100%;
  height: 40px;

  > .selected {
    background: var(--button-background-color);
    color: var(--button-text-color);
    border-top: 2px solid var(--button-background-color);
  }

  > :not(.selected) {
    background: rgba(83, 83, 83, 0.75);
    color: ${COLORS.white};
    border-top: 2px solid ${COLORS.doveGray};
  }

  /* When there are 3 or fewer items, tabs are evenly distributed */
  &:has(> :nth-child(3):last-child) > * {
    flex: 1;
  }

  /* When there are 4 items, collapse non-active tabs */
  &:has(> :nth-child(4)) > :not(.selected) {
    flex: 1;
    
    ${SidebarTabText} {
      display: none;
    }
  }

  &:has(> :nth-child(4)) > .selected {
    flex: 4;
  }
`;

export const SidebarTab = styled.div`
  position: relative;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 15px;
  white-space: nowrap;
  cursor: pointer;
  overflow: hidden;
  box-sizing: border-box;
  border-radius: 7px 7px 0 0;
  font-size: 14px;
  line-height: 17px;
  transition: all 0.3s ease;
`;

export const SidebarTabIcon = styled.div<{ 
  $isActive?: boolean;
  $isCommentIcon?: boolean;
}>`
  width: 16px;
  height: 16px;
  flex-shrink: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${({ $isCommentIcon }): string => $isCommentIcon ? 'var(--button-background-color)' : 'inherit'};
  
  svg {
    width: 16px;
    height: 16px;
    
    ${({ $isCommentIcon, $isActive }): string => {
      if ($isCommentIcon) {
        return 'transform: translateY(-1px) scale(1.1);';
      }
      return `
        path { fill: ${$isActive ? 'var(--button-text-color)' : 'var(--button-background-color)'} }
        line { stroke: ${$isActive ? 'var(--button-text-color)' : 'var(--button-background-color)'} }
      `;
    }}
  }
`;

export const SidebarTabCounter = styled.div`
  flex-shrink: 0;
  min-width: 14px;
  text-align: right;
  padding-left: 8px;
`;

export const TabContainer = styled.div<{ $isVisible: boolean }>`
  display: ${({ $isVisible }) => ($isVisible ? 'flex' : 'none')};
  flex-direction: column;
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 0;

  &::-webkit-scrollbar {
    width: 3px;
    background: rgba(83, 83, 83, 0.5);
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.mischka};
  }
`;

export const DisabledMessageModal = styled.div`
  padding: 16px;
  border-radius: 4px 4px 0 0;
  color: ${COLORS.white};
  font-size: 14px;
  text-align: center;
`;
