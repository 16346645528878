export const COLORS = {
  white: '#ffffff',
  black: '#030303',
  deepBlack: '#101010',
  codGray: '#0A0A0A',
  mischka: '#d6d7e3',
  red: '#f24e1e',
  turbo: '#FFE406',
  gorse: '#FFEC51',
  corn: '#DAC308',
  mineShaft: '#303030',
  doveGray: '#6E6E6E',
  codGrayGradient: 'linear-gradient(117.57deg, #0a0a0a -0.48%, #0a0a0a 114.27%)',
  galleryGradient: 'linear-gradient(180deg, #ECECEC 20.59%, #C9C9C9 85.35%)',
  mineShaftGradient:
    'linear-gradient(0deg, #333333, #333333), linear-gradient(0deg, #333333, #333333), linear-gradient(0deg, #333333, #333333), #333333',
  charcoalGradient: 'linear-gradient(0deg, #1B1A1A 0%, #464646 83%)',
  shineGradient: 'linear-gradient(75deg, transparent 0%, rgba(255, 255, 255, 0.4) 50%, transparent 100%)'
};

export const EFFECTS = {
  shine: `
    @keyframes shine {
      0%, 50% {
        transform: translateX(-100%);
      }
      100% {
        transform: translateX(100%);
      }
    }

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background: ${COLORS.shineGradient};
      animation: shine 3s cubic-bezier(0, 0.25, 0.5, 0.75) infinite;
      z-index: 0;
    }

    span {
      position: relative;
      z-index: 2;
    }
  `
};
