import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';
import { FONTS } from 'shared/constants/fonts';
import { getContentMinHeightCSS, getContentPaddingTopCSS } from 'shared/styles';
import { HEADER_HEIGHT } from 'shared/constants/header';

export const RootContainer = styled.main<{
  $isAuth: boolean;
  $isArMode: boolean;
  $isPageWithScene: boolean;
  $isViewerPage: boolean;
  $isZenMode: boolean;
  $isEmbeddedModelMode: boolean;
}>`
  position: relative;
  width: 100%;
  overflow-y: ${({ $isArMode }): string => ($isArMode ? 'clip' : 'initial')};
  display: flex;
  flex-direction: column;

  ${(): string => getContentMinHeightCSS(HEADER_HEIGHT.NONE, true)};
  
  font-family: ${FONTS.interRegular};
  ${({ $isArMode, $isZenMode, $isEmbeddedModelMode }): string =>
    getContentPaddingTopCSS($isArMode, $isZenMode, $isEmbeddedModelMode)};
  padding-left: ${({ $isAuth, $isArMode, $isPageWithScene, $isZenMode, $isEmbeddedModelMode }): string => {
    switch (true) {
      case !$isAuth || $isArMode || $isZenMode || $isEmbeddedModelMode:
        return '0';
      case $isPageWithScene:
        return '60px';
      default:
        return '220px';
    }
  }};

  @media ${DEVICE.tabletLarge} {
    padding-left: 0;
  }
`;
