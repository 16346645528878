import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';

export const ModalWindowContainer = styled.div`
  width: 30vw;
  min-width: 420px;
  max-width: 500px;
  display: flex;
  flex-direction: column;
  color: ${COLORS.mischka};

  @media ${DEVICE.mobile} {
    min-width: 300px;
  }
`;

export const ContentText = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
`;

export const FeaturesBlock = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 15px;
  margin-bottom: 25px;
`;

export const FeatureItem = styled.div`
  display: flex;
  align-items: center;
  height: 36px;
  padding: 0 13px;
  font-weight: 500;
  font-size: 13px;
  line-height: 14px;
  background: ${COLORS.mineShaft};

  &:nth-child(even) {
    background: rgba(71, 71, 71, 0.35);
  }

  &:first-child {
    border-radius: 6px 6px 0 0;
  }

  &:last-child {
    border-radius: 0 0 6px 6px;
  }
`;

export const ActionButtons = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 8px 0;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  transition: all 0.2s;
  opacity: 1;

  &.downgrade-button {
    background: #484848;
    color: ${COLORS.white};

    &:hover {
      color: ${COLORS.black};
      background: ${COLORS.red};
    }
  }

  &.cancel-button {
    background: var(--button-background-color);
    color: var(--button-text-color);

    &:hover {
      opacity: 0.75;
    }
  }
`;
