import * as THREE from 'three';
import { BaseLoadModelStrategy } from './base-load-model.strategy';
import { FBXLoader } from 'three/examples/jsm/loaders/FBXLoader';
import { LoadModelData, LoadModelResult } from 'shared/interfaces';

export class FbxLoadModelStrategy extends BaseLoadModelStrategy {
  private fbxLoader: FBXLoader;

  constructor(renderer: THREE.WebGLRenderer, onModelResourcesLoaded?: () => void) {
    super(renderer, onModelResourcesLoaded);

    this.fbxLoader = new FBXLoader(this.loadingManager).setCrossOrigin('anonymous');
  }

  public async loadModel({ path, assets }: LoadModelData): Promise<LoadModelResult> {
    this.setLoadingContent(assets);

    const model = await this.fbxLoader.loadAsync(path);
    this.convertMaterialsToStandard(model);

    this.setLoadingContent([]);

    return {
      gltf: null,
      model
    };
  }

  private convertMaterialsToStandard(model: THREE.Object3D): void {
    model.traverse((child: THREE.Object3D) => {
      if (child instanceof THREE.Mesh) {
        this.convertMeshMaterials(child);
      }
    });
  }

  private convertMeshMaterials(mesh: THREE.Mesh): void {
    if (!mesh.material) return;

    const materials = Array.isArray(mesh.material) ? mesh.material : [mesh.material];
    materials.forEach((material, index) => {
      if (!(material instanceof THREE.MeshStandardMaterial)) {
        const standardMaterial = new THREE.MeshStandardMaterial();
        standardMaterial.copy(material);
        
        if (Array.isArray(mesh.material)) {
          mesh.material[index] = standardMaterial;
        } else {
          mesh.material = standardMaterial;
        }
      }
    });
  }
}
