import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  width: 70vw;
  min-width: 310px;
  max-width: 1000px;
  overflow-y: scroll;
  display: flex;
  padding: 30px 0;
  color: ${COLORS.mischka};
  overflow-x: hidden;
  max-height: 90vh;

  ::-webkit-scrollbar {
    width: 4px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLORS.doveGray};
    border-radius: 2px;
  }

  @media ${DEVICE.tabletLarge} {
    flex-direction: column;
    width: 60vw;
    padding: 30px;
  }

  @media ${DEVICE.tablet} {
    width: 95vw;
  }
`;

export const LeftSide = styled.div`
  width: 55%;
  padding: 0 15px 0 40px;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  @media ${DEVICE.tabletLarge} {
    width: 100%;
    padding: 0;
  }
`;

export const BillingFrequencyBlock = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CheckboxField = styled.div<{ $isDisabled?: boolean }>`
  width: 100%;
  height: 46px;
  background: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  border-radius: 4px;
  display: flex;
  align-items: center;
  padding: 0 20px;
  margin-top: 20px;
  cursor: pointer;
  opacity: ${({ $isDisabled }): string => ($isDisabled ? '0.6' : '1')};
  pointer-events: ${({ $isDisabled }): string => ($isDisabled ? 'none' : 'initial')};

  span {
    margin-left: 20px;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
  }
`;

export const Checkbox = styled.div<{ $isActive: boolean }>`
  width: 12px;
  height: 12px;
  border: 1px solid ${({ $isActive }): string => ($isActive ? COLORS.turbo : COLORS.white)};
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  img {
    width: 8px;
    height: 8px;
  }
`;

export const CardSavedText = styled.div`
  margin-top: 20px;
  padding: 0 13px;
  height: 46px;
  display: flex;
  align-items: center;
  font-weight: 600;
  font-size: 14px;
  line-height: 15px;
  background: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  border-radius: 4px;
`;

export const CardForm = styled.div`
  width: 100%;
  background: ${COLORS.mineShaft};
  border-radius: 4px;
`;

export const CardFormFooter = styled.div`
  margin-top: 20px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const StripeLabel = styled.img`
  height: 30px;
  margin-left: auto;

  @media ${DEVICE.mobile} {
    height: 22px;
  }
`;

export const SaveCardButton = styled.button`
  width: 100px;
  height: 33px;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  border-radius: 4px;
  background: ${COLORS.turbo};
  color: ${COLORS.black};
  transition: all 0.1s;

  &:hover {
    background: ${COLORS.gorse};
  }
`;

export const SwitcherContainer = styled.div`
  position: relative;
  width: 100%;
  height: 36px;
  background: ${COLORS.doveGray};
  border-radius: 5px;
  display: flex;
  align-items: center;
  margin-top: 15px;
`;

export const BillingFrequencyField = styled.button<{ $isActive?: boolean }>`
  flex: 1;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  background: transparent;
  border: none;
  color: ${COLORS.black};
  cursor: pointer;
  transition: all 0.2s ease;
  font-size: 12px;
  font-weight: 400;
  z-index: 1;
`;

export const SwitcherWheel = styled.div<{ $isMonth: boolean }>`
  position: absolute;
  width: 50%;
  height: calc(100% - 6px);
  background: ${COLORS.turbo};
  border-radius: 5px;
  transition: transform 0.2s ease;
  transform: translateX(${({ $isMonth }): string => ($isMonth ? '3px' : 'calc(100% - 3px)')});
`;

export const PromoCodeForm = styled.form`
  display: flex;
  flex-direction: column;
  margin-top: 30px;
`;

export const PromoCodeField = styled.div`
  display: flex;
  margin-top: 13px;
`;

export const PromoCodeInputWrapper = styled.div`
  position: relative;
  width: 100%;
  margin-right: 6px;
`;

export const PromoCodeInput = styled.input`
  height: 33px;
  width: 100%;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  border: 1px solid ${COLORS.doveGray};
  border-radius: 4px;
  color: ${COLORS.white};
  padding: 0 28px 0 5px;

  &:focus {
    border: 1px solid ${COLORS.white};
  }
`;

export const ValidationIcon = styled.span<{ $isValid?: boolean }>`
  position: absolute;
  right: 8px;
  top: 50%;
  transform: translateY(-50%);
  display: flex;
  align-items: center;
  pointer-events: none;
  
  .anticon {
    font-size: 16px;
    color: ${({ $isValid }): string => 
      $isValid ? '#0CD085' : COLORS.red
    };
  }
`;

export const PaymentInfoBlock = styled.div`
  margin-top: 30px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const CardFormContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  background: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  border-radius: 8px;
  padding: 25px 15px;
`;


export const RightSide = styled.div`
  width: 45%;
  padding: 0 40px 0 15px;
  display: flex;
  flex-direction: column;

  @media ${DEVICE.tabletLarge} {
    width: 100%;
    padding: 0;
    margin-top: 50px;
  }
`;

export const FieldLabel = styled.span`
  font-weight: 700;
  font-size: 15px;
  line-height: 17px;
`;

export const RightSideHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const PayBlock = styled.div`
  margin-top: 15px;
  width: 100%;
  min-height: 300px;
  background: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  border-radius: 5px;
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
`;

export const AccountPlan = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
`;

export const PriceField = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-weight: 500;
  font-size: 15px;
  line-height: 17px;
  margin-top: 20px;
`;

export const PayBlockFooter = styled.div`
  margin-top: auto;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const TermsConditions = styled.a`
  color: ${COLORS.turbo};
  font-weight: 400;
  font-size: 13px;
  line-height: 14px;
  cursor: pointer;
  text-align: left;
  
  &:hover {
    color: ${COLORS.turbo};
    text-decoration: underline;
  }
`;

export const PayButton = styled.button`
  margin-top: 15px;
  width: 100%;
  height: 33px;
  font-weight: 400;
  font-size: 12px;
  line-height: 13px;
  border: none;
  border-radius: 4px;
  background: ${COLORS.turbo};
  color: ${COLORS.black};
  transition: all 0.1s;

  &:hover {
    background: ${COLORS.gorse};
  }
`;

export const ActionButton = styled.button`
  width: 100px;
  height: 33px;
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  color: ${DEFAULT_THEME_CSS_VARIABLES.buttonTextColor};
  background: ${DEFAULT_THEME_CSS_VARIABLES.buttonBackgroundColor};
  cursor: pointer;
  transition: all 0.2s ease;

  &:hover {
    background: ${COLORS.gorse};
  }
`;