import * as THREE from 'three';
import { EEnvironmentPresets } from 'shared/enums/EEnvironmentPresets';
import { IEnvironmentOptions } from 'shared/interfaces';
import { debounce } from 'utils/delay-utils';
import { EEnvironmentTypes } from 'shared/enums/EEnvironmentTypes';
import { LoadEnvironmentService } from 'services/strategy-services';
import { DEFAULT_ENVIRONMENTS_PRESETS } from 'shared/constants/default-environments-presets';
import { MainScene } from 'shared/webgl/scenes';
import { ENVIRONMENT_WRAPPER_SCALE } from '../../constants/scene-settings-constants';
import { MAIN_SCENE_CANVAS_ID } from 'shared/constants/html-elements-ids';

export class EnvironmentController {
  public handleSetIsEnvironmentLoaded: ((value: boolean) => void) | undefined;

  private readonly environmentWrapper: THREE.Object3D;
  private readonly renderer: THREE.WebGLRenderer;
  private activeEnv: undefined | EEnvironmentPresets | string;
  private activeEnvType: EEnvironmentTypes | undefined;
  private prevEnv: undefined | EEnvironmentPresets | string;
  private loadEnvironmentService: LoadEnvironmentService;
  private receiveShadow: boolean = false;

  constructor(private mainScene: MainScene) {
    this.loadEnvironmentService = new LoadEnvironmentService(this.mainScene.renderer, (): void => {
      requestAnimationFrame((): void => {
        this.onEnvReady();
      });
    });

    this.renderer = mainScene.renderer;
    this.environmentWrapper = mainScene.environmentWrapper;

    this.environmentWrapper.scale.setScalar(ENVIRONMENT_WRAPPER_SCALE);
  }

  public updateByOptions: (options: IEnvironmentOptions) => void = debounce(
    (options: IEnvironmentOptions): void => {
      if (options.receiveShadow !== undefined && options.receiveShadow !== this.receiveShadow) {
        this.receiveShadow = options.receiveShadow;
        this.preformTraverseUpdating();
      }

      if (options.envPreset !== this.activeEnv) {
        this.setEnvironmentPreset(options);
      }
    },
    400
  );

  public setEnvironmentPreset(options: IEnvironmentOptions): void {
    this.setUpEnv(options).then((): void => {
      this.preformTraverseUpdating();
    });
  }

  private async setUpEnv({ envPreset, customEnvData }: IEnvironmentOptions): Promise<void> {
    if (typeof envPreset === 'string' && !customEnvData) return;

    if (this.activeEnv !== undefined && this.activeEnv === envPreset) {
      return;
    }

    if (this.activeEnv !== undefined && this.activeEnv !== envPreset) {
      this.prevEnv = this.activeEnv;
    }

    if (this.handleSetIsEnvironmentLoaded) {
      this.handleSetIsEnvironmentLoaded(false);
    }

    this.activeEnv = envPreset;
    const envData = DEFAULT_ENVIRONMENTS_PRESETS[envPreset as EEnvironmentPresets];

    const { type, data } =
      typeof envPreset === 'string'
        ? customEnvData!
        : envData;
    this.activeEnvType = type;

    const env = await this.loadEnvironmentService.changeEnvironment(type, {
      canvasId: MAIN_SCENE_CANVAS_ID,
      environmentWrapper: this.environmentWrapper,
      renderer: this.renderer,
      data
    });
    if (env) env.receiveShadow = this.receiveShadow;

    if (type !== EEnvironmentTypes.file) {
      this.onEnvReady();
    }
  }

  private onEnvReady(): void {
    if (this.handleSetIsEnvironmentLoaded && this.activeEnv !== undefined) {
      requestAnimationFrame((): void => {
        this.handleSetIsEnvironmentLoaded!(true);
      });
    }
  }

  private preformTraverseUpdating(): void {
    this.environmentWrapper.receiveShadow = this.receiveShadow;
    this.environmentWrapper.traverse((object): void => {
      object.layers.set(1);

      object.receiveShadow = this.receiveShadow;
    });
  }
}
