import * as Styled from './styles';
import Done from 'assets/images/done.svg';
import Close from 'assets/images/close.svg';
import { extractPlanData } from 'utils/subscription-plans-utils';
import { EFeatureCategory } from 'shared/types/enums';
import React from 'react';
import { formatNumberToNumberWithCommas } from 'utils/form-utils';

type Props = {
  isMonthlyType?: boolean;
  extractedPlansData: Array<ReturnType<typeof extractPlanData>>;
  seats?: number;
};

const SubscriptionFeatures: React.FC<Props> = ({
  isMonthlyType,
  extractedPlansData,
  seats = 1
}): JSX.Element => {
  return (
    <Styled.FeaturesTableContainer>
      <Styled.HeaderWrapper>
        <Styled.FeatureTableHeaderBackground />
      </Styled.HeaderWrapper>
      <Styled.FeatureTableBodyBackground />
      <Styled.FeatureTable>
        <thead>
          <Styled.FeatureTableRow $isHeader={true}>
            <Styled.FeatureHeaderCell $isHeaderColumn={true}>All Features</Styled.FeatureHeaderCell>
            {extractedPlansData.map(({ title, monthPrice, yearPrice, isCustomPlan }) => {
              return (
                <Styled.FeatureHeaderCell key={title}>
                  {title}<br />
                    {isCustomPlan ? (
                      <Styled.CustomPrice>Custom</Styled.CustomPrice>
                    ) : !monthPrice.original ? (
                      <Styled.CustomPrice>FREE</Styled.CustomPrice>
                    ) : (
                      <>
                        <Styled.PriceSection>
                          <Styled.Currency>$</Styled.Currency>
                          <Styled.Price>
                            {(isMonthlyType ? monthPrice : yearPrice).actual}
                          </Styled.Price>
                          <Styled.Duration>
                            {`${seats > 1 ? '/ user' : ''} / ${isMonthlyType ? 'month' : 'year'}`}
                          </Styled.Duration>
                        </Styled.PriceSection>
                      </>
                    )}
                </Styled.FeatureHeaderCell>
              );
            })}
          </Styled.FeatureTableRow>
        </thead>
        <tbody>
          {Object.entries(extractedPlansData[0]?.categorizedFeatures || {}).map(([category, features]) => {
            if (category === EFeatureCategory.SPOTLIGHT) return null;
            
            return (
              <React.Fragment key={category}>
                <Styled.FeatureTableRow>
                  <Styled.FeatureCell 
                    $isHeaderColumn={true} 
                    $isSectionHeader={true} 
                    colSpan={extractedPlansData.length + 1}
                  >
                    <Styled.CustomPrice>{category}</Styled.CustomPrice>
                  </Styled.FeatureCell>
                </Styled.FeatureTableRow>

                {features.map((feature, featureIndex) => (
                  <Styled.FeatureTableRow key={feature.title}>
                    <Styled.FeatureCell $isHeaderColumn={true}>
                      {feature.title}
                    </Styled.FeatureCell>
                    {extractedPlansData.map((plan) => {
                      const planFeature = plan.categorizedFeatures[category as EFeatureCategory][featureIndex];
                      return (
                        <Styled.FeatureCell key={`${plan.id}-${feature.title}-feature`}>
                          {typeof planFeature.value === 'boolean' ? (
                            <Styled.FeatureAvailableIcon
                              $isAvailable={planFeature.value}
                              src={planFeature.value ? Done : Close}
                              alt={planFeature.value ? 'Available' : 'Not available'}
                            />
                          ) : (
                            <span>
                              {typeof planFeature.value === 'number'
                                ? planFeature.value === -1
                                ? 'Unlimited'
                                : formatNumberToNumberWithCommas(planFeature.value * seats)
                                : planFeature.value
                              }
                            </span>
                          )}
                        </Styled.FeatureCell>
                      );
                    })}
                  </Styled.FeatureTableRow>
                ))}
              </React.Fragment>
            );
          })}
        </tbody>
      </Styled.FeatureTable>
    </Styled.FeaturesTableContainer>
  );
}

export default SubscriptionFeatures;