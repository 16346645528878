import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { HEADER_HEIGHT } from 'shared/constants/header';
import { getContentHeightCSS } from 'shared/styles';

export const HomePageContainer = styled.div<{
  $isModelLoaded: boolean;
  $isArMode: boolean;
  $isZenMode: boolean;
  $isEmbeddedModel: boolean;
}>`
  background: ${COLORS.codGrayGradient};
  position: ${({ $isArMode }): string => ($isArMode ? 'absolute' : 'relative')};
  top: 0;
  left: 0;
  width: 100%;

  ${({ $isModelLoaded }): string => {
    if($isModelLoaded) return 'height: 100%;'
    else return getContentHeightCSS(HEADER_HEIGHT.LARGE, false);
  }}
`;

export const PageContent = styled.div<{ $isArMode: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: min-content;
  padding: 0 11px;
  z-index: ${({ $isArMode }): string => ($isArMode ? '11' : '1')};
`;
