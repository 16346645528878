import React from 'react';
import * as Styled from './styles';

type Props = {
  leftText: string;
  rightText: string;
  onChange: (isLeftActive: boolean) => void;
  isLeftActive?: boolean;
};

const DualSwitcher: React.FC<Props> = ({
  leftText,
  rightText,
  onChange,
  isLeftActive = false,
}): JSX.Element => {
  return (
    <Styled.DualSwitcherContainer>
      <Styled.SwitcherOption $isActive={isLeftActive} onClick={(): void => onChange(true)}>
        {leftText}
      </Styled.SwitcherOption>
      <Styled.SwitchTrack $isLeftActive={isLeftActive} onClick={(): void => onChange(!isLeftActive)}>
        <Styled.SwitchKnob $isLeftActive={isLeftActive} />
      </Styled.SwitchTrack>
      <Styled.SwitcherOption $isActive={!isLeftActive} onClick={(): void => onChange(false)}>
        {rightText}
      </Styled.SwitcherOption>
    </Styled.DualSwitcherContainer>
  );
};

export default DualSwitcher; 