import { AxiosResponse } from 'axios';
import ApiService from './api';
import {
  CalculatePlanResponse,
  CurrentCard,
  CurrentSubscriptionProduct,
  PlanFeature,
  Limits,
  SetupPaymentIntent,
  SubscriptionProduct,
  Transaction,
  CheckoutResponse,
  EQuotaName
} from 'shared/types';

type DeleteCardResponse = {
  success: boolean;
  source: string;
};

type PromoCodeResponse = {
  promo: string;
  valid: boolean;
};

type TransactionsResponse = {
  data: Transaction[];
  has_more: boolean;
  object: string;
  url: string;
};

type LimitResponse = {
  quota: EQuotaName;
  title: string;
  limit: number;
  used: number;
  success: boolean;
};

export const getSubscriptionProducts = async (): Promise<AxiosResponse<SubscriptionProduct[]>> =>
  await ApiService.get<SubscriptionProduct[]>(`/subscription/products`);

export const getCurrentUserSubscription = async (): Promise<
  AxiosResponse<CurrentSubscriptionProduct>
> => await ApiService.get<CurrentSubscriptionProduct>(`/subscription/current`);

export const getCardPaymentIntent = async (): Promise<AxiosResponse<SetupPaymentIntent>> =>
  await ApiService.get<SetupPaymentIntent>(`/payment-settings/payment-methods/setup`);

export const getCurrentUserCards = async (): Promise<AxiosResponse<CurrentCard[]>> =>
  await ApiService.get<CurrentCard[]>(`/payment-settings/payment-methods`);

export const deleteCurrentUserCard = async (
  sourceId: string
): Promise<AxiosResponse<DeleteCardResponse>> =>
  await ApiService.delete<DeleteCardResponse>(`/payment-settings/payment-methods/${sourceId}`);

export const setupUserPaymentMethod = async (setupIntent: string): Promise<AxiosResponse<CurrentCard>> =>
  await ApiService.post<{ setupIntent: string }, CurrentCard>(
    `/payment-settings/payment-methods/confirm-setup`,
    {
      setupIntent
    }
  );

export const payUserSubscription = async (payload: {
  card: string;
  price: string;
  promo?: string;
}): Promise<AxiosResponse<CheckoutResponse>> =>
  await ApiService.post<{ card: string; price: string; promo?: string }, CheckoutResponse>(
    `/subscription/checkout`,
    payload
  );

export const fetchPlanPrice = async (payload: {
  price: string;
  card: string;
  teamId?: 'new' | number;
  promo?: string;
  qty?: number;
}): Promise<AxiosResponse<CalculatePlanResponse>> =>
  await ApiService.post<
    { price: string; promo?: string; qty?: number; vatRegistered: boolean },
    CalculatePlanResponse
  >(`/subscription/calculate`, { ...payload, vatRegistered: true });

export const checkPromoCode = async (promo: string, productId: string): Promise<AxiosResponse<PromoCodeResponse>> =>
  await ApiService.post<{ promo: string, productId: string }, PromoCodeResponse>(`/subscription/promo-code/validate`, {
    promo,
    productId
  });

export const getUserTransactions = async (
  limit: number,
  startingAfter?: string
): Promise<AxiosResponse<TransactionsResponse>> => {
  const params = !!startingAfter ? `&starting_after=${startingAfter}` : '';
  return await ApiService.get<TransactionsResponse>(
    `/subscription/transactions?limit=${limit}${params}`
  );
};

export const getUserLimits = async (): Promise<AxiosResponse<Limits>> =>
  await ApiService.get<Limits>(`/users/me/usage-limits`);

export const getUserFeatures = async (): Promise<AxiosResponse<PlanFeature[]>> =>
  await ApiService.get<PlanFeature[]>(`/users/me/paid-features`);

export const getUserPlanLimit = async (
  quotaName: EQuotaName,
  userId: number | string = 'me',
  teamId?: number
): Promise<AxiosResponse<LimitResponse>> => {
  const queryParams = !!teamId ? `?teamId=${teamId}` : '';
  return await ApiService.get<LimitResponse>(
    `/users/${userId}/usage-limits/${quotaName}/validate${queryParams}`
  );
};

export const getModelPlanLimit = async (
  modelId: string,
  quotaName: EQuotaName
): Promise<AxiosResponse<LimitResponse>> =>
  await ApiService.get<LimitResponse>(`/models/${modelId}/usage-limits/${quotaName}/validate`);

export const incrementArModeView = async (modelId: string): Promise<AxiosResponse<void>> =>
  await ApiService.post(
    `/models/${modelId}/usage-limits/${EQuotaName.MONTHLY_AR_VIEWS_LIMIT}/touch`,
    {}
  );

export const payTeamSubscription = async (
  teamId: number,
  payload: {
    card?: string;
    price: string;
    qty: number;
    setupIntent?: string;
    paymentMethod?: string;
    promo?: string;
  }
): Promise<AxiosResponse<CheckoutResponse>> =>
  await ApiService.post<
    {
      card?: string;
      price: string;
      qty: number;
      setupIntent?: string;
      promo?: string;
    },
    CheckoutResponse
  >(`teams/${teamId}/subscription/checkout`, payload);

export const fetchTeamPlanPrice = async (
  teamId: 'new' | number,
  payload: {
    price: string;
    card?: string;
    promo?: string;
    qty?: number;
    vatRegistered?: boolean;
    setupIntent?: string;
  }
): Promise<AxiosResponse<CalculatePlanResponse>> =>
  await ApiService.post<
    {
      price: string;
      card?: string;
      promo?: string;
      qty?: number;
      vatRegistered?: boolean;
      setupIntent?: string;
    },
    CalculatePlanResponse
  >(`/subscription/calculate?teamId=${teamId}`, { ...payload, vatRegistered: true });

export const getCurrentTeamSubscription = async (
  teamId: number
): Promise<AxiosResponse<CurrentSubscriptionProduct>> =>
  await ApiService.get<CurrentSubscriptionProduct>(`/teams/${teamId}/subscription/current`);

export const getTeamTransactions = async (
  teamId: number,
  limit: number,
  startingAfter?: string
): Promise<AxiosResponse<TransactionsResponse>> => {
  const params = !!startingAfter ? `&starting_after=${startingAfter}` : '';
  return await ApiService.get<TransactionsResponse>(
    `/teams/${teamId}/subscription/transactions?limit=${limit}${params}`
  );
};

export const getTeamMemberLimits = async (teamId: number): Promise<AxiosResponse<Limits>> =>
  await ApiService.get<Limits>(`/users/me/usage-limits?teamId=${teamId}`);

export const getTeamCardPaymentIntent = async (
  teamId: number | 'new'
): Promise<AxiosResponse<SetupPaymentIntent>> =>
  await ApiService.get<SetupPaymentIntent>(
    `/teams/${teamId}/payment-settings/payment-methods/setup`
  );

export const getCurrentTeamCard = async (teamId: number): Promise<AxiosResponse<CurrentCard>> =>
  await ApiService.get<CurrentCard>(`teams/${teamId}/payment-settings/payment-methods/current`);

export const updateTeamCard = async (
  teamId: number,
  setupIntent: string
): Promise<AxiosResponse<void>> =>
  await ApiService.patch(`/teams/${teamId}/payment-settings/payment-methods/current`, {
    setupIntent
  });
