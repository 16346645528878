import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import { DEVICE } from 'shared/constants/deviceSizes';

export const SeoBlockContainer = styled.main`
  font-family: ${FONTS.interRegular};
  color: ${COLORS.white};
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media${DEVICE.tablet} {
    padding: 60px 0;
  }

  p {
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;

    @media${DEVICE.mobile} {
      font-size: 16px;
      line-height: 18px;
    }
  }

  .subtitle {
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: rgba(214, 215, 227, 0.8);
  }

  h1,
  h2,
  h3 {
    font-family: ${FONTS.interRegular};
    color: ${COLORS.white};
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
  }
`;

export const SectionFreeOnlineViewer = styled.section`
  width: 100%;
  display: flex;
  align-items: center;
  background-color: #090909;
  position: relative;
  max-width: 1440px;
  padding-bottom: 30px;

  .section-image {
    max-width: 611px;
    width: 50%;
    object-fit: contain;
    z-index: 1;

    @media${DEVICE.tablet} {
      width: 100%;
      max-width: 500px;
      margin-top: 30px;
      transform: translateX(50px);
    }

    @media${DEVICE.mobile} {
      align-self: flex-end;
      max-width: initial;
    }
  }

  .section-background-image {
    width: 400px;
    position: absolute;
    top: 0;
    right: -120px;

    @media${DEVICE.tablet} {
      width: 140px;
      right: -40px;
    }

    @media${DEVICE.mobile} {
      display: none;
    }
  }

  @media${DEVICE.tablet} {
    flex-direction: column;
    padding-bottom: 30px;
  }
`;

export const SectionFreeOnlineViewerLeftSide = styled.article`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 500px;
  padding: 63px 63px 63px 97px;

  .title {
    margin-top: 10px;
  }

  p:first-child {
    margin-top: 7px;
  }

  @media${DEVICE.tabletLarge} {
    padding: 30px;
    min-width: 400px;
  }

  @media${DEVICE.tablet} {
    width: 100%;
    padding-right: 100px;
  }

  @media${DEVICE.mobile} {
    padding: 10px;
    min-width: initial;
  }
`;

export const UploadModelButton = styled.button`
  width: max-content;
  margin-top: 20px;
  padding: 15px 25px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: ${FONTS.interRegular};
  color: ${COLORS.black};
  font-weight: 600;
  font-size: 16px;
  line-height: 18px;
  background: ${COLORS.turbo};
  border: none;
  border-radius: 7px;

  &:hover {
    background: ${COLORS.gorse};
  }
`;

export const SectionShareModels = styled.section`
  position: relative;
  display: flex;
  justify-content: space-between;
  max-width: 1440px;
  width: 100%;
  background: #141414;

  .section-image {
    margin-left: -80px;
    max-width: 670px;
    width: 50%;
    object-fit: contain;

    @media${DEVICE.tablet} {
      width: 100%;
      max-width: 500px;
      padding: 30px 10px;
      margin-left: 0;
    }
  }

  @media${DEVICE.tablet} {
    flex-direction: column;
    align-items: center;
  }
`;

export const SectionRightSide = styled.article`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  text-align: right;
  width: 50%;
  min-width: 500px;
  padding: 76px 94px 59px 0;

  .title {
    margin-top: 10px;
  }

  p:first-child {
    margin-top: 7px;
  }

  @media${DEVICE.tabletLarge} {
    padding: 30px;
  }

  @media${DEVICE.tablet} {
    width: 100%;
    min-width: initial;
  }

  @media${DEVICE.mobile} {
    padding: 10px 10px 30px;
  }
`;

export const SectionPlatformFeatures = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #090909;
  max-width: 1440px;

  .section-image {
    max-width: 740px;
    margin-right: -80px;
    width: 50%;
    object-fit: contain;

    @media${DEVICE.tablet} {
      width: 100%;
      max-width: 450px;
      padding: 30px 0;
      margin-right: 0;
    }

    @media${DEVICE.mobile} {
      max-width: 300px;
    }
  }

  @media${DEVICE.tablet} {
    flex-direction: column;
  }
`;

export const SectionPlatformFeaturesLeftSide = styled.article`
  display: flex;
  flex-direction: column;
  width: 50%;
  min-width: 500px;
  padding: 63px 63px 63px 97px;

  .title {
    margin-top: 10px;
  }

  @media${DEVICE.tabletLarge} {
    padding: 30px;
  }

  @media${DEVICE.tablet} {
    width: 100%;
    min-width: initial;
    padding: 30px 30px 0;
  }
`;

export const PlatformFeaturesList = styled.div`
  margin-top: 36px;
  display: flex;
  flex-direction: column;
`;

export const ListRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  height: max-content;

  &:last-child {
    margin-top: 13px;
  }

  @media${DEVICE.mobile} {
    flex-direction: column;
  }
`;

export const ListRowItem = styled.div`
  display: flex;
  flex-direction: column;
  width: 47%;

  h4 {
    font-family: ${FONTS.interRegular};
    color: ${COLORS.white};
    font-weight: 600;
    font-size: 18px;
    line-height: 20px;
  }

  @media${DEVICE.mobile} {
    width: 100%;
  }
`;

export const SectionWhoDesignedFor = styled.section`
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: #090909;
  max-width: 1440px;

  @media${DEVICE.tablet} {
    flex-direction: column;
  }
`;

export const SectionImageContainer = styled.div`
  display: flex;
  position: relative;
  width: 50%;
  height: 100%;
  padding: 81px 59px 106px;
  z-index: 1;

  @media${DEVICE.tabletLarge} {
    padding: 30px;
  }

  @media${DEVICE.tablet} {
    width: 100%;
    padding: 60px 30px;
  }

  .section-image {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    max-width: 380px;
    min-width: 310px;
    width: 50%;
    object-fit: contain;

    @media${DEVICE.tabletLarge} {
      min-width: 270px;
    }

    @media${DEVICE.tablet} {
      width: 100%;
      max-width: 300px;
      padding: 30px 0;
      margin-right: 0;
    }

    @media${DEVICE.mobile} {
      max-width: 200px;
    }
  }
`;

export const IconBlur = styled.div`
  background: rgba(255, 255, 248, 0.4);
  backdrop-filter: blur(10px);
  border-radius: 7px;
  height: 295px;
  width: 100%;
`;

export const SectionWhatIsGltf = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  max-width: 1440px;
  padding: 87px 0;
  background: linear-gradient(259.95deg, #525356 -61.87%, #0a0a0a 130.29%);

  & .section-block {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
  }

  & .section-block:last-child {
    margin-top: 58px;
  }

  @media${DEVICE.tablet} {
    padding: 30px 0;
  }

  .section-image {
    transform: rotateX(180deg);
    position: absolute;
    left: -180px;
    top: -280px;
    width: 480px;

    @media${DEVICE.tabletLarge} {
      left: -120px;
      top: -180px;
      width: 340px;
    }

    @media${DEVICE.tablet} {
      left: -60px;
      top: -110px;
      width: 180px;
    }

    @media${DEVICE.mobile} {
      display: none;
    }
  }

  p {
    width: 60%;
    text-align: center;

    @media${DEVICE.tablet} {
      width: 90%;
    }
  }

  h3 + p {
    margin-top: 40px;

    @media${DEVICE.tablet} {
      margin-top: 20px;
    }
  }
`;

export const SectionHowShare = styled.section`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 1440px;
  padding: 66px 300px 150px 100px;

  @media${DEVICE.tabletLarge} {
    padding: 66px 240px 150px 100px;
  }

  @media${DEVICE.tablet} {
    padding: 30px 140px 100px 30px;
  }

  @media${DEVICE.mobile} {
    padding: 30px 10px 100px 10px;
  }

  .title {
    margin-top: 11px;
    font-family: ${FONTS.interRegular};
    color: ${COLORS.white};
    font-weight: 600;
    font-size: 24px;
    line-height: 26px;
  }

  .title + p {
    margin-top: 11px;
  }

  .section-image {
    position: absolute;
    right: -160px;
    top: -100px;
    width: 480px;

    @media${DEVICE.tabletLarge} {
      right: -100px;
      top: -60px;
      width: 340px;
    }

    @media${DEVICE.tablet} {
      right: -40px;
      top: -40px;
      width: 180px;
    }

    @media${DEVICE.mobile} {
      display: none;
    }
  }
`;
