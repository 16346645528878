import React, { ChangeEvent, useEffect, useState } from 'react';
import * as Styled from './styles';
import { SubscriptionInfo } from 'shared/components';
import { ErrorMessage } from 'shared/styles';
import { NUMBER_REGEXP } from 'shared/constants/regexps';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { CreateTeamState, EPaymentType, TeamsState } from 'shared/types';
import { setMembersCount, setTeamName } from 'services/store/reducers/createTeamReducer';
import { MAX_TEAM_MEMBER_COUNT, MIN_TEAM_MEMBER_COUNT, MAX_TEAM_NAME_LENGTH } from 'shared/constants/limits';

type Props = {
  paymentType: EPaymentType.CREATE_TEAM_PLAN | EPaymentType.UPDATE_TEAM_PLAN;
  withCounter?: boolean;
};

const TeamSubscriptions: React.FC<Props> = ({ withCounter, paymentType }): JSX.Element => {
  const dispatch = useAppDispatch();
  const { membersCount, teamName } = useAppSelector((store): CreateTeamState => store.createTeam);
  const { activeTeam } = useAppSelector((store): TeamsState => store.teams);
  const [membersCountInputValue, setMembersCountInputValue] = useState<string>(
    MIN_TEAM_MEMBER_COUNT.toString()
  );
  const [teamNameError, setTeamNameError] = useState<string>('');

  useEffect((): void => {
    if (!!+membersCountInputValue) {
      dispatch(setMembersCount(+membersCountInputValue));
    }
  }, [dispatch, membersCountInputValue]);

  useEffect((): void => {
    if (teamName.length > MAX_TEAM_NAME_LENGTH) {
      setTeamNameError(`Only ${MAX_TEAM_NAME_LENGTH} symbols allowed`);
      return;
    }
    setTeamNameError('');
  }, [teamName]);

  const handleMembersCounterButtonClick =
    (buttonType: 'minus' | 'plus'): (() => void) =>
    (): void => {
      const result =
        buttonType === 'minus' ? +membersCountInputValue - 1 : +membersCountInputValue + 1;

      if (!!result && result <= MAX_TEAM_MEMBER_COUNT && result >= MIN_TEAM_MEMBER_COUNT) {
        setMembersCountInputValue(result.toString());
        dispatch(setMembersCount(result));
      }
    };

  const getCounterFieldError = (): JSX.Element | void => {
    if (+membersCountInputValue < MIN_TEAM_MEMBER_COUNT) {
      return (
        <ErrorMessage>{`${MIN_TEAM_MEMBER_COUNT} - minimum number of teammates`}</ErrorMessage>
      );
    }
    if (+membersCountInputValue > MAX_TEAM_MEMBER_COUNT) {
      return (
        <ErrorMessage>{`${MAX_TEAM_MEMBER_COUNT} - maximum number of teammates`}</ErrorMessage>
      );
    }
  };

  const handleMembersCounterBlur = (): void => {
    if (!+membersCountInputValue || +membersCountInputValue < MIN_TEAM_MEMBER_COUNT) {
      setMembersCountInputValue(MIN_TEAM_MEMBER_COUNT.toString());
      dispatch(setMembersCount(MIN_TEAM_MEMBER_COUNT));
    }
    if (+membersCountInputValue > MAX_TEAM_MEMBER_COUNT) {
      setMembersCountInputValue(MAX_TEAM_MEMBER_COUNT.toString());
      dispatch(setMembersCount(MAX_TEAM_MEMBER_COUNT));
    }
  };

  const handleMembersCounterChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;

    if (NUMBER_REGEXP.test(value) || !value) {
      setMembersCountInputValue(value);
    }
  };

  const handleWorkspaceNameBlur = (): void => {
    if (!teamName) {
      setTeamNameError('Required field');
    }
  };

  const handleWorkspaceNameChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    dispatch(setTeamName(value.trimStart()));
  };

  return (
    <Styled.ContentContainer>
      {withCounter && (
        <Styled.InputsRow>
          <Styled.WorkspaceNameField>
            <Styled.NameFieldLabel>Workspace Name</Styled.NameFieldLabel>
            <Styled.NameFieldInputContainer>
              <Styled.NameFieldInput
                $isError={!!teamNameError}
                value={teamName}
                onChange={handleWorkspaceNameChange}
                onBlur={handleWorkspaceNameBlur}
                placeholder='Enter team workspace name'
                autoFocus={true}
              />
              {!!teamNameError && <ErrorMessage>{teamNameError}</ErrorMessage>}
            </Styled.NameFieldInputContainer>
          </Styled.WorkspaceNameField>
          
          <Styled.MembersCounterContainer>
            <Styled.CounterField>
              <Styled.NameFieldLabel>Team Members (Seats)</Styled.NameFieldLabel>
              <Styled.MembersCounter>
                <Styled.CounterButton onClick={handleMembersCounterButtonClick('minus')}>
                  -
                </Styled.CounterButton>
                <Styled.CounterInput
                  value={membersCountInputValue}
                  onChange={handleMembersCounterChange}
                  onBlur={handleMembersCounterBlur}
                  maxLength={MAX_TEAM_MEMBER_COUNT.toString().length}
                />
                <Styled.CounterButton onClick={handleMembersCounterButtonClick('plus')}>
                  +
                </Styled.CounterButton>
              </Styled.MembersCounter>
            </Styled.CounterField>
            {getCounterFieldError()}
          </Styled.MembersCounterContainer>
        </Styled.InputsRow>
      )}
      <SubscriptionInfo
        paymentType={paymentType}
        title="Choose your team plan"
        seats={paymentType === EPaymentType.CREATE_TEAM_PLAN 
          ? membersCount 
          : activeTeam?.totalSeats || MIN_TEAM_MEMBER_COUNT
        }
      />
    </Styled.ContentContainer>
  );
};

export default TeamSubscriptions;
