import { COLORS } from 'shared/constants/colors';
import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';
import { PLAN_CARD } from '../constants';
import { PlanContainer } from '../subscription-plan/styles';

export const SubscriptionsContainer = styled.div<{ $numPlans: number, $isModal?: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-bottom: 20px;
  max-width: 100%;
  container-type: inline-size;
`;

export const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  width: 100%;
  margin-bottom: 32px;
  flex-wrap: wrap;
  gap: 20px;
  margin-bottom: 20px;
`;

export const Title = styled.h1`
  font-size: 32px;
  font-weight: 400;
  color: ${COLORS.white};
  margin: 0;
  line-height: 110%;
  min-width: min-content;
  flex-basis: auto;
`;

export const BillingTypeSwitcher = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 12px;
  margin-left: auto;
  padding-bottom: 5px;
  filter: drop-shadow(0px 4px 4px rgba(0, 0, 0, 0.25));
`;

export const PlansContainer = styled.div<{ $numPlans: number; $isModal?: boolean }>`
  display: grid;
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  row-gap: 0;

  ${({ $numPlans, $isModal }) => {
    const cardWidth = PLAN_CARD.scaleValue(PLAN_CARD.width, $isModal);
    const minGap = PLAN_CARD.minGap;
    const maxGap = $isModal ? PLAN_CARD.minGap : PLAN_CARD.maxGap;
    const numColumns = Math.min(4, $numPlans);
    const containerThreshold2Column = PLAN_CARD.getColumnWidth(numColumns, $isModal);
    const containerThreshold1Column = PLAN_CARD.getColumnWidth(2, $isModal);
    
    return `
      grid-template-columns: repeat(${numColumns}, ${cardWidth}px);
      column-gap: clamp(${minGap}px, calc((100% - (${numColumns} * ${cardWidth}px)) / ${numColumns}), ${maxGap}px);

      @container (max-width: ${containerThreshold2Column}px) {
        grid-template-columns: repeat(2, ${cardWidth}px);
        max-width: ${cardWidth * 2 + maxGap}px;
        column-gap: clamp(${minGap}px, calc((100% - (2 * ${cardWidth}px)) / 2), ${maxGap}px);

        & > div:nth-child(n+3) ${PlanContainer} {
          margin-top: 0;
        }
      }

      @container (max-width: ${containerThreshold1Column}px) {
        grid-template-columns: ${cardWidth}px;
      }
    `;
  }}
`;

export const MostPopularLabel = styled.div<{ $isActive: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  height: 26px;
  color: ${COLORS.black};
  background: ${COLORS.turbo};
  opacity: ${({ $isActive }): string => ($isActive ? '0.6' : '1')};
  font-size: 12px;
  font-weight: 700;
  border-radius: 4px 4px 0 0;
  padding-top: 2px;
  margin-bottom: -4px;

  @media ${DEVICE.mobile} {
    font-size: 8px;
    margin-bottom: -8px;
  }
`;

export const LinksContainer = styled.div<{ $showEnterprisePlanLink?: boolean }>`
  display: flex;
  justify-content: ${({ $showEnterprisePlanLink }): string => ($showEnterprisePlanLink ? 'space-between' : 'center')};
  align-items: center;
  margin-top: ${({ $showEnterprisePlanLink }): string => ($showEnterprisePlanLink ? '10px' : '20px')};
  margin-bottom: 10px;
  width: 100%;
  font-size: ${({ $showEnterprisePlanLink }): string => ($showEnterprisePlanLink ? '13px' : '16px')};
`;

export const TeamPlanLink = styled.div`
  display: flex;
  flex-wrap: wrap;

  span {
    line-height: 15px;
  }

  span:first-child {
    margin-right: 5px;
  }

  span:last-child {
    color: ${COLORS.turbo};
    cursor: pointer;
  }
`;


export const EnterprisePlanLink = styled.div`
  display: flex;
  flex-wrap: wrap;

  span {
    line-height: 15px;
  }

  span:first-child {
    margin-right: 5px;
  }

  span:last-child {
    color: ${COLORS.turbo};
    cursor: pointer;
  }
`;
