import styled from 'styled-components';
import { COLORS, EFFECTS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import { DEVICE } from 'shared/constants/deviceSizes';

export const ShareModelContainer = styled.section`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

export const ShareTabsContainer = styled.div`
  width: 100%;
  display: flex;

  @media ${DEVICE.tabletLarge} {
    display: none;
  }
`;

export const ShareTab = styled.div<{ $isShareTab: boolean }>`
  width: 50%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ $isShareTab }): string =>
    $isShareTab ? 'var(--button-background-color)' : 'rgba(83, 83, 83, 0.75)'};
  color: ${({ $isShareTab }): string => ($isShareTab ? 'var(--button-text-color)' : COLORS.white)};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 7px 7px 0 0;
  border-top: ${({ $isShareTab }): string => $isShareTab ? '2px solid var(--button-background-color)' : `2px solid ${COLORS.doveGray}`};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
`;

export const ArViewTab = styled.div<{ $isArViewTab: boolean; $hasArViewAccess: boolean }>`
  width: 50%;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: ${({ $isArViewTab }): string =>
    $isArViewTab ? 'var(--button-background-color)' : 'rgba(83, 83, 83, 0.75)'};
  color: ${({ $isArViewTab, $hasArViewAccess }): string =>
    $hasArViewAccess ? ($isArViewTab ? 'var(--button-text-color)' : COLORS.white) : '#949494'};
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 7px 7px 0 0;
  border-top: ${({ $isArViewTab }): string => $isArViewTab ? '2px solid var(--button-background-color)' : `2px solid ${COLORS.doveGray}`};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  cursor: pointer;
  position: relative;
`;

export const Lock = styled.div`
  width: 18px;
  height: 18px;
  position: absolute;
  top: 0;
  right: 0;
  background: ${COLORS.turbo};
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    width: 14px;
    height: 14px;
  }
`;

export const ShareModelContent = styled.div`
  background: rgba(83, 83, 83, 0.4);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border-radius: 2px;
  padding: 14px;
  display: flex;
  flex-direction: column;
  font-family: ${FONTS.interRegular};
  width: 100%;
  max-height: calc(100vh - 164px);

  &::-webkit-scrollbar {
    width: 3px;
    background: rgba(83, 83, 83, 0.5);
  }

  &::-webkit-scrollbar-thumb {
    background: ${COLORS.mischka};
  }

  @media (max-height: 750px) {
    overflow-y: auto;
  }

  @media ${DEVICE.tabletLarge} {
    max-height: initial;
  }
`;

export const FieldLabel = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 130%;
  color: ${COLORS.white};
  display: inline-flex;
  align-items: center;
  margin-bottom: 5px;

  img {
    width: 12px;
    height: 12px;
    transform: translateY(-1px);
  }
`;

export const QrCodeContainer = styled.div`
  width: 100%;
  height: 180px;
`;

export const ShareQrCode = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${COLORS.white};
  padding: 6px;

  img.qr-code {
    width: 100%;
    height: 100%;
  }
`;

export const ArQrCode = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  background: ${COLORS.white};
  padding: 6px;

  img.qr-code {
    width: 100%;
    height: 100%;
  }
`;

export const InactiveQrCode = styled.div`
  width: 100%;
  height: 180px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: ${COLORS.white};
  padding: 6px 6px 2px;
  position: relative;

  img {
    width: 100%;
    height: 100%;
  }
`;

export const CoverLayer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 2;
  background: rgba(0, 0, 0, 0.96);
  font-weight: 600;
  font-size: 14px;
  line-height: 17px;
  display: flex;
  align-items: center;
  text-align: center;
  color: ${COLORS.white};
  padding: 0 5px;
`;

export const Spinner = styled.img`
  width: 60px;
  height: 60px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

export const DownloadButtonsContainer = styled.div`
  display: flex;
  padding: 0 8px;
  justify-content: center;
`;

export const ShareLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const ShareLinkBlockHeader = styled.div`
  display: inline-flex;
  align-items: center;
  vertical-align: middle;

  img {
    width: 13px;
    height: 13px;
    margin-left: 6px;
  }
`;

export const ShareLinkField = styled.div`
  height: 36px;
  width: 100%;
  border-radius: 2px;
  display: flex;
  align-items: stretch;
  background: ${COLORS.deepBlack};
  border: 1px solid var(--button-background-color);
  padding: 0;
  transition: border-color 0.15s;
  overflow: hidden;

  &:has(button:hover) {
    border-color: color-mix(in srgb, var(--button-background-color), transparent 25%);
  }
`;

export const Link = styled.input`
  font-family: 'FiraCode Regular', sans-serif;
  font-weight: 400;
  font-size: 12px;
  line-height: 16px;
  color: ${COLORS.white};
  border: none;
  background: transparent;
  padding: 0 10px;
  width: 100%;
  height: 100%;
`;

export const CopyLinkButton = styled.button`
  background: var(--button-background-color);
  width: 40px;
  min-width: 40px;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: opacity 0.2s;
  border: none;
  position: relative;
  overflow: hidden;
  ${EFFECTS.shine}

  &:hover {
    opacity: 0.75;
  }

  img {
    width: 17px;
    height: 17px;
    color: var(--button-text-color);
  }
`;

export const CreateLinkButton = styled.button`
  height: 36px;
  width: 100%;
  border-radius: 2px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--button-text-color);
  background: var(--button-background-color);
  font-size: 12px;
  font-weight: 500;
  line-height: 15px;
  opacity: 1;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  ${EFFECTS.shine}

  &:hover {
    opacity: 0.75;
  }
`;

export const UnstylzedButton = styled.button`
  margin: 10px auto 0 auto;
  display: flex;
  align-items: center;
  background: none;
  border: none;
  color: ${COLORS.white};
  opacity: 1;

  img {
    width: 15px;
    height: 15px;
  }

  span {
    margin-left: 10px;
    font-weight: 600;
    font-size: 12px;
    line-height: 15px;
  }

  &:hover:not(:disabled) {
    opacity: 0.75;
    cursor: pointer;
    text-decoration: underline;
  }

  &:disabled {
    opacity: 0.4;
    cursor: pointer;
    background: none;
  }
`;

export const InnerBlock = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const ShareBeyondButtons = styled.div`
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(0, 1fr));
  gap: 10px;

  button {
    width: 100%;
    height: 36px;
    border-radius: 2px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: var(--button-text-color);
    background: var(--button-background-color);
    font-size: 12px;
    line-height: 15px;
    opacity: 1;
    transition: all 0.3s;
    position: relative;
    overflow: hidden;

    &:hover:not(:disabled) {
      opacity: 0.75;
    }

    &:disabled {
      opacity: 0.3;
      cursor: not-allowed;
    }

    img {
      width: 15px;
      height: 15px;
      margin-right: 8px;
    }

    span {
      margin: 0;
      font-weight: 500;
      font-size: 12px;
      line-height: 15px;
    }
  }
`;
