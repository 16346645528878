import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import styled from 'styled-components';
import Checked from 'assets/images/check-black.svg';
import CheckedYellow from 'assets/images/check-yellow.svg';

export const ActionsContainer = styled.section`
  display: flex;
  flex-direction: column;
`;

export const SelectContainer = styled.div<{ $isMenuActive: boolean }>`
  color: ${COLORS.white};
  font-family: ${FONTS.interRegular};
  font-size: 14px;
  line-height: 17px;
  width: 100%;
  display: flex;
  flex-direction: column;
  position: relative;
`;

export const MainField = styled.div<{ $isMenuActive: boolean; $expandUp: boolean }>`
  position: relative;
  min-width: 220px;
  width: 100%;
  height: 42px;
  background: ${COLORS.deepBlack};
  display: flex;
  justify-content: space-between;
  align-items: center;
  cursor: pointer;
  border-radius: 4px;
  transition: border-radius 0.1s ease-out 0.2s;
  border-bottom-left-radius: ${({ $isMenuActive }): string => ($isMenuActive ? '0' : '4px')};
  border-bottom-right-radius: ${({ $isMenuActive }): string => ($isMenuActive ? '0' : '4px')};

  &::after {
    content: '';
    position: absolute;
    ${({ $expandUp }): string => ($expandUp ? 'top: 0' : 'bottom: 0')};
    width: 100%;
    transform: scaleX(${({ $isMenuActive }): string => ($isMenuActive ? '1' : '0')});
    transition: all 0.2s ease-out;
    border-bottom: 1px solid ${COLORS.white};
  }

  & div {
    width: 100%;
    padding-left: 15px;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    font-weight: 500;
    font-size: 12px;
    line-height: 15px;
  }

  .select-arrow {
    padding: 0 20px;
    width: 20px;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    & img {
      transform: ${({ $isMenuActive, $expandUp }): string =>
        $expandUp ? ($isMenuActive ? '0' : 'rotate(180deg)') : $isMenuActive ? 'rotate(180deg)' : '0'};
      transition: all 0.3s;
      min-width: 10px;
    }
  }

  &:hover div {
    color: ${COLORS.turbo};
  }
`;

export const SelectMenuContainer = styled.div<{ $isMenuActive: boolean; $expandUp: boolean }>`
  position: absolute;
  z-index: 11;
  width: 100%;
  pointer-events: ${({ $isMenuActive }): string => ($isMenuActive ? 'initial' : 'none')};
  ${({ $expandUp }): string => ($expandUp ? 'transform: translateY(-100%)' : 'top: 100%')};
`;

export const SelectMenu = styled.div<{ $isMenuActive: boolean; $expandUp: boolean }>`
  display: flex;
  flex-direction: column;
  overflow-y: auto;
  overflow-x: hidden;
  width: 100%;
  max-height: 200px;
  background: ${COLORS.deepBlack};
  transform-origin: ${({ $expandUp }): string => ($expandUp ? '0 100%' : '100% 0')};
  transform: scaleY(${({ $isMenuActive }): string => ($isMenuActive ? '1' : '0')});
  transition: all 0.3s;
  border-radius: ${({ $expandUp }): string => ($expandUp ? '4px 4px 0 0' : '0 0 4px 4px')};
  box-shadow: ${({ $expandUp }): string => ($expandUp ? '0px -30px 20px rgba(0, 0, 0, 90%)' : '')};

  &::-webkit-scrollbar {
    width: 4px;
    height: 2px;
  }

  ::-webkit-scrollbar-thumb {
    background: ${COLORS.turbo};
  }
`;

export const Checkbox = styled.div<{ $isActive: boolean }>`
  width: 16px;
  height: 16px;
  border-radius: 4px;
  border: 1px solid ${COLORS.white};
  background-image: ${({ $isActive }): string => ($isActive ? `url(${CheckedYellow})` : 'none')};
  background-size: 12px 12px;
  background-position: center;
  background-repeat: no-repeat;
  cursor: pointer;
`;

export const OptionItem = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  height: 42px;
  padding: 0 18px;
  background: ${({ $isActive }): string => ($isActive ? 'rgba(255, 255, 255, 0.1)' : 'transparent')};
  cursor: pointer;

  &:hover {
    background: ${COLORS.turbo};

    span {
      color: ${COLORS.black};
    }

    ${Checkbox} {
      border: 1px solid ${COLORS.black};
      background-image: ${({ $isActive }): string => ($isActive ? `url(${Checked})` : 'none')};
    }
  }

  & span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: block;
    max-width: 98%;
    text-align: left;
    margin-right: 10px;
    color: ${COLORS.white};
  }
`;

export const SaveButton = styled.button`
  margin-top: 30px;
  width: 100%;
  padding: 10px;
  background: ${COLORS.turbo};
  border: none;
  border-radius: 4px;
  color: ${COLORS.black};
  font-size: 14px;
  font-family: ${FONTS.interRegular};
  transition: all 0.2s;

  &:last-child {
    margin-top: 15px;
  }

  &.download,
  &.save {
    color: ${COLORS.black};
    background: ${COLORS.corn};
  }

  &:hover {
    background: ${COLORS.gorse};
    color: ${COLORS.black};
  }
`;

export const Lock = styled.div`
  width: 16px;
  height: 16px;
  position: absolute;
  top: 50%;
  right: 4px;
  transform: translateY(-50%);
  background: ${COLORS.turbo};
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;

  img {
    width: 12px;
    height: 12px;
  }
`;
