import React from 'react';
import * as Styled from './styles';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { closeConfirmModal } from 'services/store/reducers/modalReducer';
import { EConfirmModalHeader, ModalState } from 'shared/types';
import Edit from 'assets/images/edit.svg';
import Close from 'assets/images/close.svg';
import Trash from 'assets/images/trash.svg';
import Shopping from 'assets/images/shopping-icon.svg';

const ConfirmModalWindow = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const { confirmModalContent, confirmModalHeader } = useAppSelector(
    (state): ModalState => state.modal
  );

  const handleCancelButtonClick = (): void => {
    dispatch(closeConfirmModal());
  };

  const getHeader = (): JSX.Element | null => {
    switch (confirmModalHeader) {
      case EConfirmModalHeader.EDIT:
        return (
          <>
            <Styled.ModalIcon src={Edit} alt='Edit' />
            <Styled.ModalTitle>Confirm Edits</Styled.ModalTitle>
          </>
        );
      case EConfirmModalHeader.DELETE:
        return (
          <>
            <Styled.ModalIcon src={Trash} alt='Delete' />
            <Styled.ModalTitle>Confirm Deletion</Styled.ModalTitle>
          </>
        );
      case EConfirmModalHeader.CHECKOUT:
        return (
          <>
            <Styled.ModalIcon src={Shopping} alt='Shopping' />
            <Styled.ModalTitle>Checkout</Styled.ModalTitle>
          </>
        );
      case null:
        return null;
      default:
        return <Styled.ModalTitle>{confirmModalHeader}</Styled.ModalTitle>;
    }
  };

  return (
    <>
      <Styled.ModalWindowContainer>
        <Styled.ModalWindowHeader>
          {getHeader()}
          <Styled.CloseButton onClick={handleCancelButtonClick} src={Close} alt='Close' />
        </Styled.ModalWindowHeader>
        <Styled.ModalWindowContent>{confirmModalContent}</Styled.ModalWindowContent>
      </Styled.ModalWindowContainer>
      <Styled.ModalWindowOverlay onClick={handleCancelButtonClick} />
    </>
  );
};

export default ConfirmModalWindow;
