import React from 'react';

const NewTabIcon = (): JSX.Element => {
  return (
    <svg width="18" height="17" viewBox="0 0 18 17" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M16 15.1111H2V1.88889H9V0H2C0.89 0 0 0.85 0 1.88889V15.1111C0 16.15 0.89 17 2 17H16C17.1 17 18 16.15 18 15.1111V8.5H16V15.1111ZM11 0V1.88889H14.59L4.76 11.1728L6.17 12.5044L16 3.22056V6.61111H18V0H11Z" fill="currentColor"/>
    </svg>
  );
};

export default NewTabIcon;
