import React, { useCallback, useEffect, useMemo } from 'react';
import * as Styled from './styles';
import { ResetIcon } from 'assets/dynamic-icons/reset-icon';
import { 
  ModelWithMetadata, 
  EModelType, 
  AxisValues, 
  ETransformFiledType, 
  BrandOption, 
  SelectOption,
  CustomTheme,
  CustomEnvironment,
  CustomCubemap,
  ETeamRole,
  TeamsState,
  CameraData,
  Camera
} from 'shared/types';
import { useAppDispatch, useAppSelector, useCustomBrandingData } from 'shared/hooks';
import { RootState } from 'services/store';
import { ESnackbarStyle } from 'shared/types';
import { openNotification } from 'utils/notification-utils';
import moment from 'moment';
import { BrandingSelect, SettingsSelect } from '..';
import { setBranding } from 'services/store/reducers/brandingReducer';
import { setThemeCSSVariables, convertBrandColorsToCSSVariables } from 'utils/theme-utils';
import { DEFAULT_THEME_CSS_VARIABLES, DEFAULT_BRANDING } from 'shared/constants/customization';
import { LIGHTING_OPTIONS, ENVIRONMENT_OPTIONS, CUBEMAP_OPTIONS } from 'shared/constants/model-settings';
import { EEnvironmentTypes } from 'shared/enums/EEnvironmentTypes';
import { E3DModelFileTypes } from 'shared/enums/E3DModelFileTypes';
import { convertUrlToNotFromCacheUrl } from 'utils/model-utils';
import { changeModelRotation, changeModelOffset, changeModelScale, setModelSettings, setDefaultView } from 'services/store/reducers/viewerDataReducer';
import { TransformField, Switcher, Select, SaveModelBlock } from 'shared/components';

interface Props {
  model: ModelWithMetadata;
  setIsRecenterAction: (value: boolean) => void;
  getCameraData: (() => Promise<CameraData & { camera: Camera } >) | null;
}

const NO_AUTO_SAVE_TEXT = 'Changes visible only to you';
const AUTO_SAVE_TEXT = 'Changes saved automatically';

const DisplayTab: React.FC<Props> = ({
  model,
  setIsRecenterAction,
  getCameraData,
}) => {
  const dispatch = useAppDispatch();
  const { user, isAuth } = useAppSelector((store: RootState) => store.auth);
  const { isArMode } = useAppSelector((store: RootState) => store.arMode);
  const { modelSettings, modelType, animations } = useAppSelector((store: RootState) => store.viewerData);
  const { activeBranding } = useAppSelector((store: RootState) => store.branding);
  const { teamRole } = useAppSelector((store): TeamsState => store.teams);
  const { buttonBackgroundColor, buttonTextColor } = activeBranding.brandColors;
  const { hasEditAccess, hasLightingAccess, hasEnvironmentAccess, hasCubemapAccess, hasCustomEnvironmentAccess, hasCustomBrandingAccess } = useAppSelector((store: RootState) => store.modelFeatureAccess);
  const { brands, environments, cubemaps, fetchCustomBranding, fetchCustomEnvironments, fetchCustomCubemaps } = useCustomBrandingData();
  const {
    lighting,
    environment,
    cubemap,
    autoRotate,
    animation,
    enableShadows,
    wireframe,
    showDimensions,
    arScaleInMeters,
    vertexColors
  } = modelSettings;
  const isSampleModel = modelType === EModelType.SAMPLE;
  const isQuickView = modelType === EModelType.QUICK_VIEW;
  const isTeamModel = modelType === EModelType.TEAM;
  const isModelOwner = model.ownerId === user?.id;

  const hasBrandingAccess = useMemo(() => {
    if (isTeamModel) {
      return teamRole === ETeamRole.OWNER || 
             teamRole === ETeamRole.ADMIN || 
             teamRole === ETeamRole.MANAGER;
    }
    return isModelOwner || isSampleModel;
  }, [isTeamModel, teamRole, isModelOwner, isSampleModel]);

  const BRANDING_OPTIONS = useMemo((): BrandOption[] => {
    return [
      { title: DEFAULT_BRANDING.brandName, brand: DEFAULT_BRANDING },
      ...brands.map((b: CustomTheme): BrandOption => ({ title: b.brandName, brand: b }))
    ];
  }, [brands]);

  const CUSTOM_ENVIRONMENTS = useMemo(() => environments.map(
    ({ environmentName, publicUrl, presets, thumbnailUrl }: CustomEnvironment) => {
      const fileType = publicUrl
        .split('/')
        .reverse()[0]
        .split('.')[1] as keyof typeof E3DModelFileTypes;
      return {
        title: environmentName,
        value: {
          key: environmentName,
          envPreset: environmentName,
          customEnvData: {
            type: EEnvironmentTypes.file,
            data: {
              path: publicUrl,
              fileType: E3DModelFileTypes[fileType],
              scale: presets.scale
            }
          }
        },
        preview: thumbnailUrl,
        isAvailable: false
      };
    }
  ), [environments]);

  const CUSTOM_CUBEMAPS = useMemo(() => cubemaps.map(
    ({
      cubemapName,
      nyPlaneUrl,
      nxPlaneUrl,
      pxPlaneUrl,
      nzPlaneUrl,
      pzPlaneUrl,
      pyPlaneUrl
    }: CustomCubemap) => ({
      title: cubemapName,
      value: {
        key: cubemapName,
        cubemapName,
        imagePaths: {
          nyPlaneUrl: convertUrlToNotFromCacheUrl(nyPlaneUrl),
          nxPlaneUrl: convertUrlToNotFromCacheUrl(nxPlaneUrl),
          pxPlaneUrl: convertUrlToNotFromCacheUrl(pxPlaneUrl),
          nzPlaneUrl: convertUrlToNotFromCacheUrl(nzPlaneUrl),
          pzPlaneUrl: convertUrlToNotFromCacheUrl(pzPlaneUrl),
          pyPlaneUrl: convertUrlToNotFromCacheUrl(pyPlaneUrl)
        }
      },
      preview: nzPlaneUrl,
      isAvailable: false
    })
  ), [cubemaps]);

  const lightOptions = useMemo(() => 
    hasLightingAccess
      ? LIGHTING_OPTIONS.map((option): any => ({ ...option, isAvailable: true }))
      : LIGHTING_OPTIONS,
    [hasLightingAccess]
  );

  const envOptions = useMemo(() => 
    hasEnvironmentAccess
      ? [...ENVIRONMENT_OPTIONS, ...CUSTOM_ENVIRONMENTS].map(
          (option): any => ({ ...option, isAvailable: true })
        )
      : ENVIRONMENT_OPTIONS,
    [hasEnvironmentAccess, CUSTOM_ENVIRONMENTS]
  );

  const cubemapOptions = useMemo(() => 
    hasCubemapAccess
      ? [...CUBEMAP_OPTIONS, ...CUSTOM_CUBEMAPS].map(
          (option): any => ({ ...option, isAvailable: true })
        )
      : CUBEMAP_OPTIONS,
    [hasCubemapAccess, CUSTOM_CUBEMAPS]
  );

  useEffect(() => {
    fetchCustomBranding(model, hasCustomBrandingAccess && hasBrandingAccess);
    if(hasCustomEnvironmentAccess) {
      fetchCustomEnvironments(model);
      fetchCustomCubemaps(model);
    }
  }, [
    hasCustomEnvironmentAccess,
    fetchCustomEnvironments,
    fetchCustomCubemaps,
    model,
    hasCustomBrandingAccess,
    fetchCustomBranding,
    hasBrandingAccess
  ]);

  const handleOptionChange = useCallback((key: string) => (option: SelectOption): void => {
    dispatch(setModelSettings({ ...modelSettings, [key]: option.value }));
  }, [dispatch, modelSettings]);

  const handleSwitcherChange = useCallback((key: string) => (value: boolean): void => {
    dispatch(setModelSettings({ ...modelSettings, [key]: value }));
  }, [dispatch, modelSettings]);

  const handleBrandingSelectChange = useCallback(({ brand }: BrandOption): void => {
    dispatch(setBranding(brand));
    const isDefaultTheme = !brand.brandColors.buttonBackgroundColor.length;
    isDefaultTheme
      ? setThemeCSSVariables(DEFAULT_THEME_CSS_VARIABLES)
      : convertBrandColorsToCSSVariables(brand.brandColors);
  }, [dispatch]);

  const handleTransformFieldChange = useCallback((fieldType: ETransformFiledType, values: AxisValues): void => {
    switch (fieldType) {
      case ETransformFiledType.SCALE:
        dispatch(changeModelScale(values));
        break;
      case ETransformFiledType.OFFSET:
        dispatch(changeModelOffset(values));
        break;
      case ETransformFiledType.ROTATION:
        dispatch(changeModelRotation(values));
    }
  }, [dispatch]);

  const handleDefaultViewClick = async (reset: boolean): Promise<void> => {
    if (!getCameraData) {
      openNotification(ESnackbarStyle.ERROR, `Unable to get camera data`);
      return;
    }

    const newView = reset ? undefined : (await getCameraData()).camera;
    dispatch(setDefaultView(newView));
    openNotification(ESnackbarStyle.SUCCESS, `Starting point succesfully ${reset ? 'reset' : 'set'}.`);

    if(reset) {
      setIsRecenterAction(true);
    }
  };

  return (
    <Styled.FullModeContent>
      <Styled.SettingsOptionContainer>
        <Styled.FieldLabel>Lighting</Styled.FieldLabel>
        <Styled.SettingsOption>
          <SettingsSelect
            selectId='lighting-select'
            options={lightOptions}
            activeValue={lighting}
            action={handleOptionChange('lighting')}
            isModelOwner={!isSampleModel}
            isLighting
          />
        </Styled.SettingsOption>
      </Styled.SettingsOptionContainer>

      {!isArMode && (
        <Styled.SettingsOptionContainer>
          <Styled.FieldLabel>Environment</Styled.FieldLabel>
          <Styled.SettingsOption>
            <SettingsSelect
              selectId='environment-select'
              options={envOptions}
              activeValue={environment}
              action={handleOptionChange('environment')}
              isModelOwner={!isSampleModel}
            />
          </Styled.SettingsOption>
        </Styled.SettingsOptionContainer>
      )}

      <Styled.SettingsOptionContainer>
        <Styled.FieldLabel>Cubemap (Reflections)</Styled.FieldLabel>
        <Styled.SettingsOption>
          <SettingsSelect
            selectId='cubemap-select'
            options={cubemapOptions}
            activeValue={cubemap}
            action={handleOptionChange('cubemap')}
            isModelOwner={!isSampleModel}
          />
        </Styled.SettingsOption>
      </Styled.SettingsOptionContainer>

      {hasBrandingAccess && (
        <Styled.SettingsOptionContainer>
          <Styled.FieldLabel>Branding</Styled.FieldLabel>
          <Styled.SettingsOption>
            <BrandingSelect
              activeOption={{ title: activeBranding.brandName, brand: activeBranding }}
              onChangeOption={handleBrandingSelectChange}
              options={BRANDING_OPTIONS}
            />
          </Styled.SettingsOption>
        </Styled.SettingsOptionContainer>
      )}

      <Styled.SettingsOptionContainer>
        <Styled.FieldLabel>Scale</Styled.FieldLabel>
        <Styled.SettingsOption>
          <TransformField
            action={handleTransformFieldChange}
            initialValues={model.presets.scale}
            fieldType={ETransformFiledType.SCALE}
          />
        </Styled.SettingsOption>
      </Styled.SettingsOptionContainer>

      <Styled.SettingsOptionContainer>
        <Styled.FieldLabel>Offset</Styled.FieldLabel>
        <Styled.SettingsOption>
          <TransformField
            action={handleTransformFieldChange}
            initialValues={model.presets.offset}
            fieldType={ETransformFiledType.OFFSET}
          />
        </Styled.SettingsOption>
      </Styled.SettingsOptionContainer>

      <Styled.SettingsOptionContainer>
        <Styled.FieldLabel>Rotation</Styled.FieldLabel>
        <Styled.SettingsOption>
          <TransformField
            action={handleTransformFieldChange}
            initialValues={model.presets.rotation}
            fieldType={ETransformFiledType.ROTATION}
          />
        </Styled.SettingsOption>
      </Styled.SettingsOptionContainer>

      {!isArMode && !isQuickView && hasEditAccess && (
        <Styled.SettingsOptionContainer>
          <Styled.SetDefaultViewContainer>
            <Styled.DefaultViewButton
              $leftOrRight='left'
              $activeColor={buttonBackgroundColor}
              $activeTextColor={buttonTextColor}
              onClick={() => handleDefaultViewClick(false)}>
                Set Default Viewpoint
            </Styled.DefaultViewButton>
            <Styled.DefaultViewButton
              $leftOrRight='right'
              $activeColor={buttonBackgroundColor}
              $activeTextColor={buttonTextColor}
              onClick={() => handleDefaultViewClick(true)}
            >
              <ResetIcon />
            </Styled.DefaultViewButton>
          </Styled.SetDefaultViewContainer>
        </Styled.SettingsOptionContainer>
      )}

      <Styled.SwitchersContainer>
        <Styled.SwitchBlock>
          <Styled.FieldLabel>Auto-rotate</Styled.FieldLabel>
          <Styled.SettingsOption>
            <Switcher
              switcherId={'auto-rotate-button'}
              action={handleSwitcherChange('autoRotate')}
              isActive={autoRotate}
            />
          </Styled.SettingsOption>
        </Styled.SwitchBlock>

        <Styled.SwitchBlock>
          <Styled.FieldLabel>Shadows</Styled.FieldLabel>
          <Styled.SettingsOption>
            <Switcher
              switcherId={'shadows-button'}
              action={handleSwitcherChange('enableShadows')}
              isActive={enableShadows}
            />
          </Styled.SettingsOption>
        </Styled.SwitchBlock>

        <Styled.SwitchBlock>
          <Styled.FieldLabel>Wireframe</Styled.FieldLabel>
          <Styled.SettingsOption>
            <Switcher
              switcherId={'wireframe-button'}
              action={handleSwitcherChange('wireframe')}
              isActive={wireframe}
            />
          </Styled.SettingsOption>
        </Styled.SwitchBlock>

        {!isArMode && (
          <Styled.SwitchBlock>
            <Styled.FieldLabel>Dimensions</Styled.FieldLabel>
            <Styled.SettingsOption>
              <Switcher
                switcherId={'dimensions-button'}
                action={handleSwitcherChange('showDimensions')}
                isActive={showDimensions}
              />
            </Styled.SettingsOption>
          </Styled.SwitchBlock>
        )}

        {!isQuickView && (
          <Styled.SwitchBlock>
            <Styled.FieldLabel>AR in Meters</Styled.FieldLabel>
            <Styled.SettingsOption>
              <Switcher
                switcherId={'scale-in-meters-button'}
                action={handleSwitcherChange('arScaleInMeters')}
                isActive={arScaleInMeters}
                isDisabled={isArMode}
                onClickIfDisabled={(): void => {
                  openNotification(
                    ESnackbarStyle.HOLD_UP,
                    `Cannot be modified while the AR is running. First you need to stop AR.`
                  );
                }}
              />
            </Styled.SettingsOption>
          </Styled.SwitchBlock>
        )}

        <Styled.SwitchBlock>
          <Styled.FieldLabel>Vertex Colors</Styled.FieldLabel>
          <Styled.SettingsOption>
            <Switcher
              switcherId={'vertex-colors-button'}
              action={handleSwitcherChange('vertexColors')}
              isActive={vertexColors}
            />
          </Styled.SettingsOption>
        </Styled.SwitchBlock>
      </Styled.SwitchersContainer>

      <Styled.AnimationsBlock>
        <Styled.FieldLabel>Play Animations</Styled.FieldLabel>
        <Styled.SettingsOption>
          <Select
            options={animations}
            activeValue={animation}
            action={handleOptionChange('animation')}
          />
        </Styled.SettingsOption>
      </Styled.AnimationsBlock>

      <Styled.AutoSavingText>
        {hasEditAccess ? AUTO_SAVE_TEXT : NO_AUTO_SAVE_TEXT}
      </Styled.AutoSavingText>

      {isAuth && isQuickView && (
        <Styled.SaveModelBlockContainer>
          <SaveModelBlock expandUp />
        </Styled.SaveModelBlockContainer>
      )}

      {!isAuth && (
        <Styled.CopyrightContainer>
          <span>Copyright © 2022-{moment().year()} glbee</span>
          <span>instant 3D model sharing</span>
        </Styled.CopyrightContainer>
      )}
    </Styled.FullModeContent>
  );
};

export default DisplayTab; 