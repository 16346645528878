import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import styled from 'styled-components';

export const LogoContainer = styled.div`
  cursor: pointer;
  display: flex;
  align-items: center;
`;

export const LogoWrapper = styled.div`
  width: fit-content;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const CompanyLogo = styled.img`
  max-width: 75px;
  max-height: 40px;
  object-fit: contain;

  @media ${DEVICE.tablet} {
    max-width: 40px;
    max-height: 25px;
  }
`;

export const CompanyTitle = styled.div`
  margin-left: 10px;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  color: ${COLORS.white};
  max-width: 600px;
  word-break: break-word;
  font-size: 24px;
  line-height: 24px;
  font-weight: 600;
  margin-bottom: 0;

  @media ${DEVICE.tablet} {
    font-size: 18px;
    line-height: 18px;
  }

  @media ${DEVICE.mobile} {
    display: none;
  }
`;
