import styled from 'styled-components';
import { DEVICE } from 'shared/constants/deviceSizes';
import { COLORS } from 'shared/constants/colors';

export const PageLoaderContainer = styled.div<{
  $showProgress: boolean;
  $isAuth: boolean;
  $isPageWithScene: boolean;
  $isModalActive: boolean;
  $isZenMode: boolean;
  $isEmbeddedModelMode: boolean;
}>`
  position: fixed;
  width: calc(
    100% -
      ${({ $isAuth, $isPageWithScene, $isModalActive, $isZenMode, $isEmbeddedModelMode }): string =>
        $isAuth && !$isModalActive && !$isZenMode && !$isEmbeddedModelMode
          ? $isPageWithScene
            ? '60px'
            : '200px'
          : '0px'}
  );
  left: ${({ $isModalActive }): string => ($isModalActive ? '0' : 'initial')};
  top: ${({ $isModalActive }): string => ($isModalActive ? '0' : 'initial')};
  height: calc(
    100% -
      ${({ $isAuth, $isModalActive, $isZenMode, $isEmbeddedModelMode }): string =>
        $isAuth && !$isModalActive && !$isZenMode && !$isEmbeddedModelMode ? '82px' : '0px'}
  );
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: ${({ $isModalActive }): string => ($isModalActive ? '1060' : '1020')};
  background: ${({ $showProgress: $isLoading }): string => ($isLoading ? 'rgba(0, 0, 0, 0.7)' : 'transparent')};

  @media${DEVICE.tabletLarge} {
    width: 100%;
  }

  @media${DEVICE.tablet} {
    height: calc(
      100% -
        ${({ $isAuth, $isModalActive, $isEmbeddedModelMode }): string =>
          !$isAuth || $isModalActive || $isEmbeddedModelMode ? '0px' : '38px'}
    );
  }
`;

export const Loader = styled.img`
  width: 70px;
  height: 70px;
`;

export const ProgressBarContainer = styled.section`
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 90%;
  max-width: 600px;
  align-items: center;
`;

export const LoadingIcon = styled.img<{ $percent: number }>`
  width: 70px;
  height: 70px;
  opacity: ${({ $percent }): number => ($percent / 100)};
  transform: translateY(-10px);
  animation: float 6s ease-in-out infinite;

  @keyframes float {
    0% {
      transform: translatey(-10px);
    }
    50% {
      transform: translatey(10px);
    }
    100% {
      transform: translatey(-10px);
    }
  }
`;

export const ProgressWrapper = styled.div`
  width: 100%;
  padding: 10px 0;
`;

export const LoadingPercent = styled.div`
  color: ${COLORS.white};
  font-size: 16px;
`;

export const LoadingState = styled.div`
  color: ${COLORS.white};
  font-size: 14px;
`;
