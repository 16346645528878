import * as THREE from 'three';
import { Camera, CameraData, EModelType, ESnackbarStyle, ResponseModel } from 'shared/types';
import { ISceneMetadata } from 'shared/interfaces';
import React, { JSX, useEffect, useMemo, useRef, useState } from 'react';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { openNotification } from 'utils/notification-utils';
import { RootState } from 'services/store';
import { ESceneTypes } from 'shared/enums/ESceneTypes';
import * as Styled from './styles';
import {
  AnimationController,
  CameraController,
  CubemapController,
  EnvironmentController,
  LightController,
  ObjectController
} from 'shared/webgl/controllers';
import { E3DModelFileTypes } from 'shared/enums/E3DModelFileTypes';
import {
  setHasSkeleton,
  setIsModelInitialized,
  setIsSceneCompletelyLoaded,
  setModelSettings,
  setScaleFactor
} from 'services/store/reducers/viewerDataReducer';
import { CommentsController } from 'shared/components/comments/comments-controller';
import { fetchKeyBindings } from 'services/store/reducers/keyBindingsReducer';
import { CreateScreenshotService, ScenesGlobalStateService } from 'services/strategy-services';
import { CubeOrbitControllerScene, EighthWallScene, MainScene } from 'shared/webgl/scenes';
import { preventDefaults } from 'utils/dom-utils';
import { EEnvironmentPresets } from 'shared/enums/EEnvironmentPresets';
import { clearCommentsState } from 'services/store/reducers/commentsReducer';
import { setIsViewerLoading } from 'services/store/reducers/loaderReducer';
import { ViewerSceneTooltipBlock } from 'shared/components/viewer-scene-tooltip-block';
import { ModelDimensionsBlock } from 'shared/components/model-dimensions-block';
import { EScreenshotTypes } from 'shared/enums/EScreenshotTypes';

type Props = {
  modelLink: string;
  modelFileType?: E3DModelFileTypes;
  modelMtls?: string[];
  modelContent?: ResponseModel[];
  cubeOrbitControllerBlockRef?: React.RefObject<HTMLDivElement>;
  isUploadMode?: boolean;
  isRecenterAction?: boolean;
  setAnimations?: (animations: string[]) => Promise<void>;
  setIsRecenterAction?: (value: boolean) => void;
  setCameraDataCallback?: (callback: () => Promise<CameraData & { camera: Camera }>) => void;
  setScenesRefs?: (mainScene: MainScene, cubeOCScene: CubeOrbitControllerScene) => void;
  setCreateScreenshotFn?: (createScreenshot: (type: EScreenshotTypes) => Promise<string>) => void;
  isEmbedModelScene?: boolean;
};

const scenesGlobalStateService = new ScenesGlobalStateService();


export const ViewController: React.FC<Props> = ({
  modelLink,
  modelFileType,
  modelMtls,
  modelContent,
  cubeOrbitControllerBlockRef,
  setScenesRefs,
  isUploadMode,
  isRecenterAction,
  setAnimations,
  setIsRecenterAction,
  setCameraDataCallback,
  setCreateScreenshotFn,
  isEmbedModelScene
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const viewerControllerRef = useRef<HTMLDivElement>(null);
  const tooltipBlockRef = useRef<HTMLDivElement>(null);
  const modelDimensionsBlockRef = useRef<HTMLDivElement>(null);
  const eventsReceiver = useRef<
    ((event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void) | null
  >(null);
  const mainScene = useMemo((): MainScene => new MainScene(), []);
  const eighthWallScene = useMemo((): EighthWallScene => new EighthWallScene(), []);
  const lightController = useMemo(
    (): LightController => new LightController(mainScene, eighthWallScene),
    []
  );
  const environmentController = useMemo(
    (): EnvironmentController => new EnvironmentController(mainScene),
    []
  );
  const cubemapController = useMemo((): CubemapController => new CubemapController(mainScene), []);
  const objectController = useMemo(
    (): ObjectController => new ObjectController(mainScene, eighthWallScene),
    []
  );
  const cameraController = useMemo((): CameraController => new CameraController(mainScene), []);
  const animationController = useMemo(
    (): AnimationController => new AnimationController(mainScene, eighthWallScene),
    []
  );
  const createScreenshotService = useMemo(
    (): CreateScreenshotService => new CreateScreenshotService({ mainScene }),
    []
  );
  const store = useAppSelector((store): RootState => store);
  const { isArMode } = store.arMode;
  const {
    modelSettings,
    isZenMode,
    model,
    isTransparentBackground,
    isEmbeddedModelMode,
    modelType,
    animations,
    isFirstUpload
  } = store.viewerData;
  const { user } = store.auth;
  const { keyBindings } = store.keyBindings;
  const { activeBranding } = store.branding;

  const [isSelectedFileLoaded, setIsIsSelectedFileLoaded] = useState<boolean>(false);
  const [isModelFileLoading, setIsModelFileLoading] = useState<boolean>(false);
  const [isFirstSetup, setIsFirstSetup] = useState<boolean>(true);
  const [activeScene, setActiveScene] = useState<ESceneTypes | undefined>();
  const [previousModelLink, setPreviousModelLink] = useState<string>('');
  const [isModelResourcesLoaded, setIsModelResourcesLoaded] = useState<boolean>(false);
  const [isEnvLoaded, setIsEnvLoaded] = useState<boolean>(false);

  useEffect((): (() => void) => {
    scenesGlobalStateService.addScenes(mainScene, eighthWallScene);

    if (viewerControllerRef.current) {
      viewerControllerRef.current.addEventListener('contextmenu', preventDefaults);
    }

    return (): void => {
      if (process.env.REACT_APP_EIGHTHWALL_DEV && (window as any).XR8) {
        eighthWallScene.stop();
      }

      cameraController.remove();
      scenesGlobalStateService.clearScenes();
      dispatch(clearCommentsState());

      if (viewerControllerRef.current) {
        viewerControllerRef.current.removeEventListener('contextmenu', preventDefaults);
      }
    };
  }, []);

  useEffect((): void => {
    modelLink.length ? cameraController.play() : cameraController.pause();
  }, [modelLink]);

  useEffect((): void => {
    setupCallbacksChainsAndRefs();
    setUpControllers();

    mainScene.onInitialized = handleMainSceneInitialized;
    mainScene.onModelResourcesLoaded = (): void => {
      requestAnimationFrame((): void => {
        setIsModelResourcesLoaded(true);
      });
    };

    if (!viewerControllerRef.current) return;

    mainScene.mounting({ parent: viewerControllerRef.current });
    cameraController.setUp({
      listeningBlock: viewerControllerRef.current,
      tooltipBlock: tooltipBlockRef.current,
      cubeOrbitControllerBlock: cubeOrbitControllerBlockRef?.current,
    });
    cameraController.init();
  }, []);

  useEffect((): void => {
    if (user && user.id) {
      dispatch(fetchKeyBindings({}));
    }
  }, [user?.id]);

  useEffect((): void => {
    cameraController.setKeybindingsMap(keyBindings);
  }, [keyBindings]);

  useEffect((): void => {
    if (!modelLink || !modelFileType || modelLink === previousModelLink) return;

    setPreviousModelLink(modelLink);
    setIsModelResourcesLoaded(false);
    setIsIsSelectedFileLoaded(false);
    setIsFirstSetup(true);
    dispatch(setIsModelInitialized(false));

    updateModelFile(modelLink, modelFileType, modelMtls, modelContent);
    handleUpdateModelOptions();
  }, [modelLink, modelFileType, modelMtls, modelContent]);

  useEffect((): void => {
    lightController.updateByOptions({
      lightPreset: modelSettings.lighting.lightPreset,
      castShadow: modelSettings.enableShadows,
      isFirstUpload: isFirstUpload,
    }, isFirstUpload);
  }, [modelLink, lightController, modelSettings.enableShadows, modelSettings.lighting, isArMode, isFirstUpload]);

  useEffect((): void => {
    lightController.refreshLight(modelSettings.scale);
  }, [lightController, modelSettings.scale]);

  useEffect((): void => {
    if (isEmbedModelScene) {
      isTransparentBackground
        ? environmentController.updateByOptions({
            envPreset: EEnvironmentPresets.NoEnvironment
          })
        : environmentController.updateByOptions({
            envPreset: modelSettings.environment.envPreset,
            customEnvData: modelSettings.environment.customEnvData,
            receiveShadow: modelSettings.enableShadows
          });
    }
  }, [environmentController, isEmbedModelScene, isTransparentBackground, modelSettings]);

  useEffect((): void => {
    if (!isEmbedModelScene) {
      environmentController.updateByOptions({
        envPreset: modelSettings.environment.envPreset,
        customEnvData: modelSettings.environment.customEnvData,
        receiveShadow: modelSettings.enableShadows
      });
    }
  }, [modelLink, environmentController, modelSettings, isEmbedModelScene]);

  useEffect((): void => {
    cubemapController.updateByOptions(modelSettings.cubemap);
  }, [modelLink, cubemapController, modelSettings.cubemap]);

  useEffect((): void => {
    objectController.updateByOptions({
      ...modelSettings,
      scaleFactor: modelSettings.scaleFactor.x,
      firstSetup: !!isUploadMode && isFirstSetup
    });

    objectController.updateAutoRotate(modelSettings.autoRotate);

    eighthWallScene.updateByOptions({
      // offset: modelSettings.offset,
      rotation: modelSettings.rotation,
      enableShadows: modelSettings.enableShadows
    });
  }, [
    modelLink,
    isFirstSetup,
    isUploadMode,
    modelSettings.autoRotate,
    modelSettings.enableShadows,
    modelSettings.wireframe,
    modelSettings.vertexColors,
    modelSettings.offset,
    modelSettings.playAnimation,
    modelSettings.rotation,
    modelSettings.scale,
    modelSettings.scaleFactor.x,
    modelSettings.showDimensions,
    objectController
  ]);

  useEffect((): void => {
    animationController.activeAnimation = modelSettings.animation;
  }, [modelLink, animationController, modelLink, modelSettings.animation]);

  useEffect((): void => {
    handleUpdateScene();
  }, [isArMode, isSelectedFileLoaded]);

  useEffect((): void => {
    if (isRecenterAction && !!setIsRecenterAction) {
      if (isModelResourcesLoaded) {
        setIsRecenterAction(false);
        objectController.positioned = false;
        cameraController.handleRecenterCamera(modelSettings.camera);
      }
    }
  }, [isRecenterAction]);

  useEffect((): void => {
    mainScene.updateViewerSize();
  }, [isZenMode]);

  useEffect((): void => {
    if (isModelResourcesLoaded && isEnvLoaded) {
      dispatch(setIsSceneCompletelyLoaded(true));
    } else {
      dispatch(setIsSceneCompletelyLoaded(false));
    }
  }, [isModelResourcesLoaded, isEnvLoaded]);

  useEffect((): void => {
    eighthWallScene.setActiveBranding(activeBranding);
  }, [activeBranding, eighthWallScene]);

  useEffect((): void => {
    if (modelType === EModelType.SAMPLE && animations.length > 1) {
      dispatch(setModelSettings({ ...modelSettings, animation: animations[1].value }));
    }
  }, [animations, modelType]);

  const updateModelFile = async (
    path: string,
    modelFileType: E3DModelFileTypes,
    modelMtls?: string[],
    modelContent?: ResponseModel[]
  ): Promise<void> => {
    if (!isModelFileLoading) {
      setIsModelFileLoading(true);

      try {
        const decodedPath = decodeURIComponent(path);  // Fix double encoded spaces
        await mainScene.loadModel(decodedPath, modelFileType, modelMtls, modelContent);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e.message);
        setIsModelFileLoading(false);
        dispatch(setIsViewerLoading(false));
      }

      objectController.positioned = false;

      if (mainScene.gltf || mainScene.model) {
        if (!objectController.positioned) {
          objectController.callbackStackOnPositioned.push((): void => {
            animationController.setUpObject(mainScene.gltf || mainScene.model!);
          });
        } else {
          animationController.setUpObject(mainScene.gltf || mainScene.model!);
        }

        setIsIsSelectedFileLoaded(true);
      }

      updateAnimationList();
      setIsModelFileLoading(false);
    }
  };

  const setupCallbacksChainsAndRefs = (): void => {
    if (!!setScenesRefs) {
      setScenesRefs(mainScene, cameraController.cubeOrbitControllerScene);
    }

    if (!!setCameraDataCallback) {
      setCameraDataCallback(getMetadata);
    }

    if (setCreateScreenshotFn) {
      setCreateScreenshotFn((type: EScreenshotTypes): Promise<string> => {
        return createScreenshotService.createScreenShot(type, { cubemapController });
      });
    }
  };

  const setUpObjectControllerObject = (isArMode: boolean): void => {
    if (isModelResourcesLoaded) {
      cameraController.handleRecenterCamera(modelSettings.camera);
    }

    const { updatedScaleFactor, hasSkeleton } = objectController.switchScene({
      modelDimensionsBlock: modelDimensionsBlockRef.current,
      isArMode,
      scaleFactor: modelSettings.scaleFactor.x,
      firstSetup: isFirstSetup
    });

    if (
      updatedScaleFactor &&
      Number.isFinite(updatedScaleFactor) &&
      updatedScaleFactor !== modelSettings.scaleFactor.x
    ) {
      setIsFirstSetup(false);
      dispatch(
        setScaleFactor({ x: updatedScaleFactor, y: updatedScaleFactor, z: updatedScaleFactor })
      );
    }

    dispatch(setHasSkeleton(hasSkeleton ?? false));
  };

  const setUpControllers = (): void => {
    lightController.setUp(mainScene.lightWrapper, mainScene.objectWrapper);
    mainScene.rotateRimLight = (angle: number): void => {
      if (isArMode) return;

      lightController.rotateRimLight(angle);
    };

    environmentController.handleSetIsEnvironmentLoaded = (value: boolean): void => {
      setIsEnvLoaded(value);
    };
    cubemapController.setUpScene(mainScene.scene);

    setUpObjectControllerObject(false);

    environmentController.updateByOptions({
      envPreset: modelSettings.environment.envPreset,
      customEnvData: modelSettings.environment.customEnvData,
      receiveShadow: modelSettings.enableShadows
    });
    lightController.updateByOptions(
      {
        lightPreset: modelSettings.lighting.lightPreset,
        castShadow: modelSettings.enableShadows
      },
      true
    );
    lightController.refreshLight(modelSettings.scale);
  };

  const handleMainSceneInitialized = (): void => {
    dispatch(setIsModelInitialized(true));
    cameraController.setCameraPlacement(modelSettings.camera);
    handleUpdateModelOptions();
  };

  const handleUpdateModelOptions = (): void => {
    lightController.updateByOptions(
      {
        lightPreset: modelSettings.lighting.lightPreset,
        castShadow: modelSettings.enableShadows
      },
      isFirstSetup
    );
    lightController.refreshLight(modelSettings.scale);

    environmentController.updateByOptions({
      envPreset: modelSettings.environment.envPreset,
      customEnvData: modelSettings.environment.customEnvData,
      receiveShadow: modelSettings.enableShadows
    });

    cubemapController.updateByOptions(modelSettings.cubemap, isFirstSetup);

    objectController.updateByOptions({
      ...modelSettings,
      scaleFactor: modelSettings.scaleFactor.x,
      firstSetup: !!isUploadMode && isFirstSetup
    });

    objectController.updateAutoRotate(modelSettings.autoRotate);

    if (isRecenterAction && !!setIsRecenterAction && isModelResourcesLoaded) {
      objectController.positioned = false;
      cameraController.handleRecenterCamera(modelSettings.camera);
      setIsRecenterAction(false);
    }

    animationController.activeAnimation = modelSettings.animation;
  };

  const updateARScene = (): void => {
    if (!isSelectedFileLoaded || !mainScene.model || !model) return;

    mainScene.stopAnimationLoop();

    eighthWallScene.setActiveBranding(activeBranding);
    eighthWallScene.setup(modelSettings.arScaleInMeters);
    eighthWallScene.run();

    cameraController.pause();

    lightController.setUp(eighthWallScene.lightWrapper, eighthWallScene.objectWrapper);
    eighthWallScene.rotateRimLight = (angle: number): void => {
      if (!isArMode) return;

      lightController.rotateRimLight(angle);
    };

    setUpObjectControllerObject(true);

    eighthWallScene.refreshLight = lightController.refreshLight;

    lightController.updateByOptions(
      {
        lightPreset: modelSettings.lighting.lightPreset,
        castShadow: modelSettings.enableShadows
      },
      true
    );
    lightController.refreshLight(modelSettings.scale);

    eighthWallScene.setUpCubemapControllerScene = (scene: THREE.Scene): void => {
      cubemapController.setUpScene(scene);
      cubemapController.updateByOptions(modelSettings.cubemap, true);
    };

    eighthWallScene.updateByOptions({
      offset: modelSettings.offset,
      rotation: modelSettings.rotation,
      enableShadows: modelSettings.enableShadows
    });

    setActiveScene(ESceneTypes.ar);
  };

  const updateMainScene = (): void => {
    if (!isSelectedFileLoaded || !mainScene.model) return;

    requestAnimationFrame((): void => {
      mainScene.refreshWebGL();
      cameraController.handleRefreshCOCSWebGL();
    });

    if (process.env.REACT_APP_EIGHTHWALL_DEV && (window as any).XR8) {
      eighthWallScene.stop();
    }

    cameraController.play();

    mainScene.setupModel();
    mainScene.startAnimationLoop();

    setUpObjectControllerObject(false);

    lightController.setUp(mainScene.lightWrapper, mainScene.objectWrapper);
    mainScene.rotateRimLight = (angle: number): void => {
      if (isArMode) return;

      lightController.rotateRimLight(angle);
    };

    cubemapController.setUpScene(mainScene.scene);

    lightController.updateByOptions(
      {
        lightPreset: modelSettings.lighting.lightPreset,
        castShadow: modelSettings.enableShadows
      },
      true
    );
    lightController.refreshLight(modelSettings.scale);

    cubemapController.updateByOptions(modelSettings.cubemap, activeScene === ESceneTypes.ar);

    objectController.updateByOptions({
      ...modelSettings,
      scaleFactor: modelSettings.scaleFactor.x
    });

    objectController.updateAutoRotate(modelSettings.autoRotate);

    setActiveScene(ESceneTypes.main);
  };

  const handleUpdateScene = (): void => {
    if (process.env.REACT_APP_EIGHTHWALL_DEV && isArMode) {
      if (!(window as any).XR8) {
        setTimeout((): void => {
          handleUpdateScene();
        }, 100);
      } else {
        updateARScene();
      }
    } else if (!isArMode) {
      updateMainScene();
    }
  };

  const getMetadata = (): Promise<ISceneMetadata> => {
    return new Promise((resolve, reject): void => {
      window.requestAnimationFrame(async (): Promise<void> => {
        if (!mainScene.gltf && !mainScene.model) {
          return;
        }

        const screenshot = await mainScene.createScreenshot({ cubemapController });
        const model = mainScene.gltf ? mainScene.gltf : mainScene.model!;
        const animations = model.animations ? model.animations.length : 0;
        const animationsDetails = model.animations
          ? model.animations.map((a: any): any => a.name)
          : [];
        const cameraPosition = {
          position: mainScene.camera.position.clone(),
          rotation: new THREE.Vector3().setFromEuler(mainScene.camera.rotation),
          target: cameraController.cameraTarget.clone()
        };

        resolve({
          screenshot,
          animations,
          animationsDetails,
          camera: cameraPosition
        });
      });
    });
  };

  const updateAnimationList = async (): Promise<void> => {
    const metadata = await getMetadata();

    if (!metadata) return;

    if (!!setAnimations) {
      await setAnimations(metadata.animationsDetails || []);
    }
  };

  const passEventToEventsReceiver = (event: React.MouseEvent<HTMLDivElement, MouseEvent>): void => {
    if (eventsReceiver.current) {
      eventsReceiver.current(event);
    }
  };

  return (
    <div>
      <Styled.Viewer
        $isZenMode={isZenMode}
        $isEmbeddedModelMode={isEmbeddedModelMode}
        $isEmbedModelScene={!!isEmbedModelScene}
        $isArMode={isArMode}
        ref={viewerControllerRef}
        onMouseDown={passEventToEventsReceiver}
        onMouseUp={passEventToEventsReceiver}
      >
        <ViewerSceneTooltipBlock tooltipBlockRef={tooltipBlockRef} />

        <ModelDimensionsBlock modelDimensionsBlockRef={modelDimensionsBlockRef} />

        <CommentsController
          mainScene={mainScene}
          cameraController={cameraController}
          setEventsReceiver={(callback): void => {
            eventsReceiver.current = callback;
          }}
          createScreenshot={async (editor?: HTMLDivElement): Promise<string> => {
            return mainScene.createScreenshot({ cubemapController, editor });
          }}
        />
      </Styled.Viewer>
    </div>
  );
};
