import React from 'react';

const DeleteIcon: React.FC = () => {
  return (
    <svg width="12" height="14" viewBox="0 0 12 14" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M11.5714 0.875004H8.35714L8.10536 0.363677C8.05202 0.25436 7.96986 0.162405 7.86812 0.0981575C7.76638 0.0339095 7.6491 -8.27959e-05 7.52946 4.7941e-06H4.46786C4.34849 -0.000463619 4.23142 0.0334019 4.13004 0.0977214C4.02866 0.162041 3.94708 0.254213 3.89464 0.363677L3.64286 0.875004H0.428571C0.314907 0.875004 0.205898 0.921098 0.125526 1.00315C0.0451529 1.08519 0 1.19647 0 1.3125L0 2.1875C0 2.30354 0.0451529 2.41482 0.125526 2.49686C0.205898 2.57891 0.314907 2.625 0.428571 2.625H11.5714C11.6851 2.625 11.7941 2.57891 11.8745 2.49686C11.9548 2.41482 12 2.30354 12 2.1875V1.3125C12 1.19647 11.9548 1.08519 11.8745 1.00315C11.7941 0.921098 11.6851 0.875004 11.5714 0.875004ZM1.425 12.7695C1.44544 13.1027 1.58951 13.4155 1.82787 13.6441C2.06624 13.8727 2.38098 14 2.70804 14H9.29196C9.61902 14 9.93376 13.8727 10.1721 13.6441C10.4105 13.4155 10.5546 13.1027 10.575 12.7695L11.1429 3.5H0.857143L1.425 12.7695Z" fill="currentColor"/>
    </svg>
);	
};

export default DeleteIcon;
