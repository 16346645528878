import React, { useState } from 'react';
import { BrandOption, EPremiumFeature } from 'shared/types';
import * as Styled from './styles';
import ArrowDownIcon from 'assets/images/arrow-down.svg';
import { useAppSelector, useHandleClickOutside } from 'shared/hooks';
import { convertStringToButtonId } from 'utils/form-utils';
import { ModalFeatureSignedIn } from 'shared/components/modals';
import { showModal } from 'services/store/reducers/modalReducer';
import { useDispatch } from 'react-redux';
import { RootState } from 'services/store';

type Props = {
  activeOption: BrandOption;
  onChangeOption: (value: BrandOption) => void;
  options: BrandOption[];
};

const Select: React.FC<Props> = ({ activeOption, onChangeOption, options }): JSX.Element => {
  const dispatch = useDispatch();
  const store = useAppSelector((store): RootState => store);
  const { hasCustomBrandingAccess } = store.modelFeatureAccess;
  const [isMenuActive, setIsMenuActive] = useState<boolean>(false);

  const handleOptionClick =
    (option: BrandOption): (() => void) =>
    (): void => {
      setIsMenuActive(false);
      onChangeOption(option);
    };

  const handleUpgradeClick = (): void => {
    dispatch(showModal(<ModalFeatureSignedIn feature={EPremiumFeature.BRANDING} />));
    setIsMenuActive(false);
  };

  const ref = useHandleClickOutside((): void => setIsMenuActive(false));

  return (
    <Styled.SelectContainer $isMenuActive={isMenuActive} ref={ref}>
      <Styled.MainField
        id='branding-select'
        $isMenuActive={isMenuActive}
        onClick={() => setIsMenuActive((state): boolean => !state)}
      >
        <div>{activeOption.title}</div>
        <img src={ArrowDownIcon} alt='Arrow' />
      </Styled.MainField>
      <Styled.SelectMenu $isMenuActive={isMenuActive}>
        {options.map(
          (option): JSX.Element => (
            <Styled.OptionItem
              $isActive={activeOption.title === option.title}
              onClick={handleOptionClick(option)}
              id={convertStringToButtonId(option.title)}
              key={option.title}
            >
              <span>{option.title}</span>
            </Styled.OptionItem>
          )
        )}
        {!hasCustomBrandingAccess && (
          <Styled.UpgradeButtonWrapper $isMenuActive={isMenuActive}>
            <Styled.UpgradeButton onClick={handleUpgradeClick}>
              Upgrade for More
            </Styled.UpgradeButton>
          </Styled.UpgradeButtonWrapper>
        )}
      </Styled.SelectMenu>
    </Styled.SelectContainer>
  );
};

export default Select;
