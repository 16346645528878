import React, { useCallback, useEffect, useState } from 'react';
import { Skeleton } from 'antd';
import * as Styled from './styles';
import Warning from 'assets/images/warning.svg';
import { convertBytesToMegabytes, convertToEngineeringFormat } from 'utils/model-utils';
import { ESnackbarStyle, Model, ModelTexture } from 'shared/types';
import { store } from 'services/store';
import { capitalize } from 'utils/form-utils';
import { DELETE_BRACKETS_REGEXP } from 'shared/constants/regexps';
import { getModel } from 'services/api/modelService';
import { openNotification } from 'utils/notification-utils';

type Props = {
  model: Model;
  isStatsActive: boolean;
};

type ModelMetadata = {
  format: string;
  size: string;
  materials: string;
  litValue: string;
  owner: string;
};

type ModelStats = {
  columnName: string;
  columnData: { title: string; value?: number }[];
}[];

const statsCache = new Map<string, {
  modelMetadata: ModelMetadata;
  modelStats: ModelStats;
}>();

const ModelCardStats: React.FC<Props> = ({ model, isStatsActive }): React.ReactElement => {
  const [modelMetadata, setModelMetadata] = useState<ModelMetadata | null>();
  const [modelStats, setModelStats] = useState<ModelStats | null>();

  const fetchModelStats = useCallback(async (): Promise<void> => {
    const cached = statsCache.get(model.id);
    if (cached) {
      setModelMetadata(cached.modelMetadata);
      setModelStats(cached.modelStats);
      return;
    }

    try {
      const modelWithMetadata = (await getModel(model.id)).data;
      if (!!modelWithMetadata.metadata) {
        const { geometry, textures, resources, format, unlitFlag } = modelWithMetadata.metadata;

        const isModelOwner: boolean = store.getState().auth.user?.id === modelWithMetadata.ownerId;
        const isLit = unlitFlag === 1 || unlitFlag === 3;
        const isUnlit = unlitFlag === 2 || unlitFlag === 3;
        const maxAnimationsListLength = 7;
        const animationsList = resources.animations.animations.slice(0, maxAnimationsListLength);

        const geometryStats = Object.entries(geometry).map(
          (item): { title: string; value: string } => ({
            title: capitalize(item[0]),
            value: convertToEngineeringFormat(item[1])
          })
        );

        const texturesStats = Object.entries(textures)
          .filter((i): boolean => i[0] !== 'totalCount' && !!(i[1] as ModelTexture).totalCount)
          .map((item): { title: string; value: number } => ({
            title: item[0] === '128' ? '0 to 128' : item[0],
            value: (item[1] as ModelTexture).totalCount
          }));

        const modelMetadata = {
          format: format.toUpperCase().slice(1),
          size: `${convertBytesToMegabytes(modelWithMetadata.modelSize)}MB`,
          materials: `${resources.materials} Materials`,
          litValue: isLit ? 'Lit' : isUnlit ? 'Unlit' : 'Lit/Unlit',
          owner: `Owner: ${
            isModelOwner ? 'Me' : modelWithMetadata.ownerName.replace(DELETE_BRACKETS_REGEXP, '')
          }`
        };

        const modelStats = [
          {
            columnName: 'Geometry',
            columnData: geometryStats
          },
          {
            columnName: `Textures (${textures.totalCount})`,
            columnData: texturesStats
          },
          {
            columnName: `Animations (${resources.animations.totalCount})`,
            columnData: animationsList.map((title): { title: string } => ({ title }))
          }
        ];

        statsCache.set(model.id, {
          modelMetadata,
          modelStats
        });

        setModelMetadata(modelMetadata);
        setModelStats(modelStats);
      }
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    }
  }, [model.id]);

  useEffect((): void => {
    if (isStatsActive && !modelMetadata) {
      fetchModelStats();
    }
  }, [fetchModelStats, isStatsActive, modelMetadata]);

  return (
    <Styled.CardStatsContainer $isStatsActive={isStatsActive}>
      {!modelMetadata || !modelStats ? (
        <Styled.StatsLoading>
          <div className='skeleton-top'>
            {Array.from(Array(5).keys()).map(
              (i): React.ReactElement => (
                <Skeleton.Button key={i} size='small' block active />
              )
            )}
          </div>
          <div className='skeleton-bottom'>
            {Array.from(Array(3).keys()).map(
              (i): React.ReactElement => (
                <Skeleton.Button key={i} block active />
              )
            )}
          </div>
        </Styled.StatsLoading>
      ) : (
        <Styled.CardStats>
          <Styled.StatsHeader>
            {Object.entries(modelMetadata).map(
              (i): React.ReactElement => (
                <Styled.StatsHeaderItem
                  key={i[0]}
                  className={i[0] === 'owner' ? 'owner' : undefined}
                >
                  {i[1]}
                </Styled.StatsHeaderItem>
              )
            )}
          </Styled.StatsHeader>

          <Styled.StatsTable>
            {modelStats.map(
              ({ columnName, columnData }): React.ReactElement => (
                <Styled.StatsTableColumn key={columnName}>
                  <Styled.TableColumnTitle>{columnName}</Styled.TableColumnTitle>
                  {columnData.map(
                    ({ title, value }): React.ReactElement => (
                      <Styled.TableColumnItem
                        key={title}
                        className={title === 'NPOT' ? 'npot' : undefined}
                      >
                        <span className='item-title'>{title}</span>
                        {title === 'NPOT' && <img src={Warning} alt='NPOT' />}
                        {!!value && <span className='item-value'>{value}</span>}
                      </Styled.TableColumnItem>
                    )
                  )}
                </Styled.StatsTableColumn>
              )
            )}
          </Styled.StatsTable>
        </Styled.CardStats>
      )}
    </Styled.CardStatsContainer>
  );
};

export default ModelCardStats;
