import React, { useState, useCallback, useEffect, useRef } from 'react';
import * as THREE from 'three';
import { CSS2DObject } from 'three/examples/jsm/renderers/CSS2DRenderer';
import { AimOutlined, CaretDownOutlined } from '@ant-design/icons';
import * as Styled from './styles';
import MyModelsIcon from 'assets/dynamic-icons/my-models-icon/MyModelsIcon';
import { CustomTooltip } from 'shared/components';
import { MainScene } from 'shared/webgl/scenes';

interface SceneNodeProps {
  node: THREE.Object3D;
  level: number;
  mainScene: MainScene;
  shouldExpand: boolean;
  incrementNodeCount: () => void;
}

const SceneNode: React.FC<SceneNodeProps> = ({
  node,
  level,
  mainScene,
  shouldExpand,
  incrementNodeCount,
}): JSX.Element => {
  const labelRef = useRef<HTMLSpanElement>(null);
  const [isTextOverflowing, setIsTextOverflowing] = useState(false);
  const [isExpanded, setIsExpanded] = useState(false);
  const [visibleChildren, setVisibleChildren] = useState<THREE.Object3D[]>([]);

  const hasChildren = visibleChildren.length > 0;
  const isFocusable = node instanceof THREE.Mesh || node instanceof THREE.Group || hasChildren;
  const nodeLabel = node.name || node.type;

  useEffect(() => {
    incrementNodeCount();
  }, [incrementNodeCount]);

  useEffect(() => {
    if (shouldExpand && level < 5 && hasChildren) {
      setIsExpanded(true);
    }
  }, [shouldExpand, level, hasChildren]);

  useEffect(() => {
    const visibleChildren = node.children.filter(child => {
      const hasCommentChild = child.children.some(grandChild => grandChild instanceof CSS2DObject);
      return !(child instanceof CSS2DObject) && !hasCommentChild && child.visible;
    });
    setVisibleChildren(visibleChildren);
  }, [node]);

  useEffect(() => {
    if (labelRef.current) {
      setIsTextOverflowing(labelRef.current.scrollWidth > labelRef.current.clientWidth);
    }
  }, [node.name, node.type]);

  const handleFocusObject = useCallback((object: THREE.Object3D) => {
    if (!mainScene?.camera) return;

    const box = new THREE.Box3().setFromObject(object);
    const center = box.getCenter(new THREE.Vector3());
    const size = box.getSize(new THREE.Vector3());
    const maxDim = Math.max(size.x, size.y, size.z);
    const fov = mainScene.camera.fov * (Math.PI / 180);
    const cameraDistance = Math.abs(maxDim / Math.sin(fov / 2) / 1.5);
    const direction = mainScene.camera.position.clone()
      .sub(mainScene.orbitControls.target)
      .normalize();

    mainScene.camera.position.copy(
      center.clone().add(direction.multiplyScalar(cameraDistance))
    );
    mainScene.orbitControls.target.copy(center);
    mainScene.orbitControls.update();
  }, [mainScene]);

  const handleClick = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    if (!hasChildren && isFocusable) {
      handleFocusObject(node);
    } else if (hasChildren) {
      setIsExpanded(prev => !prev);
    }
  }, [hasChildren, isFocusable, node, handleFocusObject]);

  const handleFocus = useCallback((e: React.MouseEvent) => {
    e.stopPropagation();
    handleFocusObject(node);
  }, [node, handleFocusObject]);

  return (
    <div>
      <Styled.TreeNode 
        $level={level} 
        $hasChildren={hasChildren}
        $isFocusable={isFocusable}
        onClick={handleClick}
      >
        {hasChildren ? (
          <Styled.CaretIcon style={{ transform: isExpanded ? 'rotate(0deg)' : 'rotate(-90deg)' }}>
            <CaretDownOutlined />
          </Styled.CaretIcon>
        ) : (
          <Styled.NodeIcon $isFocusable={isFocusable}>
            {isFocusable ? <MyModelsIcon /> : <Styled.QuestionIcon>?</Styled.QuestionIcon>}
          </Styled.NodeIcon>
        )}
        {isTextOverflowing ? (
          <CustomTooltip content={nodeLabel} placement="top">
            <Styled.NodeLabel ref={labelRef}>{nodeLabel}</Styled.NodeLabel>
          </CustomTooltip>
        ) : (
          <Styled.NodeLabel ref={labelRef}>{nodeLabel}</Styled.NodeLabel>
        )}
        {isFocusable && (
          <Styled.FocusIcon $hasChildren={hasChildren} onClick={handleFocus}>
            <AimOutlined />
          </Styled.FocusIcon>
        )}
      </Styled.TreeNode>
      {hasChildren && (
        <Styled.ChildrenContainer $isExpanded={isExpanded}>
          {visibleChildren.map(child => (
            <SceneNode
              key={child.uuid}
              node={child}
              level={level + 1}
              mainScene={mainScene}
              shouldExpand={shouldExpand}
              incrementNodeCount={incrementNodeCount}
            />
          ))}
        </Styled.ChildrenContainer>
      )}
    </div>
  );
};

export default SceneNode; 