import { createAction, createReducer } from '@reduxjs/toolkit';
import { ELoadingState, LoaderState } from 'shared/types';

const initialState: LoaderState = {
  isLoaderActive: false,
  isFullScreenLoaderActive: false,
  isViewerLoading: false,
  loadingState: ELoadingState.STARTING,
  totalLoadingSteps: 0,
  currentLoadingPercent: 0,
  percentPerStep: 0
};

// Actions

export const startLoader = createAction('loader/startLoader');
export const stopLoader = createAction('loader/stopLoader');
export const setIsViewerLoading = createAction<boolean>('loader/setIsViewerLoading');
export const startFullScreenLoader = createAction('loader/startFullScreenLoader');
export const stopFullScreenLoader = createAction('loader/stopFullScreenLoader');
export const setTotalLoadingSteps = createAction<number>('loader/setTotalLoadingSteps');
export const setLoadingState = createAction<ELoadingState>('loader/setLoadingState');
export const setInitialLoadingData = createAction('loader/setInitialLoadingData');

// Reducer

export const loaderReducer = createReducer(initialState, (builder): void => {
  builder
    .addCase(startLoader, (state): void => {
      state.isLoaderActive = true;
    })
    .addCase(stopLoader, (state): void => {
      if (!state.isViewerLoading) {
        state.isLoaderActive = false;
      }
    })
    .addCase(startFullScreenLoader, (state): void => {
      state.isFullScreenLoaderActive = true;
    })
    .addCase(setIsViewerLoading, (state, { payload }): void => {
      state.isViewerLoading = payload;
      state.isLoaderActive = payload;
    })
    .addCase(stopFullScreenLoader, (state): void => {
      state.isFullScreenLoaderActive = false;
    })
    .addCase(setTotalLoadingSteps, (state, { payload }): void => {
      state.totalLoadingSteps = payload;
      const percentRest = 100 - state.currentLoadingPercent;
      state.percentPerStep = +(percentRest / payload).toFixed();
    })
    .addCase(setLoadingState, (state, { payload }): void => {
      state.loadingState = payload;
      const percent =
        payload === ELoadingState.LOADED
          ? 100
          : +(state.currentLoadingPercent + state.percentPerStep).toFixed();
      state.currentLoadingPercent = percent > 100 ? 100 : percent;
    })
    .addCase(setInitialLoadingData, (state): void => {
      state.currentLoadingPercent = 0;
      state.percentPerStep = 0;
      state.loadingState = ELoadingState.STARTING;
      state.totalLoadingSteps = 0;
    });
});
