import React from 'react';

const AnalyzeIcon: React.FC = (): JSX.Element => {
  return (
    <svg width="30" height="30" viewBox="10 0 50 100" xmlns="http://www.w3.org/2000/svg"
      fill="none" stroke="black" strokeWidth="10" strokeLinecap="round">
      <line x1="10" y1="20" x2="70" y2="20"/>
      <line x1="30" y1="40" x2="70" y2="40"/>
      <line x1="30" y1="60" x2="70" y2="60"/>
      <line x1="50" y1="80" x2="70" y2="80"/>
    </svg>
  );
};

export default AnalyzeIcon;