import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';

export const CardStatsContainer = styled.div<{ $isStatsActive: boolean }>`
  display: flex;
  width: 100%;
  max-height: ${({ $isStatsActive }): string => ($isStatsActive ? '600px' : '0')};
  background-color: ${COLORS.codGray};
  transition: max-height 0.3s ease-in-out;
  border-radius: 7px 0 7px 7px;
  overflow-y: hidden;
  overflow-x: hidden;

  @media ${DEVICE.mobile} {
    border-radius: 0 0 7px 7px;
  }
`;

export const CardStats = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  padding: 11px;
  width: 100%;
  overflow: hidden;
`;

export const StatsHeader = styled.div`
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  width: 100%;

  @media ${DEVICE.mobile} {
    margin-top: 0;
    gap: 4px;
  }
`;

export const StatsHeaderItem = styled.div`
  padding: 4px 6px;
  text-align: center;
  color: ${COLORS.white};
  background-color: ${COLORS.doveGray};
  border-radius: 3px;
  font-size: 11px;
  font-weight: 300;
  line-height: normal;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  min-width: fit-content;
  flex: 1 1 auto;

  &.owner {
    min-width: 0;
    max-width: 100%;

    @media ${DEVICE.mobile} {
      flex-basis: 100%;
    }
  }
`;

export const StatsTable = styled.div`
  display: flex;
  justify-content: space-between;
  gap: 8px;
  overflow-x: auto;
  -ms-overflow-style: none;
  scrollbar-width: none;
  
  &::-webkit-scrollbar {
    display: none;
  }

  @media ${DEVICE.mobile} {
    flex-direction: column;
    overflow-x: visible;
    gap: 0;
  }
`;

export const StatsTableColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;
  height: fit-content;
  margin-top: 8px;
  background-color: ${COLORS.doveGray};
  border-radius: 3px;
  font-size: 11px;
  line-height: 13px;
  white-space: nowrap;

  @media ${DEVICE.mobile} {
    margin-top: 4px;
  }
`;

export const TableColumnTitle = styled.div`
  display: flex;
  align-items: center;
  height: 21px;
  font-weight: 600;
  padding: 0 8px;
`;

export const TableColumnItem = styled.div`
  display: flex;
  align-items: center;
  height: 19px;
  font-weight: 400;
  padding-left: 8px;

  &:last-child {
    padding-top: 3px;
    align-items: flex-start;
    height: 21px;
    border-radius: 0 0 3px 3px;
  }
  
  &:nth-child(even) {
    background-color: rgba(71, 71, 71, 0.59);
  }

  &.npot {
    background-color: rgba(255, 2, 2, 0.59);
  }

  span {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .item-title {
    margin-right: 5px;
  }

  .item-value {
    margin-left: auto;
    padding-right: 8px;
  }

  img {
    margin-right: 5px;
  }
`;

export const StatsLoading = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  width: 100%;
  padding: 8px;
  overflow: hidden;

  .skeleton-top {
    display: flex;
    justify-content: space-between;
    gap: 8px;
  }

  .skeleton-bottom {
    display: flex;
    justify-content: space-between;
    gap: 8px;

    .ant-skeleton {
      height: 113px;
    }

    .ant-skeleton-button {
      width: 100%;
      height: 100%;
    }
  }
`;
