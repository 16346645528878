import React, { useEffect, useState, useMemo, memo, useCallback } from 'react';
import * as Styled from './styles';
import LeftArrow from 'assets/images/left-arrow.svg';
import {
  EPremiumFeature,
  EModelType,
  ModelWithMetadata,
  CameraData,
  Camera,
  EConfirmModalHeader,
} from 'shared/types';
import { DisplayTab, AnalyzeTab, WarningsTab } from './components';
import { useAppDispatch, useAppSelector, useAutoSave, useFeatureAccess } from 'shared/hooks';
import {
  showModal,
  closeConfirmModal,
  showConfirmModal
} from 'services/store/reducers/modalReducer';
import { ModalFeatureSignedIn, ModalRenameModel } from 'shared/components';
import CommentsBar from 'shared/components/comments-bar/CommentsBar';
import {
  setIsSidebarHidden,
  stopZenMode,
  clearViewerDataState,
} from 'services/store/reducers/viewerDataReducer';
import { DEVICE_SIZES } from 'shared/constants/deviceSizes';
import { RootState } from 'services/store';
import { setIsCommentsBarActive, setIsCommentsVisible } from 'services/store/reducers/commentsReducer';
import { FEATURES } from 'shared/constants/features';
import { CommentIcon, MyModelsIcon, AnalyzeIcon, DeleteIcon, EditIcon } from 'assets/dynamic-icons';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { MainScene } from 'shared/webgl/scenes';
import { ESnackbarStyle } from 'shared/types';
import { openNotification } from 'utils/notification-utils';
import { ModalDeleteModel } from 'shared/components';
import { deleteModel } from 'services/api/modelService';
import { MODEL_DELETED } from 'shared/constants/notifications';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { useHistory } from 'react-router-dom';
import { COLORS } from 'shared/constants/colors';
import { getContentMaxHeightCSS } from 'shared/styles';
import { HEADER_HEIGHT } from 'shared/constants/header';
import styled from 'styled-components';

type Props = {
  model: ModelWithMetadata;
  setIsRecenterAction: (value: boolean) => void;
  getCameraData: (() => Promise<CameraData & { camera: Camera } >) | null;
  mainScene?: MainScene | null;
};

enum ESidebarTab {
  DISPLAY = 'display',
  COMMENTS = 'comments',
  ANALYZE = 'analyze',
  WARNINGS = 'warnings'
}

const MemoizedDisplayTab = memo(DisplayTab);
const MemoizedCommentsBar = memo(CommentsBar);
const MemoizedAnalyzeTab = memo(AnalyzeTab);
const MemoizedWarningsTab = memo(WarningsTab);

const SidebarModel: React.FC<Props> = ({ model, setIsRecenterAction, getCameraData, mainScene }): JSX.Element => {
  const dispatch = useAppDispatch();
  const store = useAppSelector((store): RootState => store);
  const { isArMode } = store.arMode;
  const { user, isAuth } = store.auth;
  const { isZenMode, modelType, isSidebarHidden, modelName } =
    store.viewerData;
  const { plainComments, isCommentsBarActive, isCommentsVisible } = store.comments;
  const { hasEditAccess } = store.modelFeatureAccess;
  const [activeTab, setActiveTab] = useState<ESidebarTab>(ESidebarTab.DISPLAY);
  const isQuickView = modelType === EModelType.QUICK_VIEW;
  const isTeamModel = modelType === EModelType.TEAM;
  const isModelOwner = model.ownerId === user?.id;
  const { checkCommentAccess } = useFeatureAccess(model);
  const history = useHistory();
  const _ = useAutoSave();  // Start auto-saving.

  useEffect(() => {
    const isMobile = window.innerWidth <= DEVICE_SIZES.mobile;
    if (isMobile) {
      dispatch(setIsSidebarHidden(true));
    }
  }, [dispatch]);

  const hasDeleteAccess = useMemo(
    (): boolean => (isTeamModel ? hasEditAccess : isModelOwner),
    [hasEditAccess, isModelOwner, isTeamModel]
  );

  const handleDeleteModelClick = (): void => {
    const action = async (): Promise<void> => {
      try {
        dispatch(startLoader());
        await deleteModel(model.id);
        dispatch(clearViewerDataState());
        dispatch(closeConfirmModal());
        history.push(isTeamModel ? `/workspace/${model.teamId}` : isAuth ? '/my-workspace' : '/');
        openNotification(ESnackbarStyle.SUCCESS, MODEL_DELETED);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    };

    dispatch(
      showConfirmModal({
        header: EConfirmModalHeader.DELETE,
        content: <ModalDeleteModel model={model!} action={action} />
      })
    );
  };

  const handleEditClick = useCallback((): void => {
    dispatch(showConfirmModal({
      header: EConfirmModalHeader.EDIT,
      content: <ModalRenameModel modelName={modelName} />
    }));
  }, [model, modelName]);

  useEffect((): void => {
    if (isArMode) {
      dispatch(stopZenMode());
      dispatch(setIsSidebarHidden(true));
    }
  }, [dispatch, isArMode]);

  useEffect(() => {
    const handleResize = (): void => {
      const isDesktop = window.innerWidth > DEVICE_SIZES.tabletLarge;
      if (!isDesktop && isCommentsBarActive) {
        handleCommentsTabClick();
      } else if (activeTab === ESidebarTab.COMMENTS) {
        setActiveTab(ESidebarTab.DISPLAY);
      }
    };

    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, [isCommentsBarActive, activeTab]);

  useEffect((): void => {
    window.dispatchEvent(new Event('sidebarStateChange'));
  }, [isSidebarHidden]);

  const handleCommentsTabClick = async (): Promise<void> => {
    const hasAccess = await checkCommentAccess();

    if(!hasAccess && isModelOwner) {
      dispatch(setIsCommentsBarActive(false));
      dispatch(setIsCommentsVisible(false));
      dispatch(showModal(<ModalFeatureSignedIn feature={EPremiumFeature.COMMENTS} />));
      return;
    }

    if (window.innerWidth < DEVICE_SIZES.tabletLarge) {
      dispatch(setIsCommentsBarActive(true));
      dispatch(setIsCommentsVisible(hasAccess));
      setActiveTab(ESidebarTab.COMMENTS);
    }
  };

  const handleTabClick = (tab: ESidebarTab): void => {
    if (window.innerWidth < DEVICE_SIZES.tabletLarge) {
      dispatch(setIsCommentsBarActive(false));
    }
    setActiveTab(tab);
  };

  const handleSidebarArrowClick = (): void => {
    dispatch(setIsSidebarHidden(!isSidebarHidden));
  };

  const formatDate = useCallback((date: string): string => {
    const d = new Date(date);
    return `Uploaded at ${d.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' })} on ${d.toLocaleDateString([], { day: 'numeric', month: 'long', year: 'numeric' })}`;
  }, []);

  const tabComponents = useMemo(() => ({
    [ESidebarTab.DISPLAY]: (
      <Styled.TabContainer $isVisible={activeTab === ESidebarTab.DISPLAY} key={ESidebarTab.DISPLAY}>
        <MemoizedDisplayTab
          model={model}
          setIsRecenterAction={setIsRecenterAction}
          getCameraData={getCameraData}
        />
      </Styled.TabContainer>
    ),
    [ESidebarTab.COMMENTS]: isCommentsVisible ? (
      <Styled.TabContainer $isVisible={activeTab === ESidebarTab.COMMENTS} key={ESidebarTab.COMMENTS}>
        <MemoizedCommentsBar modelId={model.id} />
      </Styled.TabContainer>
    ) : (
      <Styled.TabContainer $isVisible={activeTab === ESidebarTab.COMMENTS} key={ESidebarTab.COMMENTS}>
        <Styled.DisabledMessageModal>
          {FEATURES[EPremiumFeature.COMMENTS].lockedMessage}
        </Styled.DisabledMessageModal>
      </Styled.TabContainer>
    ),
    [ESidebarTab.ANALYZE]: mainScene?.model ? (
      <Styled.TabContainer $isVisible={activeTab === ESidebarTab.ANALYZE} key={ESidebarTab.ANALYZE}>
        <MemoizedAnalyzeTab mainScene={mainScene} model={model} />
      </Styled.TabContainer>
    ) : null,
    [ESidebarTab.WARNINGS]: (
      <Styled.TabContainer $isVisible={activeTab === ESidebarTab.WARNINGS} key={ESidebarTab.WARNINGS}>
        <MemoizedWarningsTab model={model} />
      </Styled.TabContainer>
    ),
  }), [
    activeTab,
    model,
    setIsRecenterAction,
    getCameraData,
    mainScene,
    isCommentsVisible
  ]);

  return (
    <Styled.SidebarContainer
      $isArMode={isArMode}
      $isZenMode={isZenMode}
    >
      <Styled.ArrowLeftContainer onClick={handleSidebarArrowClick}>
        <Styled.ArrowLeft src={LeftArrow} $isSidebarHidden={isSidebarHidden} />
      </Styled.ArrowLeftContainer>
      <Styled.FullModeContainer>
        <Styled.SidebarHeaderContainer>
          <Styled.ModelHeaderRow>
            {hasDeleteAccess && (
              <Styled.IconButton $isDelete onClick={handleDeleteModelClick}>
                <DeleteIcon />
              </Styled.IconButton>
            )}
            <Styled.ModelName>
              {
                modelType === EModelType.SAMPLE
                  ? modelName.replace(/_/g, ' ').replace(/\b\w/g, str => str.toUpperCase()) + " - Sample"
                  : modelName
              }
            </Styled.ModelName>
            {hasDeleteAccess && (
              <Styled.IconButton onClick={handleEditClick}>
                <EditIcon />
              </Styled.IconButton>
            )}
          </Styled.ModelHeaderRow>
          <Styled.ModelTimestamp>
            {formatDate(model.createdAt)}
          </Styled.ModelTimestamp>
        </Styled.SidebarHeaderContainer>
        <Styled.SidebarTabs>
          <Styled.SidebarTab
            onClick={() => handleTabClick(ESidebarTab.DISPLAY)}
            className={activeTab === ESidebarTab.DISPLAY ? 'selected' : ''}
          >
            <Styled.SidebarTabIcon $isActive={activeTab === ESidebarTab.DISPLAY}>
              <MyModelsIcon />
            </Styled.SidebarTabIcon>
            <Styled.SidebarTabText>Display</Styled.SidebarTabText>
          </Styled.SidebarTab>
          {!isArMode && !isQuickView && window.innerWidth < DEVICE_SIZES.tabletLarge && (
            <Styled.SidebarTab
              onClick={handleCommentsTabClick}
              className={activeTab === ESidebarTab.COMMENTS ? 'selected' : ''}
            >
              <Styled.SidebarTabIcon $isCommentIcon>
                <CommentIcon />
              </Styled.SidebarTabIcon>
              <Styled.SidebarTabText>Comments</Styled.SidebarTabText>
              <Styled.SidebarTabCounter>
                {plainComments.length <= 99999 ? plainComments.length : '99999+'}
              </Styled.SidebarTabCounter>
            </Styled.SidebarTab>
          )}
          <Styled.SidebarTab
            onClick={() => handleTabClick(ESidebarTab.ANALYZE)}
            className={activeTab === ESidebarTab.ANALYZE ? 'selected' : ''}
          >
            <Styled.SidebarTabIcon $isActive={activeTab === ESidebarTab.ANALYZE}>
              <AnalyzeIcon />
            </Styled.SidebarTabIcon>
            <Styled.SidebarTabText>Analyze</Styled.SidebarTabText>
          </Styled.SidebarTab>
          <Styled.SidebarTab
            onClick={() => handleTabClick(ESidebarTab.WARNINGS)}
            className={activeTab === ESidebarTab.WARNINGS ? 'selected' : ''}
          >
            <Styled.SidebarTabIcon $isActive={activeTab === ESidebarTab.WARNINGS}>
              <ExclamationCircleFilled />
            </Styled.SidebarTabIcon>
            <Styled.SidebarTabText>Warnings</Styled.SidebarTabText>
          </Styled.SidebarTab>
        </Styled.SidebarTabs>
        {Object.values(tabComponents)}
      </Styled.FullModeContainer>
    </Styled.SidebarContainer>
  );
};

export default SidebarModel;
