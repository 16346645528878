import { COLORS, EFFECTS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { HEADER_HEIGHT } from 'shared/constants/header';
import styled from 'styled-components';

export const HeaderContainer = styled.header`
  position: fixed;
  display: flex;
  flex-direction: column;
  min-height: ${() => HEADER_HEIGHT.LARGE}px;
  width: 100%;
  z-index: 1040;

  @media ${DEVICE.tablet} {
    min-height: ${() => HEADER_HEIGHT.SMALL}px;
  }
`;

export const HeaderContent = styled.div<{ $backgroundColor: string }>`
  height: ${() => HEADER_HEIGHT.LARGE}px;
  width: 100%;
  background-color: var(--header-background-color);
  padding: 0 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  transition: all 0.3s;

  @media ${DEVICE.tablet} {
    padding: 0 10px;
    height: ${() => HEADER_HEIGHT.SMALL}px;
    background-color: ${({ $backgroundColor }): string => $backgroundColor || COLORS.deepBlack};
  }
`;

export const ProjectLogoContainer = styled.div<{ $isAuth: boolean }>`
  min-width: max-content;
`;

export const MenuButton = styled.div`
  margin: 0 30px 0 15px;
  width: 30px;
  height: 30px;
  display: none;
  color: var(--button-background-color);
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);

  @media ${DEVICE.tabletLarge} {
    display: block;
  }

  @media ${DEVICE.tablet} {
    margin: 0 20px 0 10px;
    width: 22px;
    height: 22px;
  }
`;

export const HeaderActions = styled.nav<{ $isAuth: boolean }>`
  display: flex;
  align-items: center;
  gap: 25px;
  margin-left: auto;

  @media ${DEVICE.tabletLarge} {
    gap: 15px;
  }
`;

export const AuthButton = styled.button<{ $filled?: boolean }>`
  height: 32px;
  width: 90px;
  padding: 0 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 3px;
  font-weight: 400;
  font-size: 14px;
  transition: all 0.3s;
  position: relative;
  overflow: hidden;
  background: ${({ $filled }): string => $filled 
    ? `var(--button-background-color)`
    : 'transparent'};
  color: ${({ $filled }): string => ($filled
    ? 'var(--button-text-color)'
    : 'var(--button-background-color)')};
  border: 1px solid var(--button-background-color);
  z-index: 1;

  ${({ $filled }): string => $filled ? EFFECTS.shine : ''}

  &:hover {
    opacity: 0.75;
  }

  @media ${DEVICE.tablet} {
    height: 25px;
    width: 60px;
    padding: 0 12px;
    font-size: 12px;
  }
`;

export const LogoMobileView = styled.img<{ $isAuth: boolean }>`
  display: none;
  max-width: 35px;
  max-height: 24px;
  object-fit: contain;
  position: ${({ $isAuth }): string => (!$isAuth ? 'initial' : 'absolute')};
  left: 50%;
  transform: translateX(${({ $isAuth }): string => (!$isAuth ? '0' : '-50%')});
`;

export const ShareArViewButton = styled.button`
  width: 90px;
  height: 28px;
  background-color: var(--button-background-color);
  border-radius: 3px;
  color: var(--button-text-color);
  font-weight: 400;
  font-size: 10px;
  line-height: 12px;
  transition: all 0.3s;
  opacity: 0.9;

  &:hover {
    opacity: 1;
  }
`;

export const GuideButton = styled.button`
  width: 32px;
  height: 32px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: var(--button-background-color);
  color: var(--button-text-color);
  border-radius: 50%;
  font-weight: 600;
  font-size: 20px;
  line-height: 20px;
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);

  @media ${DEVICE.tablet} {
    width: 24px;
    height: 24px;
    font-size: 16px;
    line-height: 16px;
  }
`;

export const IconWrapper = styled.div`
  width: 34px;
  height: 34px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  filter: drop-shadow(rgba(0, 0, 0, 0.25) 0px 4px 4px);
  fill: var(--button-background-color);
  color: var(--button-text-color);
  margin-right: 5px;

  @media ${DEVICE.tablet} {
    width: 26px;
    height: 26px;
  }
`;

export const NavButtonContainer = styled.button<{ $isAuth: boolean }>`
  display: flex;
  align-items: center;
  font-weight: 400;
  font-size: 14px;
  line-height: 16px;
  color: var(--text-color);
  white-space: nowrap;
  transition: color 0.3s;

  span {
    margin-left: 5px;
  }

  &:hover {
    color: var(--theme-secondary-color);
    
    > ${IconWrapper} {
      transition: opacity 0.3s;
      opacity: 0.75;
    }
  }

  @media ${DEVICE.tablet} {
    font-size: 12px;
  }

  @media ${DEVICE.mobile} {
    display: ${({ $isAuth }): string => (!$isAuth ? 'none' : 'flex')};
    
    span.hide-mobile {
      display: none;
    }
  }
`;
