import React, { useState, useCallback } from 'react';
import * as Styled from './styles';
import { MainScene } from 'shared/webgl/scenes';
import { ModelMetadata } from '..';
import { ModelWithMetadata } from 'shared/types';
import SceneNode from '../scene-node/SceneNode';

const AnalyzeTab: React.FC<{ mainScene: MainScene, model: ModelWithMetadata }> = ({ mainScene, model }) => {
  const [visibleCount, setVisibleCount] = useState(0);
  
  const incrementNodeCount = useCallback(() => {
    setVisibleCount(prev => prev + 1);
  }, []);

  if (!mainScene?.model) return null;

  return (
    <Styled.Container>
      <Styled.ModelDescriptionTitle>Statistics</Styled.ModelDescriptionTitle>
      <ModelMetadata model={model} />
      <Styled.ModelDescriptionTitle>Scene Breakdown</Styled.ModelDescriptionTitle>
      <Styled.AnalyzeContainer>
        <SceneNode
          node={mainScene.model}
          level={0}
          mainScene={mainScene}
          shouldExpand={visibleCount < 5}
          incrementNodeCount={incrementNodeCount}
        />
      </Styled.AnalyzeContainer>
    </Styled.Container>
  );
};

export default AnalyzeTab;