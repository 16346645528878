import React, { useEffect } from 'react';
import * as Styled from './styles';
import Download from 'assets/images/download.svg';
import { deleteCurrentUserCard } from 'services/api/subscriptionsService';
import { openNotification } from 'utils/notification-utils';
import { SubscriptionsState, ESnackbarStyle } from 'shared/types';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { setCurrentUserCards } from 'services/store/reducers/subscriptionsReducer';
import { useTransactionsData } from 'shared/hooks';
import moment from 'moment';
import { CardForm } from 'shared/components';

const BillingTab = (): JSX.Element => {
  const ONE_USD = 100;
  const ONE_SECOND = 1000;
  const dispatch = useAppDispatch();
  const { currentUserCard, currentSubscriptionPlan, subscriptionPlans } = useAppSelector(
    (store): SubscriptionsState => store.subscriptions
  );
  const { transactions, fetchTransactions, hasMore } = useTransactionsData();
  const LAST_4 = !!currentUserCard?.card
    ? currentUserCard?.card.last4
    : currentUserCard?.sepa_debit?.last4;

  const checkIsCurrentFreePlan = (): boolean => {
    const freePlan = subscriptionPlans.find((plan): boolean => plan.prices[0]?.unit_amount === 0);
    return (
      !!freePlan && !!currentSubscriptionPlan && freePlan.id === currentSubscriptionPlan.productId
    );
  };

  useEffect((): void => {
    fetchTransactions();
  }, [fetchTransactions]);

  const handleDeleteClick = async (): Promise<void> => {
    dispatch(startLoader());
    try {
      await deleteCurrentUserCard(currentUserCard!.id);
      dispatch(setCurrentUserCards(null));
      openNotification(ESnackbarStyle.SUCCESS, 'Your card has been successfully removed');
    } catch (e) {
      openNotification(ESnackbarStyle.ERROR, e?.message);
    } finally {
      dispatch(stopLoader());
    }
  };

  const handleDownloadButtonClick = (url: string, filename: string): void => {
    dispatch(startLoader());
    const link = document.createElement('a');
    link.href = url;
    link.download = filename;
    link.click();
    dispatch(stopLoader());
  };

  const handleLoadMoreClick = async (): Promise<void> => {
    await fetchTransactions();
  };

  return (
    <Styled.PageContent>
      <Styled.BillingSettingsContainer>
        {!!currentUserCard && (
          <Styled.PaymentBlock>
            <Styled.FormTitle>Current card</Styled.FormTitle>
            <Styled.CardContainer>
              {!!LAST_4 && (
                <Styled.CurrentCard>
                  <span>**** **** **** {LAST_4}</span>
                  {!!currentUserCard.card && (
                    <span>
                      {`${currentUserCard.card.exp_month} / ${currentUserCard.card.exp_year}`}
                    </span>
                  )}
                </Styled.CurrentCard>
              )}
              {currentUserCard.type === 'paypal' && <Styled.PaymentName>Paypal</Styled.PaymentName>}
            </Styled.CardContainer>
            <Styled.PaymentBlockFooter>
              <Styled.ActionButton
                onClick={handleDeleteClick}
                disabled={!checkIsCurrentFreePlan()}
                id='delete-card-button'
              >
                Delete
              </Styled.ActionButton>
            </Styled.PaymentBlockFooter>
          </Styled.PaymentBlock>
        )}
        <Styled.CardFormContainer>
          <CardForm />
        </Styled.CardFormContainer>
        <Styled.PaymentHistoryContainer>
          <Styled.FormTitle>Payment History</Styled.FormTitle>
          {!!transactions.length ? (
            <Styled.PaymentHistoryList>
              {transactions.map(({ total, number, created, invoice_pdf, id }): JSX.Element => {
                const totalValue = total / ONE_USD;
                const payValue = totalValue < 0 ? `-$${-totalValue}` : `$${totalValue}`;
                return (
                  <Styled.HistoryItem key={id}>
                    <span className='transaction-number'>Transaction number #{number}</span>
                    <span className='transaction-pay'>{payValue}</span>
                    <span className='transaction-date'>
                      {moment(created * ONE_SECOND).format('DD MMM YYYY')}
                    </span>
                    <Styled.DownloadButton
                      onClick={(): void => handleDownloadButtonClick(invoice_pdf, number)}
                    >
                      <img src={Download} alt='Download' />
                    </Styled.DownloadButton>
                  </Styled.HistoryItem>
                );
              })}
            </Styled.PaymentHistoryList>
          ) : (
            <Styled.NoTransactionsText>No transactions</Styled.NoTransactionsText>
          )}

          {hasMore && (
            <Styled.LoadMoreButton onClick={handleLoadMoreClick}>Load more</Styled.LoadMoreButton>
          )}
        </Styled.PaymentHistoryContainer>
      </Styled.BillingSettingsContainer>
    </Styled.PageContent>
  );
};

export default BillingTab;
