import * as THREE from 'three';
import { CreateScreenshotData, CSInitializationData, CSSetupCameraData } from 'shared/interfaces';
import { setupRenderer } from 'utils/scenes-utils';
import {
  CAMERA_FAR,
  SS_CAMERA_FAR,
  SS_CAMERA_FOV,
  SS_CAMERA_NEAR
} from 'shared/constants/scene-settings-constants';
import { CubemapController, MainScene } from 'shared/webgl';
import { ECubemapPresets } from 'shared/enums/ECubemapPresets';

export abstract class BaseSsStrategy {
  protected readonly mainScene: MainScene;

  protected coverImageWidth: number = 512;
  protected coverImageHeight: number = 256;
  protected canvasTDO: HTMLCanvasElement | undefined;
  protected contextTDO: CanvasRenderingContext2D | undefined;

  protected constructor({ mainScene }: CSInitializationData) {
    this.mainScene = mainScene;
  }

  protected setSize(width: number, height: number): void {
    this.coverImageWidth = width;
    this.coverImageHeight = height;
  }

  protected setupCamera(customSettings?: CSSetupCameraData): void {
    const { screenshotCamera, camera } = this.mainScene;

    const { position, rotation } = camera;

    screenshotCamera.near = SS_CAMERA_NEAR;
    screenshotCamera.fov = SS_CAMERA_FOV;
    screenshotCamera.aspect = this.coverImageWidth / this.coverImageHeight;

    if (customSettings) {
      screenshotCamera.far = CAMERA_FAR;
      screenshotCamera.position.copy(customSettings.position);
      screenshotCamera.lookAt(customSettings.target);
    } else {
      screenshotCamera.far = SS_CAMERA_FAR;
      screenshotCamera.position.copy(position);
      screenshotCamera.rotation.copy(rotation);
    }

    screenshotCamera.updateProjectionMatrix();
  }

  protected setupRenderer(): void {
    const { screenshotRenderer, renderer } = this.mainScene;

    setupRenderer(screenshotRenderer, this.coverImageWidth, this.coverImageHeight);

    screenshotRenderer.outputColorSpace = renderer.outputColorSpace;
    screenshotRenderer.toneMapping = renderer.toneMapping;
    screenshotRenderer.toneMappingExposure = renderer.toneMappingExposure;
  }

  protected setupCanvasTDO(width?: number, height?: number): void {
    this.canvasTDO = document.createElement('canvas');

    this.canvasTDO.width = width || this.coverImageWidth;
    this.canvasTDO.height = height || this.coverImageHeight;

    this.contextTDO = this.canvasTDO.getContext('2d')!;
  }

  private handleReplaceCubemap(
    cubemapController: CubemapController,
    pmremGenerator: THREE.PMREMGenerator
  ): void {
    if (cubemapController._currentCubemapPresetKey === ECubemapPresets.Neutral) {
      cubemapController.setNeutralCubeMap(this.mainScene.scene, pmremGenerator);
    }
  }

  private restoreCubemap(cubemapController: CubemapController): void {
    const { pmremGenerator } = this.mainScene;

    this.handleReplaceCubemap(cubemapController, pmremGenerator);
  }

  protected cheatCubemap(cubemapController?: CubemapController): () => void {
    if (!cubemapController) return (): void => undefined;

    const { screenshotPmremGenerator } = this.mainScene;

    this.handleReplaceCubemap(cubemapController, screenshotPmremGenerator);

    return this.restoreCubemap.bind(this, cubemapController);
  }

  public abstract createScreenshot(createScreenshotData: CreateScreenshotData): Promise<string>;
}
