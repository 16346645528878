import { EPremiumFeature, EQuotaName } from 'shared/types';
import ArPreview from 'assets/gifs/ar-feature-preview.gif';
import CommentsPreview from 'assets/gifs/comments-feature-preview.gif';
import SceneSettingsPreview from 'assets/gifs/scene-settings-feature-preview.gif';

type Features = {
  [key in EPremiumFeature | EQuotaName ]: {
    title: string;
    hook: string;
    description: string[];
    preview?: string;
    lockedMessage?: string;
  };
};

export const FEATURES: Features = {
  [EPremiumFeature.AR]: {
    title: 'Upgrade to view in AR!',
    hook: 'You can view 3D models in your room!',
    description: [
      'Upgrade to',
      'experience',
      'your models in your',
      'real-world environment',
      'with Augmented Reality - no app needed.'
    ],
    preview: ArPreview,
    lockedMessage: 'Augmented Reality Viewing on this model is disabled. Contact the model owner to enable AR View.'
  },
  [EPremiumFeature.COMMENTS]: {
    title: 'Upgrade to comment!',
    hook: 'Hoping to get feedback from others?',
    description: [
      'Upgrade to',
      'collaborate',
      'in real-time by',
      'pinning comments',
      'onto your 3D model.'
    ],
    preview: CommentsPreview,
    lockedMessage: 'Commenting on this model is currently disabled. Contact the model owner to enable Comments.'
  },
  [EPremiumFeature.SCENE_SETTINGS]: {
    title: 'Upgrade for more settings!',
    hook: 'Need more display options?',
    description: [
      'Upgrade to',
      'enhance the presentation',
      'of your 3D models with additional lighting, cubemap, and environment options.',
    ],
    preview: SceneSettingsPreview
  },
  [EPremiumFeature.BRANDING]: {
    title: 'Upgrade to customize branding!',
    hook: 'Want to make your brand stand out?',
    description: [
      'Upgrade to unlock',
      'custom colors and logos',
      'that truly',
      'reflect your identity.'
    ],
  },
  [EPremiumFeature.EMBED_OPTIONS]: {
    title: 'Upgrade to customize your embedded viewer!',
    hook: 'Want your own buttons? Want to restrict access?',
    description: [
      'Upgrading allows you to',
      'add custom buttons',
      'and',
      'limit who can access',
      'your models.'
    ],
  },
  [EQuotaName.ACTIVE_MODELS_LIMIT]: {
    title: 'Upgrade to upload more models!',
    hook: 'Looks like you need more storage!',
    description: [
      'You reached your plan limit.',
      'Please upgrade below',
      'or delete models to free up space.',
    ],
  },
  [EQuotaName.MODEL_SIZE_LIMIT]: {
    title: 'Upgrade to upload larger files!',
    hook: 'Looks like you need more storage!',
    description: [
      'Your 3D model is too large.',
      'Please upgrade below',
      'or reduce the size of the file before uploading.',
    ],
  },
  [EQuotaName.MODEL_VIEWS_LIMIT]: {
    title: 'Upgrade to allow for more views!',
    hook: 'Looks like your model is popular!',
    description: [
      'You reached your monthly view limit.',
      'Please upgrade below',
      'or wait until your view limit is reset.',
    ],
  },
  [EQuotaName.MONTHLY_AR_VIEWS_LIMIT]: {
    title: 'Upgrade to allow for more AR views!',
    hook: 'Looks like your model is popular!',
    description: [
      'You reached your monthly AR view limit.',
      'Please upgrade below',
      'or wait until your AR view limit is reset.',
    ],
  },
  [EQuotaName.IFRAME_CUSTOM_BUTTONS]: {
    title: '',
    hook: '',
    description: []
  }
};
