import { useCallback, useEffect, useRef, useState } from 'react';
import { ESnackbarStyle, Model } from 'shared/types';
import { useAppDispatch } from 'shared/hooks';
import { getSharedWithMeModels } from 'services/api/modelsService';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { openNotification } from 'utils/notification-utils';
import { getModel } from 'services/api/modelService';

type FetchParams = { page: number; limit?: number; search?: string };

type Result = {
  modelsData: Model[];
  fetchModel: (modelId: string) => Promise<void>;
  isSearchMode: boolean;
  setSearch: (value: string) => void;
  setIsSearchMode: (value: boolean) => void;
  resetModelList: () => Promise<void>;
  updateModelList: () => Promise<Model[]>;
  refreshModelList: () => Promise<void>;
};

const useSharedWithMeData = (): Result => {
  const LIMIT = 12;
  const dispatch = useAppDispatch();
  const [modelsData, setModelsData] = useState<Model[]>([]);
  const [currentPage, setCurrentPage] = useState<number>(1);
  const [search, setSearch] = useState<string>('');
  const [isSearchMode, setIsSearchMode] = useState<boolean>(false);
  const isFirstPageVisit = useRef<boolean>(true);

  const fetchModels = useCallback(
    async ({ page, limit, search }: FetchParams): Promise<Model[] | undefined> => {
      try {
        return (await getSharedWithMeModels(page, limit || LIMIT, search)).data.items;
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      }
    },
    [dispatch]
  );

  const handleSearchChange = useCallback(async (): Promise<void> => {
    const models = await fetchModels({ page: 1, search });
    if (!!models?.length) {
      setModelsData(models);
      setCurrentPage(1);
      setIsSearchMode(!!search);
    } else {
      setModelsData([]);
    }
  }, [fetchModels, search]);

  useEffect((): void => {
    if (!isFirstPageVisit.current) {
      handleSearchChange();
    }
    isFirstPageVisit.current = false;
  }, [handleSearchChange]);

  const updateModelList = useCallback(async (): Promise<Model[]> => {
    const models = await fetchModels({ page: currentPage + 1, search });
    if (!!models?.length) {
      setModelsData((prev): Model[] => [...prev, ...models]);
      setCurrentPage(currentPage + 1);
      return models;
    }
    return [];
  }, [currentPage, search, fetchModels]);

  const refreshModelList = useCallback(async (): Promise<void> => {
    const models = await fetchModels({ page: 1, search, limit: modelsData.length });
    if (!!models) {
      setModelsData(models);
    }
  }, [modelsData, search, fetchModels]);

  const resetModelList = useCallback(async (): Promise<void> => {
    setModelsData([]);
    setCurrentPage(1);
    const models = await fetchModels({ page: 1, search });
    if (!!models?.length) {
      setModelsData(models);
    }
  }, [fetchModels, search]);

  const fetchModel = useCallback(
    async (modelId: string): Promise<void> => {
      dispatch(startLoader());
      try {
        const model = (await getModel(modelId)).data;
        setModelsData([model]);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    },
    [dispatch]
  );

  return {
    modelsData,
    fetchModel,
    isSearchMode,
    setSearch,
    setIsSearchMode,
    resetModelList,
    updateModelList,
    refreshModelList
  };
};

export default useSharedWithMeData;
