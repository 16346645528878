import {
  Cubemap,
  Environment,
  EQuotaName,
  ESharingStatus,
  EModelType,
  Lighting,
  Model,
  ModelSettings,
  ModelTexture,
  UpdateModelRequest,
  ModelWithMetadata
} from 'shared/types';
import { EEnvironmentPresets } from 'shared/enums/EEnvironmentPresets';
import { ECubemapPresets } from 'shared/enums/ECubemapPresets';
import {
  ANONYMOUS_MODEL_ID,
  CUBEMAP_OPTIONS,
  ENVIRONMENT_OPTIONS,
  INITIAL_CAMERA,
  INITIAL_MODEL_SETTINGS,
  LIGHTING_OPTIONS,
  QUICK_VIEW_MODEL_ID,
  SAMPLE_MODEL_ID
} from 'shared/constants/model-settings';
import { capitalize } from './form-utils';
import { MEGABYTE } from 'shared/constants/limits';
import moment from 'moment';
import { store } from 'services/store';
import { getModelPlanLimit } from 'services/api/subscriptionsService';
import { showModal } from 'services/store/reducers/modalReducer';
import { ModalFeatureSignedIn } from 'shared/components';
import { getModelCustomCubemaps, getModelCustomEnvironments } from 'services/api/modelService';
import { E3DModelFileTypes } from 'shared/enums/E3DModelFileTypes';
import { EEnvironmentTypes } from 'shared/enums/EEnvironmentTypes';

type ModelMetadataResult = {
  geometry: { title: string; value: string }[];
  textures: { title: string; value: number; texture: ModelTexture }[];
  details: { title: string; value: number }[];
  litValues: { isLit: boolean; isUnlit: boolean };
};

export const getModelType = (model: Model): EModelType => {
  const isModelOwner: boolean = store.getState().auth.user?.id === model.ownerId;
  const { teams } = store.getState().teams;
  const hasModelTeam: boolean = !!teams.find((team): boolean => team.id === model.teamId);

  switch (true) {
    case hasModelTeam:
      return EModelType.TEAM;
    case isModelOwner:
      return EModelType.OWNER;
    case model.ownerId === ANONYMOUS_MODEL_ID:
      return EModelType.ANONYMOUS;
    case model.id === SAMPLE_MODEL_ID:
      return EModelType.SAMPLE;
    case model.id === QUICK_VIEW_MODEL_ID:
      return EModelType.QUICK_VIEW;
    default:
      return EModelType.SHARED;
  }
};

export const convertUrlToNotFromCacheUrl = (url: string): string =>
  `${url}?${Date.now()}-not-from-cache`;

export const getModelSettings = async (
  { presets, id }: Model,
  signal: AbortSignal,
  isTransparentBackground?: boolean
): Promise<ModelSettings> => {
  const { lighting, environment, cubemap, animation, camera } = presets;
  const isZeroCameraPosition =
    !camera || (camera.position.x === 0 && camera.position.y === 0 && camera.position.z === 0);

  const isDefaultEnv = Object.values(EEnvironmentPresets).includes(+environment);
  const isDefaultCubemap = Object.values(ECubemapPresets).includes(+cubemap);

  const getDefaultCubemapValue = (): Cubemap => {
    const key = cubemap.length ? +cubemap : INITIAL_MODEL_SETTINGS.cubemap.key;

    return (
      CUBEMAP_OPTIONS.find((item): boolean => item.value.key === key)?.value ||
      INITIAL_MODEL_SETTINGS.cubemap
    );
  };

  const getCustomCubemapValue = async (): Promise<Cubemap> => {
    if (!cubemap) return INITIAL_MODEL_SETTINGS.cubemap;

    const cubemaps = (await getModelCustomCubemaps(id, signal)).data;
    const activeCubemap = cubemaps.find((c): boolean => c.cubemapName === cubemap);

    if (!activeCubemap) return INITIAL_MODEL_SETTINGS.cubemap;

    const { cubemapName, nyPlaneUrl, nxPlaneUrl, pxPlaneUrl, nzPlaneUrl, pzPlaneUrl, pyPlaneUrl } =
      activeCubemap;

    return {
      key: cubemapName,
      cubemapName,
      imagePaths: {
        nyPlaneUrl: convertUrlToNotFromCacheUrl(nyPlaneUrl),
        nxPlaneUrl: convertUrlToNotFromCacheUrl(nxPlaneUrl),
        pxPlaneUrl: convertUrlToNotFromCacheUrl(pxPlaneUrl),
        nzPlaneUrl: convertUrlToNotFromCacheUrl(nzPlaneUrl),
        pzPlaneUrl: convertUrlToNotFromCacheUrl(pzPlaneUrl),
        pyPlaneUrl: convertUrlToNotFromCacheUrl(pyPlaneUrl)
      }
    };
  };

  const getDefaultEnvironmentValue = (): Environment => {
    const key = environment.length ? +environment : INITIAL_MODEL_SETTINGS.environment.key;

    return (
      ENVIRONMENT_OPTIONS.find((item): boolean => item.value.key === key)?.value ||
      INITIAL_MODEL_SETTINGS.environment
    );
  };

  const getCustomEnvValue = async (): Promise<Environment> => {
    if (!environment) return INITIAL_MODEL_SETTINGS.environment;
    const environments = (await getModelCustomEnvironments(id, signal)).data;
    const activeEnv = environments.find((e): boolean => e.environmentName === environment);
    if (!activeEnv) return INITIAL_MODEL_SETTINGS.environment;
    const fileType = activeEnv.publicUrl
      .split('/')
      .reverse()[0]
      .split('.')[1] as keyof typeof E3DModelFileTypes;
    return {
      key: activeEnv.environmentName,
      envPreset: activeEnv.environmentName,
      customEnvData: {
        type: EEnvironmentTypes.file,
        data: {
          path: activeEnv.publicUrl,
          fileType: E3DModelFileTypes[fileType],
          scale: activeEnv.presets.scale
        }
      }
    };
  };

  const getDefaultLightingValue = (): Lighting => {
    const key = lighting.length ? +lighting : INITIAL_MODEL_SETTINGS.lighting.key;

    return (
      LIGHTING_OPTIONS.find((item): boolean => item.value.key === key)?.value ||
      INITIAL_MODEL_SETTINGS.lighting
    );
  };

  const envValue: Environment = isTransparentBackground
    ? { key: EEnvironmentPresets.NoEnvironment, envPreset: EEnvironmentPresets.NoEnvironment }
    : isDefaultEnv
    ? getDefaultEnvironmentValue()
    : await getCustomEnvValue();

  const cubemapValue: Cubemap = isDefaultCubemap
    ? getDefaultCubemapValue()
    : await getCustomCubemapValue();

  return {
    ...INITIAL_MODEL_SETTINGS, // INFO: prevent bugs if the old model does not have some properties
    ...presets,
    camera: !isZeroCameraPosition ? camera : INITIAL_MODEL_SETTINGS.camera,
    lighting: getDefaultLightingValue(),
    environment: envValue,
    cubemap: cubemapValue,
    animation
  };
};

export const getChangedModel = (
  modelName: string,
  modelSettings: ModelSettings,
  brandingId: number | null
): UpdateModelRequest => {
  const { lighting, environment, cubemap } = modelSettings;
  return {
    modelName,
    presets: {
      ...modelSettings,
      lighting: lighting.lightPreset.toString(),
      environment: environment.envPreset.toString(),
      cubemap: cubemap.key.toString()
    },
    brandingId
  };
};

export const convertToEngineeringFormat = (value: number): string => {
  const maxNumberFor4Char = 9999;
  return value <= maxNumberFor4Char
    ? value.toLocaleString()
    : Intl.NumberFormat('en', { notation: 'compact', maximumFractionDigits: 1 })
        .format(value)
        .toLowerCase();
};

export const getModelMetadata = (model: ModelWithMetadata): ModelMetadataResult => {
  const { textures: modelTextures, materials, animations } = model.metadata.resources;

  const geometry = Object.entries(model.metadata.geometry).map(
    (item): { title: string; value: string } => ({
      title: capitalize(item[0]),
      value: convertToEngineeringFormat(item[1])
    })
  );
  const textures = Object.entries(model.metadata.textures)
    .filter((i): boolean => i[0] !== 'totalCount')
    .map((item): { title: string; value: number; texture: ModelTexture } => ({
      title: item[0] === '128' ? '0 to 128' : item[0],
      value: (item[1] as ModelTexture).totalCount,
      texture: item[1] as ModelTexture
    }));

  const details = [
    { title: 'Textures', value: modelTextures },
    { title: 'Materials', value: materials },
    { title: 'Animations', value: animations.totalCount }
  ];

  const litValues = {
    isLit: model.metadata.unlitFlag === 1 || model.metadata.unlitFlag === 3,
    isUnlit: model.metadata.unlitFlag === 2 || model.metadata.unlitFlag === 3
  };

  return { geometry, textures, details, litValues };
};

export const convertBytesToMegabytes = (size: number, value: number = 2): string =>
  (size / MEGABYTE).toFixed(value);

export const createNewModelObject = (
  modelName: string,
  modelSize: number,
  publicUrl: string,
  modelType: EModelType
): ModelWithMetadata => {
  const geometry = { triangles: 0, vertices: 0, unlit: false, texturesDetail: [] };
  const materials = { animations: 0, materials: 0, textures: 0 };
  const { environment, cubemap, lighting } = INITIAL_MODEL_SETTINGS;
  const presets = {
    ...INITIAL_MODEL_SETTINGS,
    lighting: lighting.toString(),
    environment: environment.toString(),
    cubemap: cubemap.toString(),
    camera: INITIAL_CAMERA
  };
  const owner = { id: '', email: '', firstName: '', lastName: '' };
  const texture = {
    totalCount: 0,
    png: { count: 0, maxSize: 0, minSize: 0 },
    jpeg: { count: 0, maxSize: 0, minSize: 0 }
  };
  const metadata = {
    modelId: '',
    fileSize: { totalSize: 0, gltfSize: 0, binSize: 0 },
    format: '',
    unlitFlag: 0,
    geometry: { vertices: 0, triangles: 0, quads: 0, polygons: 0 },
    resources: {
      animations: { animations: [], totalCount: 0 },
      meshes: 0,
      textures: 0,
      materials: 0,
      scenes: 0
    },
    textures: {
      128: texture,
      512: texture,
      1024: texture,
      2048: texture,
      4096: texture,
      '4096+': texture,
      NPOT: texture,
      totalCount: 0
    }
  };

  return {
    content: [],
    id: modelType === EModelType.SAMPLE ? '-1' : modelType === EModelType.QUICK_VIEW ? '-2' : '',
    owner,
    ownerId: modelType === EModelType.ANONYMOUS ? -1 : 0,
    ownerName: '',
    modelSize,
    modelName,
    geometry,
    materials,
    presets,
    metadata,
    publicUrl,
    coverImageId: null,
    coverImageUrl: null,
    createdAt: moment(Date.now()).toISOString(),
    updatedAt: moment(Date.now()).toISOString(),
    accessLevel: [],
    status: ESharingStatus.DRAFT,
    shortCode: '',
    shortLinkUrl: '',
    shareARViews: false,
    teamId: null,
    brandingId: null
  };
};

export const checkQuota = async (modelId: string, quota: EQuotaName): Promise<void> => {
  const dispatch = store.dispatch;
  const { success, limit } = (await getModelPlanLimit(modelId, quota)).data;
  const isSuperAdmin = limit === -1;
  const isSuccess = isSuperAdmin || success;
  if (!isSuccess) {
    dispatch(showModal(<ModalFeatureSignedIn feature={quota} />));
  }
};
