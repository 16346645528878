import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { DEVICE } from 'shared/constants/deviceSizes';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';

export const ModalWindowContainer = styled.div`
  width: 30vw;
  min-width: 500px;
  max-width: 700px;
  display: flex;
  flex-direction: column;
  color: ${COLORS.mischka};

  @media ${DEVICE.mobile} {
    min-width: 300px;
  }
`;

export const Message = styled.span`
  font-weight: 400;
  font-size: 16px;
  line-height: 16px;
  margin-bottom: 20px;
`;

export const Confirmation = styled.span`
  margin-top: 30px;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  text-align: center;
`;

export const ConfirmationInput = styled.input`
  width: 100%;
  height: 30px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundColor};
  border: 1px solid ${COLORS.white};
  border-radius: 4px;
  padding: 0 10px;
  color: ${COLORS.white};
  font-size: 16px;
  text-align: left;

  &:focus {
    outline: none;
    border: 1px solid var(--theme-secondary-color);
  }
`;

export const ActionButtons = styled.div`
  margin-top: 20px;
  width: 100%;
  display: flex;
  justify-content: flex-end;
  gap: 15px;
`;

export const ActionButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100px;
  padding: 8px 0;
  border-radius: 3px;
  font-weight: 600;
  font-size: 12px;
  line-height: 12px;
  transition: all 0.2s;
  opacity: 1;

  &.delete-button {
    background: #484848;
    color: ${COLORS.white};

    &:hover {
      color: ${COLORS.black};
      background: ${COLORS.red};
    }
  }

  &.cancel-button {
    background: var(--button-background-color);
    color: var(--button-text-color);

    &:hover {
      opacity: 0.75;
    }
  }
`;

