import React, { useMemo, useState, useRef, useEffect } from 'react';
import * as Styled from './styles';
import { SubscriptionPlan, SubscriptionFeatures, ModalLogin, DualSwitcher } from 'shared/components';
import { SubscriptionsState, EPaymentType, TeamsState } from 'shared/types';
import { useAppSelector } from 'shared/hooks';
import { extractPlanData } from 'utils/subscription-plans-utils';
import { PLAN_CARD } from '../constants';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { closeModal, showModal } from 'services/store/reducers/modalReducer';

type Props = {
  isModal?: boolean;
  title?: string;
  paymentType?: EPaymentType;
  seats?: number;
  hideCustomPlan?: boolean;
};

const SubscriptionInfo: React.FC<Props> = ({ 
  isModal, 
  title,
  paymentType,
  hideCustomPlan = false,
  seats = 1
}): JSX.Element => {
  const { subscriptionPlans, currentSubscriptionPlan } = useAppSelector((store): SubscriptionsState => store.subscriptions);
  const { teamPlan } = useAppSelector((store): TeamsState => store.teams);
  const isAuth = useAppSelector((store): boolean => store.auth.isAuth);

  const history = useHistory();
  const dispatch = useDispatch();
  const featuresRef = useRef<HTMLDivElement>(null);
  const subscriptionsContainerRef = useRef<HTMLDivElement>(null);

  const [isMultiRow, setIsMultiRow] = useState(false);
  const isTeamPlan = paymentType === EPaymentType.CREATE_TEAM_PLAN || paymentType === EPaymentType.UPDATE_TEAM_PLAN;
  const [isMonthlyType, setIsMonthlyType] = useState<boolean>(() => {
    const activePlan = isTeamPlan ? teamPlan : currentSubscriptionPlan;
    return !activePlan || activePlan.recurringInterval === 'month';
  });

  const filteredPlans = useMemo(() => {
    return paymentType === EPaymentType.CREATE_TEAM_PLAN || paymentType === EPaymentType.UPDATE_TEAM_PLAN
      ? subscriptionPlans.slice(-2)
      : subscriptionPlans; 
  }, [subscriptionPlans, paymentType]);

  const extractedPlansData = useMemo(
    () => filteredPlans.map((plan) => extractPlanData(plan, seats)),
    [filteredPlans, seats]
  );

  const customPlanData = useMemo((): ReturnType<typeof extractPlanData> => {
    return extractPlanData(undefined, seats);
  }, [seats]);

  useEffect(() => {
    const container = subscriptionsContainerRef.current;
    if (!container) return;

    let frameId: number;
    const checkMultiRow = () => {
      cancelAnimationFrame(frameId);
      frameId = requestAnimationFrame(() => {
        const threshold = 3 * PLAN_CARD.scaleValue(PLAN_CARD.width, true);
        setIsMultiRow(container.clientWidth <= threshold);
      });
    };

    const resizeObserver = new ResizeObserver(checkMultiRow);
    resizeObserver.observe(container);
    checkMultiRow();

    return () => {
      resizeObserver.disconnect();
      cancelAnimationFrame(frameId);
    };
  }, []);

  const handleEnterprisePlanClick = (): void => {
    window.open('/contact-us', '_blank');
  };

  const handleTeamPlanClick = (): void => {
    if (isAuth) {
      if (isModal) {
        dispatch(closeModal());
      }
      history.push('/create-team');
    } else {
      dispatch(showModal(<ModalLogin />));
    }
  };

  const showEnterprisePlanLink = useMemo(() => hideCustomPlan && !isMultiRow, [hideCustomPlan, isMultiRow]);
  const totalPlans = useMemo(() => 
    showEnterprisePlanLink ? filteredPlans.length : filteredPlans.length + 1,
    [showEnterprisePlanLink, filteredPlans.length]
  );

  return (
    <Styled.SubscriptionsContainer
      $numPlans={totalPlans}
      $isModal={isModal}
      ref={subscriptionsContainerRef}>
      <Styled.HeaderContainer>
        {title && <Styled.Title>{title}</Styled.Title>}
        <Styled.BillingTypeSwitcher>
          <DualSwitcher
            leftText="Monthly"
            rightText="Yearly"
            isLeftActive={isMonthlyType}
            onChange={(isLeftActive): void => setIsMonthlyType(isLeftActive)}
          />
        </Styled.BillingTypeSwitcher>
      </Styled.HeaderContainer>
      <Styled.PlansContainer 
        $numPlans={totalPlans} 
        $isModal={isModal}
      >
        {filteredPlans.map(
          (plan, index): JSX.Element => (
            <SubscriptionPlan 
              key={plan.id}
              plan={plan}
              extractedPlanData={extractedPlansData[index]}
              isMonthlyType={isMonthlyType} 
              isModal={isModal}
              featuresRef={featuresRef}
              paymentType={paymentType}
              seats={seats}
            />
          )
        )}
        {!showEnterprisePlanLink && (
          <SubscriptionPlan
            key={customPlanData.id}
            extractedPlanData={customPlanData}
            isMonthlyType={isMonthlyType} 
            isModal={isModal}
            featuresRef={featuresRef}
            paymentType={paymentType}
            seats={seats}
          />
        )}
      </Styled.PlansContainer>
      <Styled.LinksContainer $showEnterprisePlanLink={showEnterprisePlanLink}>
        {!isTeamPlan && (
          <Styled.TeamPlanLink>
            <span>Need a shared team workspace?</span>
            <span onClick={handleTeamPlanClick}>Click here!</span>
          </Styled.TeamPlanLink>
        )}
        {showEnterprisePlanLink && (
          <Styled.EnterprisePlanLink>
            <span>Need an enterprise plan?</span>
            <span onClick={handleEnterprisePlanClick}>Click here!</span>
          </Styled.EnterprisePlanLink>
        )}
      </Styled.LinksContainer>
      {!isModal && (
        <div ref={featuresRef}>
          <SubscriptionFeatures 
            isMonthlyType={isMonthlyType}
            extractedPlansData={[...extractedPlansData, customPlanData]}
            seats={seats}
          />
        </div>
      )}
    </Styled.SubscriptionsContainer>
  );
};

export default SubscriptionInfo;
