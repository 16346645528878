import React, { useEffect, useState } from 'react';
import * as Styled from './styles';
import Spinner from 'assets/images/spinner.svg';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { RootState } from 'services/store';
import { setInitialLoadingData } from 'services/store/reducers/loaderReducer';
import Progress from 'antd/lib/progress/progress';
import { COLORS } from 'shared/constants/colors';
import Cube from 'assets/images/logo-cube.png';

const PageLoader: React.FC = (): JSX.Element => {
  const dispatch = useAppDispatch();
  const store = useAppSelector((store): RootState => store);
  const { isAuth } = store.auth;
  const { isPageWithScene } = store.sidebar;
  const { isModalOpened, isConfirmModalOpened } = store.modal;
  const { isZenMode, isEmbeddedModelMode } = store.viewerData;
  const { isViewerLoading, loadingState, currentLoadingPercent } = store.loader;
  const [showProgress, setShowProgress] = useState(false);

  const DISPLAY_DELAY = 2000;

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowProgress(true);
    }, DISPLAY_DELAY);
    return () => clearTimeout(timer);
  }, []);

  useEffect(
    (): (() => void) => (): void => {
      dispatch(setInitialLoadingData());
    },
    [dispatch]
  );

  return (
    <Styled.PageLoaderContainer
      $showProgress={showProgress}
      $isAuth={isAuth}
      $isPageWithScene={isPageWithScene}
      $isModalActive={isModalOpened || isConfirmModalOpened}
      $isZenMode={isZenMode}
      $isEmbeddedModelMode={isEmbeddedModelMode}
    >
      {isViewerLoading && showProgress ? (
        <Styled.ProgressBarContainer>
          <Styled.LoadingIcon $percent={currentLoadingPercent} src={Cube} />
          <Styled.ProgressWrapper>
            <Progress
              percent={currentLoadingPercent}
              status='active'
              strokeColor={{ from: COLORS.doveGray, to: COLORS.turbo }}
              showInfo={false}
              trailColor={COLORS.mischka}
              size={'small'}
            />
          </Styled.ProgressWrapper>
          <Styled.LoadingPercent>{currentLoadingPercent}%</Styled.LoadingPercent>
          <Styled.LoadingState>{loadingState}</Styled.LoadingState>
        </Styled.ProgressBarContainer>
      ) : showProgress ? (
        <Styled.Loader className='spinner' src={Spinner} alt='Loading' />
      ) : null}
    </Styled.PageLoaderContainer>
  );
};

export default PageLoader;
