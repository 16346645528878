import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import * as Styled from './styles';
import {
  AuthState,
  ESnackbarStyle,
  PlanPrice,
  SubscriptionProduct,
  TeamsState
} from 'shared/types';
import { extractPlanData } from 'utils/subscription-plans-utils';
import { useAppDispatch, useAppSelector } from 'shared/hooks';
import { openNotification } from 'utils/notification-utils';
import { closeConfirmModal } from 'services/store/reducers/modalReducer';
import { withElements } from 'shared/hocs/withElements';
import { useElements, useStripe } from '@stripe/react-stripe-js';
import {
  checkPromoCode,
  fetchTeamPlanPrice,
  getCurrentTeamSubscription,
  payTeamSubscription
} from 'services/api/subscriptionsService';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { TeamCardForm } from 'shared/components';
import { SUBSCRIPTION_UPDATED } from 'shared/constants/notifications';
import { setActiveTeamPlan } from 'services/store/reducers/teamsReducer';
import { throttle } from 'utils/delay-utils';
import { checkIsStripeTestCardError } from 'utils/error-utils';
import { CheckCircleFilled, ExclamationCircleFilled } from '@ant-design/icons';

type Props = {
  plan: SubscriptionProduct;
  isMonthlyType: boolean;
};

const ModalTeamCheckout: React.FC<Props> = ({ plan, isMonthlyType }): JSX.Element => {
  const dispatch = useAppDispatch();
  const stripe = useStripe();
  const elements = useElements();
  const { isAuth } = useAppSelector((store): AuthState => store.auth);
  const { teamPlan, teamCard } = useAppSelector((store): TeamsState => store.teams);
  const { activeTeam } = useAppSelector((store): TeamsState => store.teams);
  const [isMonth, setIsMonth] = useState(isMonthlyType);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isCardFormActive, setIsCardFormActive] = useState<boolean>(false);
  const [promoCodeInputValue, setPromoCodeInputValue] = useState<string>('');
  const promoCodeValue = useRef<string>('');
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const [tax, setTax] = useState<number>(0);
  const isValidPromoCode = useRef<boolean>(false);
  const ONE_USD = 100;
  const LAST_4 = !!teamCard?.card ? teamCard?.card.last4 : teamCard?.sepa_debit?.last4;
  const isCurrentPlanInterval = teamPlan?.recurringInterval === (isMonth ? 'month' : 'year');
  const isCurrentPlan = plan?.id === teamPlan?.productId && isCurrentPlanInterval;
  const [showValidation, setShowValidation] = useState<boolean>(false);
  const [promoDiscount, setPromoDiscount] = useState<number>(0);
  const [validPromoName, setValidPromoName] = useState<string>('');

  const priceObj = useMemo(
    (): PlanPrice | undefined =>
      plan.prices.find((i): boolean => i.recurring.interval === (isMonth ? 'month' : 'year')),
    [isMonth, plan]
  );

  const { title, subtitle, monthPrice, yearPrice } = extractPlanData(plan, activeTeam?.totalSeats || 5);
  const planPrice = (isMonth ? monthPrice : yearPrice).actual;

  const calculatePlanPrice = useCallback(async (): Promise<void> => {
    setIsLoading(true);
    dispatch(startLoader());
    const promo = promoCodeValue.current;

    if (!!priceObj && !!teamCard) {
      try {
        if (!!promo) {
          const { valid } = (await checkPromoCode(promo, plan.id)).data;
          isValidPromoCode.current = valid;
          setShowValidation(true);
          if (valid) {
            setValidPromoName(promo);
            openNotification(ESnackbarStyle.SUCCESS, 'Congratulations! Your promo code has been successfully applied!');
          } else {
            setPromoDiscount(0);
            setValidPromoName('');
            openNotification(ESnackbarStyle.ERROR, 'This promo code is not valid! Please double check the code and try again.');
          }
        } else {
          setPromoDiscount(0);
          setValidPromoName('');
        }

        const price = priceObj.id;
        const qty = activeTeam!.totalSeats;
        const card = teamCard.id;
        const payload =
          isValidPromoCode.current && !!promo
            ? { price, qty, card, promo }
            : { price, card, qty };
        const { amount_due, tax } = (await fetchTeamPlanPrice(activeTeam!.id, payload)).data;
        setTotalPrice(amount_due / ONE_USD);
        setTax(tax / ONE_USD);
        const calculatedDiscount = planPrice * (activeTeam?.totalSeats || 5) - (amount_due / ONE_USD);
        setPromoDiscount(calculatedDiscount > 0 ? calculatedDiscount : 0);
      } catch (e) {
        setPromoDiscount(0);
        setValidPromoName('');
        setShowValidation(true);
        openNotification(ESnackbarStyle.ERROR, e?.message);
      }
    }
    dispatch(stopLoader());
    setIsLoading(false);
  }, [activeTeam, dispatch, priceObj, teamCard, planPrice]);

  useEffect((): void => {
    calculatePlanPrice();
  }, [calculatePlanPrice]);

  const handleApplyClick = (event: React.MouseEvent<HTMLButtonElement>): void => {
    event.preventDefault();
    calculatePlanPrice();
  };

  const handlePromoCodeChange = (event: React.ChangeEvent<HTMLInputElement>): void => {
    const value = event.target.value.trim();
    setShowValidation(false);
    isValidPromoCode.current = false;
    setPromoCodeInputValue(value);
    promoCodeValue.current = value;
  };

  const handleEditCardClick = (): void => {
    setIsCardFormActive((prevState): boolean => !prevState);
  };

  const getCardBrand = (): string => {
    if (!!teamCard?.card) {
      const { brand } = teamCard.card;
      return brand[0].toUpperCase() + brand.slice(1);
    } else {
      return 'Card';
    }
  };

  const handlePayButtonClick = async (): Promise<void> => {
    if (!!teamCard && !!stripe && !!elements && !!priceObj) {
      setIsLoading(true);
      dispatch(startLoader());
      try {
        const promo = promoCodeValue.current;
        const card = teamCard.id;
        const price = priceObj.id;
        const qty = activeTeam!.totalSeats;
        const payload =
          isValidPromoCode.current && !!promo
            ? { card, price, qty, promo }
            : { card, price, qty };
        const { payment_intent } = (await payTeamSubscription(activeTeam!.id, payload)).data;
        const isFail = payment_intent?.status === 'requires_payment_method';
        const isRequiresAction =
          payment_intent?.status === 'requires_action' ||
          payment_intent?.status === 'requires_confirmation';
        if (isFail) {
          throw new Error('Requires payment method');
        }
        if (!!payment_intent?.client_secret && isRequiresAction) {
          const { error } = await stripe.confirmCardPayment(payment_intent.client_secret, {
            payment_method: card
          });
          if (!!error) throw new Error(checkIsStripeTestCardError(error));
        }

        await throttle(5000);
        const currentPlan = (await getCurrentTeamSubscription(activeTeam!.id)).data;
        dispatch(setActiveTeamPlan(currentPlan));
        dispatch(closeConfirmModal());
        openNotification(ESnackbarStyle.SUCCESS, SUBSCRIPTION_UPDATED);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
        setIsLoading(false);
      }
    }
  };

  return (
    <Styled.ModalWindowContainer>
      <Styled.LeftSide>
        <Styled.BillingFrequencyBlock>
          <Styled.FieldLabel>Billing Type</Styled.FieldLabel>
          <Styled.SwitcherContainer>
            <Styled.SwitcherWheel $isMonth={isMonth} />
            <Styled.BillingFrequencyField
              onClick={(): void => setIsMonth(true)}
              $isActive={isMonth}
            >
              Monthly
            </Styled.BillingFrequencyField>
            <Styled.BillingFrequencyField
              onClick={(): void => setIsMonth(false)}
              $isActive={!isMonth}
            >
              Yearly
            </Styled.BillingFrequencyField>
          </Styled.SwitcherContainer>
        </Styled.BillingFrequencyBlock>
        <Styled.PromoCodeForm>
          <Styled.FieldLabel>Promo Code</Styled.FieldLabel>
          <Styled.PromoCodeField>
            <Styled.PromoCodeInputWrapper>
              <Styled.PromoCodeInput 
                value={promoCodeInputValue} 
                onChange={handlePromoCodeChange} 
              />
              {showValidation && (
                <Styled.ValidationIcon $isValid={isValidPromoCode.current}>
                  {isValidPromoCode.current ? (
                    <CheckCircleFilled />
                  ) : (
                    <ExclamationCircleFilled />
                  )}
                </Styled.ValidationIcon>
              )}
            </Styled.PromoCodeInputWrapper>
            <Styled.ActionButton onClick={handleApplyClick}>
              Apply
            </Styled.ActionButton>
          </Styled.PromoCodeField>
        </Styled.PromoCodeForm>
        <Styled.PaymentInfoBlock>
          <Styled.FieldLabel>Payment Info</Styled.FieldLabel>
          <Styled.PaymentInfoField>
            {!!teamCard ? (
              <>
                {teamCard.type === 'paypal' ? (
                  <Styled.CardInfo>Paypal</Styled.CardInfo>
                ) : (
                  <Styled.CardInfo>{`${getCardBrand()} ending in ${LAST_4}`}</Styled.CardInfo>
                )}
              </>
            ) : (
              <Styled.CardInfo>No saved card</Styled.CardInfo>
            )}
            <Styled.ActionButton onClick={handleEditCardClick} id='edit-credit-card-button'>
              {!!teamCard ? 'Change' : 'Add'}
            </Styled.ActionButton>
          </Styled.PaymentInfoField>
        </Styled.PaymentInfoBlock>
        {isCardFormActive && (
          <Styled.CardFormContainer>
            <TeamCardForm teamId={activeTeam!.id} plan={plan} isMonthlyType={isMonth} isCheckout />
          </Styled.CardFormContainer>
        )}
      </Styled.LeftSide>
      <Styled.RightSide>
        <Styled.PayBlock>
          <Styled.AccountPlan>
            <div>
              <div>Account Plan:</div>
              <div>{`${title} ${subtitle} (${activeTeam!.totalSeats} Seats)`}</div>
            </div>
            <span>{`$${planPrice * (activeTeam?.totalSeats || 5)}`}</span>
          </Styled.AccountPlan>
          {promoDiscount > 0 && validPromoName && (
            <Styled.PriceField>
              <div>
                <div>Promo Discount:</div>
                <div>{validPromoName}</div>
              </div>
              <span>-${promoDiscount}</span>
            </Styled.PriceField>
          )}
          <Styled.PriceField>
            <span>Tax</span>
            <span>{`$${tax}`}</span>
          </Styled.PriceField>
          <Styled.PriceField>
            <span>Total due today</span>
            <span>{`$${totalPrice}`}</span>
          </Styled.PriceField>
          <Styled.PayBlockFooter>
            <Styled.TermsConditions
              href={`${process.env.REACT_APP_URL}/terms-of-use?auth=${isAuth ? '1' : '0'}`}
              target='_blank'
              rel='noreferrer'
              id='term-of-use-button'
            >
              Terms of Use
            </Styled.TermsConditions>
            <Styled.PayButton
              onClick={handlePayButtonClick}
              disabled={isCurrentPlan || isLoading || !teamCard}
              id='pay-now-button'
            >
              Pay now
            </Styled.PayButton>
          </Styled.PayBlockFooter>
        </Styled.PayBlock>
      </Styled.RightSide>
    </Styled.ModalWindowContainer>
  );
};

export default withElements(ModalTeamCheckout);
