import React from 'react';

const EditIcon: React.FC = () => {
  return (
  <svg width="15" height="15" viewBox="0 0 15 15" fill="none" xmlns="http://www.w3.org/2000/svg">
    <path d="M6.52351 14H13.8882M12.047 3.8721L12.9676 4.79281M13.4279 1.5703C13.6093 1.75165 13.7532 1.96695 13.8513 2.20392C13.9495 2.44089 14 2.69488 14 2.95138C14 3.20788 13.9495 3.46187 13.8513 3.69883C13.7532 3.9358 13.6093 4.15111 13.4279 4.33246L4.68234 13.0793L1 14L1.92059 10.3687L10.6698 1.57398C11.0147 1.2274 11.4772 1.02317 11.9656 1.00185C12.4541 0.980534 12.9327 1.14368 13.3064 1.45889L13.4279 1.5703Z" stroke="currentColor" strokeLinecap="round" strokeLinejoin="round"/>
    </svg>
  );	
};

export default EditIcon;
