import React, { ChangeEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import * as Styled from './styles';
import { useAppDispatch } from 'shared/hooks';
import { openNotification } from 'utils/notification-utils';
import { closeConfirmModal } from 'services/store/reducers/modalReducer';
import { ESnackbarStyle } from 'shared/types';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { clearModelLocalStorage } from 'utils/storage-utils';
import { deleteAccount } from 'services/api/authService';
import { logoutUser } from 'services/store/reducers/authReducer';

const ModalDeleteAccount: React.FC = (): JSX.Element => {
  const history = useHistory();
  const dispatch = useAppDispatch();
  const DELETE_TEXT = 'DELETE';
  const [value, setValue] = useState<string>('');

  const handleInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setValue(value);
  };

  const handleDeleteButtonClick = async (): Promise<void> => {
    if (value === DELETE_TEXT) {
      try {
        dispatch(startLoader());
        await deleteAccount();
        dispatch(logoutUser());
        clearModelLocalStorage();
        dispatch(closeConfirmModal());
        openNotification(ESnackbarStyle.SUCCESS, 'Your account has been deleted');
        history.push('/');
        history.go(0);
      } catch (e) {
        openNotification(ESnackbarStyle.ERROR, e?.message);
      } finally {
        dispatch(stopLoader());
      }
    } else {
      openNotification(ESnackbarStyle.ERROR, 'Text must be "DELETE"');
    }
  };

  const handleCancelButtonClick = (): void => {
    dispatch(closeConfirmModal());
  };

  const handleEnterClick = async (event: React.KeyboardEvent<HTMLInputElement>): Promise<void> => {
    if (event.key === 'Enter') {
      dispatch(closeConfirmModal());
    }
  };

  return (
    <Styled.ModalWindowContainer id='delete-mw'>
      <Styled.Message>
        <strong>Are you sure you want to delete your account?</strong>
      </Styled.Message>
      <Styled.Message>
        Deleting will remove all the content associated with it.
      </Styled.Message>
      <Styled.Message>Confirm by typing DELETE below.</Styled.Message>
      <Styled.ConfirmationInput
        value={value}
        onChange={handleInputChange}
        onKeyDown={handleEnterClick}
      />
      <Styled.ActionButtons>
        <Styled.ActionButton
            className='delete-button'
            onClick={handleDeleteButtonClick}
          >
          Delete Forever
        </Styled.ActionButton>
        <Styled.ActionButton className='cancel-button' onClick={handleCancelButtonClick}>
          Cancel
        </Styled.ActionButton>
      </Styled.ActionButtons>
    </Styled.ModalWindowContainer>
  );
};

export default ModalDeleteAccount;
