import React, {
  ChangeEvent,
  JSX,
  SyntheticEvent,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import * as Styled from './styles';
import ExclamationCircleFilled from '@ant-design/icons/lib/icons/ExclamationCircleFilled';
import Copy from 'assets/images/copy-black.svg';
import Lock from 'assets/images/lock-icon.svg';
import Logo from 'assets/images/logo.png';
import ModelLink from 'assets/images/new-tab-icon.svg';
import Remove from 'assets/images/add-circle.svg';
import Add from 'assets/images/plus-add.svg';
import LinkIcon from 'assets/dynamic-icons/link-icon/LinkIcon';
import {
  useAppDispatch,
  useAppSelector,
  useCopyToClipboard,
  useResizeListener,
  useEmbeddedSettingsData
} from 'shared/hooks';
import { CustomTooltip, Switcher } from 'shared/components';
import { NUMBER_REGEXP, URL_PATTERN } from 'shared/constants/regexps';
import { RootState } from 'services/store';
import {
  setActiveCommentId,
  setIsCommentsVisible,
  setIsEmbedPreviewActive
} from 'services/store/reducers/commentsReducer';
import { IosShareIcon, RecenterIcon, ViewInARIcon } from 'assets/dynamic-icons';
import { CubeOrbitControllerScene, MainScene } from 'shared/webgl/scenes';
import { EFeatureId, EmbeddedModelCustomButtons, EModelType, ESnackbarStyle, EPremiumFeature } from 'shared/types';
import {
  getEmbeddedModelSettings,
  saveEmbeddedModelSettings,
  setIsEmbeddedModelSettingsActive
} from 'utils/storage-utils';
import { ErrorMessage } from 'shared/styles';
import { checkModelFeature } from 'services/api/modelService';
import { openNotification } from 'utils/notification-utils';
import { startLoader, stopLoader } from 'services/store/reducers/loaderReducer';
import { checkIsValidURL } from 'utils/helper-utils';
import { fetchModelPermissions } from 'services/store/reducers/modelPermissionsReducer';
import { DualSwitcher } from 'shared/components/dual-switcher';
import { showModal } from 'services/store/reducers/modalReducer';
import ModalFeatureSignedIn from 'shared/components/modals/modal-feature-signed-in/ModalFeatureSignedIn';

type Props = {
  mainScene: MainScene | null;
  cubeOCScene: CubeOrbitControllerScene | null;
  hasEditAccess: boolean;
};

const ModalEmbedModel: React.FC<Props> = ({
  mainScene,
  cubeOCScene,
  hasEditAccess
}): JSX.Element => {
  const dispatch = useAppDispatch();
  const sceneRef = useRef<HTMLDivElement | null>(null);
  const cubeOrbitControllerBlockRef = useRef<HTMLDivElement | null>(null);
  const initialActiveCommentId = useRef<number>(-1);
  const controller = useMemo((): AbortController => new AbortController(), []);
  const { copyToClipboard } = useCopyToClipboard();
  const { ref: sceneFooterRef, size: sceneFooterSize, targetElement } = useResizeListener();
  const signal: AbortSignal = controller.signal;
  const store = useAppSelector((store): RootState => store);
  const { model, modelType, modelName } = store.viewerData;
  const { isCommentsVisible, activeCommentId } = store.comments;
  const { isArViewShared } = store.modelPermissions;
  const { brandLogoUrl, brandColors } = store.branding.activeBranding;
  const { buttonBackgroundColor } = brandColors;
  const { ref: sceneAreaRef, size: sceneAreaSize } = useResizeListener();
  const { fetchDomainWhitelist, addWhitelistDomain, removeWhitelistDomain, domainsWhitelist } =
    useEmbeddedSettingsData(model?.shortCode || '');
  const isSampleModel = modelType === EModelType.SAMPLE;
  const EVERYONE_SYMBOL = '*';
  const INITIAL_MAX_SCENE_WIDTH = 480;
  const INITIAL_MAX_SCENE_HEIGHT = 360;
  const MIN_WIDTH_INPUT_VALUE = 360;
  const MIN_HEIGHT_INPUT_VALUE = 500;
  const INITIAL_WIDTH_INPUT_VALUE = '680';
  const INITIAL_HEIGHT_INPUT_VALUE = '510';
  const maxSceneWidth = useMemo(
    (): number =>
      sceneAreaSize.width < INITIAL_MAX_SCENE_WIDTH ? sceneAreaSize.width : INITIAL_MAX_SCENE_WIDTH,
    [sceneAreaSize]
  );
  const maxSceneHeight = useMemo(
    (): number =>
      sceneAreaSize.height < INITIAL_MAX_SCENE_HEIGHT
        ? sceneAreaSize.height
        : INITIAL_MAX_SCENE_HEIGHT,
    [sceneAreaSize]
  );
  const initialCustomButtons = {
    first: { name: '', link: '' },
    second: { name: '', link: '' },
    third: { name: '', link: '' }
  };
  const CUSTOM_BUTTONS: (keyof EmbeddedModelCustomButtons)[] = ['first', 'second', 'third'];
  const [isColumnButtonsView, setIsColumnButtonsView] = useState<boolean>(false);
  const [isArAvailable, setIsArAvailable] = useState<boolean>(isSampleModel);
  const [isCommentsAvailable, setIsCommentsAvailable] = useState<boolean>(isSampleModel);
  const [isCustomButtonsAvailable, setIsCustomButtonsAvailable] = useState<boolean>(isSampleModel);
  const [hasAllowedDomainsAccess, setIsWhiteListAvailable] = useState<boolean>(false);
  const [sceneScale, setSceneScale] = useState<number>(1);
  const [isFullHtml, setIsFullHtml] = useState<boolean>(true);
  const [isCustomize, setIsCustomize] = useState<boolean>(true);
  const [sceneWidth, setSceneWidth] = useState<number>(INITIAL_MAX_SCENE_WIDTH);
  const [sceneHeight, setSceneHeight] = useState<number>(INITIAL_MAX_SCENE_HEIGHT);
  const [widthInputValue, setWidthInputValue] = useState<string>(INITIAL_WIDTH_INPUT_VALUE);
  const [heightInputValue, setHeightInputValue] = useState<string>(INITIAL_HEIGHT_INPUT_VALUE);
  const [isWidthInputError, setIsWidthInputError] = useState<boolean>(false);
  const [isHeightInputError, setIsHeightInputError] = useState<boolean>(false);
  const [isFixedSize, setIsFixedSize] = useState<boolean>(false);
  const [isTransparent, setIsTransparent] = useState<boolean>(false);
  const [isShowComments, setIsShowComments] = useState<boolean>(false);
  const [initialCommentsVisibility, setInitialCommentsVisibility] =
    useState<boolean>(isCommentsVisible);
  const [isShowCubeOC, setIsShowCubeOC] = useState<boolean>(true);
  const [isRecenterButtonActive, setIsRecenterButtonActive] = useState<boolean>(true);
  const [isARButtonActive, setIsARButtonActive] = useState<boolean>(false);
  const [isShareButtonActive, setIsShareButtonActive] = useState<boolean>(false);
  const [isRestricted, setIsRestricted] = useState<boolean>(false);
  const [newDomainValue, setNewDomainValue] = useState<string>('');
  const [customButtons, setCustomButtons] =
    useState<EmbeddedModelCustomButtons>(initialCustomButtons);
  const [isCorrectDomain, setIsCorrectDomain] = useState(true);
  const isInitialValidation = useRef<boolean>(true);
  const isARFeatureActive = isSampleModel || (isArAvailable && isArViewShared);
  const transparent = isTransparent ? '&transparent=1' : '';
  const comments = isShowComments ? '&comments=1' : '';
  const cubeOC = isShowCubeOC ? '&cubeoc=1' : '';
  const recenter = isRecenterButtonActive ? '&recenter=1' : '';
  const share = isShareButtonActive ? '&share=1' : '';
  const ar = isARButtonActive && isARFeatureActive ? '&ar-button=1' : '';
  const { first, second, third } = customButtons;
  const firstCb =
    !!first.name && !!first.link && checkIsValidURL(first.link)
      ? `&first-cb-name=${encodeURIComponent(first.name)}&first-cb-link=${encodeURIComponent(
          first.link
        )}`
      : '';
  const secondCb =
    !!second.name && !!second.link && checkIsValidURL(second.link)
      ? `&second-cb-name=${encodeURIComponent(second.name)}&second-cb-link=${encodeURIComponent(
          second.link
        )}`
      : '';
  const thirdCb =
    !!third.name && !!third.link && checkIsValidURL(third.link)
      ? `&third-cb-name=${encodeURIComponent(third.name)}&third-cb-link=${encodeURIComponent(
          third.link
        )}`
      : '';
  const params = `embed=1${transparent}${comments}${cubeOC}${recenter}${share}${ar}${firstCb}${secondCb}${thirdCb}`;
  const sampleModelShareLink = `${process.env.REACT_APP_URL}?sample-model=${modelName}`;
  const LINK = isSampleModel ? `${sampleModelShareLink}&${params}` : `${model?.shortLinkUrl}?${params}`;
  const size = isFixedSize
    ? `width="${widthInputValue}px" height="${heightInputValue}px"`
    : 'width="100%" height="auto"';
  const style = `${isFixedSize ? '' : 'aspect-ratio: 1/1;'} ${
    isTransparent ? 'border: none' : `border: 2px solid ${buttonBackgroundColor || '#FFE406'}`
  };`;
  const allow = 'camera;gyroscope;accelerometer;magnetometer;xr-spatial-tracking;clipboard-write';
  const HTML = `<iframe title="Glbee Embedded Model" ${size} src="${LINK}" style="${style}" allow="${allow}" allowfullscreen></iframe>`;

  useEffect((): void => {
    (async (): Promise<void> => {
      if (!isSampleModel) {
        dispatch(startLoader());
        try {
          const checkFeature = async (feature: EFeatureId): Promise<boolean> =>
            (await checkModelFeature(model?.id || '', feature)).data.enabled;
          setIsArAvailable(await checkFeature(EFeatureId.WEB_AR_VIEWING_MODE));
          setIsCommentsAvailable(await checkFeature(EFeatureId.MAKE_3D_NOTES));
          setIsWhiteListAvailable(
            hasEditAccess && (await checkFeature(EFeatureId.DOMAINS_WHITELIST))
          );
          setIsCustomButtonsAvailable(await checkFeature(EFeatureId.IFRAME_CUSTOM_BUTTONS));
          const hasEveryonePoint = await fetchDomainWhitelist();
          setIsRestricted(!hasEveryonePoint);
          if (!!model) await dispatch(fetchModelPermissions({ model, signal }));
          isInitialValidation.current = false;
        } catch (e) {
          openNotification(ESnackbarStyle.ERROR, e?.message);
        } finally {
          dispatch(stopLoader());
        }
      }
    })();
  }, [dispatch, fetchDomainWhitelist, hasEditAccess, isSampleModel, model, signal]);

  useEffect((): void => {
    setIsEmbeddedModelSettingsActive(true);
    const settings = getEmbeddedModelSettings();
    if (!!settings) {
      setWidthInputValue(settings.widthInputValue);
      setHeightInputValue(settings.heightInputValue);
      setIsTransparent(settings.isTransparent);
      setIsShowComments(settings.isShowComments);
      setIsShowCubeOC(settings.isShowCubeOC);
      setIsARButtonActive(settings.isARButtonActive);
      setIsShareButtonActive(settings.isShareButtonActive);
      setIsRecenterButtonActive(settings.isRecenterButtonActive);
      setIsFixedSize(settings.isFixedSize);
      setCustomButtons(settings.customButtons);
    }
  }, []);

  useEffect((): void => {
    saveEmbeddedModelSettings({
      widthInputValue,
      heightInputValue,
      isTransparent,
      isShowComments,
      isShowCubeOC,
      isARButtonActive,
      isShareButtonActive,
      isRecenterButtonActive,
      isFixedSize,
      customButtons
    });
  }, [
    customButtons,
    heightInputValue,
    isARButtonActive,
    isFixedSize,
    isRecenterButtonActive,
    isShareButtonActive,
    isShowComments,
    isShowCubeOC,
    isTransparent,
    widthInputValue
  ]);

  useEffect((): (() => void) => {
    setIsEmbeddedModelSettingsActive(false);

    return (): void => {
      cubeOCScene?.togglePreviewScene(false);
    };
  }, [cubeOCScene]);

  useEffect((): void => {
    if (!mainScene || !sceneRef.current || !cubeOCScene || !cubeOrbitControllerBlockRef.current)
      return;

    mainScene.mountPreviewScene(sceneRef.current);
    cubeOCScene.mountPreview(cubeOrbitControllerBlockRef.current);
  }, [cubeOCScene, mainScene]);

  useEffect((): (() => void) => {
    setInitialCommentsVisibility(isCommentsVisible);
    initialActiveCommentId.current = activeCommentId;

    dispatch(setActiveCommentId(-1));
    dispatch(setIsEmbedPreviewActive(true));

    if (mainScene && sceneRef.current) {
      mainScene.setPreviewSceneActive(true);
      mainScene.mountPreviewScene(sceneRef.current);
    }

    return (): void => {
      dispatch(setIsEmbedPreviewActive(false));
      dispatch(setActiveCommentId(initialActiveCommentId.current));
      dispatch(setIsCommentsVisible(initialCommentsVisibility));

      mainScene?.setPreviewSceneActive(false);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect((): void => {
    mainScene?.enablePreviewCameraFilter(isTransparent);
  }, [isTransparent, mainScene]);

  useEffect((): void => {
    if (!mainScene) return;

    mainScene.updatePreviewSize({ width: sceneWidth, height: sceneHeight });
  }, [mainScene, sceneWidth, sceneHeight]);

  useEffect((): void => {
    const width =
      +widthInputValue < MIN_WIDTH_INPUT_VALUE ? MIN_WIDTH_INPUT_VALUE : +widthInputValue;
    const height =
      +heightInputValue < MIN_HEIGHT_INPUT_VALUE ? MIN_HEIGHT_INPUT_VALUE : +heightInputValue;
    if (width <= maxSceneWidth && height <= maxSceneHeight) {
      setSceneWidth(width);
      setSceneHeight(height);
    } else {
      const gcd = (width: number, height: number): number =>
        height ? gcd(height, width % height) : width;
      const g: number = gcd(width, height);
      const ratioWidth = width / g;
      const ratioHeight = height / g;
      const point: number =
        ratioWidth > ratioHeight
          ? +(maxSceneWidth / ratioWidth).toFixed(2)
          : +(maxSceneHeight / ratioHeight).toFixed(2);
      const sceneWidth = point * ratioWidth;
      const sceneHeight = point * ratioHeight;
      const heightFactor = maxSceneHeight / sceneHeight;
      setSceneWidth(heightFactor < 1 ? sceneWidth * heightFactor : sceneWidth);
      setSceneHeight(heightFactor < 1 ? sceneHeight * heightFactor : sceneHeight);
    }
  }, [widthInputValue, heightInputValue, maxSceneWidth, maxSceneHeight]);

  useEffect((): void => {
    const width = +widthInputValue;
    const height = +heightInputValue;
    width > maxSceneWidth || height > maxSceneHeight
      ? setSceneScale(width > height ? maxSceneWidth / width : maxSceneHeight / height)
      : setSceneScale(1);
  }, [heightInputValue, widthInputValue, maxSceneWidth, maxSceneHeight]);

  useEffect((): void => {
    const footerPadding = 20;
    if (!!targetElement) {
      const elements = Array.from(targetElement.children);
      let footerWidth = 0;
      for (const element of elements) {
        footerWidth += element.clientWidth;
      }
      setIsColumnButtonsView(footerWidth > sceneWidth - footerPadding);
    }
  }, [sceneWidth, sceneHeight, sceneFooterSize, targetElement]);

  useEffect((): void => {
    dispatch(setIsCommentsVisible(isShowComments));
    mainScene?.updatePreviewScene();

    setTimeout((): void => {
      mainScene?.updatePreviewScene();
    }, 100);
  }, [dispatch, isShowComments, mainScene]);

  useEffect((): void => {
    (async (): Promise<void> => {
      if (!isSampleModel) {
        const everyonePoint = domainsWhitelist.find(
          (domain): boolean => domain.domainName === EVERYONE_SYMBOL
        );
        if (!isInitialValidation.current) {
          if (isRestricted) {
            if (!!everyonePoint && domainsWhitelist.length > 1) {
              await removeWhitelistDomain(everyonePoint.id);
            }
          } else {
            if (!everyonePoint) {
              await addWhitelistDomain(EVERYONE_SYMBOL);
              setNewDomainValue('');
            }
          }
        }
      }
    })();
  }, [addWhitelistDomain, domainsWhitelist, isRestricted, isSampleModel, removeWhitelistDomain]);

  const handleCopyButtonClick = async (): Promise<void> => {
    await copyToClipboard(isFullHtml ? HTML : LINK, 'Successfully copied');
  };

  const toggleIsFixedSize = (isFixed: boolean): void => {
    setIsFixedSize(isFixed);
    if (!isFixed) {
      setWidthInputValue(INITIAL_HEIGHT_INPUT_VALUE);
      setHeightInputValue(INITIAL_HEIGHT_INPUT_VALUE);
    }
  };

  const toggleIsRestricted = (isRestricted: boolean): void => {
    setIsRestricted(isRestricted);
  };

  const toggleSettingsTab =
    (isCustomize: boolean): (() => void) =>
    (): void => {
      setIsCustomize(isCustomize);
    };

  const handleARModeChange = (isActive: boolean): void => {
    if (isARFeatureActive) {
      setIsARButtonActive(isActive);
    }
  };

  const handleCommentsModeChange = (isActive: boolean): void => {
    if (isCommentsAvailable) {
      setIsShowComments(isActive);
    }
  };

  const handleSceneWidthChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (NUMBER_REGEXP.test(value) && isFixedSize) {
      setWidthInputValue(value);
      setIsWidthInputError(+value < MIN_WIDTH_INPUT_VALUE);
    }
  };

  const handleSceneHeightChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    if (NUMBER_REGEXP.test(value) && isFixedSize) {
      setHeightInputValue(value);
      setIsHeightInputError(+value < MIN_HEIGHT_INPUT_VALUE);
    }
  };

  const handleWidthInputBlur = (): void => {
    if (+widthInputValue < MIN_WIDTH_INPUT_VALUE) {
      setWidthInputValue(MIN_WIDTH_INPUT_VALUE.toString());
      setIsWidthInputError(false);
    }
  };

  const handleHeightInputBlur = (): void => {
    if (+heightInputValue < MIN_HEIGHT_INPUT_VALUE) {
      setHeightInputValue(MIN_HEIGHT_INPUT_VALUE.toString());
      setIsHeightInputError(false);
    }
  };

  const handleCustomButtonNameChange =
    (button: keyof EmbeddedModelCustomButtons): ((event: ChangeEvent<HTMLInputElement>) => void) =>
    (event): void => {
      if (isCustomButtonsAvailable) {
        const { value } = event.target;
        setCustomButtons(
          (prevValue: EmbeddedModelCustomButtons): EmbeddedModelCustomButtons => ({
            ...prevValue,
            [button]: { name: value, link: prevValue[button].link }
          })
        );
      }
    };

  const handleCustomButtonLinkChange =
    (button: keyof EmbeddedModelCustomButtons): ((event: ChangeEvent<HTMLInputElement>) => void) =>
    (event): void => {
      if (isCustomButtonsAvailable) {
        const { value } = event.target;
        setCustomButtons(
          (prevValue: EmbeddedModelCustomButtons): EmbeddedModelCustomButtons => ({
            ...prevValue,
            [button]: { name: prevValue[button].name, link: value }
          })
        );
      }
    };

  const handleSiteInputChange = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setNewDomainValue(value);
    if (URL_PATTERN.test(value) || !value) setIsCorrectDomain(true);
  };

  const handleSiteInputBlur = (event: ChangeEvent<HTMLInputElement>): void => {
    const { value } = event.target;
    setIsCorrectDomain(URL_PATTERN.test(value) || !value);
    if (!value || URL_PATTERN.test(value)) {
      setNewDomainValue(value);
    }
  };

  const handleAddNewDomainClick = async (event: SyntheticEvent): Promise<void> => {
    event.preventDefault();
    if (URL_PATTERN.test(newDomainValue)) {
      await addWhitelistDomain(newDomainValue);
      setNewDomainValue('');
    }
  };

  const handleRemoveDomainClick =
    (domainId: number): (() => Promise<void>) =>
    async (): Promise<void> => {
      await removeWhitelistDomain(domainId);
    };

  const previewButtons = [
    { key: 'recenter', name: 'Recenter', icon: <RecenterIcon />, isActive: isRecenterButtonActive },
    {
      key: 'ar',
      name: 'View in AR',
      icon: <ViewInARIcon />,
      isActive: isARFeatureActive && isARButtonActive
    },
    { key: 'share', name: 'Share', icon: <IosShareIcon />, isActive: isShareButtonActive },
    {
      key: 'first-cb',
      name: customButtons.first.name,
      icon: <LinkIcon />,
      isActive:
        isCustomButtonsAvailable &&
        !!customButtons.first.name &&
        !!customButtons.first.link &&
        checkIsValidURL(customButtons.first.link)
    },
    {
      key: 'second-cb',
      name: customButtons.second.name,
      icon: <LinkIcon />,
      isActive:
        isCustomButtonsAvailable &&
        !!customButtons.second.name &&
        !!customButtons.second.link &&
        checkIsValidURL(customButtons.second.link)
    },
    {
      key: 'third-cb',
      name: customButtons.third.name,
      icon: <LinkIcon />,
      isActive:
        isCustomButtonsAvailable &&
        !!customButtons.third.name &&
        !!customButtons.third.link &&
        checkIsValidURL(customButtons.third.link)
    }
  ];

  const arTooltip = !isArAvailable
    ? 'Enabling this button requires Mesh plan or higher.'
    : !isArViewShared
    ? 'Enabling this button requires AR sharing permissions to be enabled.'
    : '';

  const WHITELIST = domainsWhitelist.filter(
    ({ domainName }): boolean => domainName !== EVERYONE_SYMBOL
  );

  const FOOTER_BUTTONS = previewButtons.filter(({ isActive }): boolean => isActive);

  const handleUpgradeClick = (feature: EPremiumFeature): void => {
    dispatch(showModal(<ModalFeatureSignedIn feature={feature} />));
  };

  return (
    <Styled.ModalWindowContainer>
      <Styled.ModalWindowContent>
        <Styled.ModalTitle>Copy the embed code below</Styled.ModalTitle>
        <Styled.ModalSubtitle>
          Add the embed code to your webpage so your model is viewable alongside your other content.
        </Styled.ModalSubtitle>
        <Styled.PermissionsAlert>
          <ExclamationCircleFilled className='info-icon' />
          <span className='permissions-alert'>
            Public viewing permissions must be enabled. For AR features, public AR viewing must be
            enabled.
          </span>
        </Styled.PermissionsAlert>
        <Styled.MainContent>
          <Styled.LeftSection>
            <Styled.CodeBlockContainer>
              <Styled.BlockTabs>
                <Styled.BlockTab $isActive={isFullHtml} onClick={(): void => setIsFullHtml(true)}>
                  Full HTML
                </Styled.BlockTab>
                <Styled.BlockTab $isActive={!isFullHtml} onClick={(): void => setIsFullHtml(false)}>
                  Link Only
                </Styled.BlockTab>
              </Styled.BlockTabs>
              <Styled.CodeBlock>
                <Styled.CopyButton onClick={handleCopyButtonClick} type='button'>
                  <img src={Copy} alt='Copy' />
                </Styled.CopyButton>
                <Styled.CodeBlockTextArea value={isFullHtml ? HTML : LINK} readOnly={true} />
              </Styled.CodeBlock>
            </Styled.CodeBlockContainer>
            <Styled.SceneArea ref={sceneAreaRef}>
              <Styled.PreviewSceneContainer $sceneWidth={sceneWidth} $sceneHeight={sceneHeight}>
                <Styled.PreviewLogoContainer $sceneScale={sceneScale}>
                  <Styled.PreviewLogo src={brandLogoUrl || Logo} $sceneScale={sceneScale} />
                  <Styled.PreviewPoweredBy $sceneScale={sceneScale}>
                    Powered by glbee
                  </Styled.PreviewPoweredBy>
                </Styled.PreviewLogoContainer>
                <Styled.ModelLinkContainer $sceneScale={sceneScale}>
                  <img src={ModelLink} alt='Model Link' />
                </Styled.ModelLinkContainer>
                <Styled.PreviewFooterContainer>
                  <Styled.PreviewFooter ref={sceneFooterRef} $isColumn={isColumnButtonsView}>
                    {FOOTER_BUTTONS.map(
                      ({ name, icon, key }): JSX.Element => (
                        <Styled.PreviewButton
                          key={key}
                          $isColumn={isColumnButtonsView}
                          $sceneScale={sceneScale}
                          type='button'
                        >
                          <Styled.IconContainer className={key} $sceneScale={sceneScale}>
                            {icon}
                          </Styled.IconContainer>
                          <span>{name}</span>
                        </Styled.PreviewButton>
                      )
                    )}
                  </Styled.PreviewFooter>
                </Styled.PreviewFooterContainer>

                <Styled.PreviewScene
                  ref={sceneRef}
                  $sceneHeight={sceneHeight}
                  $isTransparent={isTransparent}
                />

                <Styled.CubeOCContainer $isShow={isShowCubeOC} ref={cubeOrbitControllerBlockRef} />
              </Styled.PreviewSceneContainer>
            </Styled.SceneArea>
          </Styled.LeftSection>
          <Styled.RightSection>
            <Styled.BlockTabs>
              <Styled.BlockTab $isActive={isCustomize} onClick={toggleSettingsTab(true)}>
                <span>Customize</span>
              </Styled.BlockTab>
              <Styled.BlockTab $isActive={!isCustomize} onClick={toggleSettingsTab(false)}>
                <span>Allowed Domains</span>
              </Styled.BlockTab>
            </Styled.BlockTabs>
            {isCustomize ? (
              <Styled.TabContent>
                <Styled.ResolutionBlock>
                  <Styled.ResolutionSwitcher>
                    <DualSwitcher
                      leftText="Fit to Website"
                      rightText="Fixed Size"
                      isLeftActive={!isFixedSize}
                      onChange={(isLeftActive): void => toggleIsFixedSize(!isLeftActive)}
                    />
                  </Styled.ResolutionSwitcher>
                  <Styled.ResolutionField $isFixedSize={isFixedSize}>
                    <Styled.ResolutionInput
                      $isError={isWidthInputError}
                      onChange={handleSceneWidthChange}
                      onBlur={handleWidthInputBlur}
                      value={widthInputValue}
                      readOnly={!isFixedSize}
                    />
                    X
                    <Styled.ResolutionInput
                      $isError={isHeightInputError}
                      onChange={handleSceneHeightChange}
                      onBlur={handleHeightInputBlur}
                      value={heightInputValue}
                      readOnly={!isFixedSize}
                    />
                    pixels
                  </Styled.ResolutionField>
                  <Styled.ErrorMessageContainer>
                    {isWidthInputError && (
                      <ErrorMessage>{`${MIN_WIDTH_INPUT_VALUE}px is min width`}</ErrorMessage>
                    )}
                    {isHeightInputError && (
                      <ErrorMessage>{`${MIN_HEIGHT_INPUT_VALUE}px is min height`}</ErrorMessage>
                    )}
                  </Styled.ErrorMessageContainer>
                </Styled.ResolutionBlock>
                <Styled.SettingsBlock>
                  <Styled.SwitcherField>
                    <Switcher
                      action={setIsTransparent}
                      isActive={isTransparent}
                      switcherId={'transparent-background'}
                      isSmall
                    />
                    <Styled.SwitcherName>Transparent Background</Styled.SwitcherName>
                  </Styled.SwitcherField>
                  <Styled.SwitcherField>
                    <Switcher
                      action={setIsShowCubeOC}
                      isActive={isShowCubeOC}
                      switcherId={'show-cubeoc'}
                      isSmall
                    />
                    <Styled.SwitcherName>Show Cube Controller</Styled.SwitcherName>
                  </Styled.SwitcherField>
                  <Styled.SwitcherField>
                    <Switcher
                      action={handleCommentsModeChange}
                      isActive={isShowComments}
                      switcherId={'show-comments'}
                      isDisabled={!isCommentsAvailable}
                      isSmall
                    />
                    <Styled.SwitcherName $isDisabled={!isCommentsAvailable}>
                      Show Comments
                    </Styled.SwitcherName>
                    {!isCommentsAvailable && (
                      <>
                        <Styled.LockIcon>
                          <img src={Lock} alt='Locked' />
                        </Styled.LockIcon>
                        <Styled.UpgradeButton onClick={() => handleUpgradeClick(EPremiumFeature.COMMENTS)}>
                          Upgrade
                        </Styled.UpgradeButton>
                      </>
                    )}
                  </Styled.SwitcherField>
                </Styled.SettingsBlock>
                <Styled.SettingsBlock>
                  <Styled.SwitcherField>
                    <Switcher
                      action={setIsRecenterButtonActive}
                      isActive={isRecenterButtonActive}
                      switcherId={'recenter-button'}
                      isSmall
                    />
                    <Styled.SwitcherName>Recenter Button</Styled.SwitcherName>
                  </Styled.SwitcherField>
                    <Styled.SwitcherField>
                      <Switcher
                        isDisabled={!isARFeatureActive}
                        action={handleARModeChange}
                        isActive={isARButtonActive}
                        switcherId={'ar-button'}
                        isSmall
                      />
                      <Styled.SwitcherName $isDisabled={!isARFeatureActive}>
                        3D/AR Button
                      </Styled.SwitcherName>
                      {!isArAvailable && (
                        <>
                          <Styled.LockIcon>
                            <img src={Lock} alt='Locked' />
                          </Styled.LockIcon>
                          <Styled.UpgradeButton onClick={() => handleUpgradeClick(EPremiumFeature.AR)}>
                            Upgrade
                          </Styled.UpgradeButton>
                        </>
                      )}
                    </Styled.SwitcherField>
                  <Styled.SwitcherField>
                    <Switcher
                      action={setIsShareButtonActive}
                      isActive={isShareButtonActive}
                      switcherId={'share-button'}
                      isSmall
                    />
                    <Styled.SwitcherName>Share Button</Styled.SwitcherName>
                  </Styled.SwitcherField>
                </Styled.SettingsBlock>
                <CustomTooltip
                  content='Add custom links and call to actions.'
                  showTooltip={!isCustomButtonsAvailable}
                >
                  <Styled.SettingsBlock>
                    <Styled.BlockTitle>
                      <Styled.SwitcherName $isDisabled={!isCustomButtonsAvailable}>Custom Buttons</Styled.SwitcherName>
                      {!isCustomButtonsAvailable && (
                        <>
                          <Styled.LockIcon>
                            <img src={Lock} alt='Locked' />
                          </Styled.LockIcon>
                          <Styled.UpgradeButton onClick={() => handleUpgradeClick(EPremiumFeature.EMBED_OPTIONS)}>
                            Upgrade
                          </Styled.UpgradeButton>
                        </>
                      )}
                    </Styled.BlockTitle>
                    <Styled.CustomButtonsList $isActive={isCustomButtonsAvailable}>
                      {CUSTOM_BUTTONS.map(
                        (button): JSX.Element => (
                          <Styled.CustomButtonRow key={button}>
                            <Styled.CustomButtonNameInput
                              value={customButtons[button].name}
                              onChange={handleCustomButtonNameChange(button)}
                              placeholder='Button Name'
                              maxLength={16}
                              readOnly={!isCustomButtonsAvailable}
                            />
                            <Styled.CustomButtonLinkInput
                              value={customButtons[button].link}
                              onChange={handleCustomButtonLinkChange(button)}
                              $isValid={
                                !customButtons[button].link ||
                                checkIsValidURL(customButtons[button].link)
                              }
                              placeholder='Button Link'
                              readOnly={!isCustomButtonsAvailable}
                            />
                          </Styled.CustomButtonRow>
                        )
                      )}
                    </Styled.CustomButtonsList>
                  </Styled.SettingsBlock>
                </CustomTooltip>
              </Styled.TabContent>
            ) : (
              <Styled.TabContent>
                <Styled.SettingsBlock $isBlurred={!hasAllowedDomainsAccess}>
                  <Styled.LimitNotification>
                    Limit your embedded models to load only on allowed websites.
                  </Styled.LimitNotification>
                </Styled.SettingsBlock>
                <Styled.SettingsBlock $isBlurred={!hasAllowedDomainsAccess}>
                  <Styled.DomainVisibilitySwitcher>
                    <DualSwitcher
                      leftText="Everyone"
                      rightText="Restricted"
                      isLeftActive={!isRestricted}
                      onChange={(isLeftActive): void => toggleIsRestricted(!isLeftActive)}
                    />
                  </Styled.DomainVisibilitySwitcher>
                  <Styled.DomainList $isActive={isRestricted}>
                    {WHITELIST.map(
                      ({ id, domainName }): JSX.Element => (
                        <Styled.DomainRow key={id}>
                          <Styled.DomainInput value={domainName} readOnly={true} />
                          <Styled.RemoveDomainButton
                            type='button'
                            onClick={handleRemoveDomainClick(id)}
                          >
                            <img src={Remove} alt='Remove' />
                          </Styled.RemoveDomainButton>
                        </Styled.DomainRow>
                      )
                    )}
                    <Styled.AddDomainRowContainer>
                      <Styled.AddDomainRow onSubmit={handleAddNewDomainClick}>
                        <Styled.DomainInput
                          className='new-domain'
                          value={newDomainValue}
                          onChange={handleSiteInputChange}
                          onBlur={handleSiteInputBlur}
                          placeholder='https://glb.ee'
                        />
                        <Styled.AddDomainButton type='submit'>
                          <img src={Add} alt='Add' />
                        </Styled.AddDomainButton>
                      </Styled.AddDomainRow>
                      {!isCorrectDomain && <ErrorMessage>Incorrect url</ErrorMessage>}
                    </Styled.AddDomainRowContainer>
                  </Styled.DomainList>
                </Styled.SettingsBlock>
                {!hasAllowedDomainsAccess && (
                  <CustomTooltip
                    content={'Allow only select websites to embed your model.'}
                    showTooltip={!hasAllowedDomainsAccess}
                  >
                    <Styled.UpgradeOverlay>
                      <Styled.UpgradeButton onClick={() => handleUpgradeClick(EPremiumFeature.EMBED_OPTIONS)}>
                        Upgrade to Unlock
                      </Styled.UpgradeButton>
                    </Styled.UpgradeOverlay>
                  </CustomTooltip>
                )}
              </Styled.TabContent>
            )}
          </Styled.RightSection>
        </Styled.MainContent>
      </Styled.ModalWindowContent>
    </Styled.ModalWindowContainer>
  );
};

export default ModalEmbedModel;
