import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
  padding: 20px;
`;

export const ModelDescriptionTitle = styled.span`
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${COLORS.white};
  user-select: none;
`;

export const AnalyzeContainer = styled.div`
  margin-top: 10px;
  background-color: ${COLORS.deepBlack};
  border-radius: 4px;
  padding: 10px;
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.2);
`;
