import React, { useState, useCallback, useEffect } from 'react';
import { useInView } from 'react-intersection-observer';
import { ModelCard } from 'shared/components';
import { Model } from 'shared/types';
import spinner from 'assets/images/spinner.svg';
import * as Styled from './styles';

type Props = {
  initialModels: Model[];
  fetchMoreModels?: () => Promise<Model[]>;
  showMyModelsLabel?: boolean;
  isTeamPage?: boolean;
};

const ModelList: React.FC<Props> = ({
  initialModels,
  fetchMoreModels,
  showMyModelsLabel,
  isTeamPage,
}): JSX.Element | null => {
  const [models, setModels] = useState(initialModels);
  const [isStatsActive, setIsStatsActive] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  
  const { ref: spinnerRef, inView } = useInView({ rootMargin: '100px' });

  useEffect(() => {
    if(!fetchMoreModels) {
      setModels(initialModels);
    }
  }, [initialModels]);

  const handleStatsToggle = () => {
    setIsStatsActive(prev => !prev);
  };

  const removeModelCard = useCallback((modelId: string) => {
    setModels(prevModels => prevModels.filter(model => model.id !== modelId));
  }, []);

  const loadMore = useCallback(async () => {
    if (!hasMore || !fetchMoreModels || isLoading) return;
    
    setIsLoading(true);
    try {
      const newItems = await fetchMoreModels();
      if (!newItems?.length) {
        setHasMore(false);
      } else {
        setModels(prev => [...prev, ...newItems]);
      }
    } finally {
      setIsLoading(false);
    }
  }, [fetchMoreModels, hasMore, isLoading]);

  // Keep re-calling loadMore() until items fill the screen or there are no more items to load.
  useEffect(() => {
    if (inView) {
      loadMore();
    }
  }, [inView]);

  if (!models.length) return <Styled.NoDataBlock>No Models Found</Styled.NoDataBlock>;

  return (
    <Styled.ModelsListContainer>
      <div className="model-cards-grid">
        {models.map(model => (
          <ModelCard
            key={model.id}
            item={model}
            showMyModelsLabel={showMyModelsLabel}
            isTeamPage={isTeamPage}
            isStatsActive={isStatsActive}
            onStatsToggle={handleStatsToggle}
            onDelete={removeModelCard}
          />
        ))}
      </div>
      {fetchMoreModels && hasMore && (
        <div ref={spinnerRef} style={{ display: 'flex', justifyContent: 'center', padding: '20px' }}>
          {isLoading && <img src={spinner} alt="Loading..." width={50} height={50} />}
        </div>
      )}
    </Styled.ModelsListContainer>
  );
};

export default ModelList; 