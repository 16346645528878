import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';
import { FONTS } from 'shared/constants/fonts';
import { DEFAULT_THEME_CSS_VARIABLES } from 'shared/constants/customization';
import { DEVICE } from 'shared/constants/deviceSizes';

export const FullModeContent = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  padding: 20px;
  background: rgba(83, 83, 83, 0.4);
`;

export const SettingsOptionContainer = styled.div`
  display: flex;
  flex-direction: column;

  &:not(:first-child) {
    margin-top: 20px;
  }
`;

export const SettingsOption = styled.div`
  margin-top: 10px;
`;

export const FieldLabel = styled.span`
  font-family: ${FONTS.interRegular};
  font-weight: 400;
  font-size: 14px;
  line-height: 17px;
  color: ${COLORS.white};
  display: inline-block;
  user-select: none;
`;

export const SwitchersContainer = styled.div`
  margin-top: 10px;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
`;

export const SwitchBlock = styled.div`
  margin-top: 10px;
  display: flex;
  flex-direction: column;
  min-width: 95px;
`;

export const AnimationsBlock = styled.div`
  margin-top: 20px;
  display: flex;
  flex-direction: column;
`;

export const ModelInfoContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  color: ${COLORS.white};
`;

export const InputContainer = styled.div`
  margin-top: 10px;
  width: 100%;
  height: 50px;
`;

export const ModelNameInput = styled.input<{ readOnly?: boolean }>`
  width: 100%;
  height: 42px;
  padding: 5px 10px;
  background-color: ${DEFAULT_THEME_CSS_VARIABLES.inputBackgroundSecondaryColor};
  border: 1px solid ${COLORS.mineShaft};
  border-radius: 4px;
  color: ${COLORS.white};
  font-size: 14px;
  font-family: ${FONTS.interRegular};

  &:focus {
    border: 1px solid ${COLORS.mischka};
  }

  ${({ readOnly }) => readOnly && `
    color: ${COLORS.doveGray};
    cursor: default;
    user-select: none;
    
    &:focus {
      outline: none;
    }
  `}
`;

export const ActionButton = styled.button<{ $activeColor: string; $activeTextColor: string }>`
  margin-top: 5px;
  width: 100%;
  padding: 10px;
  color: ${COLORS.white};
  background: ${COLORS.black};
  border: none;
  border-radius: 4px;
  font-size: 14px;
  font-family: ${FONTS.interRegular};
  transition: all 0.2s;
  opacity: 0.85;

  &:last-child {
    margin-top: 15px;
  }

  &.download {
    color: ${({ $activeTextColor }): string => $activeTextColor || COLORS.black};
    background: ${({ $activeColor }): string => $activeColor || COLORS.turbo};
  }

  &.delete:hover {
    font-weight: 800;
    background: ${COLORS.red};
    color: ${COLORS.black};
    opacity: 1;
  }

  &:hover {
    background: ${({ $activeColor }): string => $activeColor || COLORS.turbo};
    color: ${({ $activeTextColor }): string => $activeTextColor || COLORS.black};
    opacity: 1;
  }
`;

export const SetDefaultViewContainer = styled.div`
  width: 100%;
  height: 40px;
  display: flex;
  align-items: center;
  margin: 0;
`;

export const DefaultViewButton = styled.button<{
  $leftOrRight: 'left' | 'right';
  $activeColor: string | undefined;
  $activeTextColor: string;
}>`
  width: ${({ $leftOrRight: leftOrRight }): string => leftOrRight === 'left' ? '85%' : '15%'};
  height: 40px;
  background: ${({ $leftOrRight: leftOrRight }): string =>
    leftOrRight === 'left' ? COLORS.deepBlack : 'transparent'};
  color: ${COLORS.white};
  border: none;
  border-radius: 4px;
  transition: all 0.2s;
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    stroke: ${COLORS.white};
    margin: 5px;
    width: 20px;
    height: 20px;
  }

  &:hover {
    background: ${({ $leftOrRight, $activeColor }): string =>
      $leftOrRight === 'left' ? ($activeColor || COLORS.turbo) : 'transparent'};
    color: ${({ $leftOrRight, $activeTextColor, $activeColor }): string =>
      $leftOrRight === 'left' ? ($activeTextColor || COLORS.black) : $activeColor || COLORS.turbo};
    svg {
      stroke: ${({ $leftOrRight, $activeTextColor, $activeColor }): string =>
        $leftOrRight === 'left' ? ($activeTextColor || COLORS.black) : $activeColor || COLORS.turbo};
    }
  }
`;

export const AutoSavingText = styled.span`
  color: ${COLORS.white};
  font-weight: 500;
  font-size: 14px;
  line-height: 17px;
  text-align: center;
  margin-top: 20px;
  margin-bottom: 10px;
`;

export const SaveModelBlockContainer = styled.div`
  margin-top: 5px;
`;

export const CopyrightContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 10px;
  padding-top: 24px;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  color: rgba(255, 255, 255, 0.5);
  font-size: 12px;
  line-height: 1.5;
  text-align: center;
  
  span {
    display: block;
  }

  @media ${DEVICE.tablet} {
    margin-bottom: 20px;
  }
`; 