import { EQuotaName, PlanLimit, SubscriptionProduct } from 'shared/types';
import { CUSTOM_PLAN } from 'shared/constants/subscription-plans';
import { MEGABYTE } from 'shared/constants/limits';
import { CATEGORY_FEATURES, SPOTLIGHT_TITLE_OVERRIDES } from 'shared/constants/subscription-features';
import { EFeatureCategory, EFeatureId } from 'shared/types/enums';

type ModifyPlanResult = {
  id: string;
  title: string;
  subtitle: string;
  monthPrice: {
    original: number;
    actual: number;
  };
  yearPrice: {
    original: number;
    actual: number;
  };
  categorizedFeatures: Record<EFeatureCategory, { title: string; value: boolean | number | string }[]>;
  description: string;
  icon: string;
  isMostPopular: boolean;
  isCustomPlan: boolean;
};

const ONE_USD = 100;

const calculatePrice = (
  prices: SubscriptionProduct['prices'],
  type: 'month' | 'year', seats: number
): ModifyPlanResult['monthPrice'] | ModifyPlanResult['yearPrice'] => {
  const priceObj = prices.find((i): boolean => i.billing_scheme === 'tiered' && i.recurring.interval === type);
  if (!priceObj) {
    return { actual: 0, original: 0 };
  }

  let infiniteTier = null; 
  let seatTier = null;
  let originalTier = null;
  for (const tier of priceObj.tiers) {
    if (!tier.up_to) {
      infiniteTier = tier;  // Only one infinite tier is possible
      continue;
    }
    
    if (seats <= tier.up_to && (!seatTier || tier.up_to < seatTier.up_to)) {
      seatTier = tier;
    }
    
    if (!originalTier || tier.up_to < originalTier.up_to) {
      originalTier = tier;
    }
  }

  return {
    original: (originalTier?.unit_amount || infiniteTier?.unit_amount || 0) / ONE_USD,
    actual: (seatTier?.unit_amount || infiniteTier?.unit_amount || 0) / ONE_USD
  };
}

export const extractPlanData = (
  plan?: SubscriptionProduct,
  seats: number = 1
): ModifyPlanResult => {
  if (!plan) {
    return {
      id: (Date.now() * Math.random()).toString(),
      ...CUSTOM_PLAN,
      isCustomPlan: true
    };
  }

  const { id, name, prices, metadata, description, images } = plan;
  const icon = images[0];
  const titleArr = name.split('/');
  const title = titleArr[0];
  const subtitle = titleArr[1];
  const monthPrice = calculatePrice(prices, 'month', seats);
  const yearPrice = calculatePrice(prices, 'year', seats);

  const limitsMap = metadata.product_limits.reduce((acc, { id, title, value }) => {
    acc[id as EQuotaName] = {
      title,
      value: id === EQuotaName.IFRAME_CUSTOM_BUTTONS ? value !== 0 : value
    };
    return acc;
  }, {} as Record<EQuotaName, { title: string; value: number | string | boolean }>);
  
  const featuresMap = metadata.product_features.reduce((acc, { id, title, enabled }) => {
    acc[id as EFeatureId] = { title, value: enabled };
    return acc;
  }, {} as Record<EFeatureId, { title: string; value: boolean }>);
    
  const categorizedFeatures = Object.entries(CATEGORY_FEATURES).reduce((acc, [category, featureIds]) => {
    acc[category as EFeatureCategory] = featureIds.map((featureId) => {
      let featureData = featureId in limitsMap
        ? limitsMap[featureId as EQuotaName]
        : featuresMap[featureId as EFeatureId];
      if (category === EFeatureCategory.SPOTLIGHT && featureId in SPOTLIGHT_TITLE_OVERRIDES)
        featureData = { ...featureData, title: SPOTLIGHT_TITLE_OVERRIDES[featureId] || featureData.title };
      return featureData;
    });
    return acc;
  }, {} as Record<EFeatureCategory, { title: string; value: boolean | number | string }[]>);

  const isMostPopular = !!plan.metadata.most_popular;

  return {
    id,
    title,
    subtitle,
    monthPrice,
    yearPrice,
    categorizedFeatures,
    description,
    icon,
    isMostPopular,
    isCustomPlan: false
  };
};

export const getSubscriptionLimits = (
  plan: SubscriptionProduct
): { modelSizePlanLimit: number; modelsPlanLimit: number } => {
  const findLimit = (limitId: EQuotaName): PlanLimit =>
    plan.metadata.product_limits.find(({ id }): boolean => id === limitId)!;
  const sizeLimit = findLimit(EQuotaName.MODEL_SIZE_LIMIT).value;
  const modelsLimit = findLimit(EQuotaName.ACTIVE_MODELS_LIMIT).value;
  const sizeInBytes: number = !!sizeLimit ? +`${sizeLimit}`.replace('MB', '') * MEGABYTE : 0;
  return { modelSizePlanLimit: sizeInBytes, modelsPlanLimit: +modelsLimit };
};
