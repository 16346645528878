import moment from 'moment-timezone';
import React from 'react';
import { ScheduledMaintenance } from './styles';

const ScheduledMaintenancePanel = (): React.ReactElement => {
  const maintenanceDurationHr = 48;
  const timeISO = '2024-05-04T06:00:00.000Z'
  const localTime = moment(timeISO).format('dddd, Do MMMM YYYY @ ha');
  const localTimezone = moment().tz(moment.tz.guess()).zoneAbbr();

  return (
    <ScheduledMaintenance>
      <span className='bold-text'>
        {`Scheduled Maintenance, ${localTime} ${localTimezone}:`}
      </span>
      <span>
        {`Please note that there will be a platform outage for approximately ${maintenanceDurationHr} hours due to
            platform upgrades.`}
      </span>
    </ScheduledMaintenance>
  );
};

export default ScheduledMaintenancePanel;
