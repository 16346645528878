import styled from 'styled-components';
import { COLORS } from 'shared/constants/colors';

export const DualSwitcherContainer = styled.div`
  display: inline-flex;
  align-items: center;
  gap: 8px;
  overflow: hidden;
`;

export const SwitcherOption = styled.div<{ $isActive: boolean }>`
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 1;
  font-weight: 600;
  font-size: 12px;
  line-height: 20px;
  color: ${({ $isActive }): string => ($isActive ? 'var(--button-background-color)' : COLORS.mischka)};
  cursor: pointer;

  &:hover {
    color: var(--button-background-color);
  }
`;

export const SwitchKnob = styled.div<{ $isLeftActive: boolean }>`
  width: 24px;
  height: 24px;
  background: ${COLORS.galleryGradient};
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5), -2px 0 10px rgba(0, 0, 0, 0.5), 2px 0 10px rgba(0, 0, 0, 0.5);
  border-radius: 50%;
  position: absolute;
  top: 50%;
  transform: translateY(-50%) ${({ $isLeftActive }): string => 
    $isLeftActive ? 'translateX(2px)' : 'translateX(28px)'};
  transition: all 0.3s ease;
`;

export const SwitchTrack = styled.div<{ $isLeftActive: boolean }>`
  width: 56px;
  height: 28px;
  background: var(--button-background-color);
  border-radius: 50px;
  position: relative;
  padding: 1px;
  cursor: pointer;
  transition: opacity 0.3s ease;

  &:hover {
    ${SwitchKnob} {
      transform: translateY(-50%) ${({ $isLeftActive }): string => 
        $isLeftActive ? 'translateX(2px) scale(1.1)' : 'translateX(28px) scale(1.1)'};
    }
  }
`;
